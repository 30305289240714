import {
  faCompressAlt,
  faFilter as faFilterSolid,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useContext, useMemo, useState } from "react";
import styles from "./FilterList.module.css";
import classnames from "classnames";
import Button from "../Button/Button";
import { GridContext } from "../Grid/GridHelper";
import { useGridCustomFilter } from "../Grid/GridCustomFilterProvider";

export const FilterListContext = React.createContext();

const FilterListProvider = ({ children, defautlOpenState }) => {
  const [isOpen, setIsOpen] = useState(defautlOpenState || true);
  const [isHidden, setIsHidden] = useState(false);
  const value = useMemo(() => {
    return { isOpen, setIsOpen, isHidden, setIsHidden };
  }, [isHidden, isOpen]);

  return (
    <FilterListContext.Provider value={value}>
      {children}
    </FilterListContext.Provider>
  );
};

export const FilterListToggler = ({ className }) => {
  const { settings, updateSettings, filterStateSetterRef } = useContext(
    GridContext
  );
  const { isCustomFilterOpen, isCustomFilterEnabled } = settings;
  const customFilter = useGridCustomFilter();
  const handleToggle = useCallback(() => {
    if (filterStateSetterRef.current) filterStateSetterRef.current();
    updateSettings((s) => ({
      isCustomFilterEnabled: !s.isCustomFilterEnabled,
    }));
  }, [filterStateSetterRef, updateSettings]);
  const handleToggleHidden = useCallback(() => {
    updateSettings((s) => ({ isCustomFilterOpen: !s.isCustomFilterOpen }));
  }, [updateSettings]);

  if (customFilter) return null;

  if (isCustomFilterEnabled) {
    return (
      <div
        className={classnames(
          "d-flex align-items-center",
          className,
          styles.buttonContainer
        )}
      >
        <Button
          onClick={handleToggle}
          className={classnames(styles.FirstButton, {
            [styles.active]: isCustomFilterEnabled,
            [styles.inactive]: !isCustomFilterEnabled,
          })}
          vType="none"
        >
          <FontAwesomeIcon icon={faFilterSolid} />
        </Button>
        <Button
          onClick={handleToggleHidden}
          className={classnames(styles.LastButton, {
            [styles.active]: !isCustomFilterOpen,
            [styles.inactive]: isCustomFilterOpen,
          })}
          vType="none"
        >
          <FontAwesomeIcon size="lg" icon={faCompressAlt} />
        </Button>
      </div>
    );
  } else
    return (
      <Button
        onClick={handleToggle}
        vType="none"
        style={{ width: 48 }}
        className={classnames(className, "px-0", styles.buttonContainer, {
          [styles.active]: isCustomFilterEnabled,
          [styles.inactive]: !isCustomFilterEnabled,
        })}
      >
        <FontAwesomeIcon size="lg" icon={faFilterSolid} />
      </Button>
    );
};

export default FilterListProvider;
