
import createAutoCompleteInput from './AutoCompleteInput';

export const ProductsNameInput = createAutoCompleteInput("articles/query");

export const ProductsPackInput = createAutoCompleteInput("packs/query")

export const ProductsRangeInput = createAutoCompleteInput("ranges/query")

export const ProductsQuantitiesInput = createAutoCompleteInput("quantities/query")

export const ProductsUnitsInput = createAutoCompleteInput("units/query");

export const ProductsProductionType = createAutoCompleteInput("productionTypes/query");


export const NameInput = createAutoCompleteInput("leaflets/names/query");

export const RegionInput = createAutoCompleteInput("regions/query");

export const SizesInput = createAutoCompleteInput("leaflets/sizeNames/query");

export const LeafletTypeInput = createAutoCompleteInput("leaflets/types/query");

export const MediaTypeInput = createAutoCompleteInput("media/query");

export const PromotionArticlePriceInput = createAutoCompleteInput("promotions/prices/query");

export const PromotionPageInput = createAutoCompleteInput("promotions/pages/query");

