import { useState, useCallback } from "react";

export const roleTypes = {
  admin: "Admin",
  backofficeAll: "BackOfficeAll",
  BackOfficePartial: "BackOfficePartial",
  client: "Client",
};

export const buildUserDefaultRoles = (user) => {
  const State = {
    admin: false,
    backofficeAll: false,
    backofficePartial: false,
    client: false,
  };
  if (!user || !user.Roles) return State;
  else {
    const { Roles } = user;
    for (const e of Roles) {
      switch (e.Name) {
        case roleTypes.admin:
          State.admin = true;
          break;
        case roleTypes.backofficeAll:
          State.backofficeAll = true;
          break;
        case roleTypes.BackOfficePartial:
          State.backofficePartial = true;
          break;
        case roleTypes.client:
          State.client = true;
          break;
        default:
          break;
      }
    }
  }
  return State;
};

export const useRolesState = (user) => {
  const [state, setState] = useState(() => buildUserDefaultRoles(user));

  const handleChange = useCallback((e) => {

    const { name, checked } = e.target;
    setState((f) => {
      return {
        ...f,
        [name]: checked,
      };
    });
  }, []);

  return [state, handleChange];
};

export const getRolesFromState = (state) => {
  const Roles = [];

  const { admin, backofficeAll, backofficePartial, client } = state;
  if (admin) Roles.push(roleTypes.admin);
  if (backofficeAll) Roles.push(roleTypes.backofficeAll);
  if (backofficePartial) Roles.push(roleTypes.BackOfficePartial);
  if (client) Roles.push(roleTypes.client);

  return Roles;
};
