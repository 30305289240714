import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import CompanyType from "./CompanieTypes/CompanyTypes";
import Companies from "./Companies/Companies";
import BrandsMain from "./BrandsMain/BrandsMain";
import Brands from "./Brands/Brands";

const BrandsRouter = () => {
  return (
    <Switch>
      <Route path="/brands/companyTypes">
        <CompanyType />
      </Route>
      <Route path="/brands/companies">
        <Companies />
      </Route>
      <Route path="/brands/main">
		  <BrandsMain/>
	  </Route>
      <Route exact path="/brands">
		  <Brands/>
	  </Route>
      <Redirect to="/brands" />
    </Switch>
  );
};

export default BrandsRouter;
