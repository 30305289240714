import React, { useState, useRef, useContext } from "react";
import BaseGrid from "../Common/Grid/Grid";
import DatePicker from "../Common/DatePicker/DatePicker";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import moment from "moment";
import classnames from "classnames";
import { getDate } from "../Helpers/DateHelper";
import { faEye, faPencil, faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DeleteModal from "../Common/DeleteModalContent/DeleteModalContent";
import { InsertDateFilterCell } from "../Leaflets/LeafletsGrid";
import { formatPrice, getGridItemValue } from "../Helpers/MiscHelper";
import { UserRoleContext } from "../App";
import { getHighestPermission, RolesEnum } from "../AppHelper";
import {
  FilterOperatorEnum,
  FilterTypeEnum
} from "../Common/FilterList/FilterListTypings";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { usePageType } from "./PromotionsHelper";
import { useGridCustomFilter } from "../Common/Grid/GridCustomFilterProvider";

const dateCell = ({ dataItem, field }) => {
  const value = dataItem[field];
  return (
    <td>{value === null ? "" : new moment(value).format("DD/MM/YYYY")}</td>
  );
};

const DifdateCell = ({ dataItem, field }) => {
  const fieldArr = field.split(".");
  let singleField = dataItem;
  for (const e of fieldArr) {
    singleField = singleField[e];
  }

  const value = parseInt(singleField);

  return (
    <td>
      {value === null ? "" : new moment(getDate(value)).format("DD/MM/YYYY")}
    </td>
  );
};

const PageTypeCell = ({ dataItem, field }) => {
  const pageType = usePageType(
    dataItem.LeafleatData.TotalPage,
    dataItem.PromotionPage
  );

  return <td>{pageType}</td>;
};

const priceCell = ({ dataItem, field }) => {
  const value = getGridItemValue(dataItem, field) || null;
  return <td>{value === null ? "" : `${formatPrice(parseInt(value))} €`} </td>;
};

export const DateFilterCell = ({ onChange, ...rest }) => {
  const DatePickerRef = useRef();
  const [Operator, setOperator] = useState({
    value: "eqDate",
    Text: "É igual a"
  });
  const Operators = [
    { value: "eqDate", Text: "É igual a" },
    { value: "neqDate", Text: "Não é igual a" },
    { value: "ltDate", Text: "É menor que" },
    { value: "gtDate", Text: "É maior que" },
    { value: "lteDate", Text: "É menor ou igual que" },
    { value: "gteDate", Text: "É maior ou igual que" }
  ];

  const focusedItemIndex = (data, inputText, textField) => {
    return Operators.findIndex((e) => {
      return e.value === Operator.value;
    });
  };

  const handleChange = (event) => {
    onChange({
      value: event.target.value,
      operator: Operator.value,
      syntheticEvent: event.syntheticEvent
    });
  };

  const HandleOperatorChange = (event) => {
    if (DatePickerRef.current.value) {
      onChange({
        value: DatePickerRef.current.value,
        operator: event.value.value,
        syntheticEvent: event.syntheticEvent
      });
    }
    setOperator(event.value);
  };

  const onClearButtonClick = (event) => {
    event.preventDefault();
    onChange({
      value: null,
      operator: "",
      syntheticEvent: event
    });
  };

  return (
    <div className="k-filtercell">
      <div className="k-filtercell-wrapper">
        <DatePicker
          format="dd-MM-yyyy"
          ref={DatePickerRef}
          className="flex-1"
          {...rest}
          onChange={handleChange}
        />
        <DropDownList
          focusedItemIndex={focusedItemIndex}
          style={{ width: 32 }}
          popupSettings={{ width: "" }}
          iconClassName="k-i-filter k-icon"
          className="k-dropdown-operator ml-2"
          data={Operators}
          textField={"Text"}
          onChange={HandleOperatorChange}
          value={Operator}
        />
        <button
          className={classnames("k-button k-button-icon", {
            "k-clear-button-visible": Boolean(rest.value),
            "k-clear-button-hidden": !Boolean(rest.value)
          })}
          title="Clear"
          disabled={!Boolean(rest.value)}
          onClick={onClearButtonClick}
        >
          <span className="k-icon k-i-filter-clear" />
        </button>
      </div>
    </div>
  );
};
const ButtonCell = ({ dataItem }) => {
  const [toggleRemove] = useContext(ToggleEditionModalsContext);

  const history = useHistory();
  const value = dataItem["id"];

  const openForm = () => {
    history.push(`leaflets/${dataItem.LeafleatDataId}/promotions/${value}`);
  };

  const handleRemove = () => {
    toggleRemove(dataItem);
  };

  const Roles = useContext(UserRoleContext);
  const Permission = getHighestPermission(Roles);

  const customFilter = useGridCustomFilter();

  const detailsFilter = customFilter ? `?customFilter=${customFilter}` : "";

  return (
    <td>
      <div className="d-flex align-items-center">
        <Link
          to={`/promotions/${dataItem.id}/details${detailsFilter}`}
          className="p-2 grid-button"
        >
          <FontAwesomeIcon size="sm" icon={faEye} title="Ver" />
        </Link>
        {Permission >= RolesEnum.BackTwo && (
          <div onClick={openForm} className="p-2 mr-2 grid-button">
            <FontAwesomeIcon size="sm" icon={faPencil} title="Editar" />
          </div>
        )}
        {Permission >= RolesEnum.BackTwo && (
          <div onClick={handleRemove} className="p-2 grid-button">
            <FontAwesomeIcon size="sm" icon={faTrashAlt} title="Eliminar" />
          </div>
        )}
      </div>
    </td>
  );
};

const ToggleEditionModalsContext = React.createContext();

const columns = [
  {
    title: "",
    minResizableWidth: 90,
    field: "Button",
    headerClassName: "hide-grid-title",
    filterable: false,
    sortable: false,
    width: 90,
    cell: ButtonCell,
    show: true,
    disableCustomFilter: true /*custom filter */
  },
  {
    field: "LeafleatData.MediaName.Name",
    title: "Fonte",
    // disableCustomFilter: true, //custom filter
    queryUrl: "media/query" /*custom filter */
  },
  //{ field: "Id", title: "Id", filterable: false },
  {
    field: "ArticleName.Name",
    title: "Nome Promoção",
    queryUrl: "articleNames/query" /*custom filter */
  },
  {
    field:
      "ArticleData.SubCategoryName.CategoryName.MainCategoryName.MainSectorName.Name",
    title: "Sector",
    queryUrl: "mainSectors/query" /*custom filter */,
    dependents: [
      "ArticleData.SubCategoryName.MainCategoryName.Name",
      "ArticleData.SubCategoryName.CategoryName.Name",
      "ArticleData.SubCategoryName.Name"
    ]
  },
  {
    field: "LeafleatData.ShopName.Name",
    title: "Insígnia",
    queryUrl: "shops/query" /*custom filter */
  },
  {
    field: "ArticleData.SubCategoryName.CategoryName.MainCategoryName.Name",
    title: "Categoria principal",
    queryUrl: "categories/main/query" /*custom filter */,
    dependents: [
      "ArticleData.SubCategoryName.CategoryName.Name",
      "ArticleData.SubCategoryName.Name"
    ]
  },
  {
    field: "ArticleData.SubCategoryName.CategoryName.Name",
    title: "Categoria",
    queryUrl: "categories/query" /*custom filter */,
    dependents: ["ArticleData.SubCategoryName.Name"],
    filterDependencies: [
      {
        field: "ArticleData.SubCategoryName.CategoryName.MainCategoryName.Name",
        queryName: "mainCategories"
      }
    ]
  },
  {
    field: "ArticleData.SubCategoryName.Name",
    title: "Subcategoria",
    queryUrl: "categories/sub/query" /*custom filter */,
    filterDependencies: [
      {
        field: "ArticleData.SubCategoryName.CategoryName.MainCategoryName.Name",
        queryName: "mainCategories"
      },
      {
        field: "ArticleData.SubCategoryName.CategoryName.Name",
        queryName: "categories"
      }
    ]
  },
  {
    field: "ArticleData.BrandName.MainBrandName.CompanyName.Name",
    title: "Empresa",
    queryUrl: "companies/query" /*custom filter */,
    dependents: ["ArticleData.BrandName.MainBrandName.Name"]
  },
  {
    field: "ArticleData.BrandName.MainBrandName.Name",
    title: "Marca Principal",
    queryUrl: "brands/main/query" /*custom filter */,
    dependents: ["ArticleData.BrandName.Name"]
  },
  {
    field: "ArticleData.BrandName.Name",
    title: "Marca",
    queryUrl: "brands/query" /*custom filter */,
    filterDependencies: [
      {
        field: "ArticleData.BrandName.MainBrandName.Name",
        queryName: "mainBrands"
      }
    ]
  },
  {
    field: "LeafleatData.LeafleatName.Name",
    title: "Folheto",
    queryUrl: "leaflets/names/query" /*custom filter*/
  },
  {
    field: "LeafleatData.DateBegin.Name",
    title: "Data de Inicio",
    filter: "date",
    width: 300,
    filterCell: DateFilterCell,
    cell: DifdateCell,
    filterType: FilterTypeEnum.date, //custom filter
    dateOperator: FilterOperatorEnum.GreaterOrEqual //custom filter
  },
  {
    field: "LeafleatData.DateEnd.Name",
    title: "Data de Fim",
    filter: "date",
    width: 300,
    filterCell: DateFilterCell,
    cell: DifdateCell,
    filterType: FilterTypeEnum.date, //custom filter
    dateOperator: FilterOperatorEnum.LesserOrEqual //custom filter
  },

  {
    field: "ArticleData.Article.Name",
    title: "Artigo",
    queryUrl: "articles/names/query" /*custom filter */
  },

  {
    field: "PromotionPage.Name",
    title: "Página",
    disableCustomFilter: true /*custom filter */
  },
  {
    field: "PromotionPage.Name",
    title: "Tipo Página",
    disableCustomFilter: true /*custom filter */,
    filterable: false,
    cell: PageTypeCell
  },
  {
    field: "PromotionTypePromotion.PromotionType.Name",
    title: "Tipo de Promoção",
    queryUrl: "promotions/types/query" /*custom filter */
  },
  {
    field: "PromotionTypePromotion.Promotion.Name",
    title: "Promoção",
    queryUrl: "promotions/query" /*custom filter */
  },
  // { field: "Region", title: "Região" },
  // { field: "Shop.Name", title: "Loja" },
  {
    field: "PriceBeforePromotion.Name",
    filterable: false,
    title: "Preço",
    cell: priceCell,
    filterType: FilterTypeEnum.price /*custom filter */
  },
  {
    field: "PromotionPrice.Name",
    filterable: false,
    title: "Preço de Promoção",
    cell: priceCell,
    filterType: FilterTypeEnum.price /*custom filter */
  },
  {
    width: 200,
    field: "InsertDate",
    filter: "date",
    filterCell: InsertDateFilterCell,
    title: "Data de Inserção",
    cell: dateCell,
    disableForClients: true,
    disableCustomFilter: true /*custom filter */
  }
];

const PromotionsGrid = () => {
  const SelectedCARef = useRef();

  const [deletingComercialArea, setDeletingComercialArea] = useState(false);

  const toggleDeleteModal = (data) => {
    if (!deletingComercialArea) {
      SelectedCARef.current = data;
    } else {
      SelectedCARef.current = undefined;
    }
    setDeletingComercialArea((open) => !open);
  };

  return (
    <ToggleEditionModalsContext.Provider value={[toggleDeleteModal]}>
      <DeleteModal
        isOpen={deletingComercialArea}
        onClose={toggleDeleteModal}
        data={SelectedCARef.current}
        closeModal={toggleDeleteModal}
        url="promotions/data"
      />
      <BaseGrid Columns={columns} BaseUrl={"promotions/data"} />
    </ToggleEditionModalsContext.Provider>
  );
};

export default PromotionsGrid;
