import React, {
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
  useCallback,
  useMemo,
  useContext
} from "react";
import LoaderSpinner from "../LoaderSpinner/LoaderSpinner";
import pdfjsLib from "pdfjs-dist/webpack";
import classnames from "classnames";
import ReactCrop from "react-image-crop";
// import PdfViewer from '../pdfjs/web/viewer.html'
import "pdfjs-dist/web/pdf_viewer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faChevronLeft,
  faChevronRight
} from "@fortawesome/pro-solid-svg-icons";
import { faMinus, faPlus } from "@fortawesome/pro-light-svg-icons";
import { PromotionLeafletContext } from "../../App";
import GrabToPan from "../PdfDragger/grab-to-pan";
import Percentage from "../PercentageLoader/Percentage";

const usePage = (fileUrl, pageNumber = 1, LeafletId) => {
  const [pdfPage, setPdfPage] = useState();
  const [pdfReadError, setPdfReadError] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(true);
  const [pdfDoc, setPdfDoc] = useState(null);
	const [loadingPercentage, setLoadingPercetange] = useState(0)
  const [maxPages, setMaxPages] = useState();
  const LeafletFileRef = useContext(PromotionLeafletContext);

  const MountedRef = useRef(true);

  useEffect(() => {
    return () => {
      MountedRef.current = false;
    };
  }, []);

	const handlePageLoading = useCallback((progress) => {
		const {loaded, total} = progress
		const loadedPercentage = Math.round((100 * loaded) / total)
		setLoadingPercetange(loadedPercentage)
	},[])

  const pdf = useMemo(() => {
    if (LeafletFileRef.current && LeafletFileRef.current.Id === `${LeafletId}`)
      return false;
    else {
      try {
        return pdfjsLib.getDocument({ url: fileUrl });
      } catch (error) {
        return null;
      }
    }
  }, [LeafletFileRef, LeafletId, fileUrl]);

  const pendingPageRef = useRef();
  // eslint-disable-next-line no-unused-vars
  const renderingPageRef = useRef(false);

  useEffect(() => {
    if (!pdf && LeafletFileRef.current.Id === `${LeafletId}`) {
      setPdfDoc(LeafletFileRef.current.Pdf);
      setMaxPages(LeafletFileRef.current.Pdf.numPages);
    } else if (pdf) {
      setPdfLoading(true);
			pdf.onProgress = (progressData) => {
				handlePageLoading(progressData)
			};
      pdf.promise
        .then(function (pdfdoc) {
          if (!MountedRef.current) return;
          setPdfDoc(pdfdoc);
          setMaxPages(pdfdoc.numPages);
          LeafletFileRef.current = {
            Id: LeafletId,
            Pdf: pdfdoc,
            FileUrl: fileUrl
          };
        })
        .catch(function (error) {
          if (!MountedRef.current) return;
          console.log(error);
          //   setPdfLoading(false);
          setPdfReadError(true);
        });
			
    }
  }, [LeafletFileRef, LeafletId, fileUrl, handlePageLoading, pdf]);

  useEffect(() => {
    if (pdfDoc) {
      setPdfLoading(true);
      if (pendingPageRef.current !== undefined) {
        pendingPageRef.current = pageNumber;
        return;
      }
      const handlePageChange = (numb) => {
        pdfDoc.getPage(numb).then(function (page) {
          if (!MountedRef.current) return;
          if (pendingPageRef.current !== numb) {
            handlePageChange(pendingPageRef.current);
            return;
          }
          pendingPageRef.current = undefined;
          setPdfPage(page);
          setPdfLoading(false);
        });
      };
      pendingPageRef.current = pageNumber;
      handlePageChange(pageNumber);
    }
  }, [pageNumber, pdf, pdf.promise, pdfDoc]);

  return { pdfPage, pdfLoading, pdfReadError, maxPages, loadingPercentage };
};

const useCanvasRender = (pdfPage, zoomScale = 1) => {
  const ContainerCanvasRef = useRef();
  const heightRef = useRef();
  const CanvasRef = useRef();
  const textLayerRef = useRef();
  const [pdfImageFile, setPdfImageFile] = useState(null);
  const [fileLoading, setFileLoading] = useState(true);
  const [pdfRendering, setPdfRendering] = useState(false);
  const ShouldRerender = useRef(false);
  const CurrentlyRendering = useRef(false);

  const MountedRef = useRef(true);

  useEffect(() => {
    return () => {
      MountedRef.current = false;
    };
  }, []);

  useLayoutEffect(() => {
    let desiredHeight = heightRef.current;
    if (!desiredHeight && ContainerCanvasRef.current) {
      heightRef.current = ContainerCanvasRef.current.offsetHeight;
      desiredHeight = heightRef.current;
    }
    if (pdfPage) {
      const canvas = CanvasRef.current;

      const listner = () => {
        setPdfImageFile(null);
        if (CurrentlyRendering.current) {
          ShouldRerender.current = true;
          return;
        }
        setFileLoading(true);
        canvas.height = 0;
        canvas.width = 0;
        const desiredWidth = ContainerCanvasRef.current.offsetWidth - 10;

        const viewport = pdfPage.getViewport({ scale: 1 * zoomScale });

        // const scale = desiredWidth / viewport.height;
        const scale = desiredWidth / viewport.width;
        const scaledViewport = pdfPage.getViewport({ scale: scale });

        const context = canvas.getContext("2d");
        // canvas.style.display = "none";
        canvas.height = scaledViewport.height;
        canvas.width = scaledViewport.width;

        const renderContext = {
          canvasContext: context,
          viewport: scaledViewport
        };
        const renderTask = pdfPage.render(renderContext);
        CurrentlyRendering.current = true;
        setPdfRendering(true);
        renderTask.promise
          .then(function () {
            if (!MountedRef.current) return;
            setPdfRendering(false);

            CurrentlyRendering.current = false;
            if (ShouldRerender.current) {
              ShouldRerender.current = false;
              listner();
            } else {
              canvas.style.display = "block";
            }
            setFileLoading(false);
            return pdfPage.getTextContent();
          })
          .then(function (textContent) {
            if (!MountedRef.current) return;
            // PDF canvas
            textLayerRef.current.innerHTML = "";
            var pdf_canvas = canvas;

            // Canvas offset
            var canvas_offset_left = pdf_canvas.offsetLeft;
            var canvas_offset_top = pdf_canvas.offsetTop;

            // Canvas height
            var canvas_height = pdf_canvas.height;

            // Canvas width
            var canvas_width = pdf_canvas.width;

            // Assign CSS to the text-layer element
            textLayerRef.current.style.left = canvas_offset_left + "px";
            textLayerRef.current.style.top = canvas_offset_top + "px";
            textLayerRef.current.style.height = canvas_height + "px";
            textLayerRef.current.style.width = canvas_width + "px";

            // Pass the data to the method for rendering of text over the pdf canvas.
            var textLayer = pdfjsLib.renderTextLayer({
              textContent: textContent,
              container: textLayerRef.current,
              viewport: scaledViewport,
              textDivs: []
            });

            textLayer._render();
          });
      };

      listner();
      let timeout;
      const resiseFunction = () => {
        clearTimeout(timeout);
        // canvas.height = 0;
        // canvas.width = 0;
        timeout = setTimeout(() => {
          listner();
        }, 300);
      };

      window.addEventListener("resize", resiseFunction);
      return () => {
        window.removeEventListener("resize", resiseFunction);
        // wasCanceled = true;
        clearTimeout(timeout);
      };
    }
  }, [pdfPage, zoomScale]);

  useEffect(() => {
    if (pdfPage && !pdfRendering && !fileLoading && !pdfImageFile) {
      new Promise((resolve, reject) => {
        const canvas = CanvasRef.current;
        canvas.toBlob(
          (blob) => {
            blob.name = "ImagePdf";
            resolve(blob);
          },
          "image/jpeg",
          1
        );
      }).then((image) => {
        if (!MountedRef.current) return;
        setPdfImageFile(URL.createObjectURL(image));
      });
    }
  }, [fileLoading, pdfImageFile, pdfPage, pdfRendering]);

  return {
    pdfImageFile,
    ContainerCanvasRef,
    fileLoading,
    CanvasRef,
    textLayerRef
  };
};

const getCroppedImg = (image, crop, fileName) => {
  const canvas = document.createElement("canvas");
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext("2d");

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );

  // As Base64 string
  // const base64Image = canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (!blob) resolve(null);
        else {
          blob.name = fileName;
          resolve(blob);
        }
      },
      "image/png",
      1
    );
  });
};

const CropComponent = ({ isCroping, onCropEnd, pdfImageFile, currentPage }) => {
  const [crop, setCrop] = useState({});

  const MountedRef = useRef(true);

  useEffect(() => {
    return () => {
      MountedRef.current = false;
    };
  }, []);

  const imgRef = useRef();

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const handleCrop = async () => {
    const croppedImg = await getCroppedImg(
      imgRef.current,
      crop,
      "CroppedImage.png"
    );
    if (!MountedRef.current) return;
    if (croppedImg) {
      onCropEnd({ img: croppedImg, page: currentPage });
      setCrop({});
    }
  };

  if (!pdfImageFile) return <div></div>;
  else
    return (
      <ReactCrop
        className={classnames("ar-file-pdf-crop", {
          "d-none": !isCroping
        })}
        src={pdfImageFile}
        onImageLoaded={onLoad}
        crop={crop}
        onChange={(c) => setCrop(c)}
        onDragEnd={handleCrop}
      />
    );
};

const CanvasContainerPdfPreview = ({
  pdfPage,
  isCroping,
  onCropEnd,
  currentPage,
  changePage,
  maxPages
}) => {
  const [zoomScale, setZoomScale] = useState(1.01);
  const {
    pdfImageFile,
    ContainerCanvasRef,
    CanvasRef,
    // eslint-disable-next-line no-unused-vars
    fileLoading,
    textLayerRef
  } = useCanvasRender(pdfPage, zoomScale);
  const GrabberRef = useRef();

  const handleNextPageButton = () => {
    changePage((e) => {
      let newPage = e + 1;
      if (newPage > maxPages) {
        newPage = 1;
      }
      return newPage;
    });
  };

  const handlePreviousPageButton = () => {
    changePage((e) => {
      let newPage = e - 1;
      if (newPage === 0) {
        newPage = maxPages;
      }

      return newPage;
    });
  };

  const increaseZoom = () => {
    if (zoomScale < 3) {
      setZoomScale((e) => {
        return e + 0.2;
      });
    }
  };

  const decreaseZoom = () => {
    if (zoomScale > 0.8) {
      setZoomScale((e) => {
        return e - 0.2;
      });
    }
  };

  const ContainerRef = useRef();

  useLayoutEffect(() => {
    if (ContainerRef.current && !GrabberRef.current) {
      GrabberRef.current = new GrabToPan({
        element: ContainerRef.current
      });
      GrabberRef.current.ignoreTarget = (e) => {
        if (e.tagName === "SPAN") return true;
        return false;
      };
    }
  }, []);

  useEffect(() => {
    if (isCroping) {
      GrabberRef.current.deactivate();
    } else {
      GrabberRef.current.activate();
    }
  }, [isCroping]);

  return (
    <div className="flex-1 of-auto position-relative">
      {!isCroping && (
        <>
          <div
            onClick={handlePreviousPageButton}
            className="pdfCanvasCircle previous cursor-pointer noSelection  align-items-center justify-content-center"
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </div>
          <div
            onClick={handleNextPageButton}
            className="pdfCanvasCircle next noSelection cursor-pointer align-items-center justify-content-center"
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
          <div className="pdfCanvas-ZoomPage noSelection  align-items-center justify-content-center">
            <span className="mr-2">Página</span>
            <span className="mr-3">{`${currentPage} / ${maxPages}`}</span>
            <div className="border-right mr-3 h-100"></div>
            <div onClick={increaseZoom} className="mr-3 cursor-pointer">
              <FontAwesomeIcon icon={faMinus} />
            </div>
            <div onClick={decreaseZoom} className="cursor-pointer">
              <FontAwesomeIcon icon={faPlus} />
            </div>
          </div>
        </>
      )}

      <div ref={ContainerRef} className="h-100 grabbable of-auto">
        <div>
          <div
            ref={ContainerCanvasRef}
            className={classnames("position-relative h-100 w-100", {
              //   "d-none": !pdfPage,
            })}
          >
            {/* {fileLoading && (
              <LoaderSpinner
                className="color-primary d-flex justify-content-center mx-auto my-3"
                size="xs"
                center
              />
            )} */}
            <canvas
              className="m-auto"
              style={{ visibility: isCroping && "hidden" }}
              ref={CanvasRef}
            />
            <div ref={textLayerRef} className="textLayer"></div>
            <div className="d-flex justify-content-center">
              <CropComponent
                isCroping={isCroping}
                onCropEnd={onCropEnd}
                currentPage={currentPage}
                pdfImageFile={pdfImageFile}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const InnerPromotionPdfPreview = ({
  isCroping,
  FileUrl,
  LeafletId,
  onCropEnd
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const { pdfPage, pdfLoading, pdfReadError, maxPages, loadingPercentage } = usePage(
    FileUrl,
    currentPage,
    LeafletId
  );

  if (pdfReadError) {
    return (
      <div className="flex-1 justify-content-center d-flex align-items-center ">
        <div className="text-warning text-center fw-medium">
          Ocorreu um error ao abrir o folheto.
        </div>
      </div>
    );
  } else if (!pdfPage && pdfLoading) {
    return (
      <div className="flex-1 justify-content-center d-flex align-items-center ">
				<Percentage percentage={loadingPercentage} />
        {/* <LoaderSpinner className="color-primary " size="xs" /> */}
      </div>
    );
  } else
    return (
      <CanvasContainerPdfPreview
        pdfPage={pdfPage}
        isCroping={isCroping}
        onCropEnd={onCropEnd}
        currentPage={currentPage}
        changePage={setCurrentPage}
        maxPages={maxPages}
      />
    );
};

const PromotionPdfPreview = ({ isCroping, FileUrl, LeafletId, onCropEnd }) => {
  if (!FileUrl) {
    return (
      <LoaderSpinner
        className="color-primary d-flex justify-content-center mx-auto my-3"
        size="xs"
        center
      />
    );
  }

  return (
    <InnerPromotionPdfPreview
      isCroping={isCroping}
      FileUrl={FileUrl}
      LeafletId={LeafletId}
      onCropEnd={onCropEnd}
    />
  );
};

export default PromotionPdfPreview;
