import React, { useContext, useState, useEffect, useRef } from "react";
import LoadableButton from "../Button/LoadableButton";
import {
  ExportsFilterBuilderContext,
  ExportsFilterObjectContext,
} from "../../App";
import { buildODataQuery } from "../../Helpers/ODataHelper";
import Axios from "axios";
import { apiUrl } from "../../config";
import { authObject } from "../../Helpers/AuthHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/pro-solid-svg-icons";
import { buildCustomODataFilter } from "../../Helpers/CustomFilterBuilder";

export const downloadFile = (Url, IsMounted, setIsLoading) => {
  if (IsMounted.current && typeof setIsLoading === "function") {
    setIsLoading(true);
    Axios.get(Url, {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${authObject.token}`,
      },
    }).then(
      (response) => {
        if (IsMounted.current && typeof setIsLoading === "function") {
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(response);
          link.download = "Export.xlsx";
          link.click();
          link.remove();
          setIsLoading(false);
        }
      },
      (error) => {
        // console.log(error);
      }
    );
  }
};

const ExcelExportButton = ({ BaseUrl, removemargin }) => {
  const FilterObjRef = useContext(ExportsFilterObjectContext);
  const FilterBuilderRef = useContext(ExportsFilterBuilderContext);
  const IsMounted = useRef(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleButtonClick = () => {
    const Filters = FilterObjRef.current;
    let FilterObj = { ...Filters };
    FilterObj.count = false;
    FilterObj.take = false;

    const filterBuilder = FilterObj.filter?.logic
      ? undefined
      : FilterBuilderRef.current || buildCustomODataFilter;

    const url =
      apiUrl +
      "/" +
      BaseUrl +
      buildODataQuery(FilterObj, true, { filterBuilder, exporting: true });
    downloadFile(url, IsMounted, setIsLoading);
  };

  useEffect(() => {
    return () => {
      IsMounted.current = false;
    };
  }, []);

  return (
    <LoadableButton
      className={!removemargin && "mr-3"}
      loading={isLoading}
      onClick={handleButtonClick}
    >
      <div className="d-flex align-items-center">
        <FontAwesomeIcon icon={faFileExcel} className="mr-2" />
        Exportar para Excel
      </div>
    </LoadableButton>
  );
};

export default ExcelExportButton;
