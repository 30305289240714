import React, { useContext, useMemo } from "react";
import Button from "../Button/Button";
import styles from "./ViewToggler.module.css";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faThLarge } from "@fortawesome/pro-solid-svg-icons";

export const ViewTogglerContext = React.createContext();

const ViewToggler = ({ className }) => {
  const [currentView, setCurrentView] = useContext(ViewTogglerContext);

  const toggleCardView = () => {
    setCurrentView("cards");
  };

  const toggleGridView = () => {
    setCurrentView("list");
  };

  const view = useMemo(() => {
    return currentView;
  }, [currentView]);

  return (
    <div
      className={classnames(
        "d-flex align-items-center",
        className,
        styles.container
      )}
    >
      <Button
        onClick={toggleGridView}
        className={classnames(styles.FirstButton, {
          [styles.active]: view !== "cards",
          [styles.inactive]: view === "cards",
        })}
        vType="none"
      >
        <FontAwesomeIcon icon={faBars} />
      </Button>
      <Button
        onClick={toggleCardView}
        className={classnames(styles.LastButton, {
          [styles.active]: view === "cards",
          [styles.inactive]: view !== "cards",
        })}
        vType="none"
      >
        <FontAwesomeIcon icon={faThLarge} />
      </Button>
    </div>
  );
};

export default ViewToggler;
