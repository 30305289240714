import React, { useState, useContext } from "react";
import Modal, { ModalHeader } from "../../../Common/Modal/Modal";
import { faUser } from "@fortawesome/pro-solid-svg-icons";
import { usePost } from "../../../Helpers/IOClient";
import Checkbox from "../../../Common/Checkbox/Checkbox";
import LoadableButton from "../../../Common/Button/LoadableButton";
import { GridRefechContext } from "../../../App";
import { useRolesState, getRolesFromState } from "../UsersHelper";

const UsersUpdateModal = ({ open, user, toggleOpen }) => {
  const [form, handleChange] = useRolesState(user);

  const GridRefetchRef = useContext(GridRefechContext);
  const { Id } = user || {};
  const [, setUnknownError] = useState();
  const [post, { loading }] = usePost(`admins/users/${Id}`, null, {
    onSuccess: () => {
      toggleOpen();
      if (GridRefetchRef.current) {
        GridRefetchRef.current();
      }
      //   setSucceeded(true);
    },
    onError: ({ error }) => {
      setUnknownError(error);
    },
  });

  const { admin, backofficeAll, backofficePartial, client } = form;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUnknownError();

    post({
      Roles: getRolesFromState(form),
    });
  };

  return (
    <Modal isOpen={open} disableClosure={loading} onClose={toggleOpen}>
      <div className="p-4">
        <ModalHeader icon={faUser}>Editar Utilizador</ModalHeader>

        <form onSubmit={handleSubmit} style={{ width: 576 }} className="mt-4">
          <div className="text-secondary fw-medium mb-2">Permissões</div>
          <Checkbox
            name="admin"
            className="mb-3"
            checked={admin}
            onChange={handleChange}
          >
            Admin
          </Checkbox>
          <Checkbox
            name="backofficeAll"
            className="mb-3"
            checked={backofficeAll}
            onChange={handleChange}
          >
            Backoffice 1
          </Checkbox>
          <Checkbox
            name="backofficePartial"
            className="mb-3"
            checked={backofficePartial}
            onChange={handleChange}
          >
            Backoffice 2
          </Checkbox>
          <Checkbox
            name="client"
            className="mb-5"
            checked={client}
            onChange={handleChange}
          >
            Cliente
          </Checkbox>

          <LoadableButton
            loading={loading}
            // disabled={Boolean(error)}
            className="w-100"
          >
            Alterar
          </LoadableButton>
        </form>
      </div>
    </Modal>
  );
};

export default UsersUpdateModal;
