import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
// import logo from "./logo.svg";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Login from "./Login/Login";
import { authObject, logout } from "./Helpers/AuthHelper";
import Header from "./Header/Header";
import Leaflets from "./Leaflets/Leaflets";
import UserProvider, { UserQueryContext } from "./User/UserProvider";
import ChangePassword from "./ChangePassword/ChangePassword";
import axios from "axios";
import Configurations from "./Configurations/Configurations";
import Shops from "./Shops/Shops";
import Brands from "./Brands/Brands";
import CategoriesRouter from "./Categories/Categories";
import Products from "./Products/Products";
import Promotions from "./Promotions/Promotions";
import { routeBaseName } from "./config";
import Dashboard from "./Dashboard/Dashboard";
import LoaderSpinner from "./Common/LoaderSpinner/LoaderSpinner";
import { getHighestPermission, RolesEnum } from "./AppHelper";
import PromotionBrands from "./PromotionBrands/PromotionBrands";
import PromotionCategories from "./PromotionCategories/PromotionCategories";
import PromotionArticles from "./PromotionArticles/PromotionArticles";
import Indicators from "./Indicators/Indicators";
import GraphDashboard from "./GraphDashboard/GraphDashboard";
import "@progress/kendo-theme-bootstrap/dist/all.css";
import "./bootstrap.css";
axios.interceptors.response.use(
  (r) => {
    return Promise.resolve(r.data);
  },
  (e) => {
    if (!e.response) return Promise.reject(e);

    if (e.response.status === 401) {
      logout();
    }
    // if (
    //   e.response.data &&
    //   e.response.data.Error &&
    //   e.response.data.Error.Type
    // )
    //   return Promise.reject(e.response.data.Error.Type);

    return Promise.reject({
      status: e.response.status,
      data: e.response.data
    });
  }
);

export const GridRefechContext = React.createContext();
export const PromotionLeafletContext = React.createContext();
export const ExportsFilterObjectContext = React.createContext();
export const ExportsFilterBuilderContext = React.createContext();
export const UserRoleContext = React.createContext();

const RouteComponent = () => {
  const { data, loading, error } = useContext(UserQueryContext);

  const ResolvedRoles = useMemo(() => {
    if (data)
      return data?.Roles.reduce((roles, role) => {
        roles[role] = true;
        return roles;
      }, {});
    else return {};
  }, [data]);

  if (loading || error) {
    return (
      <div className="d-flex w-100 h-100 align-items-center justify-content-center text-primary">
        <LoaderSpinner center size="sm" />
      </div>
    );
  }
  const Permission = getHighestPermission(ResolvedRoles);
  return (
    <UserRoleContext.Provider value={ResolvedRoles}>
      <Switch>
        <Route path="/leaflets">
          <Leaflets />
        </Route>

        <Route path="/indicators">
          <Indicators />
        </Route>

        {Permission >= RolesEnum.BackTwo && (
          <Route path="/shops">
            <Shops />
          </Route>
        )}
        {Permission >= RolesEnum.BackTwo && (
          <Route path="/articles">
            <Products />
          </Route>
        )}

        <Route path="/promotions/brands">
          <PromotionBrands />
        </Route>

        <Route path="/promotions/categories">
          <PromotionCategories />
        </Route>

        <Route path="/promotions/articles">
          <PromotionArticles />
        </Route>

        <Route path="/promotions">
          <Promotions />
        </Route>
        {Permission >= RolesEnum.BackOne && (
          <Route path="/config">
            <Configurations />
          </Route>
        )}
        {Permission >= RolesEnum.BackTwo && (
          <Route path="/brands">
            <Brands />
          </Route>
        )}
        {Permission >= RolesEnum.BackTwo && (
          <Route path="/categories">
            <CategoriesRouter />
          </Route>
        )}
        {Permission >= RolesEnum.BackTwo && (
          <Route path="/mainSectors">
            <CategoriesRouter />
          </Route>
        )}
        <Route path="/changePassword">
          <ChangePassword />
        </Route>
        <Route path="/">
          {/* <Dashboard /> */}
          <GraphDashboard />
        </Route>
        <Redirect from="/login" to="/" />
        {/* <Redirect from="/" to="/leaflets" /> */}
      </Switch>
    </UserRoleContext.Provider>
  );
};

const AuthenticatedRoutes = () => {
  const GridRefechRef = useRef();
  const LeafletFileRef = useRef();
  const ExportObjectRef = useRef();
  const ExportBuilderRef = useRef();

  return (
    <UserProvider>
      <div className="h-100 d-flex flex-column">
        <Header />
        <div className="flex-1 bg-secondary overflow-auto">
          <GridRefechContext.Provider value={GridRefechRef}>
            <PromotionLeafletContext.Provider value={LeafletFileRef}>
              <ExportsFilterObjectContext.Provider value={ExportObjectRef}>
                <ExportsFilterBuilderContext.Provider value={ExportBuilderRef}>
                  <RouteComponent />
                </ExportsFilterBuilderContext.Provider>
              </ExportsFilterObjectContext.Provider>
            </PromotionLeafletContext.Provider>
          </GridRefechContext.Provider>
        </div>
      </div>
    </UserProvider>
  );
};

const DefaultRoutes = () => {
  return (
    <Switch>
      <Route path="/login">
        <Login />
      </Route>
      <Redirect to="/login" />
    </Switch>
  );
};

function App() {
  const [isAuth, setIsAuth] = useState(() => {
    return Boolean(authObject.token);
  });

  useEffect(() => {
    authObject.onAuthStateChange = (isAuth) => {
      setIsAuth(isAuth);
    };

    return () => {
      authObject.onAuthStateChange = undefined;
    };
  }, []);

  return (
    <Router basename={routeBaseName}>
      {isAuth ? <AuthenticatedRoutes /> : <DefaultRoutes />}
    </Router>
  );
}

export default App;
