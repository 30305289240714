import React, { useState, useContext } from "react";
import BrandsGrid from "./BrandsGrid";

import AddBrandsModal from "./BrandsModal/AddBrandsModal";
import ExcelExportButton from "../../Common/ExcelButton/ExcelExportButton";
import { getHighestPermission, RolesEnum } from "../../AppHelper";
import { UserRoleContext } from "../../App";
import PlusButton from "../../Common/PlusButton/PlusButton";

const Brands = () => {
  const [addingChain, setAddingChain] = useState(false);

  const toggleModal = () => {
    setAddingChain((open) => !open);
  };

  const Roles = useContext(UserRoleContext);
  const Permission = getHighestPermission(Roles);

  return (
    <div className="p-4 h-100 d-flex flex-column main-grid">
      <AddBrandsModal isOpen={addingChain} onClose={toggleModal} />
      <div className="d-flex align-items-center justify-content-between mb-2">
        <div className="fs-40 fw-medium">Marcas</div>
        <div className="d-flex flex-1 align-items-center justify-content-end">
          {Permission >= RolesEnum.BackOne && (
            <PlusButton className="mr-3" onClick={toggleModal}>
              Adicionar
            </PlusButton>
          )}
          <ExcelExportButton
            removemargin={!(Permission >= RolesEnum.BackOne)}
            BaseUrl={"brands/export"}
          />
        </div>
      </div>
      <BrandsGrid />
    </div>
  );
};

export default Brands;
