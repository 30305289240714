import moment from "moment";
import React from "react";
import ExcelExportButton from "../Common/ExcelButton/ExcelExportButton";
import { FilterListToggler } from "../Common/FilterList/FilterListProvider";
import {
  FilterOperatorEnum,
  FilterTypeEnum,
} from "../Common/FilterList/FilterListTypings";
import BaseGrid from "../Common/Grid/Grid";
import { GridProvider } from "../Common/Grid/GridHelper";
import { getDate } from "../Helpers/DateHelper";
import { formatPrice, getGridItemValue } from "../Helpers/MiscHelper";
import { DateFilterCell } from "../Leaflets/LeafletsGrid";
import { buildShopBrandsFilterQuery } from "./IndicatorsHelper";

const dateCell = ({ dataItem, field }) => {
  const value = getGridItemValue(dataItem, field);
  return (
    <td>
      {value === null ? "" : new moment(getDate(value)).format("DD/MM/YYYY")}
    </td>
  );
};

const priceCell = ({ dataItem, field }) => {
  const value = getGridItemValue(dataItem, field) || null;
  return <td>{value === null ? "" : `${formatPrice(parseInt(value))} €`} </td>;
};

const columns = [
  {
    field: "MainBrandName",
    title: "Marca Principal",
    queryUrl: "brands/main/query", //custom filter
    dependents: ["BrandName"],
  },
  {
    field: "BrandName",
    title: "Marca",
    queryUrl: "brands/query", //custom filter
    filterDependencies: [
      {
        field: "MainBrandName",
        queryName: "mainBrands",
      },
    ],
  },
  {
    field: "ArticleName",
    title: "Artigo",
    queryUrl: "articles/names/query" /*custom filter */,
  },
  {
    field: "ShopName",
    title: "Insígnia",
    queryUrl: "shops/query" /*custom filter */,
  },
  {
    field: "LeafleatName",
    title: "Folheto",
    queryUrl: "leaflets/names/query", //custom filter
  },
  {
    field: "DateBegin",
    title: "Data de Inicio",
    filter: "date",
    width: 200,
    filterCell: DateFilterCell,
    cell: dateCell,
    filterOrder: 2,
    filterType: FilterTypeEnum.date, //custom filter
    dateOperator: FilterOperatorEnum.GreaterOrEqual, //custom filter
  },
  {
    field: "DateEnd",
    title: "Data de Fim",
    filter: "date",
    width: 200,
    filterCell: DateFilterCell,
    cell: dateCell,
    filterOrder: 3,
    filterType: FilterTypeEnum.date, //custom filter
    dateOperator: FilterOperatorEnum.LesserOrEqual, //custom filter
  },
  {
    field: "PromotionPrice",
    filterable: false,
    title: "Preço de Promoção",
    cell: priceCell,
    filterType: FilterTypeEnum.price /*custom filter */,
  },
];

const Grid = () => {
  return (
    <BaseGrid
      customODataFilterBuilder={buildShopBrandsFilterQuery}
      customFilter
      defaultSorts={
        [
          // { dir: "asc", field: "ShopName" },
          // { dir: "asc", field: "BrandName" },
        ]
      }
      // filterCache={LeafletsFilterCacheState}
      // onFilterChange={setLeafletsFilterCacheState}
      // customCache={leafletsCache}
      Columns={columns}
      //   filterOperators={filterOperators}
      BaseUrl={"indicators/promotions"}
    />
  );
};

const PromotionIndicators = () => {
  //   const openForm = () => {
  //     history.push("/leaflets/new");
  //   };

  return (
    <GridProvider
      filterUrl={"genericFilters"}
      filterType="indicatorpromotions"
      enableCustomFilters
      id="indicatorpromotions"
    >
      <div className="p-4 h-100 d-flex flex-column">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div className="d-flex align-items-center">
            <div className="fs-40 fw-medium mr-3">Evolução do Preço</div>
          </div>
          <div className="d-flex flex-1 align-items-center justify-content-end">
            <ExcelExportButton
              // removemargin={!(Permission >= RolesEnum.BackTwo)}
              BaseUrl={"indicators/promotions/export"}
            />
            <FilterListToggler />
            {/* <ViewToggler className="ml-3" /> */}
          </div>
        </div>
        <Grid />
      </div>
    </GridProvider>
  );
};

export default PromotionIndicators;
