import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Shop from "./Shop/Shop";
import ChannelType from "./ChannelType/ChannelType";
import ComercialArea from "./ComercialArea/ComercialArea";
import Chain from "./Chain/Chain";

const Shops = () => {
  return (
    <Switch>
      <Route path="/shops/comercialAreas">
        <ComercialArea />
      </Route>
      <Route path="/shops/channelTypes">
        <ChannelType />
      </Route>
      <Route path="/shops/chains">
        <Chain />
      </Route>
      <Route exact path="/shops">
        <Shop />
      </Route>
      <Redirect to="/shops" />
    </Switch>
  );
};

export default Shops;
