import React, { useMemo, useRef, useState } from "react";
import BackLink from "../../Common/BackLink/BackLink";
import { usePost, useQuery } from "../../Helpers/IOClient";
import { useParams } from "react-router-dom";
import LoaderSpinner from "../../Common/LoaderSpinner/LoaderSpinner";
import classnames from "classnames";
import styles from "./Users.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUserCog } from "@fortawesome/pro-solid-svg-icons";
import { useRolesState } from "./UsersHelper";
import Checkbox from "../../Common/Checkbox/Checkbox";
import LoadableButton from "../../Common/Button/LoadableButton";
import { useHistory } from "react-router-dom";

const RoleCheckbox = ({ className, ...rest }) => {
  return (
    <div className={classnames("bg-secondary p-3 rounded", className)}>
      <Checkbox {...rest} />
    </div>
  );
};

const ConfigItem = ({
  title,
  values,
  name,
  onChange,
  queryUrl,
  dependencyName,
  dependency
}) => {
  const [results, setResults] = useState();
  const [search, setSearch] = useState("");

  const resolvedUrl = useMemo(() => {
    let url = `${queryUrl}?search=${search}`;

    if (!dependency) return url;

    const depList = [];

    for (const dep in dependency) {
      if (Object.hasOwnProperty.call(dependency, dep)) {
        const isActive = dependency[dep];
        if (isActive) depList.push(dep);
      }
    }

    if (depList.length === 0) return url;

    const json = JSON.stringify(depList);
    url += `&${dependencyName}=${encodeURIComponent(json)}`;

    return url;
  }, [dependency, dependencyName, queryUrl, search]);

  const { loading, error } = useQuery(resolvedUrl, null, {
    onSuccess: ({ data }) => {
      setResults(data);
    },
    onError: () => {
      setResults();
    }
  });

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const toggleSelection = (v) => {
    onChange((config) => {
      return {
        ...config,
        [name]: {
          ...config[name],
          [v]: !(config[name][v] || false)
        }
      };
    });
  };

  const areAllSelected = useMemo(() => {
    if (!results) return false;

    for (const value of results) {
      if (!values[value]) return false;
    }
    return true;
  }, [results, values]);

  const toggleAll = () => {
    if (areAllSelected) {
      onChange((config) => {
        return {
          ...config,
          [name]: {}
        };
      });
    } else {
      const selectedDict = {};

      for (const result of results) {
        selectedDict[result] = true;
      }

      onChange((config) => {
        return {
          ...config,
          [name]: selectedDict
        };
      });
    }
  };

  return (
    <div
      className={classnames(
        styles.configItem,
        "rounded overflow-auto d-flex flex-column"
      )}
    >
      <div className={classnames(styles.configItemTitle, "p-3")}>{title}</div>
      <div className="p-3 pb-0">
        <input
          value={search}
          onChange={handleSearchChange}
          className={classnames(styles.configInput, "px-2")}
        />
      </div>

      <div className="overflow-auto flex-1 p-3">
        {(!results && loading) || error ? (
          <LoaderSpinner
            size="xs"
            className="mt-1 text-primary d-flex align-items-center justify-content-center"
          />
        ) : !results || results.length === 0 ? (
          <div>Sem resultados</div>
        ) : (
          <div>
            <div className="py-1">
              <Checkbox
                // className={checked ? styles.selected : undefined}
                checked={areAllSelected}
                onChange={toggleAll}
              >
                Todos
              </Checkbox>
            </div>
            {results.map((v, i) => {
              const checked = values[v] || false;

              return (
                <div key={`${v}-${i}`} className="py-1">
                  <Checkbox
                    // className={checked ? styles.selected : undefined}
                    checked={checked}
                    onChange={() => toggleSelection(v)}
                  >
                    {v}{" "}
                  </Checkbox>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

const itemToList = (item) => {
  const arr = [];

  for (const val in item) {
    if (Object.hasOwnProperty.call(item, val)) {
      const checked = item[val];
      if (checked) arr.push(val);
    }
  }
  return arr;
};

const ConfigToList = (config) => {
  const {
    CategoryNames,
    SubCategoryNames,
    Regions,
    ShopNames,
    MainCategoryNames,
    MainSectorNames,
    Medias
  } = config;

  return {
    MainSectorNames: itemToList(MainSectorNames),
    MainCategoryNames: itemToList(MainCategoryNames),
    CategoryNames: itemToList(CategoryNames),
    SubCategoryNames: itemToList(SubCategoryNames),
    Regions: itemToList(Regions),
    ShopNames: itemToList(ShopNames),
    Medias: itemToList(Medias)
  };
};

const addConfigItems = (configObj, listItems) => {
  for (const item of listItems) {
    configObj[item] = true;
  }
};

const buildDefaultConfig = (user) => {
  const obj = {
    CategoryNames: [],
    SubCategoryNames: [],
    MainSectorNames: [],
    MainCategoryNames: [],
    Regions: [],
    ShopNames: [],
    Medias: []
  };

  const clientPermission = { ...obj, ...user?.ClientPermission };
  if (!clientPermission.MainSectorNames) clientPermission.MainSectorNames = [];
  if (!clientPermission.MainCategoryNames)
    clientPermission.MainCategoryNames = [];
  if (!clientPermission.Medias) clientPermission.Medias = [];
  // if (!clientPermission) return obj;

  const {
    CategoryNames,
    Regions,
    ShopNames,
    MainSectorNames,
    MainCategoryNames,
    SubCategoryNames,
    Medias
  } = clientPermission;

  if (CategoryNames.length > 0)
    addConfigItems(obj.CategoryNames, CategoryNames);
  if (Regions.length > 0) addConfigItems(obj.Regions, Regions);
  if (ShopNames.length > 0) addConfigItems(obj.ShopNames, ShopNames);
  if (SubCategoryNames.length > 0)
    addConfigItems(obj.SubCategoryNames, SubCategoryNames);
  if (MainCategoryNames.length > 0)
    addConfigItems(obj.MainCategoryNames, MainCategoryNames);
  if (MainSectorNames.length > 0)
    addConfigItems(obj.MainSectorNames, MainSectorNames);
  if (Medias.length > 0) addConfigItems(obj.Medias, Medias);

  return obj;
};

const InnerPermissions = ({ handleConfigChange, config, roles }) => {
  const { admin, backofficeAll, backofficePartial } = roles;

  if (admin || backofficeAll || backofficePartial) {
    return (
      <div className="d-flex flex-column flex-1 align-items-center justify-content-center">
        <div className={classnames(styles.permissionIlust, "mb-5")}></div>
        Como administrador todas as funcionalidades estão visíveis
      </div>
    );
  }

  const handleCategoryChange = (valueSetter) => {
    handleConfigChange((config) => {
      const newVal = valueSetter(config);

      return {
        ...newVal,
        SubCategoryNames: {}
      };
    });
  };

  return (
    <div className="h-100 pt-0 flex-1 overflow-auto">
      <div className={classnames("px-4", styles.configGrid)}>
        <ConfigItem
          name="Regions"
          onChange={handleConfigChange}
          title="Região"
          values={config.Regions}
          queryUrl="regions/query"
        />
        <ConfigItem
          name="ShopNames"
          onChange={handleConfigChange}
          title="Insígnias"
          values={config.ShopNames}
          queryUrl="shops/query"
        />
        <ConfigItem
          name="MainSectorNames"
          onChange={handleCategoryChange}
          title="Sectores Principais"
          values={config.MainSectorNames}
          queryUrl="MainSectors/query"
        />
        <ConfigItem
          name="MainCategoryNames"
          onChange={handleCategoryChange}
          title="Categorias Principais"
          values={config.MainCategoryNames}
          dependency={config.MainSectorNames}
          dependencyName="mainSectors"
          queryUrl="categories/main/query"
        />
        <ConfigItem
          name="CategoryNames"
          onChange={handleCategoryChange}
          title="Categorias"
          values={config.CategoryNames}
          dependency={config.MainCategoryNames}
          dependencyName="mainCategories"
          queryUrl="categories/query"
        />

        <ConfigItem
          name="SubCategoryNames"
          onChange={handleConfigChange}
          title="Subcategorias"
          values={config.SubCategoryNames}
          queryUrl="categories/sub/query"
          dependencyName="categories"
          dependency={config.CategoryNames}
        />

        <ConfigItem
          name="Medias"
          onChange={handleConfigChange}
          title="Fontes"
          values={config.Medias}
          queryUrl="media/query"
        />
      </div>
    </div>
  );
};

const InnerUserForm = ({ user, post, submitRef }) => {
  const [roles, handleChange] = useRolesState(user);

  const [config, handleConfigChange] = useState(() => buildDefaultConfig(user));

  const handleSubmit = () => {
    const resolvedConfig = ConfigToList(config);
    post({
      ClientPermissions: resolvedConfig
    });
  };
  submitRef.current = handleSubmit;

  const { admin, backofficeAll, backofficePartial, client } = roles;
  const { UserName } = user;
  return (
    <div className="flex-1 d-flex overflow-hidden">
      <div
        className={classnames(
          styles.container,
          styles.rolesContainer,
          "h-100 p-4 rounded"
        )}
      >
        <div className="d-flex align-items-center border-bottom pb-4">
          <FontAwesomeIcon size="lg" className="color-primary" icon={faUser} />
          <span className="ml-2 fs-20 fw-medium">{UserName}</span>
        </div>
        <div className="mt-4">
          <RoleCheckbox
            name="admin"
            className="mb-3"
            checked={admin}
            onChange={handleChange}
          >
            Admin
          </RoleCheckbox>
          <RoleCheckbox
            name="backofficeAll"
            className="mb-3"
            checked={backofficeAll}
            onChange={handleChange}
          >
            Backoffice 1
          </RoleCheckbox>
          <RoleCheckbox
            name="backofficePartial"
            className="mb-3"
            checked={backofficePartial}
            onChange={handleChange}
          >
            Backoffice 2
          </RoleCheckbox>
          <RoleCheckbox
            name="client"
            className="mb-5"
            checked={client}
            onChange={handleChange}
          >
            Cliente
          </RoleCheckbox>
        </div>
      </div>
      <div
        className={classnames(
          styles.container,
          "ml-4 flex-1 d-flex flex-column overflow-hidden"
        )}
      >
        <div className="d-flex align-items-center p-4">
          <FontAwesomeIcon
            size="lg"
            className="color-primary"
            icon={faUserCog}
          />
          <span className="ml-2 fs-20 fw-medium">Permissões</span>
        </div>
        <InnerPermissions
          config={config}
          handleConfigChange={handleConfigChange}
          roles={roles}
        />
      </div>
    </div>
  );
};

const UsersForm = () => {
  const { userId } = useParams();
  const { loading, error, data } = useQuery(`admins/users/${userId}`, null, {
    cache: false
  });
  const history = useHistory();
  const [post, { loading: loadingPost }] = usePost(
    `admins/users/${userId}`,
    null,
    {
      onSuccess: () => {
        history.push(`/config/users`);
        // toggleOpen();
        // if (GridRefetchRef.current) {
        //   GridRefetchRef.current();
        // }
        //   setSucceeded(true);
      },
      onError: ({ error }) => {
        // setUnknownError(error);
      }
    }
  );

  const submitRef = useRef();

  return (
    <div className="p-5 h-100 d-flex flex-column">
      <BackLink to="/config/users" />
      <div className="d-flex flex-column flex-1 overflow-hidden">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div className="fs-40 fw-medium ">Editar Utilizador</div>

          <LoadableButton
            loading={loadingPost}
            onClick={() => submitRef.current()}
          >
            Guardar
          </LoadableButton>
        </div>
        {loading || error ? (
          <div className="flex-1 d-flex align-items-center justify-content-center">
            <LoaderSpinner size="sm" className="color-primary" />
          </div>
        ) : (
          <InnerUserForm submitRef={submitRef} post={post} user={data} />
        )}
      </div>
    </div>
  );
};

export default UsersForm;
