import React, { useRef, useState, useContext, useMemo } from "react";
import BaseGrid from "../../Common/Grid/Grid";
import moment from "moment";

import DeleteModal from "../../Common/DeleteModalContent/DeleteModalContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import AddChannelTypeModal from "./channelTypeModal/AddChannelTypeModal";
import { InsertDateFilterCell } from "../../Leaflets/LeafletsGrid";
import { UserRoleContext } from "../../App";
import { getHighestPermission, RolesEnum } from "../../AppHelper";

const dateCell = ({ dataItem, field }) => {
  const value = dataItem[field];
  return (
    <td>{value === null ? "" : new moment(value).format("DD/MM/YYYY")}</td>
  );
};

const ButtonCell = ({ dataItem }) => {
  const [toggleEdit, toggleRemove] = useContext(ToggleEditionModalsContext);

  const handleEdit = () => {
    toggleEdit(dataItem);
  };

  const handleRemove = () => {
    toggleRemove(dataItem);
  };

  return (
    <td>
      <div className="d-flex align-items-center justify-content-end">
        <div onClick={handleEdit} className="p-2 mr-2 grid-button">
          <FontAwesomeIcon size="sm" icon={faPencil} title="Editar" />
        </div>
        <div onClick={handleRemove} className="p-2 grid-button">
          <FontAwesomeIcon size="sm" icon={faTrashAlt} title="Eliminar" />
        </div>
      </div>
    </td>
  );
};

const ToggleEditionModalsContext = React.createContext();

const ButtonCollumn = [
  {
    title: "",
    minResizableWidth: 120,
    field: "Button",
    headerClassName: "hide-grid-title",
    filterable: false,
    sortable: false,
    width: 90,
    cell: ButtonCell,
    show: true,
  },
];

const columns = [
  // { field: "Id", title: "Id", filterable: false, sortable: false, width: 50 },

  { field: "Code", title: "Código" },
  { field: "Name", title: "Nome" },
  { field: "ComercialArea.Name", title: "Área Comercial" },
  {
    field: "InsertDate",
    filter: "date",
    filterCell: InsertDateFilterCell,
    title: "Data de Inserção",
    cell: dateCell,
  },
];

const ChannelTypeGrid = () => {
  const SelectedCARef = useRef();

  const [editingComercialArea, setEditinggComercialArea] = useState(false);

  const toggleEditModal = (data) => {
    if (!editingComercialArea) {
      SelectedCARef.current = data;
    } else {
      SelectedCARef.current = undefined;
    }
    setEditinggComercialArea((open) => !open);
  };

  const [deletingComercialArea, setDeletingComercialArea] = useState(false);

  const toggleDeleteModal = (data) => {
    if (!deletingComercialArea) {
      SelectedCARef.current = data;
    } else {
      SelectedCARef.current = undefined;
    }
    setDeletingComercialArea((open) => !open);
  };

  const Roles = useContext(UserRoleContext);
  const Permission = getHighestPermission(Roles);

  const resolvedColumns = useMemo(() => {
    if (Permission >= RolesEnum.BackOne) {
      return [...ButtonCollumn, ...columns];
    } else {
      return columns;
    }
  }, [Permission]);

  return (
    <ToggleEditionModalsContext.Provider
      value={[toggleEditModal, toggleDeleteModal]}
    >
      <AddChannelTypeModal
        isOpen={editingComercialArea}
        onClose={toggleEditModal}
        data={SelectedCARef.current}
      />

      <DeleteModal
        isOpen={deletingComercialArea}
        onClose={toggleDeleteModal}
        data={SelectedCARef.current}
        closeModal={toggleDeleteModal}
        url="channelTypes"
      />

      <BaseGrid Columns={resolvedColumns} BaseUrl={"channelTypes"} />
    </ToggleEditionModalsContext.Provider>
  );

  // return <BaseGrid Columns={columns} BaseUrl={"channelTypes"} />;
};

export default ChannelTypeGrid;
