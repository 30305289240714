import React, { useState } from "react";
import { GridCacheProvider, GridProvider } from "../Common/Grid/GridHelper";
import { ViewTogglerContext } from "../Common/ViewToggler/ViewToggler";
import {
  Route,
  Redirect,
  Switch,
} from "react-router-dom/cjs/react-router-dom.min";
import CategoryNamesIndicators from "./CategoryNamesIndicators";
import BrandsIndicators from "./BrandsIndicators";
import CategoryBrandsIndicators from "./CategoryBrandsIndicators";
import ShopBrandsIndicators from "./ShopBrandsIndicators";
import PromotionBrandsIndicators from "./PromotionBrandsIndicators";
import PromotionIndicators from "./PromotionIndicators";

const Indicators = () => {
  //   const Roles = useContext(UserRoleContext);
  //   const Permission = getHighestPermission(Roles);

  const [currentView, setCurrentView] = useState("list");

  return (
    <GridCacheProvider>
      {/* <LeafletsCacheContext.Provider value={[leafletsCache, setLeafletsCache]}>
          <FilterListProvider>
            <LeafletsFilterCacheContext.Provider
              value={[LeafletsFilterCacheState, setLeafletsFilterCacheState]}
            >
              <PromotionsFilterCacheContext.Provider
                value={[
                  PromotionsFilterCacheState,
                  setPromotionsFilterCacheState,
                ]}
              > */}
      <ViewTogglerContext.Provider value={[currentView, setCurrentView]}>
        <Switch>
          <Route exact path="/indicators/categoryNames">
            <GridProvider
              filterUrl={"indicators/categoryNames/filters"}
              enableCustomFilters
              id="leaflets"
            >
              <CategoryNamesIndicators />
            </GridProvider>
          </Route>
          <Route exact path="/indicators/brands">
            <GridProvider
              filterUrl={"indicators/brands/filters"}
              enableCustomFilters
              id="brands"
            >
              <BrandsIndicators />
            </GridProvider>
          </Route>
          <Route exact path="/indicators/categoryBrands">
            <GridProvider
              filterUrl={"indicators/categoryBrands/filters"}
              enableCustomFilters
              id="categoryBrands"
            >
              <CategoryBrandsIndicators />
            </GridProvider>
          </Route>
          <Route exact path="/indicators/shopBrands">
            <GridProvider
              filterUrl={"indicators/shopBrands/filters"}
              enableCustomFilters
              id="shopBrands"
            >
              <ShopBrandsIndicators />
            </GridProvider>
          </Route>
          <Route exact path="/indicators/promotionBrands">
            <GridProvider
              filterUrl={"indicators/promotionBrands/filters"}
              enableCustomFilters
              id="promotionBrands"
            >
              <PromotionBrandsIndicators />
            </GridProvider>
          </Route>
          <Route exact path="/indicators/promotions">
            <GridProvider
              filterUrl={"indicators/promotions/filters"}
              enableCustomFilters
              id="promotions"
            >
              <PromotionIndicators />
            </GridProvider>
          </Route>
          <Redirect to="/indicators/categoryNames" />
        </Switch>
      </ViewTogglerContext.Provider>
      {/* </PromotionsFilterCacheContext.Provider>
            </LeafletsFilterCacheContext.Provider>
          </FilterListProvider>
        </LeafletsCacheContext.Provider> */}
    </GridCacheProvider>
  );
};

export default Indicators;
