import React, { useState, useMemo } from "react";
import { faBezierCurve, faSave } from "@fortawesome/pro-solid-svg-icons";
import LabeledInput from "../../../Common/Input/LabeledInput";
import {
  ChainDropdown,
  ChannelTypeDropdown,
  ComercialAreaDropdown
} from "../../../Common/Dropdown/DropdownHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadableButton from "../../../Common/Button/LoadableButton";
import { createEntityModal } from "../../../Common/Modal/ModalHelper";
import { ErrorMessage } from "../../../Leaflets/LeafletForm";
import ErrorMessageHandler from "../../../Common/ErrorMessage/ErrorMessage";

const InnerAddLeafletMediaModal = ({
  form,
  post,
  loading,
  handleChanges,
  setForm,
  error
}) => {
  const { Name, ChainName, ChannelType, ComercialArea } = form;

  const [errorState, setErrorState] = useState({});

  const isFormValid = () => {
    let valid = true;
    let newErrorState = {};
    if (!/(.|\s)*\S(.|\s)*/.test(Name)) {
      valid = false;
      newErrorState.Name = true;
    }
    if (!ChainName) {
      valid = false;
      newErrorState.ChainName = true;
    }
    setErrorState(newErrorState);
    return valid;
  };

  const onRemoveChannelType = () => {
    handleChanges({ target: { name: "ChannelType", value: {} } });
  };

  const onRemoveComercialArea = () => {
    handleChanges({ target: { name: "ComercialArea", value: {} } });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formValidation = isFormValid();
    if (formValidation) {
      const resolvedForm = {
        Name: form.Name,
        ChainNameId: form.ChainName.Id
      };
      post(resolvedForm);
    }
  };

  const formatChainsQueryData = (d) => {
    let unformated = d.map((e) => {
      return e.ChainName;
    });

    return Object.values(
      unformated.reduce((acc, obj) => ({ ...acc, [obj.Id]: obj }), {})
    );
  };

  const ComercialAreaExtraProfile = useMemo(() => {
    if (ComercialArea && ComercialArea.Id) {
      return {
        field: "ComercialArea/Id",
        operator: "eq",
        value: ComercialArea.Id
      };
    } else return null;
  }, [ComercialArea]);

  const ChainNameExtraProfile = useMemo(() => {
    if (ChannelType && ChannelType.Id) {
      return {
        field: "ChannelType/Id",
        operator: "eq",
        value: ChannelType.Id
      };
    } else return null;
  }, [ChannelType]);

  return (
    <div style={{ width: 576 }} className="mt-4">
      <form onSubmit={handleSubmit}>
        <LabeledInput
          name="Name"
          className="mb-3"
          value={Name}
          placeholder=" "
          pattern="(.|\s)*\S(.|\s)*"
          onChange={handleChanges}
          //   error={emailError}
          error={errorState.Name && `Nome ${ErrorMessage}`}
          // hasErrors={!isEmailValid}
          text="Nome"
        />
        <LabeledInput
          className="mb-1 ar-dropdown-modal"
          name="ComercialArea"
          value={ComercialArea}
          onChange={handleChanges}
          InputComponent={ComercialAreaDropdown}
          //   error={emailError}
          // hasErrors={!isEmailValid}
          text="Área Comercial"
        />
        {ComercialArea && ComercialArea.Id ? (
          <div
            onClick={onRemoveComercialArea}
            className="text-danger cursor-pointer mb-3"
          >
            Remover
          </div>
        ) : (
          <div className="mb-3"></div>
        )}
        <LabeledInput
          className="mb-1 ar-dropdown-modal"
          name="ChannelType"
          value={ChannelType}
          onChange={handleChanges}
          InputComponent={ChannelTypeDropdown}
          optionalFilters={ComercialAreaExtraProfile}
          //   error={emailError}
          // hasErrors={!isEmailValid}
          text="Tipo de Canal"
        />
        {ChannelType && ChannelType.Id ? (
          <div
            onClick={onRemoveChannelType}
            className="text-danger cursor-pointer mb-3"
          >
            Remover
          </div>
        ) : (
          <div className="mb-3"></div>
        )}
        <LabeledInput
          className="mb-4 ar-dropdown-modal"
          name="ChainName"
          value={ChainName}
          onChange={handleChanges}
          error={errorState.ChainName && `Cadeia ${ErrorMessage}`}
          InputComponent={ChainDropdown}
          optionalFilters={ChainNameExtraProfile}
          formatQueryData={formatChainsQueryData}
          //   error={emailError}
          // hasErrors={!isEmailValid}
          text="Cadeia"
        />
        {error && (
          <div className="text-danger mb-1 text-center">
            <ErrorMessageHandler error={error} />
          </div>
        )}
        <LoadableButton loading={loading} className="w-100" type="submit">
          <span>
            <FontAwesomeIcon className="mr-2" icon={faSave} />
            Guardar
          </span>
        </LoadableButton>
      </form>
    </div>
  );
};

const AddLeafletMediaModal = createEntityModal({
  getEndpoint: (data) => (data ? `shops/${data.Id}` : `shops`),
  Component: InnerAddLeafletMediaModal,
  headerTitle: "Adicionar Insígnia",
  headerIcon: faBezierCurve,
  getBaseForm: (entity) => {
    if (entity) {
      return {
        Name: entity.Name,
        ChainName: entity.ChainName
      };
    } else
      return {
        Name: "",
        ChainName: []
      };
  }
});

export default AddLeafletMediaModal;

// const AddShopModal = ({ data }) => {
//   const closeModal = useContext(ModalCloseContext);
//   const refetchRef = useContext(GridRefechContext);
//   const [form, setForm] = useState(() => {
//     return {
//       Name: "",
//       ChannelTypeName: "",
//       ChainName: "",
//       ...data,
//     };
//   });

//   const handleChanges = (e) => {
//     const { name, value } = e.target;

//     if (name === "ChainName") {
//       setForm((f) => {
//         return {
//           ...f,
//           [name]: value,
//           ChannelTypeName: value.ChannelType,
//         };
//       });
//     } else
//       setForm((f) => {
//         return {
//           ...f,
//           [name]: value,
//         };
//       });
//   };

//   const [post, { loading }] = usePost(
//     data ? `shops/${data.Id}` : `shops`,
//     null,
//     {
//       onSuccess: ({ data }) => {
//         if (refetchRef.current) refetchRef.current();
//         closeModal();
//       },
//       onError: ({ error }) => {
//         console.log(error);
//       },
//     }
//   );

//   const { Name, ChainName, ChannelTypeName } = form;

//   const isFormValid = () => {
//     if (!/(.|\s)*\S(.|\s)*/.test(Name)) return false;
//     if (!ChainName.Id) return false;
//     else return true;
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     const formValidation = isFormValid();
//     if (formValidation) {
//       const resolvedForm = {
//         Name: form.Name,
//         ChainNameId: form.ChainName.Id,
//       };
//       post(resolvedForm);
//     }
//   };

//   const ChainNameExtraProfile = useMemo(() => {
//     if (ChannelTypeName) {
//       return {
//         field: "ChannelType/Id",
//         operator: "eq",
//         value: ChannelTypeName.Id,
//       };
//     } else return null;
//   }, [ChannelTypeName]);

//   return (
//     <div className="p-4">
//       <ModalHeader icon={faBezierCurve}>Adicionar Loja</ModalHeader>

//       <div style={{ width: 576 }} className="mt-4">
//         <form onSubmit={handleSubmit}>
//           <LabeledInput
//             className="mb-3"
//             name="Name"
//             value={Name}
//             placeholder=" "
//             required="required"
//             pattern="(.|\s)*\S(.|\s)*"
//             onChange={handleChanges}
//             //   error={emailError}
//             // hasErrors={!isEmailValid}
//             text="Nome"
//           />
//           <LabeledInput
//             className="mb-4 ar-dropdown-modal"
//             name="ChannelTypeName"
//             value={ChannelTypeName}
//             onChange={handleChanges}
//             InputComponent={ChannelTypeDropdown}
//             //   error={emailError}
//             // hasErrors={!isEmailValid}
//             text="Tipo de Canal"
//           />
//           <LabeledInput
//             className="mb-4 ar-dropdown-modal"
//             name="ChainName"
//             value={ChainName}
//             onChange={handleChanges}
//             InputComponent={ChainDropdown}
//             optionalFilters={ChainNameExtraProfile}
//             //   error={emailError}
//             // hasErrors={!isEmailValid}
//             text="Cadeia"
//           />
//           <LoadableButton loading={loading} className="w-100" type="submit">
//             <span>
//               <FontAwesomeIcon className="mr-2" icon={faSave} />
//               Guardar
//             </span>
//           </LoadableButton>
//         </form>
//       </div>
//     </div>
//   );
// };
