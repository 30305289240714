import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Button from "../Button/Button";

export const PlusIcon = () => <FontAwesomeIcon size="lg" icon={faPlus} />;

const PlusButton = ({ children, ...rest }) => {
  return (
    <Button {...rest}>
      <PlusIcon />
      <span className="ml-2">{children}</span>
    </Button>
  );
};

export default PlusButton;
