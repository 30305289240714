import Axios from "axios";
import ReactDOM from "react-dom";
import { useReducer, useRef, useMemo, useEffect, useCallback } from "react";
import { apiUrl } from "../config";
import { authObject } from "./AuthHelper";

export class MemoryCache {
  constructor() {
    this.data = {};
  }

  write = (id, value) => {
    this.data[id] = value;
  };

  read = (id) => {
    return this.data[id];
  };

  clear = (id) => {
    delete this.data[id];
  };

  reset = () => {
    this.data = {};
  };
}

const defaultRequestConfig = {
  cancelToken: undefined,
  schema: null,
  cache: true,
  onStart: null
};

class Client {
  constructor() {
    this.cache = new MemoryCache();
    this.postCache = new MemoryCache();
  }

  get = async (endpoint, options) => {
    if (!options) options = defaultRequestConfig;

    const {
      cancelToken,
      schema,
      customCache,
      cache,
      onSuccess,
      onError,
      responseType,
      requestConfig,
      // createNormalizationAction,
      headers,
      mutateBeforeNormalization
    } = options;
    let resolvedCache;

    if (customCache !== undefined) resolvedCache = customCache;
    else if (cache) resolvedCache = this.cache;

    // let action;
    let state;
    try {
      let data = await Axios.get(`${apiUrl}/${endpoint}`, {
        responseType,
        cancelToken,
        headers: {
          ...headers,
          Authorization: `Bearer ${authObject.token}`
        },
        ...requestConfig
      });

      if (mutateBeforeNormalization) data = mutateBeforeNormalization(data);

      if (schema) {
        //   const normalizedData = normalize(data, schema);
        //   action = {
        //     type: `UPDATE_ENTITIES - GET - ${endpoint}`,
        //     response: { entities: normalizedData.entities },
        //   };
        //   if (createNormalizationAction)
        //     action = createNormalizationAction(action);
        //   if (resolvedCache) resolvedCache.write(endpoint, normalizedData.result);
        //   // if (onSuccess) onSuccess(normalizedData.result);
        //   state = { loading: false, data: normalizedData.result, error: null };
      } else {
        if (resolvedCache) resolvedCache.write(endpoint, data);
        // if (onSuccess) onSuccess(data);
        state = { loading: false, data, error: null };
      }
    } catch (e) {
      if (Axios.isCancel(e)) {
        state = { loading: false, data: null, error: null, canceled: true };
        //   return { loading: false, data: null, error: e };
      } else {
        state = { loading: false, data: null, error: e };
      }
    }
    const { error, canceled } = state;
    if (!canceled)
      ReactDOM.unstable_batchedUpdates(() => {
        //   action && store.dispatch(action);

        !error && onSuccess && onSuccess(state);
        error && onError && onError(state);
      });
    return state;
  };

  post = async (endpoint, body, newOptions = {}) => {
    const options = { ...defaultRequestConfig, ...newOptions };

    const {
      cancelToken,
      schema,
      onSuccess,
      onError,
      cache,
      headers,
      onUploadProgress
    } = options;

    let resolvedCache;

    if (cache) resolvedCache = this.postCache;

    let state;
    // let action;

    try {
      const data = await Axios.post(`${apiUrl}/${endpoint}`, body, {
        cancelToken,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authObject.token}`,
          ...headers
        },
        onUploadProgress
      });

      if (schema && data !== undefined && data !== null) {
        // const normalizedData = normalize(data, schema);
        // // if (cache) this.cache.write(endpoint, normalizedData.result);
        // action = {
        //   type: "UPDATE_ENTITIES",
        //   response: { entities: normalizedData.entities },
        // };
        // if (resolvedCache) resolvedCache.write(endpoint, normalizedData.result);
        // state = {
        //   loading: false,
        //   data: normalizedData.result,
        //   error: null,
        // };
      } else {
        if (resolvedCache) resolvedCache.write(endpoint, data);
        state = { loading: false, data, error: null };
      }
    } catch (e) {
      if (Axios.isCancel(e)) {
        state = { loading: false, data: null, error: null, canceled: true };
      } else {
        state = { loading: false, data: null, error: e };
      }
    }
    const { error, canceled } = state;
    if (!canceled)
      ReactDOM.unstable_batchedUpdates(() => {
        // action && store.dispatch(action);

        !error && onSuccess && onSuccess(state);
        error && onError && onError(state);
      });
    return state;
  };

  delete = async (endpoint, newOptions = defaultRequestConfig) => {
    const options = { ...defaultRequestConfig, ...newOptions };

    const { cancelToken, schema, onSuccess, onError, headers } = options;

    let state;
    // eslint-disable-next-line no-unused-vars
    let action;

    try {
      const data = await Axios.delete(`${apiUrl}/${endpoint}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authObject.token}`,
          ...headers
        },
        cancelToken
      });

      if (schema) {
        // const normalizedData = normalize(data, schema);
        // // if (cache) this.cache.write(endpoint, normalizedData.result);
        // action = {
        //   type: "UPDATE_ENTITIES",
        //   response: { entities: normalizedData.entities },
        // };
        // state = {
        //   loading: false,
        //   data: normalizedData.result,
        //   error: null,
        // };
      } else {
        state = { loading: false, data, error: null };
      }
    } catch (e) {
      if (Axios.isCancel(e)) {
        state = { loading: false, data: null, error: null, canceled: true };
      } else {
        state = { loading: false, data: null, error: e };
      }
    }
    const { error, canceled } = state;
    if (!canceled)
      ReactDOM.unstable_batchedUpdates(() => {
        // action && store.dispatch(action);

        !error && onSuccess && onSuccess(state);
        error && onError && onError(state);
      });
    return state;
  };
}

export const client = new Client();

export const cacheType = {
  enabled: "enabled",
  component: "component",
  disabled: "disabled"
};

const defaultQueryOptions = {
  autoFetch: true,
  cache: cacheType.enabled
};

const useQueryOptions = (options) => {
  return useMemo(() => {
    return { ...defaultQueryOptions, ...options };
  }, [options]);
};

const useQueryBase = (options) => {
  const resolvedOptions = useQueryOptions(options);
  const { cache: currentCacheType } = resolvedOptions;
  const configRef = useRef();
  let config = configRef.current;

  if (!config) {
    let cache;
    if (currentCacheType instanceof MemoryCache) {
      cache = currentCacheType;
    } else {
      switch (currentCacheType) {
        case cacheType.enabled:
          cache = client.cache;
          break;

        case cacheType.component:
          cache = new MemoryCache();
          break;

        default:
          cache = null;
          break;
      }
    }

    const obj = {
      hasUnmounted: false,
      cache
    };

    configRef.current = obj;
    config = obj;
  }

  //clear query and stop outgoing requests on unmount
  useEffect(() => {
    return () => {
      config.hasUnmounted = true;
      if (config.source) config.source.cancel();
    };
  }, [config]);

  return [config, resolvedOptions];
};

export const useQuery = (endpoint, schema, options) => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const previousEndpointRef = useRef();

  const [
    config,
    {
      autoFetch,
      onSuccess,
      onError,
      responseType,
      requestConfig,
      createNormalizationAction,
      mutateBeforeNormalization,
      headers
    }
  ] = useQueryBase(options);
  const stateRef = useRef();

  const getData = useCallback(
    (isRefetching = false) => {
      const cacheValue =
        !isRefetching && config.cache ? config.cache.read(endpoint) : undefined;
      if (config.source) config.source.cancel();

      if (!endpoint) return;

      if (cacheValue !== undefined) {
        const newState = { loading: false, data: cacheValue, error: null };
        stateRef.current = newState;

        if (onSuccess) onSuccess(newState);
        return;
      }

      config.source = Axios.CancelToken.source();

      stateRef.current.loading = true;
      stateRef.current.error = null;
      stateRef.current.data = null;

      if (isRefetching) {
        forceUpdate();
      }

      client.get(endpoint, {
        responseType: responseType,
        cancelToken: config.source.token,
        schema,
        customCache: config.cache,
        onSuccess: (state) => {
          stateRef.current = state;
          forceUpdate();
          onSuccess && onSuccess(state);
        },
        onError: (state) => {
          stateRef.current = state;
          forceUpdate();
          onError && onError(state);
        },
        requestConfig,
        createNormalizationAction,
        headers,
        mutateBeforeNormalization
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      config.cache,
      config.source,
      endpoint,
      onError,
      onSuccess,
      requestConfig,
      responseType,
      schema
    ]
  );

  //startup
  if (!stateRef.current) {
    stateRef.current = {
      data: null,
      loading: autoFetch && endpoint ? true : false,
      error: null
    };
  }

  if (endpoint !== previousEndpointRef.current) {
    previousEndpointRef.current = endpoint;
    if (autoFetch) {
      getData();
    } else {
      let data;
      if (config.cache) {
        const cacheData = config.cache.read(endpoint);
        if (cacheData !== undefined) data = cacheData;
      }
      stateRef.current = {
        data,
        loading: false,
        error: null
      };
    }
  }

  const refetch = useCallback(() => getData(true), [getData]);

  const { data, error, loading } = stateRef.current;

  const memoResult = useMemo(() => {
    return {
      data,
      error,
      loading,
      refetch
    };
  }, [data, error, loading, refetch]);

  return memoResult;
};

export const usePost = (
  endpoint,
  schema,
  {
    onSuccess,
    onError,
    handleEntityUpdate,
    updates,
    normalizedSchema,
    headers
  } = {}
) => {
  const stateRef = useRef({
    data: null,
    loading: false,
    error: null
  });
  const sourceRef = useRef();
  const hasUnmountedRef = useRef(false);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const postFunc = (body, extraConfig = {}) => {
    sourceRef.current = Axios.CancelToken.source();
    stateRef.current.loading = true;
    forceUpdate();
    client.post(endpoint, body, {
      cancelToken: sourceRef.current.token,
      schema: normalizedSchema || schema,
      onSuccess: (state) => {
        stateRef.current = state;
        forceUpdate();
        onSuccess && onSuccess({ ...state });
      },
      onError: (state) => {
        stateRef.current = state;
        forceUpdate();
        onError && onError({ ...state });
      },
      handleEntityUpdate,
      updates,
      headers,
      ...extraConfig
    });
  };

  //clear query and stop outgoing requests on unmount
  useEffect(() => {
    return () => {
      hasUnmountedRef.current = true;
      if (sourceRef.current) sourceRef.current.cancel();
    };
  }, []);

  return [postFunc, stateRef.current];
};

export const useDelete = (
  endpoint,
  schema,
  {
    onSuccess,
    onError,
    handleEntityUpdate,
    updates,
    normalizedSchema,
    headers
  } = {}
) => {
  const stateRef = useRef({
    data: null,
    loading: false,
    error: null
  });
  const sourceRef = useRef();
  const hasUnmountedRef = useRef(false);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const postFunc = () => {
    sourceRef.current = Axios.CancelToken.source();
    stateRef.current.loading = true;
    forceUpdate();
    client.delete(endpoint, {
      cancelToken: sourceRef.current.token,
      schema: normalizedSchema || schema,
      onSuccess: (state) => {
        stateRef.current = state;
        forceUpdate();
        onSuccess && onSuccess({ ...state });
      },
      onError: (state) => {
        stateRef.current = state;
        forceUpdate();
        onError && onError({ ...state });
      },
      handleEntityUpdate,
      updates,
      headers
    });
  };

  //clear query and stop outgoing requests on unmount
  useEffect(() => {
    return () => {
      hasUnmountedRef.current = true;
      if (sourceRef.current) sourceRef.current.cancel();
    };
  }, []);

  return [postFunc, stateRef.current];
};
