import { Pager } from "@progress/kendo-react-data-tools";
import {
  IntlProvider,
  load,
  loadMessages,
  LocalizationProvider,
} from "@progress/kendo-react-intl";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ExportsFilterObjectContext, GridRefechContext } from "../../App";
import { buildCustomODataFilter } from "../../Helpers/CustomFilterBuilder";
import { getDate } from "../../Helpers/DateHelper";
import { useHistory } from "react-router-dom";
import { cacheType, useQuery } from "../../Helpers/IOClient";
import { buildODataQuery } from "../../Helpers/ODataHelper";
import FilterList from "../FilterList/FilterList";
import moment from "moment";
import "./Cards.css";
import likelySubtags from "../../Assets/Translations/cldr/supplemental/likelySubtags.json";
import currencyData from "../../Assets/Translations/cldr/supplemental/currencyData.json";
import weekData from "../../Assets/Translations/cldr/supplemental/weekData.json";

import ptNumbers from "../../Assets/Translations/cldr/numbers/pt-PT/numbers.json";
import ptLocalCurrency from "../../Assets/Translations/cldr/numbers/pt-PT/currencies.json";
import ptCaGregorian from "../../Assets/Translations/cldr/dates/pt-PT/ca-gregorian.json";
import ptDateFields from "../../Assets/Translations/cldr/dates/pt-PT/dateFields.json";
import ptMessages from "../../Assets/Translations/pt.json";
import { GridCacheContext, GridContext } from "../Grid/GridHelper";

load(
  likelySubtags,
  currencyData,
  weekData,
  ptLocalCurrency,
  ptNumbers,
  ptCaGregorian,
  ptDateFields
);
loadMessages(ptMessages, "pt-PT");

const pageableOptions = {
  buttonCount: 10,
  info: true,
  type: "numeric",
  // pageSizes: [20],
  previousNext: true,
};

export const CardItemsContext = React.createContext();

const Cards = ({
  Columns,
  filterCache = {},
  onFilterChange,
  onUrlUpdate,
  defaultSorts,
  BaseUrl,
  GridSettings,
  customCache,
  className,
}) => {
  const { settings, filterStateSetterRef } = useContext(GridContext) || {};
  const GridRefetchRef = useContext(GridRefechContext);
  const { isCustomFilterOpen, isCustomFilterEnabled, id, hasCustomFilter } =
    settings || {};
  const [gridState, setGridState] = useState({
    products: { data: [], total: 0 },
    dataState: {
      count: true,
      sort: defaultSorts ? defaultSorts : [],
      ...filterCache,
      take: 20,
      skip: 0,
    },
  });
  const gridCache = useContext(GridCacheContext);
  useEffect(() => {
    if (!id || !gridCache) return;
    gridCache.write(id, gridState.dataState);
  }, [gridCache, gridState.dataState, id]);

  // useEffect(() => {
  //   onFilterChange && onFilterChange({ skip, take, filter, sort, group });
  // }, [filter, group, onFilterChange, skip, sort, take]);

  const setFilter = useCallback((filter) => {
    setGridState((kendoFilter) => {
      let rFilter;
      if (typeof filter === "function") {
        rFilter = filter(kendoFilter.dataState.filter);
      } else {
        rFilter = filter;
      }
      return {
        ...kendoFilter,
        dataState: { ...kendoFilter.dataState, skip: 0, filter: rFilter },
      };
    });
  }, []);

  useEffect(() => {
    if (!filterStateSetterRef) return;
    filterStateSetterRef.current = setFilter;

    return () => (filterStateSetterRef.current = undefined);
  }, [filterStateSetterRef, setFilter]);

  const handlePageChange = useCallback((e) => {
    setGridState((f) => {
      return {
        ...f,
        dataState: { ...f.dataState, skip: e.skip, take: e.take },
      };
    });
  }, []);

  const dataRecieved = useCallback((products) => {
    setGridState((f) => {
      return {
        ...f,
        products: products,
      };
    });
  }, []);

  const lastSuccessRef = useRef();

  const FilterObjRef = useContext(ExportsFilterObjectContext);

  const MountedRef = useRef(false);

  const [resolvedDataState, setResolvedDataState] = useState({
    ...gridState.dataState,
  });

  useEffect(() => {
    if (!MountedRef.current) {
      MountedRef.current = true;
      return;
    }
    const Timeout = setTimeout(() => {
      setResolvedDataState({ ...gridState.dataState });
    }, 300);
    return () => {
      clearTimeout(Timeout);
    };
  }, [gridState.dataState]);

  const ResolvedUrl = useMemo(() => {
    const url =
      BaseUrl +
      buildODataQuery(resolvedDataState, true, {
        filterBuilder:
          hasCustomFilter && isCustomFilterEnabled
            ? buildCustomODataFilter
            : undefined,
      });

    if (onUrlUpdate) {
      let NewGridState = { ...resolvedDataState };
      onUrlUpdate(BaseUrl, NewGridState);
    }
    return url;
  }, [
    BaseUrl,
    hasCustomFilter,
    isCustomFilterEnabled,
    onUrlUpdate,
    resolvedDataState,
  ]);

  const { refetch } = useQuery(ResolvedUrl, null, {
    cache: customCache || cacheType.enabled,
    onSuccess: ({ data }) => {
      lastSuccessRef.current = buildODataQuery(resolvedDataState, true);
      if (data && data.d && data.d.__count > 0) {
        dataRecieved({
          data: data.d.results,
          total: data.d.__count,
        });
      } else {
        dataRecieved({
          data: [],
          total: 0,
        });
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    FilterObjRef.current = gridState.dataState;

    return () => {
      FilterObjRef.current = undefined;
    };
  }, [FilterObjRef, gridState.dataState]);

  useEffect(() => {
    GridRefetchRef.current = refetch;
    return () => {
      GridRefetchRef.current = undefined;
    };
  }, [FilterObjRef, GridRefetchRef, refetch]);

  return (
    <div className="leafleft-grid flex-1 overflow-auto">
      <CardItemsContext.Provider value={gridState}>
        <LocalizationProvider language="pt-PT">
          <IntlProvider locale="pt-PT">
            <div className="h-100 d-flex">
              {isCustomFilterEnabled && isCustomFilterOpen && (
                <FilterList columns={Columns} />
              )}
              <div className="h-100 flex-1 d-flex flex-column cards-wrapper">
                <div className="cards-grid-container">
                  <CardsGenerator items={gridState.products.data} />
                </div>
                <Pager
                  skip={gridState.dataState.skip}
                  take={20}
                  total={gridState.products.total}
                  onPageChange={handlePageChange}
                  {...pageableOptions}
                />
              </div>
            </div>
          </IntlProvider>
        </LocalizationProvider>
      </CardItemsContext.Provider>
    </div>
  );
};

const SingleCard = React.memo(({ item }) => {
  const {
    FileThumbnailUrl,
    LeafleatName,
    ShopName,
    DateBegin,
    TotalPage,
    DateEnd,
    FileUrl,
    id,
  } = item;
  const history = useHistory();

  const resolvedStartDate = useMemo(() => {
    const startDate = DateBegin?.Name ? getDate(DateBegin.Name) : false;
    return !startDate ? false : `De ${moment(startDate).format("DD/MM/YYYY")}`;
  }, [DateBegin]);
  const resolvedEndDate = useMemo(() => {
    const endDate = DateEnd?.Name ? getDate(DateEnd.Name) : false;
    return !endDate ? false : `Até ${moment(endDate).format("DD/MM/YYYY")}`;
  }, [DateEnd]);

  const handleItemClick = useCallback(() => {
    history.push(`/leaflets/${id}`);
  }, [id, history]);

  const handleImageClick = useCallback(() => {
    if (FileUrl) {
      window.open(FileUrl, "_blank");
    }
  }, [FileUrl]);

  return (
    <div className="single-card-container">
      <img
        onClick={handleImageClick}
        className="single-card-thumbnail cursor-pointer mb-3"
        src={FileThumbnailUrl}
        alt="leaflet-thumbnail"
      />
      <div
        onClick={handleItemClick}
        className="fs-16 cursor-pointer single-card-text-title fw-bold text-black text-truncate"
      >
        {LeafleatName?.Name}
      </div>
      <div className="fs-14 text-grey text-truncate mb-1">{ShopName?.Name}</div>
      <div className="fs-14 text-grey">
        {TotalPage?.Name}
        {" página(s)"}
      </div>
      {resolvedStartDate && (
        <div className="fs-14 fw-bold mt-2 text-black">{resolvedStartDate}</div>
      )}
      {resolvedEndDate && (
        <div className="fs-14 fw-bold mt-2 text-black">{resolvedEndDate}</div>
      )}
    </div>
  );
});

const CardsGenerator = ({ items }) => {
  return items.map((e) => {
    return <SingleCard key={e.id} item={e} />;
  });
};

export default Cards;
