import React from "react";
import classnames from "classnames";
import "./Button.css";

const Button = ({ className, vType, ...rest }) => {
  return (
    <button
      className={classnames(
        "app-button rounded",
        vType ? vType : "primary",
        className
      )}
      {...rest}
    />
  );
};

export default Button;
