import { faChevronLeft } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const BackLink = ({ to }) => {
  return (
    <Link
      to={to}
      style={{ width: "fit-content" }}
      className="Disablehover color-primary d-flex align-items-center mb-2"
    >
      <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
      <span>Voltar</span>
    </Link>
  );
};

export default BackLink;
