import React, { useState, useRef } from "react";
import BaseGrid from "../Common/Grid/Grid";
import DatePicker from "../Common/DatePicker/DatePicker";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import classnames from "classnames";
import {
  FilterOperatorEnum,
  FilterTypeEnum
} from "../Common/FilterList/FilterListTypings";
import { getDate } from "../Helpers/DateHelper";
import moment from "moment";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/pro-light-svg-icons";

const DateFilterCell = ({ onChange, ...rest }) => {
  const DatePickerRef = useRef();
  const [Operator, setOperator] = useState({
    value: "eqDate",
    Text: "É igual a"
  });
  const Operators = [
    { value: "eqDate", Text: "É igual a" },
    { value: "neqDate", Text: "Não é igual a" },
    { value: "ltDate", Text: "É menor que" },
    { value: "gtDate", Text: "É maior que" },
    { value: "lteDate", Text: "É menor ou igual que" },
    { value: "gteDate", Text: "É maior ou igual que" }
  ];

  const focusedItemIndex = (data, inputText, textField) => {
    return Operators.findIndex((e) => {
      return e.value === Operator.value;
    });
  };

  const handleChange = (event) => {
    onChange({
      value: event.target.value,
      operator: Operator.value,
      syntheticEvent: event.syntheticEvent
    });
  };

  const HandleOperatorChange = (event) => {
    if (DatePickerRef.current.value) {
      onChange({
        value: DatePickerRef.current.value,
        operator: event.value.value,
        syntheticEvent: event.syntheticEvent
      });
    }
    setOperator(event.value);
  };

  const onClearButtonClick = (event) => {
    event.preventDefault();
    onChange({
      value: null,
      operator: "",
      syntheticEvent: event
    });
  };

  return (
    <div className="k-filtercell">
      <div className="k-filtercell-wrapper">
        <DatePicker
          format="dd-MM-yyyy"
          ref={DatePickerRef}
          className="flex-1"
          {...rest}
          onChange={handleChange}
        />
        <DropDownList
          focusedItemIndex={focusedItemIndex}
          style={{ width: 32 }}
          popupSettings={{ width: "" }}
          iconClassName="k-i-filter k-icon"
          className="k-dropdown-operator ml-2"
          data={Operators}
          textField={"Text"}
          onChange={HandleOperatorChange}
          value={Operator}
        />
        <button
          className={classnames("k-button k-button-icon", {
            "k-clear-button-visible": Boolean(rest.value),
            "k-clear-button-hidden": !Boolean(rest.value)
          })}
          title="Clear"
          disabled={!Boolean(rest.value)}
          onClick={onClearButtonClick}
        >
          <span className="k-icon k-i-filter-clear" />
        </button>
      </div>
    </div>
  );
};

const DifdateCell = ({ dataItem, field }) => {
  const fieldArr = field.split(".");
  let singleField = dataItem;
  for (const e of fieldArr) {
    singleField = singleField[e];
  }

  const value = parseInt(singleField);

  return (
    <td>
      {value === null ? "" : new moment(getDate(value)).format("DD/MM/YYYY")}
    </td>
  );
};

const ArticleCountCell = ({ dataItem, field }) => {
  let value = dataItem.ArticlesCount;

  if (!dataItem?.ArticleName?.Id) return <td>{value}</td>;

  const filter = `((ArticleName/Id eq ${dataItem.ArticleName.Id}))`;

  return (
    <td>
      {value}
      <Link
        target="_blank"
        to={`/promotions?customFilter=${filter}`}
        className="p-2 grid-button"
      >
        <FontAwesomeIcon size="sm" icon={faEye} title="Ver" />
      </Link>
    </td>
  );
};

const columns = [
  {
    field: "LeafleatData.MediaName.Name",
    title: "Fonte",
    // disableCustomFilter: true, //custom filter
    queryUrl: "media/query" /*custom filter */
  },
  {
    field: "ArticleName.Name",
    title: "Nome Promoção",
    queryUrl: "articleNames/query" /*custom filter */
  },
  // {
  //   field: "LeafleatData.ShopName.ChainName.Name",
  //   title: "Cadeia",
  //   queryUrl: "chains/query" /*custom filter */,
  //   dependents: ["LeafleatData.ShopName.Name"]
  // },
  {
    field:
      "ArticleData.SubCategoryName.CategoryName.MainCategoryName.MainSectorName.Name",
    title: "Sector",
    queryUrl: "mainSectors/query" /*custom filter */,
    dependents: [
      "ArticleData.SubCategoryName.MainCategoryName.Name",
      "ArticleData.SubCategoryName.CategoryName.Name",
      "ArticleData.SubCategoryName.Name"
    ]
  },
  {
    field: "LeafleatData.ShopName.Name",
    title: "Insígnia",
    queryUrl: "shops/query" /*custom filter */,
    filterDependencies: [
      {
        field: "LeafleatData.ShopName.ChainName.Name",
        queryName: "chainNames"
      }
    ]
  },
  {
    field: "ArticleData.SubCategoryName.CategoryName.MainCategoryName.Name",
    title: "Categoria Principal",
    queryUrl: "categories/main/query" /*custom filter */,
    dependents: [
      "ArticleData.SubCategoryName.CategoryName.Name",
      "ArticleData.SubCategoryName.Name"
    ]
  },
  {
    field: "ArticleData.SubCategoryName.CategoryName.Name",
    title: "Categoria",
    queryUrl: "categories/query" /*custom filter */,
    dependents: ["ArticleData.SubCategoryName.Name"],
    filterDependencies: [
      {
        field: "ArticleData.SubCategoryName.CategoryName.MainCategoryName.Name",
        queryName: "mainCategories"
      }
    ]
  },
  {
    field: "ArticleData.SubCategoryName.Name",
    title: "Subcategoria",
    queryUrl: "categories/sub/query" /*custom filter */,
    filterDependencies: [
      {
        field: "ArticleData.SubCategoryName.CategoryName.MainCategoryName.Name",
        queryName: "mainCategories"
      },
      {
        field: "ArticleData.SubCategoryName.CategoryName.Name",
        queryName: "categories"
      }
    ]
  },
  {
    field: "ArticleData.BrandName.MainBrandName.Name",
    title: "Marca Principal",
    queryUrl: "brands/main/query" /*custom filter */,
    dependents: ["ArticleData.BrandName.Name"]
  },
  {
    field: "ArticleData.BrandName.Name",
    title: "Marca",
    queryUrl: "brands/query" /*custom filter */,
    filterDependencies: [
      {
        field: "ArticleData.BrandName.MainBrandName.Name",
        queryName: "mainBrands"
      }
    ]
  },
  {
    field: "PromotionTypePromotion.PromotionType.Name",
    title: "Tipo de Promoção",
    queryUrl: "promotions/types/query" /*custom filter */
  },
  {
    field: "PromotionTypePromotion.Promotion.Name",
    title: "Promoção",
    queryUrl: "promotions/query" /*custom filter */
  },
  {
    field: "PromotionPage.Name",
    title: "Página",
    disableCustomFilter: true /*custom filter */
  },
  {
    field: "ArticlesCount",
    title: "Inserções Artigos",
    filterable: false,
    // sortable: false,
    cell: ArticleCountCell,
    disableCustomFilter: true /*custom filter */
  },

  {
    field: "LeafleatData.LeafleatName.Name",
    title: "Folheto",
    queryUrl: "leaflets/names/query" /*custom filter*/
  },
  {
    field: "LeafleatData.DateBegin.Name",
    title: "Data de Inicio",
    filter: "date",
    width: 300,
    filterCell: DateFilterCell,
    cell: DifdateCell,
    filterType: FilterTypeEnum.date, //custom filter
    dateOperator: FilterOperatorEnum.GreaterOrEqual //custom filter
  },
  {
    field: "LeafleatData.DateEnd.Name",
    title: "Data de Fim",
    filter: "date",
    width: 300,
    filterCell: DateFilterCell,
    cell: DifdateCell,
    filterType: FilterTypeEnum.date, //custom filter
    dateOperator: FilterOperatorEnum.LesserOrEqual //custom filter
  }
];

const filterOperators = {
  date: [
    { text: "grid.filterEqOperator", operator: "eqDate" },
    { text: "grid.filterEqOperator", operator: "neqDate" },
    { text: "grid.filterEqOperator", operator: "ltDate" },
    { text: "grid.filterEqOperator", operator: "gtDate" },
    { text: "grid.filterEqOperator", operator: "lteDate" },
    { text: "grid.filterEqOperator", operator: "gteDate" }
  ]
};

const PromotionArticlesGrid = () => {
  return (
    <BaseGrid
      customFilter
      // defaultSorts={[
      //   { dir: "desc", field: "LeafleatData.DateBegin.Name" },
      //   { dir: "asc", field: "LeafleatData.ShopName.ChainName.Name" },
      //   { dir: "asc", field: "LeafleatData.ShopName.Name" },
      //   { dir: "asc", field: "LeafleatData.LeafleatName.Name" },
      //   { dir: "asc", field: "ArticleName.Name" },
      //   { dir: "asc", field: "PromotionTypePromotion.PromotionType.Name" },
      //   { dir: "asc", field: "PromotionTypePromotion.Promotion.Name" },
      //   { dir: "asc", field: "PromotionPage.Name" }
      // ]}
      // filterCache={LeafletsFilterCacheState}
      // onFilterChange={setLeafletsFilterCacheState}
      // customCache={leafletsCache}
      Columns={columns}
      filterOperators={filterOperators}
      BaseUrl={"promotionArticles"}
    />
  );
};

export default PromotionArticlesGrid;
