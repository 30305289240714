import React, { useContext, useMemo } from "react";
import "./Header.css";
import { ReactComponent as AskLogo } from "../Assets/Img/360_logo_platform.svg";
import { NavLink } from "react-router-dom";
import HeaderProfile from "./HeaderProfile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-light-svg-icons";
import classnames from "classnames";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { getHighestPermission, RolesEnum } from "../AppHelper";
import { UserQueryContext } from "../User/UserProvider";

const HeaderLink = ({ to, group, children }) => {
  return (
    <NavLink
      activeClassName="selected"
      className={classnames("app-header-link", {
        "mx-3": !group,
        group
      })}
      to={to}
    >
      {children}
    </NavLink>
  );
};

const HeaderGroupLink = ({ to, children }) => {
  return (
    <NavLink
      activeClassName="selected"
      className="p-2 app-popup-link lg"
      to={to}
    >
      {children}
    </NavLink>
  );
};

const HeaderGroup = ({ to, text, children }) => {
  return (
    <div className="mx-3 position-relative app-header-group">
      <HeaderLink group to={to}>
        {text}
        <FontAwesomeIcon size="xs" className="ml-2" icon={faChevronDown} />
      </HeaderLink>

      <div className="app-header-group-popup bg-primary">{children}</div>
    </div>
  );
};

const Header = () => {
  const { data, loading, error } = useContext(UserQueryContext);

  const ResolvedRoles = useMemo(() => {
    if (data)
      return data?.Roles.reduce((roles, role) => {
        roles[role] = true;
        return roles;
      }, {});
    else return {};
  }, [data]);

  if (loading || error) {
    return <div></div>;
  }
  const Permission = getHighestPermission(ResolvedRoles);

  return (
    <div className="app-header d-flex align-items-center px-4">
      <div className="app-header-side">
        <Link to="/">
          <AskLogo className="app-header-logo" alt="" />
        </Link>
      </div>
      <div className="h-100 flex-1 d-flex justify-content-center">
        <HeaderLink to="/leaflets">Folhetos</HeaderLink>
        <HeaderGroup to="/promotions" text="Promoções">
          <HeaderGroupLink to="/promotions">Promoções</HeaderGroupLink>
          <HeaderGroupLink to="/promotions/brands">Marcas</HeaderGroupLink>
          <HeaderGroupLink to="/promotions/categories">
            Categorias
          </HeaderGroupLink>
          <HeaderGroupLink to="/promotions/articles">Artigos</HeaderGroupLink>
        </HeaderGroup>
        {Permission >= RolesEnum.BackTwo && (
          <HeaderLink to="/articles">Artigos</HeaderLink>
        )}
        {Permission >= RolesEnum.BackTwo && (
          <HeaderGroup to="/shops" text="Insígnias">
            <HeaderGroupLink to="/shops/comercialAreas">
              Área Comercial
            </HeaderGroupLink>
            <HeaderGroupLink to="/shops/channelTypes">
              Tipos de Canal
            </HeaderGroupLink>
            <HeaderGroupLink to="/shops/chains">Cadeias</HeaderGroupLink>
            <HeaderGroupLink to="/shops">Insígnias</HeaderGroupLink>
          </HeaderGroup>
        )}

        {Permission >= RolesEnum.BackTwo && (
          <HeaderGroup to="/brands" text="Marcas">
            <HeaderGroupLink to="/brands/companyTypes">
              Tipos de Marca
            </HeaderGroupLink>
            <HeaderGroupLink to="/brands/companies">Empresa</HeaderGroupLink>
            <HeaderGroupLink to="/brands/main">
              Marcas Principais
            </HeaderGroupLink>
            <HeaderGroupLink to="/brands">Marcas</HeaderGroupLink>
          </HeaderGroup>
        )}

        {Permission >= RolesEnum.BackTwo && (
          <HeaderGroup to="/categories" text="Categorias">
            <HeaderGroupLink to="/mainSectors">Sectores</HeaderGroupLink>
            <HeaderGroupLink to="/categories/main">
              Categorias Principais
            </HeaderGroupLink>
            <HeaderGroupLink to="/categories">Categorias</HeaderGroupLink>
            <HeaderGroupLink to="/categories/sub">Subcategoria</HeaderGroupLink>
          </HeaderGroup>
        )}
        {Permission >= RolesEnum.BackOne && (
          <HeaderLink to="/config">Configurações</HeaderLink>
        )}

        <HeaderGroup to="/indicators" text="Análises">
          <HeaderGroupLink to="/indicators/categoryName">
            Categorias
          </HeaderGroupLink>

          <HeaderGroupLink to="/indicators/brands">Marcas</HeaderGroupLink>
          <HeaderGroupLink to="/indicators/categoryBrands">
            Marcas por Categoria
          </HeaderGroupLink>
          <HeaderGroupLink to="/indicators/shopBrands">
            Marcas por Insígnia
          </HeaderGroupLink>
          <HeaderGroupLink to="/indicators/promotionBrands">
            Promoções por Marca
          </HeaderGroupLink>
          <HeaderGroupLink to="/indicators/promotions">
            Evolução do Preço
          </HeaderGroupLink>
        </HeaderGroup>
      </div>
      <div className="app-header-side">
        <HeaderProfile />
      </div>
    </div>
  );
};

export default Header;
