import React, { useState, useRef, useContext, useMemo } from "react";
import BaseGrid, { GridItemsContext } from "../Common/Grid/Grid";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import classnames from "classnames";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../Common/Modal/Modal";
import DeleteModal from "../Common/DeleteModalContent/DeleteModalContent";
import Checkbox from "../Common/Checkbox/Checkbox";
import { MultipleArticlesContext } from "../Promotions/PromotionsFormComp";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import LoadableButton from "../Common/Button/LoadableButton";
import { buildODataQuery } from "../Helpers/ODataHelper";
import { GridColumnMenuFilter } from "@progress/kendo-react-grid";
import "./Products.css";
import { formatPrice } from "../Helpers/MiscHelper";
// const dateCell = ({ dataItem, field }) => {
//   const value = dataItem[field];
//   return (
//     <td>{value === null ? "" : new moment(value).format("DD/MM/YYYY")}</td>
//   );
// };

const CheckboxHeaderCell = ({ ...props }) => {
  const { products } = useContext(GridItemsContext);
  const { data } = products;

  // eslint-disable-next-line no-unused-vars
  const [handleConfirm, list, setDeselection] = useContext(
    MultipleArticlesContext
  );

  const ActiveValue = useMemo(() => {
    if (data.length === 0) return false;

    let IdList = data.map((e) => {
      return e.Id;
    });

    for (const e of IdList) {
      if (!list[e]) return false;
    }
    return true;
  }, [data, list]);

  const handleChange = () => {
		let IdList = data.map((e) => {
			return e.Id;
		});

    setDeselection((t) => {
      
      let res = {
        ...t
      };
      if (ActiveValue) {
        for (const e of IdList) {
          res[e] = false;
        }
      } else {
        for (const e of IdList) {
          res[e] = true;
        }
      }
      //   res[Id] = res[Id] ? false : true;
      return res;
    });
  };

  return (
    <div className="align-items-center d-flex h-100">
      <Checkbox checked={Boolean(ActiveValue)} onChange={handleChange} />
    </div>
  );
};

// const ValueRenderFilterIcon = ({ ...props }) => {
//   return <span className="k-icon k-i-filter k-icon"></span>;
// };

export const InsertQuantityFilterCell = ({ onChange, ...rest }) => {
  const QuantityRef = useRef();

  const [Operator, setOperator] = useState({
    value: "eq",
    text: "É igual a"
  });

  const Operators = [
    { value: "eq", text: "É igual a" },
    { value: "lt", text: "É menor que" },
    { value: "gt", text: "É maior que" },
    { value: "ne", text: "Não é igual a" }
  ];

  // const focusedItemIndex = (data, inputText, textField) => {
  //   return Operators.findIndex((e) => {
  //     return e.value === Operator.value;
  //   });
  // };

  const handleChange = (event) => {
    if (event.target.value) {
      onChange({
        value: event.target.value * 100,
        operator: Operator.value,
        syntheticEvent: event.syntheticEvent
      });
    } else {
      onChange({
        value: null,
        operator: "",
        syntheticEvent: event
      });
    }
  };

  const HandleOperatorChange = (event) => {
    if (QuantityRef.current.value) {
      onChange({
        value: QuantityRef.current.value,
        operator: event.value.value,
        syntheticEvent: event.syntheticEvent
      });
    }
    setOperator(event.value);
  };

  const onClearButtonClick = (event) => {
    event.preventDefault();
    onChange({
      value: null,
      operator: "",
      syntheticEvent: event
    });
  };

  return (
    <div className="k-filtercell">
      <div className="k-filtercell-wrapper">
        <NumericTextBox
          spinners={false}
          ref={QuantityRef}
          onChange={handleChange}
        />
        <DropDownList
          style={{ width: 32 }}
          iconClassName="k-i-filter k-icon"
          className="k-dropdown-operator ml-2"
          data={Operators}
          ignoreCase={true}
          value={Operator}
          textField={"text"}
          onChange={HandleOperatorChange}
          popupSettings={{ width: "" }}
          required={false}
          tabIndex={0}
          validityStyles={true}
        />
        <button
          className={classnames("k-button k-button-icon", {
            "k-clear-button-visible": Boolean(rest.value),
            "k-clear-button-hidden": !Boolean(rest.value)
          })}
          title="Clear"
          disabled={!Boolean(rest.value)}
          onClick={onClearButtonClick}
        >
          <span className="k-icon k-i-filter-clear" />
        </button>
      </div>
    </div>
  );
};

const CheckboxCell = ({ dataItem, field }) => {
  // eslint-disable-next-line no-unused-vars
  const [handleConfirm, list, setDeselection] = useContext(
    MultipleArticlesContext
  );

  const ActiveValue = useMemo(() => {
    const Id = dataItem.Id;
    return list[Id];
  }, [dataItem.Id, list]);

  const handleChange = () => {
    setDeselection((t) => {
      const Id = dataItem.Id;
      let res = {
        ...t
      };
      res[Id] = res[Id] ? false : true;
      return res;
    });
  };

  return (
    <td>
      <Checkbox checked={Boolean(ActiveValue)} onChange={handleChange} />
    </td>
  );
};

const quantityCell = ({ dataItem, field }) => {
  const value = dataItem["Quantity"].Name;
  return <td>{formatPrice(value)}</td>;
};

// const ButtonCell = ({ dataItem }) => {
//   const [toggleRemove] = useContext(ToggleEditionModalsContext);

//   const history = useHistory();
//   const value = dataItem["Id"];

//   const openForm = () => {
//     history.push(`/articles/${value}`);
//   };

//   const handleRemove = () => {
//     toggleRemove(dataItem);
//   };

//   return (
//     <td>
//       <div className="d-flex align-items-center justify-content-end">
//         <div onClick={openForm} className="p-2 mr-2 grid-button">
//           <FontAwesomeIcon size="sm" icon={faPencil} title="Editar" />
//         </div>
//         <div onClick={handleRemove} className="p-2 grid-button">
//           <FontAwesomeIcon size="sm" icon={faTrashAlt} title="Eliminar" />
//         </div>
//       </div>
//     </td>
//   );
// };

const ToggleEditionModalsContext = React.createContext();

// const CustomColumnFilter = ({ ...props }) => {
//   console.log(props);

//   const [extraFilterProps, setExtraFilterProps] = useState(
//     props.ExtraFilterProps ? [...props.ExtraFilterProps] : []
//   );

//   const addExtraFilter = () => {
//     setExtraFilterProps((t) => [
//       ...t,
//       {
//         ExtraFilterObj: { ...props.firstFilterProps, value: "" },
//         logicData: [...props.logicData],
//         logicValue: { ...props.logicValue },
//         onLogicChange: props.onLogicChange,
//       },
//     ]);
//   };

//   return (
//     <>
//       <GridColumnMenuFilterUI {...props} />
//       {extraFilterProps.map((e, i) => {
//         return (
//           <div key={i}>
//             <DropDownList
//               className="k-filter-and"
//               delay={500}
//               data={e.logicData}
//               onChange={e.onLogicChange}
//               ignoreCase={true}
//               popupSettings={{ animate: true, height: "200px" }}
//               required={false}
//               tabIndex={0}
//               textField={"text"}
//               validityStyles={true}
//               value={e.logicValue}
//             />
//             <GridColumnMenuFilterCell {...e.ExtraFilterObj} />
//           </div>
//         );
//       })}
//       <Button type="button" onClick={addExtraFilter}>
//         Adicionar Condição
//       </Button>
//     </>
//   );
// };

const ColumnMenu = ({ ...props }) => {
  return (
    <div>
      <GridColumnMenuFilter {...props} expanded={true} />
    </div>
  );
};

const columns = [
  // { field: "Id", title: "Id", width: 75,filterable: false },
  {
    field: "Id",
    title: "",
    width: 50,
    cell: CheckboxCell,
    filterCell: () => null,
    headerCell: CheckboxHeaderCell
  },
  { field: "Article.Name", title: "Nome", columnMenu: ColumnMenu },
  {
    field: "SubCategoryName.CategoryName.Name",
    title: "Categoria"
    // columnMenu: ColumnMenu,
  },
  {
    field: "SubCategoryName.Name",
    title: "Subcategoria"
    // columnMenu: ColumnMenu,
  },
  { field: "BrandName.Name", title: "Marca", columnMenu: ColumnMenu },
  { field: "Pack.Name", title: "Formato Embalagem", columnMenu: ColumnMenu },
  { field: "Range.Name", title: "Variedade", columnMenu: ColumnMenu },
  {
    field: "Quantity.Name",
    filter: "numeric",
    step: "0.01",
    title: "Quantidade",
    cell: quantityCell,
    columnMenu: ColumnMenu
    // filterCell: InsertQuantityFilterCell
  },
  { field: "Unit.Name", title: "Unidade de Medida", columnMenu: ColumnMenu },
  {
    field: "ProductionType.Name",
    title: "Tipo de Produção",
    columnMenu: ColumnMenu
  }

  // { title: "", width: 90, cell: ButtonCell, filterable: false },
];

const ProductsSelectionGrid = React.memo(
  ({ MultipleArticlesState, loading, handleReturn }) => {
    const SelectedCARef = useRef();

    const [deletingArticles, setDeletingArticles] = useState(false);

    const toggleDeleteModal = (data) => {
      if (!deletingArticles) {
        SelectedCARef.current = data;
      } else {
        SelectedCARef.current = undefined;
      }
      setDeletingArticles((open) => !open);
    };

    // eslint-disable-next-line no-unused-vars
    const [handleConfirm, list, setDeselection] = useContext(
      MultipleArticlesContext
    );

    const ItemsUrl = useRef("");

    const handleItemsUrlUpdate = (BaseUrl, obj) => {
      let NewGridState = { ...obj };
      NewGridState.count = 0;
      NewGridState.take = 0;
			NewGridState.skip = 0;
      const url = BaseUrl + buildODataQuery(NewGridState, true);
      ItemsUrl.current = [url, obj];
    };

    const handleAddAllArticles = () => {
      handleConfirm(ItemsUrl.current);
    };

    return (
      <div
        style={{ display: !MultipleArticlesState ? "none" : "flex" }}
        className="p-4 h-100 d-flex flex-column main-grid"
      >
        <div
          onClick={handleReturn}
          style={{ width: "fit-content" }}
          className="Disablehover cursor-pointer color-primary d-flex align-items-center mb-3"
        >
          <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
          <span>Voltar</span>
        </div>
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div className="fs-40 fw-medium">Artigos</div>
          <LoadableButton loading={loading} onClick={handleAddAllArticles}>
            Adicionar os artigos seleccionados
          </LoadableButton>
        </div>
        <ToggleEditionModalsContext.Provider value={[toggleDeleteModal]}>
          <Modal isOpen={deletingArticles} onClose={toggleDeleteModal}>
            <DeleteModal
              data={SelectedCARef.current}
              closeModal={toggleDeleteModal}
              url="articles"
            />
          </Modal>
          <BaseGrid
            onUrlUpdate={handleItemsUrlUpdate}
            Columns={columns}
            BaseUrl={"articles/data"}
          />
        </ToggleEditionModalsContext.Provider>
      </div>
    );
  }
);

export default ProductsSelectionGrid;
