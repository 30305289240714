import React, { useContext, useState } from "react";
import Button from "../Common/Button/Button";
import ProductsGrid from "./ProductsGrid";

import ProductsForm, {
  LeafletFormConverter as ProductsFormConverter,
} from "./ProductsForm";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import ExcelExportButton from "../Common/ExcelButton/ExcelExportButton";
import { getHighestPermission, RolesEnum } from "../AppHelper";
import { UserRoleContext } from "../App";
import { faFileExcel } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ProductsImportComponent from "./ProductsImports";
import { MemoryCache } from "../Helpers/IOClient";
import PlusButton from "../Common/PlusButton/PlusButton";

export const ProductsCacheContext = React.createContext();
export const ProductsFilterCacheContext = React.createContext();

const Products = () => {
  const Roles = useContext(UserRoleContext);
  const Permission = getHighestPermission(Roles);

  const [productsCache, setProductsCache] = useState(() => new MemoryCache());
  const [ProductsFilterCacheState, setProductsFilterCacheState] = useState();

  return (
    <ProductsCacheContext.Provider value={[productsCache, setProductsCache]}>
      <ProductsFilterCacheContext.Provider
        value={[ProductsFilterCacheState, setProductsFilterCacheState]}
      >
        <Switch>
          <Route exact path="/articles">
            <ProductsComp />
          </Route>
          {Permission >= RolesEnum.Admin && (
            <Route exact path="/articles/import">
              <ProductsImportComponent />
            </Route>
          )}
          {Permission >= RolesEnum.BackOne && (
            <Route exact path="/articles/new">
              <ProductsForm />
            </Route>
          )}
          {Permission >= RolesEnum.BackOne && (
            <Route path="/articles/:id/duplicate">
              <ProductsFormConverter duplicate />
            </Route>
          )}
          {Permission >= RolesEnum.BackOne && (
            <Route path="/articles/:id">
              <ProductsFormConverter />
            </Route>
          )}
          <Redirect to="/articles" />
        </Switch>
      </ProductsFilterCacheContext.Provider>
    </ProductsCacheContext.Provider>
  );
};

const ProductsComp = () => {
  const history = useHistory();

  const openForm = () => {
    history.push("/articles/new");
  };

  const goToImports = () => {
    history.push("/articles/import");
  };

  const Roles = useContext(UserRoleContext);
  const Permission = getHighestPermission(Roles);

  return (
    <>
      <div className="p-4 h-100 d-flex flex-column main-grid">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div className="fs-40 fw-medium">Artigos</div>
          <div className="d-flex flex-1 align-items-center justify-content-end">
            {Permission >= RolesEnum.BackOne && (
              <PlusButton className="mr-3" onClick={openForm}>
                Adicionar
              </PlusButton>
            )}
            {Permission >= RolesEnum.Admin && (
              <Button type="button" className="mr-3" onClick={goToImports}>
                <FontAwesomeIcon icon={faFileExcel} className="mr-2" />
                Importar
              </Button>
            )}
            <ExcelExportButton
              removemargin={!(Permission >= RolesEnum.BackOne)}
              BaseUrl={"articles/data/export"}
            />
          </div>
        </div>
        <ProductsGrid />
      </div>
    </>
  );
};

export default Products;
