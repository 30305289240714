import React, { useContext, useMemo } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

const CustomFilterContext = React.createContext();

export const useGridCustomFilter = () => useContext(CustomFilterContext);

const GridCustomFilterProvider = ({ children }) => {
  const location = useLocation();

  const filter = useMemo(() => {
    const queryObject = queryString.parse(location.search);
    return queryObject.customFilter;
  }, [location.search]);

  return (
    <CustomFilterContext.Provider value={filter}>
      {children}
    </CustomFilterContext.Provider>
  );
};

export default GridCustomFilterProvider;
