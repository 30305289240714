import React, { useState, useContext } from "react";
import ComercialAreaGrid from "./ComercialAreaGrid";

import AddComercialAreaModal from "./AddComercialAreaModal/AddComercialAreaModal";
import ExcelExportButton from "../../Common/ExcelButton/ExcelExportButton";
import { UserRoleContext } from "../../App";
import { getHighestPermission, RolesEnum } from "../../AppHelper";
import PlusButton from "../../Common/PlusButton/PlusButton";

const ComercialArea = () => {
  const [addingComercialArea, setAddingComercialArea] = useState(false);

  const Roles = useContext(UserRoleContext);
  const Permission = getHighestPermission(Roles);
  const toggleModal = () => {
    setAddingComercialArea((open) => !open);
  };

  return (
    <>
      <AddComercialAreaModal
        isOpen={addingComercialArea}
        onClose={toggleModal}
      />

      <div className="p-4 h-100 d-flex flex-column main-grid">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div className="fs-40 fw-medium">Área Comercial</div>
          <div className="d-flex flex-1 align-items-center justify-content-end">
            {Permission >= RolesEnum.BackOne && (
              <PlusButton className="mr-3" onClick={toggleModal}>
                Adicionar
              </PlusButton>
            )}
            <ExcelExportButton
              removemargin={!(Permission >= RolesEnum.BackOne)}
              BaseUrl={"comercialAreas/export"}
            />
          </div>
        </div>
        <ComercialAreaGrid />
      </div>
    </>
  );
};

export default ComercialArea;
