import React, { useState, useEffect, useMemo } from "react";
import "./Dropdown.css";
import { useQuery } from "../../Helpers/IOClient";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { buildODataQuery } from "../../Helpers/ODataHelper";
import NoMatchsFound from "../Input/NoMatchsFound";

export const SimpleDropdownInput = ({
  value,
  optionalFilters,
  ExtraDropdownOption,
  onRemove,
  extraRef,
  options,
  filterField,
  ExtraFilters,
  ...rest
}) => {
  return (
    <DropDownList
      data={options}
      textField={filterField}
      //   suggest={false}
      value={value}
      //   onChange={onChange}
      {...rest}
      //   placeholder="e.g. Andorra"
    />
  );
};

const createODataDropdownInput = (endpoint, filterField, orderBy = "Name") => {
  const DropdownInput = ({
    value,
    optionalFilters,
    ExtraDropdownOption,
    ValueRender,
    ItemRender,
    onRemove,
    extraRef,
    formatQueryData,
    ExtraFilters,
    ...rest
  }) => {
    const [resolvedData, setData] = useState([]);
    const [filter, setFilter] = useState({});

    const [resolvedFilter, setResolvedFilter] = useState({});

    useEffect(() => {
      // if (!filter) {
      //   setResolvedFilter(filter);
      // }

      const timeout = setTimeout(() => {
        setResolvedFilter(filter);
      }, 200);

      return () => {
        clearTimeout(timeout);
      };
    }, [filter]);

    const resolvedEndpoint = useMemo(() => {
      const completeResolvedFilter = { ...resolvedFilter };

      completeResolvedFilter.filter = {
        filters: [
          ...(resolvedFilter?.filter?.filters || []),
          ...(optionalFilters ? [optionalFilters] : [])
        ],
        logic: "and"
      };

      completeResolvedFilter.ExtraFilters = ExtraFilters;
      completeResolvedFilter.take = 20;
      if (orderBy) {
        completeResolvedFilter.sort = [{ field: orderBy, dir: "asc" }];
      }
      return endpoint + buildODataQuery(completeResolvedFilter, true);
    }, [ExtraFilters, optionalFilters, resolvedFilter]);

    const { loading, data } = useQuery(resolvedEndpoint, null, {
      onSuccess: ({ data }) => {
        // ;
        // timeoutRef.current = setTimeout(() => {
        //   setData(data);
        // });
      },
      cache: false
    });

    useEffect(() => {
      setData(() => {
        if (!data) return [];
        if (typeof formatQueryData === "function") {
          return formatQueryData(data);
        }
        return data;
      });
      // return () => {
      //   clearTimeout(timeoutRef.current);
      // };
    }, [data, formatQueryData]);

    const filterChange = (event) => {
      setFilter({ filter: { filters: [event.filter] } });
    };

    const ExtraOptionResolvedData = useMemo(() => {
      return [ExtraDropdownOption, ...resolvedData];
    }, [ExtraDropdownOption, resolvedData]);

    // const itemRender = (li, itemProps) => {
    // 	const {dataItem} = itemProps;
    // 	const splitedFields = filterField.split('.');
    // 	let resolvedVal = dataItem;
    // 	for (const e of splitedFields) {
    // 		resolvedVal = resolvedVal[e];
    // 	}
    //       const itemChildren = <span>{resolvedVal}</span>;
    //       return React.cloneElement(li, li.props, itemChildren);
    //   }

    return (
      <DropDownList
        data={ExtraDropdownOption ? ExtraOptionResolvedData : resolvedData}
        filterable={Boolean(filterField)}
        textField={filterField}
        onFilterChange={filterChange}
        itemRender={ItemRender ? ItemRender : undefined}
        //   suggest={false}
        ValueRender={ValueRender ? ValueRender : undefined}
        listNoDataRender={() => <NoMatchsFound />}
        loading={loading}
        value={value}
        //   onChange={onChange}
        {...rest}
        //   placeholder="e.g. Andorra"
      />
    );
  };

  return DropdownInput;
};

export default createODataDropdownInput;
