import React from "react";
import "./LoaderSpinner.css";
import classnames from "classnames";

const LoaderSpinner = (props) => {
  const { className, size } = props;

  const svgComp = (
    <svg className={classnames("loader-spinner", size)} viewBox="25 25 50 50">
      <circle
        className="path"
        cx="50"
        cy="50"
        r="20"
        fill="none"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  );

  //   if (svgOnly) return svgComp;

  return <div className={classnames(className)}>{svgComp}</div>;
};

export default LoaderSpinner;
