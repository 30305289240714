import React from "react";
import Button from "./Button";
import LoaderSpinner from "../LoaderSpinner/LoaderSpinner";

const LoadableButton = ({ loading, disabled, children, ...rest }) => {
  return (
    <Button disabled={loading || disabled} {...rest}>
      {loading ? (
        <div className="d-flex align-items-center justify-content-center">
          <LoaderSpinner size={"xxs"} className="mx-2 text-primary" />
        </div>
      ) : (
        children
      )}
    </Button>
  );
};

export default LoadableButton;
