import React from "react";
import { buildCategoriesOnlyFilterQuery } from "../../Indicators/IndicatorsHelper";
import { graphColorPallete, GraphContainer } from "./Graphs";

const builder = (data) => {
  const values = data.map((v) => {
    return {
      name: v.Name,
      value: v.ActionsSOV
    };
  });
  return {
    title: {
      text: "SOV - Categorias",
      left: "left"
    },
    tooltip: {
      trigger: "item"
    },
    // legend: {
    //   top: "5%",
    //   left: "center",
    // },
    series: [
      {
        // name: "访问来源",
        type: "pie",
        color: graphColorPallete,
        radius: ["40%", "70%"],
        avoidLabelOverlap: false,
        // label: {
        //   show: false,
        //   position: "center",
        // },
        // emphasis: {
        //   label: {
        //     show: true,
        //     fontSize: "40",
        //     fontWeight: "bold",
        //   },
        // },
        labelLine: {
          show: false
        },
        data: values
      }
    ],
    promoData: data
  };
};

const CategorySOVGraph = () => {
  return (
    <GraphContainer
      filterBuilder={buildCategoriesOnlyFilterQuery}
      url="indicators/categoriesOnly?$orderby=ActionsSOV desc&$top=10"
      builder={builder}
    />
  );
};

export default CategorySOVGraph;
