import React, { useState } from "react";
import { faBezierCurve, faSave } from "@fortawesome/pro-light-svg-icons";
import LabeledInput from "../../../../Common/Input/LabeledInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadableButton from "../../../../Common/Button/LoadableButton";
import { createEntityModal } from "../../../../Common/Modal/ModalHelper";
import { ErrorMessage } from "../../../../Leaflets/LeafletForm";
import ErrorMessageHandler from "../../../../Common/ErrorMessage/ErrorMessage";


const InnerAddLeafletMediaModal = ({
	form,
	post,
	loading,
	handleChanges,
	setForm,
	error
  }) => {
	const { Name } = form;
	const [errorState, setErrorState] = useState({});
	const isFormValid = () => {
		let valid = true;
    let newErrorState = {};
	
	  if (!/(.|\s)*\S(.|\s)*/.test(Name)) {
		valid = false;
		newErrorState.Name = true;
	  }
	  setErrorState(newErrorState);
    return valid;
	};
  
	const handleSubmit = (e) => {
	  e.preventDefault();
  
	  const formValidation = isFormValid();
	  if (formValidation) {
		post(form);
	  }
	};
  
	return (
	  <div style={{ width: 576 }} className="mt-4">
		<form onSubmit={handleSubmit}>
		  <LabeledInput
			className="mb-4"
			name="Name"
			value={Name}
			placeholder=" "
			error={errorState.Name && `Nome ${ErrorMessage}`}
			onChange={handleChanges}
			//   error={emailError}
			// hasErrors={!isEmailValid}
			text="Nome"
		  />
		  {error && (
          <div className="text-danger mb-1 text-center">
            <ErrorMessageHandler error={error} />
          </div>
        )}
		  <LoadableButton loading={loading} className="w-100" type="submit">
			<span>
			  <FontAwesomeIcon className="mr-2" icon={faSave} />
			  Guardar
			</span>
		  </LoadableButton>
		</form>
	  </div>
	);
  };
  
  const AddPagesModal = createEntityModal({
	getEndpoint: (data) => (data ? `promotions/pages/${data.Id}` : `promotions/pages`),
	Component: InnerAddLeafletMediaModal,
	headerTitle: "Adicionar Páginas",
	headerIcon: faBezierCurve,
  });
  
  export default AddPagesModal;

// const AddPagesModal = ({data}) => {
//   const closeModal = useContext(ModalCloseContext);
//   const refetchRef = useContext(GridRefechContext);
//   const [form, setForm] = useState(() => {
//     return {
// 	  Name: "",
// 	  ...data
//     };
//   });

//   const handleChanges = (e) => {
//     const { name, value } = e.target;

//     setForm((f) => {
//       return {
//         ...f,
//         [name]: value,
//       };
//     });
//   };

//   const [post, { loading }] = usePost(data?`promotions/pages/${data.Id}`:`promotions/pages`, null, {
//     onSuccess: ({ data }) => {
// 		if(refetchRef.current)refetchRef.current()
// 		closeModal();
// 	},
//     onError: ({ error }) => {
// 		console.log(error)
// 	},
//   });

//   const { Name } = form;

//   const isFormValid = () => {
// 	  if(!(/(.|\s)*\S(.|\s)*/.test(Name))) return false
// 	  else return true
//   };

//   const handleSubmit = (e) => {
// 	  e.preventDefault();
	  
// 	  const formValidation = isFormValid();
// 	  if(formValidation){
// 		post(form)
// 	  }
//   };

//   return (
//     <div className="p-4">
//       <ModalHeader icon={faBezierCurve}>Adicionar Páginas</ModalHeader>

//       <div style={{ width: 576 }} className="mt-4">
//         <form
// 		onSubmit={handleSubmit}>
//           <LabeledInput
//             className="mb-4"
//             name="Name"
// 			value={Name}
// 			placeholder=" "
//             required="required"
//             pattern="(.|\s)*\S(.|\s)*"
//             onChange={handleChanges}
//             //   error={emailError}
//             // hasErrors={!isEmailValid}
//             text="Nome"
//           />
//           <LoadableButton loading={loading} className="w-100" type="submit">
//             <span>
//               <FontAwesomeIcon className="mr-2" icon={faSave} />
//               Guardar
//             </span>
//           </LoadableButton>
//         </form>
//       </div>
//     </div>
//   );
// };


