import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/pro-solid-svg-icons";
import { UserQueryContext } from "../User/UserProvider";
import { useContext } from "react";
import { logout } from "../Helpers/AuthHelper";
import { useState } from "react";
import classnames from "classnames";
import { useEffect } from "react";
import { useRef } from "react";
import { Link } from "react-router-dom";

const OpenToggleContext = React.createContext();

const ProfilePopup = ({ data, domRef }) => {
  const { Email } = data;
  const toggle = useContext(OpenToggleContext);
  return (
    <div ref={domRef} className="app-header-profile-popup">
      <div className="d-flex align-items-center p-4">
        <div className="app-header-profile mr-3">
          <FontAwesomeIcon icon={faUser} />
        </div>
        <div className="text-secondary">{Email}</div>
      </div>

      <div className="border-bottom">
        <Link
          to="/changePassword"
          className="app-popup-link w-100 px-4 py-2"
          onClick={toggle}
        >
          Alterar Password
        </Link>
      </div>

      <div className="app-header-signout p-4" onClick={logout}>
        Terminar sessão
      </div>
    </div>
  );
};

const HeaderProfile = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { data } = useContext(UserQueryContext);

  const toggleOpen = () => {
    setIsOpen((o) => !o);
  };

  const popupRef = useRef();

  useEffect(() => {
    if (!isOpen) return;

    const listener = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [isOpen]);

  return (
    <div className="position-relative">
      <div
        className={classnames("app-header-profile interectable", {
          active: isOpen,
        })}
        onMouseDown={!isOpen ? toggleOpen : undefined}
      >
        <FontAwesomeIcon icon={faUser} />
      </div>
      <OpenToggleContext.Provider value={toggleOpen}>
        {data && isOpen && <ProfilePopup data={data} domRef={popupRef} />}
      </OpenToggleContext.Provider>
    </div>
  );
};

export default HeaderProfile;
