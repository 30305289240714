import React, { useState, useContext, useCallback } from "react";
import Modal, { ModalHeader } from "./Modal";
import { usePost } from "../../Helpers/IOClient";
import { GridRefechContext } from "../../App";

export const createEntityModal = (Settings) => {
  const {
    getEndpoint,
    headerTitle,
    headerIcon,
    Component,
    getBaseForm
  } = Settings;

  const ModalComponent = ({ data, onClose, setLoading }) => {
    const refetchRef = useContext(GridRefechContext);
    const [form, setForm] = useState(() => {
      if (getBaseForm) return getBaseForm(data);
      else return {
        Name: "",
        ...data
      };
    });

    const handleChanges = useCallback((e) => {
      const { name, value } = e.target;

      setForm((f) => {
        return {
          ...f,
          [name]: value
        };
      });
    },[]);

    const url = getEndpoint(data);

    const [post, { loading, error }] = usePost(url, null, {
      onSuccess: ({ data }) => {
        if (refetchRef.current) refetchRef.current();
        setLoading(false);
        onClose();
      },
      onError: ({ error }) => {
        setLoading(false);
        console.log(error);
      }
    });

    const handlePost = (body) => {
      setLoading(true);
      post(body);
    };

    return (
      <div className="p-4">
        <ModalHeader icon={headerIcon}>{headerTitle}</ModalHeader>
        <Component
          form={form}
          post={handlePost}
          loading={loading}
          error={error}
          handleChanges={handleChanges}
          setForm={setForm}
        />
      </div>
    );
  };

  const ModalContainer = ({ data, isOpen, onClose }) => {
    const [loading, setLoading] = useState(false);
    return (
      <Modal disableClosure={loading} isOpen={isOpen} onClose={onClose}>
        <ModalComponent setLoading={setLoading} data={data} onClose={onClose} />
      </Modal>
    );
  };

  return ModalContainer;
};
