import React from "react";
import ExcelExportButton from "../Common/ExcelButton/ExcelExportButton";
import { FilterListToggler } from "../Common/FilterList/FilterListProvider";
import { GridCacheProvider, GridProvider } from "../Common/Grid/GridHelper";
import PromotionBrandsGrid from "./PromotionBrandsGrid";

const PromotionBrands = () => {
  return (
    <GridCacheProvider>
      <GridProvider
        filterUrl="PromotionBrands/filters"
        enableCustomFilters
        id="promotions-brands"
      >
        <PromotionBrandsComp />
      </GridProvider>
    </GridCacheProvider>
  );
};

const PromotionBrandsComp = () => {
  return (
    <div className="p-4 h-100 d-flex flex-column main-grid">
      <div className="d-flex align-items-center justify-content-between mb-2">
        <div className="d-flex align-items-center">
          <div className="fs-40 fw-medium mr-3">Promoções Marcas</div>
        </div>
        <div className="d-flex align-items-center">
          <ExcelExportButton BaseUrl={"promotionBrands/export"} />
          <FilterListToggler />
        </div>
      </div>
      <PromotionBrandsGrid />
    </div>
  );
};

export default PromotionBrands;
