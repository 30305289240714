import React, { useState } from "react";
import styles from "./Tabs.module.css";
import classnames from "classnames";

const TabsHeaderItem = ({ isSelected, setSelectedTab, tab }) => {
  const { name } = tab;

  const handleClick = () => {
    setSelectedTab(tab);
  };

  return (
    <div
      onClick={handleClick}
      className={classnames(styles.tabsHeaderItem, {
        [styles.selected]: isSelected,
      })}
    >
      {name}
    </div>
  );
};

const TabsHeader = ({ options, selectedTab, setSelectedTab }) => {
  return (
    <div className={classnames(styles.tabsHeader)}>
      {options.map((tab, i) => {
        return (
          <TabsHeaderItem
            setSelectedTab={setSelectedTab}
            tab={tab}
            isSelected={tab === selectedTab}
            key={i}
          />
        );
      })}
    </div>
  );
};

const Tabs = ({ options, domRef }) => {
  const [selectedTab, setSelectedTab] = useState(() => options[0]);
  const { Component } = selectedTab;
  return (
    <div ref={domRef} className="d-flex flex-column flex-1">
      <TabsHeader
        setSelectedTab={setSelectedTab}
        options={options}
        selectedTab={selectedTab}
      />
      <Component />
    </div>
  );
};

export default Tabs;
