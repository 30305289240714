import React, { useState, useContext } from "react";
import Modal, { ModalHeader } from "../../../Common/Modal/Modal";
import { useRolesState, getRolesFromState } from "../UsersHelper";
import { faUser } from "@fortawesome/pro-solid-svg-icons";
import LoadableButton from "../../../Common/Button/LoadableButton";
import Checkbox from "../../../Common/Checkbox/Checkbox";
import {
  passwordErrorTypes,
  usePasswordErrors,
} from "../../../Helpers/PasswordHelper";
import { usePost } from "../../../Helpers/IOClient";
import LabeledInput from "../../../Common/Input/LabeledInput";
import { isEmail } from "../../../Helpers/MiscHelper";
import { GridRefechContext } from "../../../App";
import { useHistory } from "react-router-dom";

const userErrorTypes = {
  invalidEmail: "invalidEmail",
  emailExists: "emailExists",
};

const InnerForm = ({ setLoading, toggleOpen }) => {
  const [form, setForm] = useState(() => {
    return {
      email: "",
      newPassword: "",
    };
  });

  const [error, setError] = useState();

  // eslint-disable-next-line no-unused-vars
  const [unknownError, setUnknownError] = useState();

  const [rolesState, handleRoleChanges] = useRolesState();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setError();
    setForm((f) => {
      return {
        ...f,
        [name]: value,
      };
    });
  };

  const GridRefetchRef = useContext(GridRefechContext);
  const history = useHistory();
  const [post, { loading }] = usePost(`admins/users`, null, {
    onSuccess: ({ data }) => {
      setLoading(false);
      toggleOpen();
      if (GridRefetchRef.current) {
        GridRefetchRef.current();
      }
      history.push(`/config/users/${data.Id}`);
      //   setSucceeded(true);
    },
    onError: ({ error }) => {
      if (error.data && error.data.Message) {
        if (error.data.Message === "UserExists")
          setError(userErrorTypes.emailExists);
        else if (error.data.Message.includes("Passwords must have"))
          setError(passwordErrorTypes.newPasswordRequired);
        else setUnknownError(error);
      } else {
        setUnknownError(error);
      }
    },
  });

  const { email, newPassword } = form;

  const { admin, backofficeAll, backofficePartial, client } = rolesState;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUnknownError();

    if (!isEmail(email)) {
      setError(userErrorTypes.invalidEmail);
      return;
    } else if (newPassword === "" || newPassword.length < 6) {
      setError(passwordErrorTypes.newPasswordRequired);
      return;
    }

    setLoading(true);

    post({
      Email: email,
      Password: newPassword,
      ConfirmPassword: newPassword,
      Roles: getRolesFromState(rolesState),
    });
  };

  const { newPasswordError } = usePasswordErrors(error);

  const invalidEmailError =
    error === userErrorTypes.invalidEmail &&
    "Por favor, insira um email válido.";

  const emailExistsError =
    error === userErrorTypes.emailExists && "Este email já existe.";

  return (
    <div className="p-4">
      <ModalHeader icon={faUser}>Adicionar Utilizador</ModalHeader>

      <form onSubmit={handleSubmit} style={{ width: 576 }} className="mt-4">
        <LabeledInput
          className="mb-4"
          name="email"
          type="email"
          value={email}
          onChange={handleChange}
          error={invalidEmailError || emailExistsError}
          // hasErrors={!isEmailValid}
          text="Email"
        />

        <LabeledInput
          className="mb-4"
          name="newPassword"
          type="password"
          value={newPassword}
          onChange={handleChange}
          error={newPasswordError}
          autoComplete="new-password"
          // hasErrors={!isEmailValid}
          text="Nova password"
        />

        <div className="text-secondary fw-medium mb-2">Permissões</div>
        <Checkbox
          name="admin"
          className="mb-3"
          checked={admin}
          onChange={handleRoleChanges}
        >
          Admin
        </Checkbox>
        <Checkbox
          name="backofficeAll"
          className="mb-3"
          checked={backofficeAll}
          onChange={handleRoleChanges}
        >
          Backoffice 1
        </Checkbox>
        <Checkbox
          name="backofficePartial"
          className="mb-3"
          checked={backofficePartial}
          onChange={handleRoleChanges}
        >
          Backoffice 2
        </Checkbox>
        <Checkbox
          name="client"
          className="mb-5"
          checked={client}
          onChange={handleRoleChanges}
        >
          Cliente
        </Checkbox>

        <LoadableButton
          loading={loading}
          disabled={Boolean(error)}
          className="w-100"
        >
          Adicionar
        </LoadableButton>
      </form>
    </div>
  );
};

const UsersCreationModal = ({ open, toggleOpen }) => {
  const [loading, setLoading] = useState(false);

  return (
    <Modal isOpen={open} disableClosure={loading} onClose={toggleOpen}>
      <InnerForm setLoading={setLoading} toggleOpen={toggleOpen} />
    </Modal>
  );
};

export default UsersCreationModal;
