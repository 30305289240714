import React from "react";
import "./Input.css";
import classnames from "classnames";

const Input = ({ className, ...rest }) => {
  return (
    <input
      className={classnames("app-input rounded w-100 px-2", className)}
      {...rest}
    />
  );
};

export default Input;
