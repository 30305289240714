export const passwordErrorTypes = {
  oldPasswordRequired: "oldPasswordRequired",
  newPasswordRequired: "newPasswordRequired",
  passwordInvalid: "passwordInvalid",
  passwordsDontMatch: "passwordsDontMatch",
  incorrectPassword: "incorrectPassword",
};

export const usePasswordErrors = (error) => {
  let oldPasswordError =
    error === passwordErrorTypes.oldPasswordRequired &&
    "Password antiga é obrigatória.";

  let newPasswordError =
    error === passwordErrorTypes.newPasswordRequired &&
    "As passwords devem ter no minimo 6 caracteres, pelo menos um caractere que não seja letra ou dígito, pelo menos uma letra minúscula ('a' - 'z') e uma letra maiúscula ('A' - 'Z').";

  let passowrdMismatchError =
    error === passwordErrorTypes.passwordsDontMatch &&
    "Passwords não coincidem.";

  let incorrectPasswordError =
    error === passwordErrorTypes.incorrectPassword && "A password está errada.";

  return {
    oldPasswordError,
    newPasswordError,
    passowrdMismatchError,
    incorrectPasswordError,
  };
};
