import React from "react";

export const errorDict = {
  InvalidSubCategory: "Subcategoria inválida",
  InvalidBrand: "Marca inválida",
  RelationshipExists: "Existem ligações a esta entrada",
  EntityExists: "Não é possível ter duas entradas iguais",
  InvalidMainCategoryName: "Categoria Principal inválida",
  InvalidMainSector: "Sector inválido",
  InvalidCategoryName: "Categoria inválida",
  InvalidLeaflet: "Folheto inválida",
  InvalidPromotion: "Promoção inválida",
  InvalidArticle: "Artigo inválido",
  InvalidArticleData: "Artigo inválido",
  InvalidPromotionType: "Tipo de Promoção inválida",
  InvalidCompanyName: "Empresa inválida",
  InvalidMainBrandName: "Marca Principal inválida",
  InvalidComercialArea: "Área Comercial inválida",
  InvalidChannelType: "Tipo de Canal inválido",
  InvalidCompanyType: "Tipo de Marca inválida",
  InvalidShop: "Insígnia inválida",
  InvalidChainName: "Cadeia inválida",
  DuplicateEntity: "Artigo Duplicado",
};

export const handleError = (error) => {
  const errorLog = error?.data?.Message;
  const errorTranslation = errorDict[errorLog];

  return errorTranslation ? errorTranslation : "Ocorreu um erro";
};

const ErrorMessageHandler = React.memo(({ error }) => {
  const ResolvedMessage = handleError(error);

  return ResolvedMessage;
});

export default ErrorMessageHandler;
