import React from "react";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./Input.css"
import { faFilePdf } from "@fortawesome/pro-solid-svg-icons";


const FileInput = ({ className, labelClassName,size,...rest }) => {
  return (
    <>
      <label htmlFor="file-upload" className={classnames("p-5 rounded text-center cursor-pointer", className, labelClassName)}>
        <FontAwesomeIcon size={size}  className="input-file-icon mb-2" icon={faFilePdf} /><br/>
		<div className="text-secondary">Clique para adicionar um pdf</div>
      </label>
      <input
        className={"d-none"}
        {...rest}
		type="file"
		id="file-upload"
      />
    </>
  );
};

export default FileInput;
