import React, { useState, useRef, useContext, useMemo } from "react";
import BaseGrid from "../../Common/Grid/Grid";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import DeleteModal from "../../Common/DeleteModalContent/DeleteModalContent";
import AddComercialAreaModal from "./AddCompanyTypesModal/AddCompanyTypes";
import { InsertDateFilterCell } from "../../Leaflets/LeafletsGrid";
import { UserRoleContext } from "../../App";
import { getHighestPermission, RolesEnum } from "../../AppHelper";

const dateCell = ({ dataItem, field }) => {
  const value = dataItem[field];
  return (
    <td>{value === null ? "" : new moment(value).format("DD/MM/YYYY")}</td>
  );
};

const ButtonCell = ({ dataItem }) => {
  const [toggleEdit, toggleRemove] = useContext(ToggleEditionModalsContext);

  const handleEdit = () => {
    toggleEdit(dataItem);
  };

  const handleRemove = () => {
    toggleRemove(dataItem);
  };

  return (
    <td>
      <div className="d-flex align-items-center justify-content-end">
        <div onClick={handleEdit} className="p-2 mr-2 grid-button">
          <FontAwesomeIcon size="sm" icon={faPencil} title="Editar" />
        </div>
        <div onClick={handleRemove} className="p-2 grid-button">
          <FontAwesomeIcon size="sm" icon={faTrashAlt} title="Eliminar" />
        </div>
      </div>
    </td>
  );
};

const ToggleEditionModalsContext = React.createContext();

const ButtonCollumn = [
  {
    title: "",
    minResizableWidth: 120,
    field: "Button",
    headerClassName: "hide-grid-title",
    filterable: false,
    sortable: false,
    width: 90,
    cell: ButtonCell,
    show: true,
  },
];

const columns = [
  // { field: "Id", title: "Id", filterable: false, sortable: false, width: 50 },
  { field: "Code", title: "Código" },
  { field: "Name", title: "Nome" },
  {
    title: "Data de Inserção",
    cell: dateCell,
    filterCell: InsertDateFilterCell,
    filter: "date",
    field: "InsertDate",
  },
];
const CompanyTypeGrid = () => {
  const SelectedCARef = useRef();

  const [editingCompanyType, setEditingCompanyType] = useState(false);

  const toggleEditModal = (data) => {
    if (!editingCompanyType) {
      SelectedCARef.current = data;
    } else {
      SelectedCARef.current = undefined;
    }
    setEditingCompanyType((open) => !open);
  };

  const [deletingCompanyType, setDeletingCompanyType] = useState(false);

  const toggleDeleteModal = (data) => {
    if (!deletingCompanyType) {
      SelectedCARef.current = data;
    } else {
      SelectedCARef.current = undefined;
    }
    setDeletingCompanyType((open) => !open);
  };

  const Roles = useContext(UserRoleContext);
  const Permission = getHighestPermission(Roles);

  const resolvedColumns = useMemo(() => {
    if (Permission >= RolesEnum.BackOne) {
      return [...ButtonCollumn, ...columns];
    } else {
      return columns;
    }
  }, [Permission]);

  return (
    <ToggleEditionModalsContext.Provider
      value={[toggleEditModal, toggleDeleteModal]}
    >
      <AddComercialAreaModal
        isOpen={editingCompanyType}
        onClose={toggleEditModal}
        data={SelectedCARef.current}
      />

      <DeleteModal
        isOpen={deletingCompanyType}
        onClose={toggleDeleteModal}
        data={SelectedCARef.current}
        closeModal={toggleDeleteModal}
        url="companies/types"
      />

      <BaseGrid Columns={resolvedColumns} BaseUrl={"companies/types"} />
    </ToggleEditionModalsContext.Provider>
  );
};

export default CompanyTypeGrid;
