import React, { useState } from "react";
import LabeledInput from "../Common/Input/LabeledInput";
import LoadableButton from "../Common/Button/LoadableButton";
import { usePost } from "../Helpers/IOClient";
import {
  passwordErrorTypes,
  usePasswordErrors,
} from "../Helpers/PasswordHelper";

const ChangePassword = () => {
  const [form, setForm] = useState(() => {
    return {
      password: "",
      newPassword: "",
      confirmPassword: "",
    };
  });

  const [error, setError] = useState();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setError();
    setForm((f) => {
      return {
        ...f,
        [name]: value,
      };
    });
  };

  const [unknownError, setUnknownError] = useState();
  const [succeeded, setSucceeded] = useState(false);
  const [post, { loading }] = usePost("account/changePassword", null, {
    onSuccess: () => {
      setSucceeded(true);
    },
    onError: ({ error }) => {
      if (error.data && error.data.Message) {
        if (error.data.Message === "Incorrect password.")
          setError(passwordErrorTypes.incorrectPassword);
        else if (error.data.Message.includes("Passwords must have"))
          setError(passwordErrorTypes.newPasswordRequired);
        else setUnknownError(error);
      } else {
        setUnknownError(error);
      }
    },
  });

  const { password, newPassword, confirmPassword } = form;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUnknownError();
    if (password === "") {
      setError(passwordErrorTypes.oldPasswordRequired);
      return;
    } else if (newPassword === "" || newPassword.length < 6) {
      setError(passwordErrorTypes.newPasswordRequired);
      return;
    } else if (newPassword !== confirmPassword) {
      setError(passwordErrorTypes.passwordsDontMatch);
      return;
    }

    post({
      OldPassword: password,
      NewPassword: newPassword,
      ConfirmPassword: confirmPassword,
    });
  };

  const {
    oldPasswordError,
    newPasswordError,
    passowrdMismatchError,
    incorrectPasswordError,
  } = usePasswordErrors(error);

  if (succeeded) {
    return (
      <div className="p-5 mx-auto" style={{ maxWidth: 576 }}>
        {/* <div className="fs-40 fw-medium mb-4">Alterar password</div> */}

        <div className="text-center">
          A sua password foi alterada com successo.
        </div>
      </div>
    );
  }

  return (
    <div className="p-5 mx-auto" style={{ maxWidth: 576 }}>
      <div className="fs-40 fw-medium mb-4">Alterar password</div>

      <form onSubmit={handleSubmit}>
        <LabeledInput
          className="mb-4"
          name="password"
          type="password"
          value={password}
          onChange={handleChange}
          compClassName="secondary"
          error={oldPasswordError || incorrectPasswordError}
          // hasErrors={!isEmailValid}
          text="Password"
        />

        <LabeledInput
          className="mb-4"
          name="newPassword"
          type="password"
          value={newPassword}
          onChange={handleChange}
          compClassName="secondary"
          error={newPasswordError}
          autoComplete="new-password"
          // hasErrors={!isEmailValid}
          text="Nova password"
        />

        <LabeledInput
          className="mb-5"
          name="confirmPassword"
          type="password"
          value={confirmPassword}
          onChange={handleChange}
          compClassName="secondary"
          error={passowrdMismatchError}
          autoComplete="new-password"
          // hasErrors={!isEmailValid}
          text="Confirmar nova password"
        />

        {unknownError && (
          <div className="text-center text-danger mb-3">
            Não conseguimos alterar a sua password. Por favor tente mais tarde
          </div>
        )}

        <LoadableButton
          loading={loading}
          disabled={Boolean(error)}
          className="w-100"
        >
          Alterar
        </LoadableButton>
      </form>
    </div>
  );
};

export default ChangePassword;
