import React, { useContext, useMemo } from "react";
import styles from "./FilterList.module.css";
import classnames from "classnames";
import FilterListCreator from "./FilterListCreator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import FilterListOptions from "./FilterListOptions";
import { FiltersQueryContext } from "./FilterListHelper";

const FilterCountBadge = ({ count, onClick, className }) => {
  if (!count || count === 0) return null;

  return (
    <div
      onClick={onClick}
      className={classnames(
        styles.filterCountBadge,
        className,
        "px-2 py-1 d-flex align-items-center"
      )}
    >
      <div>{count}</div>
      <div className="ml-2">
        <FontAwesomeIcon className="d-block" icon={faTimes} />
      </div>
    </div>
  );
};

const FilterListHeaderButtons = ({
  allAdvancedFilters,
  setFilter,
  filtersCount,
  hasCustomFilter,
  handleCreation,
  handleRemoval,
  handleEdit,
}) => {
  const handleAllFiltersRemoval = () => {
    setFilter({});
  };

  if (!allAdvancedFilters) return null;

  return (
    <>
      {filtersCount > 0 && (
        <FilterCountBadge
          count={filtersCount}
          onClick={handleAllFiltersRemoval}
        />
      )}
      {!hasCustomFilter && allAdvancedFilters.length === 0 ? (
        <FilterListCreator
          onClick={handleCreation}
          className={filtersCount > 0 ? "ml-2" : undefined}
        />
      ) : (
        <FilterListOptions
          className="ml-2"
          hasCustomFilter={hasCustomFilter}
          handleCreation={handleCreation}
          handleRemoval={handleRemoval}
          handleEdit={handleEdit}
        />
      )}
    </>
  );
};

const FilterListHeader = ({ advancedFilter, filter, setFilter, ...rest }) => {
  const filtersCount = useMemo(() => {
    let total = 0;
    for (const filterField in filter) {
      const filterObj = filter[filterField];

      total += filterObj.length;
    }

    return total;
  }, [filter]);

  const { Id, Name } = advancedFilter;

  const hasCustomFilter = Boolean(Id);

  const { data: allAdvancedFilters } = useContext(FiltersQueryContext) || {};

  return (
    <div
      className={classnames(
        "d-flex align-items-center justify-content-between px-3 fw-medium",
        styles.filterListHeader
      )}
    >
      <div className="d-flex align-items-center">
        {/* <Button onClick={toggleIsOpen} className="size-sm">
            <FontAwesomeIcon size="lg" icon={faCompressAlt} />
          </Button> */}
        {hasCustomFilter ? (
          <span className="fw-medium flex-1">{Name}</span>
        ) : (
          <span className="fs-14 fw-medium flex-1">FILTROS AVANÇADOS</span>
        )}
      </div>
      <div className="d-flex">
        <FilterListHeaderButtons
          allAdvancedFilters={allAdvancedFilters}
          filtersCount={filtersCount}
          hasCustomFilter={hasCustomFilter}
          setFilter={setFilter}
          {...rest}
        />
      </div>
    </div>
  );
};

export default FilterListHeader;
