import { faEllipsisV, faFilter } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./FilterList.module.css";
import classnames from "classnames";
import {
  faPencilAlt,
  faPlus,
  faTrashAlt,
  faUndoAlt,
} from "@fortawesome/pro-light-svg-icons";
import { FilterListViewContext, FilterViewTypes } from "./FilterListHelper";
import { GridContext } from "../Grid/GridHelper";

const OptionItem = ({ icon, text, onClick, className }) => {
  const close = useContext(ClosureContext);
  const handleClick = (e) => {
    close();
    onClick && onClick(e);
  };
  return (
    <div
      onClick={handleClick}
      className={classnames("px-4 py-3", styles.filterListOption, className)}
    >
      <FontAwesomeIcon icon={icon} className="mr-2" />
      <span className="fw-medium text-uppercase fs-14">{text}</span>
    </div>
  );
};

const ClosureContext = React.createContext();

const FilterListOptions = ({
  hasCustomFilter,
  handleCreation,
  handleEdit,
  handleRemoval,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClosure = useCallback(() => setIsOpen(false), []);

  const { setView } = useContext(FilterListViewContext);
  const { updateSettings } = useContext(GridContext);
  const changeFilter = useCallback(
    () => setView(FilterViewTypes.filterSelector),
    [setView]
  );

  const cleanFilters = useCallback(
    () => updateSettings({ customFilterId: undefined }),
    [updateSettings]
  );

  const popupRef = useRef();
  useEffect(() => {
    if (!isOpen) return;

    const listener = (e) => {
      const popupEl = popupRef.current;

      if (!popupEl) return;

      if (popupEl === e.target || popupEl.contains(e.target)) return;

      // document.removeEventListener("click", handleClick);

      setIsOpen(false);
    };

    document.addEventListener("click", listener);

    return () => document.removeEventListener("click", listener);
  });

  return (
    <div className={classnames("position-relative", className)}>
      <div
        onClick={() => setIsOpen(true)}
        className={classnames(styles.filterListOptions, "p-1")}
      >
        <FontAwesomeIcon icon={faEllipsisV} />
      </div>
      {isOpen && (
        <div
          ref={popupRef}
          className={classnames(styles.filterListOptionsPopup)}
        >
          <ClosureContext.Provider value={handleClosure}>
            {hasCustomFilter && (
              <OptionItem
                onClick={handleEdit}
                icon={faPencilAlt}
                text="Editar"
              />
            )}
            <OptionItem
              onClick={changeFilter}
              icon={faFilter}
              text="Alterar Filtro"
            />
            {hasCustomFilter && (
              <OptionItem
                onClick={handleRemoval}
                icon={faTrashAlt}
                text="Eliminar"
              />
            )}
            {hasCustomFilter && (
              <OptionItem
                onClick={cleanFilters}
                icon={faUndoAlt}
                text="Limpar Filtros"
              />
            )}
            <OptionItem
              onClick={handleCreation}
              className={styles.filterListOptionPrimary}
              icon={faPlus}
              text="Adicionar Novo"
            />
          </ClosureContext.Provider>
        </div>
      )}
    </div>
  );
};

export default FilterListOptions;
