import React, { useContext, useMemo, useRef, useState } from "react";
import BaseGrid from "../../Common/Grid/Grid";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faPencil } from "@fortawesome/pro-solid-svg-icons";


import DeleteModal from "../../Common/DeleteModalContent/DeleteModalContent";
import AddBrandModal from "./SubCategoriesModal/SubCategoriesModal";
import { InsertDateFilterCell } from "../../Leaflets/LeafletsGrid";
import { UserRoleContext } from "../../App";
import { getHighestPermission, RolesEnum } from "../../AppHelper";

const dateCell = ({ dataItem, field }) => {
  const value = dataItem[field];
  return (
    <td>{value === null ? "" : new moment(value).format("DD/MM/YYYY")}</td>
  );
};

const ButtonCell = ({ dataItem }) => {
  const [toggleEdit, toggleRemove] = useContext(ToggleEditionModalsContext);

  const handleEdit = () => {
    toggleEdit(dataItem);
  };

  const handleRemove = () => {
    toggleRemove(dataItem);
  };

  return (
    <td>
      <div className="d-flex align-items-center justify-content-end">
        <div onClick={handleEdit} className="p-2 mr-2 grid-button">
          <FontAwesomeIcon size="sm" icon={faPencil} title="Editar" />
        </div>
        <div onClick={handleRemove} className="p-2 grid-button">
          <FontAwesomeIcon size="sm" icon={faTrashAlt} title="Eliminar" />
        </div>
      </div>
    </td>
  );
};

const ButtonCollumn = [
  {
    title: "",
    minResizableWidth: 120,
    field: "Button",
    headerClassName: "hide-grid-title",
    filterable: false,
    sortable: false,
    width: 90,
    cell: ButtonCell,
    show: true
  }
];

const columns = [
  // { field: "Id", title: "Id", filterable: false, sortable: false, width: 50 },

  { field: "Code", title: "Código" },
  { field: "Name", title: "Nome" },
  { field: "CategoryName.Name", title: "Categoria" },
  {
    field: "InsertDate",
    filter: "date",
    filterCell: InsertDateFilterCell,
    title: "Data de Inserção",
    cell: dateCell
  }
];

const ToggleEditionModalsContext = React.createContext();

const SubCategoriesGrid = () => {
  const SelectedCARef = useRef();

  const [editingBrandsMain, setEditingBrandsMain] = useState(false);

  const toggleEditModal = (data) => {
    if (!editingBrandsMain) {
      SelectedCARef.current = data;
    } else {
      SelectedCARef.current = undefined;
    }
    setEditingBrandsMain((open) => !open);
  };

  const [deletingBrandsMain, setDeletingBrandsMain] = useState(false);

  const toggleDeleteModal = (data) => {
    if (!deletingBrandsMain) {
      SelectedCARef.current = data;
    } else {
      SelectedCARef.current = undefined;
    }
    setDeletingBrandsMain((open) => !open);
  };

  const Roles = useContext(UserRoleContext);
  const Permission = getHighestPermission(Roles);

  const resolvedColumns = useMemo(() => {
    if (Permission >= RolesEnum.BackOne) {
      return [...ButtonCollumn, ...columns];
    } else {
      return columns;
    }
  }, [Permission]);

  return (
    <ToggleEditionModalsContext.Provider
      value={[toggleEditModal, toggleDeleteModal]}
    >
      <AddBrandModal
        isOpen={editingBrandsMain}
        onClose={toggleEditModal}
        data={SelectedCARef.current}
      />

      <DeleteModal
        isOpen={deletingBrandsMain}
        onClose={toggleDeleteModal}
        data={SelectedCARef.current}
        closeModal={toggleDeleteModal}
        url="categories/sub"
      />

      <BaseGrid Columns={resolvedColumns} BaseUrl={"categories/sub"} />
    </ToggleEditionModalsContext.Provider>
  );
};

export default SubCategoriesGrid;
