import React from "react";
import classnames from "classnames";
import Input from "./Input";

const LabeledInput = ({
  className,
  compClassName,
  text,
  textClassName,
  error,
  InputComponent,
  ...rest
}) => {
  const Comp = InputComponent || Input;

  return (
    <div className={className}>
      <div
        className={classnames("mb-1 text-secondary fw-medium", textClassName)}
      >
        {text}
      </div>
      <Comp {...rest} className={classnames("w-100", compClassName)} />
      {error && <div className="text-danger fs-14 mt-1">{error}</div>}
    </div>
  );
};

export default LabeledInput;
