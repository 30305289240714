
import createODataDropdownInput from './Dropdown'
import createODataMultiDropdownInput from './MultiDropdown'


export const MainSectorDropdown = createODataDropdownInput('mainSectors', 'Name')
export const MainCategoryDropdown = createODataDropdownInput('categories/main', 'Name')
export const CategoryDropdown = createODataDropdownInput('categories', 'Name', "Name")
export const SubCategoryDropdown = createODataDropdownInput('categories/sub', 'Name')

export const PromotionsMultiDropdown = createODataMultiDropdownInput('promotions', 'Name')
export const SubCategoryMultiDropdown = createODataMultiDropdownInput('categories/sub', 'Name')
export const ChannelTypeMultiDropdown = createODataMultiDropdownInput('channelTypes', 'Name')
export const ArticleDataMultiDropdown = createODataMultiDropdownInput('articles/data', 'Name', 'Name')

export const CompanyTypesDropdown = createODataDropdownInput('companies/types', 'Name', )
export const MainBrandDropdown = createODataDropdownInput('brands/main', 'Name')
export const CompanyDropdown = createODataDropdownInput('companies', 'Name')

export const BrandDropdown = createODataDropdownInput('brands', 'Name')

export const ArticlePackDropdown = createODataDropdownInput('packs', "Name")

export const ShopsDropdown = createODataDropdownInput('shops', 'Name')
export const ChainDropdown = createODataDropdownInput('chains/dropdown', 'Name', "ChainName/Name")
export const ChannelTypeDropdown = createODataDropdownInput('channelTypes', 'Name')
export const ComercialAreaDropdown = createODataDropdownInput('comercialAreas', 'Name')

export const ArticleDropdown = createODataDropdownInput('articles', 'Name')
export const ArticleDataDropdown = createODataDropdownInput('articles/data', 'Article.Name', "Article.Name")
export const PromotionTypeDropdown = createODataDropdownInput('promotions/types', 'Name')
export const PromotionDropdown = createODataDropdownInput('promotions/TypePromotions', 'Promotion.Name', 'Promotion.Name');