import React, { useMemo } from "react";
import styles from "./FilterList.module.css";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FilterOperatorEnum, FilterTypeEnum } from "./FilterListTypings";
import moment from "moment";

const BaseFilterItemBadges = ({
  itemFilters,
  column,
  handleFilterItemUpdate,
}) => {
  const handleFilterRemoval = (v) => {
    handleFilterItemUpdate((filters) => {
      const index = filters.findIndex((f) => f.value === v);
      const newFilters = [...filters];
      if (index !== -1) newFilters.splice(index, 1);

      return newFilters;
    });
  };

  return (
    <div className={styles.filterItemContainer}>
      {itemFilters.map((f) => {
        return (
          <div
            onClick={() => handleFilterRemoval(f.value)}
            key={f.value}
            className={classnames(
              "px-2 py-1 d-inline-flex align-items-center",
              styles.filterItemBadge
            )}
          >
            <div className="text-truncate">{f.value}</div>
            <FontAwesomeIcon size="sm" className="ml-2" icon={faTimes} />
          </div>
        );
      })}
    </div>
  );
};

const DateFilterItemBadges = ({
  itemFilters,
  column,
  handleFilterItemUpdate,
}) => {
  const filter = itemFilters[0];
  const handleFilterRemoval = () => {
    handleFilterItemUpdate((filters) => []);
  };

  const formmatedDate = useMemo(
    () => moment(filter.value).format("DD/MM/YYYY"),
    [filter.value]
  );

  return (
    <div className={styles.filterItemContainer}>
      <div
        onClick={handleFilterRemoval}
        className={classnames(
          "px-2 py-1 d-inline-flex align-items-center",
          styles.filterItemBadge
        )}
      >
        <div className="text-truncate">{formmatedDate}</div>
        <FontAwesomeIcon size="sm" className="ml-2" icon={faTimes} />
      </div>
    </div>
  );
};

const getOperatorText = (operator) => {
  switch (operator) {
    case FilterOperatorEnum.LesserOrEqual:
      return "Menor";

    default:
      return "Maior";
  }
};

const PriceFilterItemBadges = ({
  itemFilters,
  column,
  handleFilterItemUpdate,
}) => {
  const filter = itemFilters[0];
  const handleFilterRemoval = () => {
    handleFilterItemUpdate((filters) => []);
  };

  const formmatedText = useMemo(() => {
    const { operator, value } = filter;

    if (operator === FilterOperatorEnum.Equal) {
      return "Sem preço";
    } else if (operator === FilterOperatorEnum.Between) {
      const { start, end } = value;
      if (!start || !end) return "Com preço";
      const fStart = start * 0.01;
      const fEnd = end * 0.01;

      return `Entre ${fStart} e ${fEnd}`;
    } else {
      return `${getOperatorText(operator)} que ${value * 0.01}`;
    }
  }, [filter]);

  return (
    <div className={styles.filterItemContainer}>
      <div
        onClick={handleFilterRemoval}
        className={classnames(
          "px-2 py-1 d-inline-flex align-items-center",
          styles.filterItemBadge
        )}
      >
        <div className="text-truncate">{formmatedText}</div>
        <FontAwesomeIcon size="sm" className="ml-2" icon={faTimes} />
      </div>
    </div>
  );
};

const FilterItemBadges = (props) => {
  const { column } = props;

  const { filterType } = column;

  switch (filterType) {
    case FilterTypeEnum.date:
      return <DateFilterItemBadges {...props} />;

    case FilterTypeEnum.price:
      return <PriceFilterItemBadges {...props} />;

    default:
      return <BaseFilterItemBadges {...props} />;
  }
};

export default FilterItemBadges;
