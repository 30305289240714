import { faBezierCurve, faSave } from "@fortawesome/pro-solid-svg-icons";
import LabeledInput from "../../../Common/Input/LabeledInput";
import {
  ComercialAreaDropdown,
  ChannelTypeMultiDropdown
} from "../../../Common/Dropdown/DropdownHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useMemo } from "react";
import LoadableButton from "../../../Common/Button/LoadableButton";
import { createEntityModal } from "../../../Common/Modal/ModalHelper";
import { ErrorMessage } from "../../../Leaflets/LeafletForm";
import ErrorMessageHandler from "../../../Common/ErrorMessage/ErrorMessage";

const InnerAddLeafletMediaModal = ({
  form,
  post,
  loading,
  error,
  handleChanges,
  setForm
}) => {
  const { Name, ComercialArea, ChannelType } = form;

  const [errorState, setErrorState] = useState({});

  const isFormValid = () => {
    let valid = true;
    let newErrorState = {};
    if (!/(.|\s)*\S(.|\s)*/.test(Name)) {
      valid = false;
      newErrorState.Name = true;
    }
    if (!ChannelType) {
      valid = false;
      newErrorState.ChannelType = true;
    }
    setErrorState(newErrorState);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formValidation = isFormValid();
    if (formValidation) {
      const resolvedForm = {
        Name: form.Name,
        ChannelTypeIds: form.ChannelType.map((e) => e.Id)
      };
      post(resolvedForm);
    }
  };

  const ComercialAreaExtraProfile = useMemo(() => {
    if (ComercialArea && ComercialArea.Id) {
      return {
        field: "ComercialArea/Id",
        operator: "eq",
        value: ComercialArea.Id
      };
    } else return null;
  }, [ComercialArea]);

  const onRemoveChannelType = () => {
    handleChanges({ target: { name: "ComercialArea", value: {} } });
    const t = { target: { value: [], name: "ChannelType" } };
    handleChanges(t);
  };

  const handleComercialAreaChanges = (e) => {
    handleChanges(e);
    const t = { target: { value: [], name: "ChannelType" } };
    handleChanges(t);
  };

  return (
    <div style={{ width: 576 }} className="mt-4 position-relative">
      <form onSubmit={handleSubmit}>
        <LabeledInput
          className="mb-3"
          name="Name"
          value={Name}
          placeholder=" "
          pattern="(.|\s)*\S(.|\s)*"
          onChange={handleChanges}
          error={errorState.Name && `Nome ${ErrorMessage}`}
          //   error={emailError}
          // hasErrors={!isEmailValid}
          text="Nome"
        />
        <LabeledInput
          className="mb-1 ar-dropdown-modal"
          name="ComercialArea"
          value={ComercialArea}
          onChange={handleComercialAreaChanges}
          InputComponent={ComercialAreaDropdown}
          //   error={emailError}
          // hasErrors={!isEmailValid}
          text="Área Comercial"
        />
        {ComercialArea && ComercialArea.Id ? (
          <div
            onClick={onRemoveChannelType}
            className="text-danger cursor-pointer mb-3"
          >
            Remover
          </div>
        ) : (
          <div className="mb-3"></div>
        )}
        <LabeledInput
          className="mb-4 ar-dropdown-modal"
          name="ChannelType"
          value={ChannelType}
          onChange={handleChanges}
          InputComponent={ChannelTypeMultiDropdown}
          optionalFilters={ComercialAreaExtraProfile}
          //error={emailError}
          error={errorState.ChannelType && `Tipo de Canal ${ErrorMessage}`}
          //hasErrors={!isEmailValid}
          text="Tipo de Canal"
        />
        {error && (
          <div className="text-danger mb-1 text-center">
            <ErrorMessageHandler error={error} />
          </div>
        )}
        <LoadableButton loading={loading} className="w-100" type="submit">
          <span>
            <FontAwesomeIcon className="mr-2" icon={faSave} />
            Guardar
          </span>
        </LoadableButton>
      </form>
    </div>
  );
};

const AddChainModal = createEntityModal({
  getEndpoint: (data) => (data ? `chains/${data.Id}` : `chains`),
  Component: InnerAddLeafletMediaModal,
  headerTitle: "Adicionar Cadeia",
  headerIcon: faBezierCurve,
  getBaseForm: (entity) => {
    if (entity) {
      const resolvedArr = entity.ChannelTypeChainNames.map(
        (e) => e.ChannelType
      );
      return {
        Name: entity.Name,
        ChannelType: resolvedArr
      };
    } else
      return {
        Name: "",
        ChannelType: []
      };
  }
});

export default AddChainModal;

// const AddChainModal = ({data}) => {
//   const closeModal = useContext(ModalCloseContext);
//   const refetchRef = useContext(GridRefechContext);
//   const [form, setForm] = useState(() => {
//     return {
// 	  Name: "",
// 	  ChannelType: {},
// 	  ...data
//     };
//   });

//   const handleChanges = (e) => {
//     const { name, value } = e.target;

//     setForm((f) => {
//       return {
//         ...f,
//         [name]: value,
//       };
//     });
//   };

//   const [post, { loading }] = usePost(data?`chains/${data.Id}`:`chains`, null, {
//     onSuccess: ({ data }) => {
// 		if(refetchRef.current)refetchRef.current()
// 		closeModal();
// 	},
//     onError: ({ error }) => {
// 		console.log(error)
// 	},
//   });

//   const { Name, ChannelType } = form;

//   const isFormValid = () => {
// 	  if(!(/(.|\s)*\S(.|\s)*/.test(Name))) return false
// 	  if(!ChannelType.Id) return false
// 	  else return true
//   };

//   const handleSubmit = (e) => {
// 	  e.preventDefault();

// 	  const formValidation = isFormValid();
// 	  if(formValidation){
// 		const resolvedForm = {
// 			Name: form.Name,
// 			ChannelTypeId: form.ChannelType.Id
// 		}
// 		post(resolvedForm)
// 	  }
//   };

//   return (
//     <div className="p-4">
//       <ModalHeader icon={faBezierCurve}>Adicionar Cadeia</ModalHeader>

//       <div style={{ width: 576 }} className="mt-4">
//         <form
// 		onSubmit={handleSubmit}>
//           <LabeledInput
//             className="mb-3"
//             name="Name"
// 			value={Name}
// 			placeholder=" "
//             required="required"
//             pattern="(.|\s)*\S(.|\s)*"
//             onChange={handleChanges}
//             //   error={emailError}
//             // hasErrors={!isEmailValid}
//             text="Nome"
//           />
// 		  <LabeledInput
//             className="mb-4 ar-dropdown-modal"
//             name="ChannelType"
// 			value={ChannelType}

// 			onChange={handleChanges}
// 			InputComponent={ChannelTypeDropdown}
//             //   error={emailError}
//             // hasErrors={!isEmailValid}
//             text="Tipo de Canal"
//           />
//           <LoadableButton loading={loading} className="w-100" type="submit">
//             <span>
//               <FontAwesomeIcon className="mr-2" icon={faSave} />
//               Guardar
//             </span>
//           </LoadableButton>
//         </form>
//       </div>
//     </div>
//   );
// };
