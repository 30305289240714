import {
  faChevronLeft,
  faFileExcel,
  faSave
} from "@fortawesome/pro-light-svg-icons";
import React, { useMemo, useState } from "react";
import { Upload } from "@progress/kendo-react-upload";
import {
  IntlProvider,
  loadMessages,
  LocalizationProvider
} from "@progress/kendo-react-intl";

import ptMessages from "../Assets/Translations/pt.json";
import LabeledInput from "../Common/Input/LabeledInput";
import LoadableButton from "../Common/Button/LoadableButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { usePost } from "../Helpers/IOClient";
import ErrorMessageHandler from "../Common/ErrorMessage/ErrorMessage";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Button from "../Common/Button/Button";
loadMessages(ptMessages, "pt-PT");

const ProductsImportComponent = ({ handleDataComplete }) => {
  const [formState, setFormState] = useState({ Pages: "" });
  const [files, setFiles] = useState([]);
  const { Pages } = formState;

  const handlePageChanges = (e) => {
    const { value } = e.target;
    setFormState((t) => {
      return { ...t, Pages: value };
    });
  };

  const onAdd = (event) => {
    if (event.newState.length > 1) setFiles([event.newState[0]]);
    else setFiles(event.newState);
  };

  const onRemove = (event) => {
    setFiles(event.newState);
  };

  const onProgress = (event) => {
    setFiles(event.newState);
  };

  const onStatusChange = (event) => {
    setFiles(event.newState);
  };
  const postUrl = useMemo(() => {
    let sheetString = "";
    // for (let index = 1; index < Pages; index++) {
    //   sheetString += `${index},`;
    // }
    sheetString += `${Pages}`;
    return `articles/data/import?sheets=${sheetString}`;
  }, [Pages]);

  const [post, { loading, error }] = usePost(postUrl, null, {
    onSuccess: ({ data }) => {
      handleDataComplete(data);
    },
    onError: ({ error }) => {
      console.log(error);
    }
  });

  const submit = () => {
    const formData = new FormData();
    const File = files[0].getRawFile();
    formData.append("File", File, File.name);
    post(formData);
  };

  const disableSubmit = useMemo(() => {
    if (files.length === 0) return true;

    if (!/^\d+?(?:,\d+)*$/.test(Pages)) return true;
    return false;
  }, [Pages, files]);

  const pagesHasError = Pages !== "" ? !/^\d+?(?:,\d+)*$/.test(Pages) : false;

  return (
    <div className="p-4">
      <div style={{ width: 576 }} className="mt-4 Disable-Upload-Buttons">
        <Upload
          batch={false}
          autoUpload={false}
          // onAdd={handleFileChanges}
          restrictions={{
            allowedExtensions: [".xls", ".xlm", ".xlsx", "slxm"]
          }}
          files={files}
          onAdd={onAdd}
          onRemove={onRemove}
          onProgress={onProgress}
          onStatusChange={onStatusChange}
          withCredentials={false}
          showActionButtons={false}
          multiple={false}
        />
        <LabeledInput
          className="mt-3 mb-4 ar-dropdown-modal "
          name="Pages"
          compClassName="secondary"
          value={Pages}
          onChange={handlePageChanges}
          required
          pattern="^\d+?(?:,\d+)*$"
          error={pagesHasError && `Páginas não estão no formato correto.`}
          type="text"
          placeholder="Por exemplo: 1,2,3"
          text="Páginas"
        />

        {error && (
          <div className="text-danger mb-1 text-center">
            <ErrorMessageHandler error={error} />
          </div>
        )}

        <LoadableButton
          loading={loading}
          onClick={submit}
          disabled={loading || disableSubmit}
          className="w-100"
          type="submit"
        >
          <span>
            <FontAwesomeIcon className="mr-2" icon={faSave} />
            Submeter
          </span>
        </LoadableButton>
      </div>
    </div>
  );
};

const SheetErrorChild = ({ item }) => {
  const { row, errorMessage } = item;
  return (
    <div className="px-2 py-1 d-flex flex-column w-100">
      <div className="text-black fs-14 fw-medium mb-1">Linha {row}</div>
      <div className="text-danger fs-14">{errorMessage}</div>
    </div>
  );
};

const SheetItem = ({ item }) => {
  const { sheet, successCount, totalCount, errorCount, errors } = item;

  if (errorCount > 0) {
    return (
      <div className="px-2 py-1 d-flex flex-column w-100">
        <div className="text-black fs-16 fw-medium mb-1">Folha {sheet}</div>
        <div className="fs-14">
          De <span className="text-primary">{totalCount}</span> linhas foram
          importadas <span className="text-success">{successCount}</span> com
          sucesso e <span className="text-danger">{errorCount}</span> com
          insucesso.
        </div>
        {errors.map((e, i) => {
          return <SheetErrorChild key={i} item={e} />;
        })}
      </div>
    );
  }

  return (
    <div className="px-2 py-1 d-flex flex-column w-100">
      <div className="text-black fs-16 fw-medium mb-1">Folha {sheet}</div>
      <div className="fs-14">
        De <span className="text-primary">{totalCount}</span> linhas foram
        importadas <span className="text-success">{successCount}</span> com
        successo.
      </div>
    </div>
  );
};

const ErrorSheetItem = ({ item }) => {
  const { sheet, errorMessage } = item;

  return (
    <div className="px-2 py-1 d-flex flex-column w-100">
      <div className="text-black fs-16 fw-medium mb-1">Folha {sheet}</div>
      <div className="text-danger fs-14">{errorMessage}</div>
    </div>
  );
};

const ImportReport = ({ ImportData }) => {
  const { SheetErrorList, Sheets } = ImportData;
  return (
    <div className="w-100 h-100 d-flex flex-column">
      {SheetErrorList.map((e, i) => (
        <ErrorSheetItem key={i} item={e} />
      ))}
      {Sheets.map((e, i) => (
        <SheetItem key={i} item={e} />
      ))}
    </div>
  );
};

const ProductsImport = () => {
  const [importData, setImportData] = useState(null);

  const clearImportData = () => {
    setImportData(null);
  };

  return (
    <LocalizationProvider language="pt-PT">
      <IntlProvider locale="pt">
        <div className="p-4 h-100 d-flex flex-column main-grid">
          <Link
            to="/articles"
            style={{ width: "fit-content" }}
            className="Disablehover color-primary d-flex align-items-center mb-2"
          >
            <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
            <span>Voltar</span>
          </Link>
          <div className="d-flex align-items-center justify-content-between mb-2">
            <div className="fs-40 fw-medium">Importação de Artigos</div>
            <div className="d-flex flex-1 align-items-center justify-content-end">
              {importData && (
                <Button
                  type="button"
                  className="mr-3"
                  onClick={clearImportData}
                >
                  <FontAwesomeIcon icon={faFileExcel} className="mr-2" />
                  Importação
                </Button>
              )}
            </div>
          </div>
          <hr></hr>
          {!importData && (
            <div className="d-flex flex-1 of-auto justify-content-center">
              <ProductsImportComponent handleDataComplete={setImportData} />
            </div>
          )}
          {importData && (
            <div className="d-flex flex-1 of-auto">
              <ImportReport ImportData={importData} />
            </div>
          )}
        </div>
      </IntlProvider>
    </LocalizationProvider>
  );
};

export default ProductsImport;
