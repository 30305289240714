import React, { useState, useContext } from "react";

import AddComepanyTypesAreaModal from "./AddCompanyTypesModal/AddCompanyTypes";
import CompanyTypeGrid from "./CompanyTypesGrid";
import ExcelExportButton from "../../Common/ExcelButton/ExcelExportButton";
import { UserRoleContext } from "../../App";
import { getHighestPermission, RolesEnum } from "../../AppHelper";
import PlusButton from "../../Common/PlusButton/PlusButton";

const CompanyType = () => {
  const [addingCompanyType, setAddingCompanyType] = useState(false);

  const toggleModal = () => {
    setAddingCompanyType((open) => !open);
  };

  const Roles = useContext(UserRoleContext);
  const Permission = getHighestPermission(Roles);

  return (
    <>
      <AddComepanyTypesAreaModal
        isOpen={addingCompanyType}
        onClose={toggleModal}
      />

      <div className="p-4 h-100 d-flex flex-column main-grid">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div className="fs-40 fw-medium">Tipos de Marca</div>
          <div className="d-flex flex-1 align-items-center justify-content-end">
            {Permission >= RolesEnum.BackOne && (
              <PlusButton className="mr-3" onClick={toggleModal}>
                Adicionar
              </PlusButton>
            )}
            <ExcelExportButton
              removemargin={!(Permission >= RolesEnum.BackOne)}
              BaseUrl={"companies/types/export"}
            />
          </div>
        </div>
        <CompanyTypeGrid />
      </div>
    </>
  );
};

export default CompanyType;
