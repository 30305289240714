import React, { useState } from "react";
import { faBezierCurve, faSave } from "@fortawesome/pro-light-svg-icons";
import LabeledInput from "../../../../Common/Input/LabeledInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadableButton from "../../../../Common/Button/LoadableButton";
import { createEntityModal } from "../../../../Common/Modal/ModalHelper";
import { ErrorMessage } from "../../../../Leaflets/LeafletForm";
import ErrorMessageHandler from "../../../../Common/ErrorMessage/ErrorMessage";
import { PromotionsMultiDropdown } from "../../../../Common/Dropdown/DropdownHelper";

// const PromotionNameTag = (tagData, li) => {
//   return React.cloneElement(li, li.props, [li.props.children]);
// };

// const InnerAddLeafletMediaModal = ({ form, ...rest }) => {
//   const defaultForm = {
//     ...form,
//     PromotionTypePromotionsResolved: form.PromotionTypePromotions.map(
//       (e) => e.Promotion
//     )
//   };
//   return <InerAddLeafletMediaModal form={defaultForm} {...rest} />;
// };

const InnerAddLeafletMediaModal = ({
  form,
  post,
  loading,
  handleChanges,
  setForm,
  error
}) => {
  const { Name, Promotions } = form;

  const [errorState, setErrorState] = useState({});
  const isFormValid = () => {
    let valid = true;
    let newErrorState = {};
    if (!/(.|\s)*\S(.|\s)*/.test(Name)) {
      valid = false;
      newErrorState.Name = true;
    }
    setErrorState(newErrorState);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formValidation = isFormValid();
    if (formValidation) {
      const resovledForm = {
        Name: Name,
        PromotionIds: Promotions.map((e) => e.Id)
      };
      post(JSON.stringify(resovledForm));
    }
  };

  return (
    <div style={{ width: 576 }} className="mt-4">
      <form onSubmit={handleSubmit}>
        <LabeledInput
          className="mb-4"
          name="Name"
          value={Name}
          placeholder=" "
          error={errorState.Name && `Nome ${ErrorMessage}`}
          onChange={handleChanges}
          //   error={emailError}
          // hasErrors={!isEmailValid}
          text="Nome"
        />
        <LabeledInput
          className="mb-4 ar-dropdown-modal"
          name="Promotions"
          value={Promotions}
          onChange={handleChanges}
          InputComponent={PromotionsMultiDropdown}
          //   error={emailError}

          // hasErrors={!isEmailValid}
          text="Promoções"
        />
        {error && (
          <div className="text-danger mb-1 text-center">
            <ErrorMessageHandler error={error} />
          </div>
        )}
        <LoadableButton loading={loading} className="w-100" type="submit">
          <span>
            <FontAwesomeIcon className="mr-2" icon={faSave} />
            Guardar
          </span>
        </LoadableButton>
      </form>
    </div>
  );
};

const AddArticleNameModal = createEntityModal({
  getEndpoint: (data) =>
    data ? `promotions/types/${data.Id}` : `promotions/types`,
  Component: InnerAddLeafletMediaModal,
  headerTitle: "Adicionar Tipo de Promoção",
  headerIcon: faBezierCurve,
  getBaseForm: (entity) => {
    return {
      Name: entity?.Name || "",
      Promotions: entity?.PromotionTypePromotions.map((e) => e?.Promotion).sort((a, b) => a.Name.localeCompare(b.Name)) || []
    };
  }
});

export default AddArticleNameModal;

// const AddArticleNameModal = ({data}) => {
//   const closeModal = useContext(ModalCloseContext);
//   const refetchRef = useContext(GridRefechContext);
//   const [form, setForm] = useState(() => {
//     return {
// 	  Name: "",
// 	  ...data
//     };
//   });

//   const handleChanges = (e) => {
//     const { name, value } = e.target;

//     setForm((f) => {
//       return {
//         ...f,
//         [name]: value,
//       };
//     });
//   };

//   const [post, { loading }] = usePost(data?`articleNames/${data.Id}`:`articleNames`, null, {
//     onSuccess: ({ data }) => {
// 		if(refetchRef.current)refetchRef.current()
// 		closeModal();
// 	},
//     onError: ({ error }) => {
// 		console.log(error)
// 	},
//   });

//   const { Name } = form;

//   const isFormValid = () => {
// 	  if(!(/(.|\s)*\S(.|\s)*/.test(Name))) return false
// 	  else return true
//   };

//   const handleSubmit = (e) => {
// 	  e.preventDefault();

// 	  const formValidation = isFormValid();
// 	  if(formValidation){
// 		post(form)
// 	  }
//   };

//   return (
//     <div className="p-4">
//       <ModalHeader icon={faBezierCurve}>Adicionar Nome de Artigo</ModalHeader>

//       <div style={{ width: 576 }} className="mt-4">
//         <form
// 		onSubmit={handleSubmit}>
//           <LabeledInput
//             className="mb-4"
//             name="Name"
// 			value={Name}
// 			placeholder=" "
//             required="required"
//             pattern="(.|\s)*\S(.|\s)*"
//             onChange={handleChanges}
//             //   error={emailError}
//             // hasErrors={!isEmailValid}
//             text="Nome"
//           />
//           <LoadableButton loading={loading} className="w-100" type="submit">
//             <span>
//               <FontAwesomeIcon className="mr-2" icon={faSave} />
//               Guardar
//             </span>
//           </LoadableButton>
//         </form>
//       </div>
//     </div>
//   );
// };
