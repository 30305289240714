import React from "react";
import { DatePicker as KendoDatePicker } from "@progress/kendo-react-dateinputs";
import "@progress/kendo-theme-bootstrap/dist/all.css";
import "./DatePicker.css";


const DatePicker = React.forwardRef((props, ref) => (
  <KendoDatePicker
    ref={ref}
    //   className="test"
    //   defaultValue={new Date()}
    format="dd/MM/yyyy"
    {...props}
    defaultShow={false}
  />
));

export default DatePicker;
