import React, {
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
  useContext
} from "react";
import Button from "../Common/Button/Button";
import {
  Link,
  Redirect,
  useHistory
} from "react-router-dom/cjs/react-router-dom.min";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faTimes } from "@fortawesome/pro-light-svg-icons";
import "./Leaflet.css";
import pdfjsLib from "pdfjs-dist/webpack";
import { faSave, faFile, faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import LabeledInput from "../Common/Input/LabeledInput";
import FileInput from "../Common/Input/FileInput";
import classnames from "classnames";
import LoaderSpinner from "../Common/LoaderSpinner/LoaderSpinner";
import { ShopsDropdown } from "../Common/Dropdown/DropdownHelper";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import ptNumbers from "../Assets/Translations/cldr/numbers/pt-PT/numbers.json";
import ptLocalCurrency from "../Assets/Translations/cldr/numbers/pt-PT/currencies.json";
import ptCaGregorian from "../Assets/Translations/cldr/dates/pt-PT/ca-gregorian.json";
import ptDateFields from "../Assets/Translations/cldr/dates/pt-PT/dateFields.json";
import ptMessages from "../Assets/Translations/pt.json";
import {
  loadMessages,
  load,
  LocalizationProvider,
  IntlProvider
} from "@progress/kendo-react-intl";
import likelySubtags from "../Assets/Translations/cldr/supplemental/likelySubtags.json";
import currencyData from "../Assets/Translations/cldr/supplemental/currencyData.json";
import weekData from "../Assets/Translations/cldr/supplemental/weekData.json";
import {
  RegionInput,
  SizesInput,
  LeafletTypeInput,
  MediaTypeInput
} from "../Common/Input/AutoCompleteInput/AutoCompleteInputHelper";
import { usePost, useQuery } from "../Helpers/IOClient";
import { createFormData } from "../Helpers/ODataHelper";
import { getDays, getDate } from "../Helpers/DateHelper";
import { useParams } from "react-router-dom";
import DeleteModal from "../Common/DeleteModalContent/DeleteModalContent";
import LoadableButton from "../Common/Button/LoadableButton";
import { PromotionLeafletContext, UserRoleContext } from "../App";
import ErrorMessageHandler from "../Common/ErrorMessage/ErrorMessage";
import { getHighestPermission, RolesEnum } from "../AppHelper";
import { GridCacheContext } from "../Common/Grid/GridHelper";
import { PlusIcon } from "../Common/PlusButton/PlusButton";
load(
  likelySubtags,
  currencyData,
  weekData,
  ptLocalCurrency,
  ptNumbers,
  ptCaGregorian,
  ptDateFields
);
loadMessages(ptMessages, "pt-PT");

export const LeafletFormChecker = () => {
  const Roles = useContext(UserRoleContext);
  const Permission = getHighestPermission(Roles);

  if (Permission >= RolesEnum.BackTwo) return <LeafletFormConverter />;
  else return <Redirect to="/leaflets" />;
};

export const LeafletFormConverter = () => {
  const { id } = useParams();

  const { data, loading, error } = useQuery(`Leaflets/${id}`, null, {
    cache: false
  });

  const MountedRef = useRef(true);

  useEffect(() => {
    return () => {
      MountedRef.current = false;
    };
  }, []);

  if (loading || error) {
    return (
      <div className="p-5">
        <Link
          to="/leaflets"
          style={{ width: "fit-content" }}
          className="Disablehover color-primary d-flex align-items-center mb-2"
        >
          <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
          <span>Voltar</span>
        </Link>
        <div className="d-flex justify-content-center">
          <LoaderSpinner className="color-primary" size="sm" center />
        </div>
      </div>
    );
  } else {
    const resolvedData = {
      ...data,
      Name: data.LeafleatName.Name,
      Region: data.RegionName.Name,
      Shop: data.ShopName,
      Type: data.LeafleatType.Name,
      Media: data.MediaName.Name,
      Size: data.SizeLeafleatName.Name,
      TotalPages: data.TotalPage.Name,
      DateBegin: getDate(data.DateBegin.Name),
      DateEnd: getDate(data.DateEnd.Name)
    };

    return <LeafletForm data={resolvedData} />;
  }
};

export const ErrorMessage = "é obrigatório.";

const LeafletForm = ({ data, convertedPdfReadError }) => {
  const cache = useContext(GridCacheContext);
  const history = useHistory();
  const { id } = useParams();
  const MountedRef = useRef(true);
  const [pdfPage, setPdfPage] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [pdfRendering, setPdfRendering] = useState(false);
  const [pdfReadError, setPdfReadError] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [form, setForm] = useState(() => {
    return {
      File: undefined,
      Name: "",
      DateBegin: null,
      DateEnd: null,
      Region: "",
      TotalPages: 1,
      Type: "",
      FileThumbnailUrl: undefined,
      Media: "",
      Size: "",
      Shop: {},
      ...data
    };
  });

  const LeafletFileRef = useContext(PromotionLeafletContext);

  useEffect(() => {
    if (!LeafletFileRef.current) {
      if (data && data.FileUrl) {
        try {
          const pdf = pdfjsLib.getDocument({ url: data.FileUrl });
          pdf.onProgress = function (data) {
            console.log("loaded : " + data.loaded);
            console.log("total : " + data.total);
          };
          pdf.promise
            .then(function (pdfFinished) {
              if (!MountedRef.current) return;
              const pageNumber = 1;
              pdfFinished.getPage(pageNumber).then(function (page) {
                if (!MountedRef.current) return;
                setPdfPage(page);
                LeafletFileRef.current = {
                  Id: id,
                  Pdf: pdfFinished,
                  FileUrl: data.FileUrl
                };
              });
            })
            .catch(function (error) {
              console.log(error);
              setPdfReadError(true);
            });
        } catch (error) {}
      }
    } else {
      if (LeafletFileRef.current.Id === id) {
        const pageNumber = 1;
        LeafletFileRef.current.Pdf.getPage(pageNumber).then(function (page) {
          setPdfPage(page);
        });
      } else {
        if (data && data.FileUrl) {
          try {
            const pdf = pdfjsLib.getDocument({ url: data.FileUrl });
            pdf.promise
              .then(function (pdf) {
                if (!MountedRef.current) return;
                const pageNumber = 1;
                pdf.getPage(pageNumber).then(function (page) {
                  if (!MountedRef.current) return;
                  setPdfPage(page);
                  LeafletFileRef.current = {
                    Id: id,
                    Pdf: pdf,
                    FileUrl: data.FileUrl
                  };
                });
              })
              .catch(function (error) {
                console.log(error);
                setPdfReadError(true);
              });
          } catch (error) {}
        }
      }
    }
  }, [LeafletFileRef, data, id]);

  useEffect(() => {
    return () => {
      MountedRef.current = false;
    };
  }, []);

  const removeFile = () => {
    setPdfPage(null);
    ContainerCanvasRef.current.style.backgroundImage = ``;
    if (FileThumbnailUrl) {
      LeafletFileRef.current = undefined;
      setForm((f) => {
        return {
          ...f,
          File: undefined,
          FileThumbnailUrl: undefined
        };
      });
    } else {
      const canvas = CanvasRef.current;
      LeafletFileRef.current = undefined;
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ContainerCanvasRef.current.style.backgroundImage = ``;
      setForm((f) => {
        return {
          ...f,
          File: undefined,
          FileThumbnailUrl: undefined
        };
      });
    }
  };

  const handlePageChanges = (value) => {
    setForm((f) => {
      return {
        ...f,
        TotalPages: value
      };
    });
  };

  const handleChanges = (e) => {
    const { name, value } = e.target;

    setForm((f) => {
      return {
        ...f,
        [name]: value
      };
    });
  };

  const {
    id: Id,
    File,
    Name,
    Shop,
    DateBegin,
    DateEnd,
    Region,
    Size,
    Type,
    TotalPages,
    Media,
    FileThumbnailUrl
  } = form;

  const handleFileChange = (e) => {
    setFileLoading(true);
    const file = e.target.files[0];
    //Read the file using file reader
    const fileReader = new FileReader();

    fileReader.onload = function () {
      const typedarray = new Uint8Array(this.result);

      const pdf = pdfjsLib.getDocument(typedarray);
      pdf.promise
        .then(function (pdf) {
          if (!MountedRef.current) return;
          setPdfReadError(false);
          const pageNumber = 1;
          handlePageChanges(pdf.numPages);
          pdf.getPage(pageNumber).then(function (page) {
            if (!MountedRef.current) return;
            setPdfPage(page);

            //   const scale = 1;
            //   const viewport = page.getViewport({ scale: scale });
          });
        })
        .catch(function (error) {
          if (!MountedRef.current) return;
          console.log(error);
          setPdfReadError(true);
        });
    };
    setForm((f) => {
      return {
        ...f,
        File: file
      };
    });
    fileReader.readAsArrayBuffer(file);
  };

  const heightRef = useRef();

  useLayoutEffect(() => {
    let desiredHeight = heightRef.current;
    if (!desiredHeight && ContainerCanvasRef.current) {
      heightRef.current = ContainerCanvasRef.current.offsetHeight;
      desiredHeight = heightRef.current;
    }

    if (FileThumbnailUrl) {
      ContainerCanvasRef.current.style.backgroundImage = `url('${FileThumbnailUrl}')`;
    } else if (pdfPage) {
      const canvas = CanvasRef.current;
      let CurrentlyRendering = false;
      let ShouldRerender = false;
      const listner = () => {
        if (CurrentlyRendering) {
          ShouldRerender = true;
          return;
        }
        setFileLoading(true);

        // const desiredWidth = ContainerCanvasRef.current.offsetWidth - 10;

        const viewport = pdfPage.getViewport({ scale: 1 });

        const scale = desiredHeight / viewport.height;
        // const scale = desiredWidth / viewport.width;
        const scaledViewport = pdfPage.getViewport({ scale: scale });

        const context = canvas.getContext("2d");
        canvas.style.display = "none";
        canvas.height = scaledViewport.height;
        canvas.width = scaledViewport.width;

        const renderContext = {
          canvasContext: context,
          viewport: scaledViewport
        };
        const renderTask = pdfPage.render(renderContext);
        CurrentlyRendering = true;
        setPdfRendering(true);
        renderTask.promise.then(function () {
          if (!MountedRef.current) return;
          setPdfRendering(false);
          CurrentlyRendering = false;
          if (ShouldRerender) {
            ShouldRerender = false;
            listner();
          } else {
            canvas.style.display = "block";
          }
          setFileLoading(false);
        });
      };
      listner();
      window.addEventListener("resize", listner);
      return () => {
        window.removeEventListener("resize", listner);
        // wasCanceled = true;
        canvas.height = 0;
        canvas.width = 0;
      };
    }
  }, [FileThumbnailUrl, pdfPage]);

  const shouldAddPromosRef = useRef(false);

  const [post, { loading, error }] = usePost(
    Id ? `leaflets/${Id}` : "leaflets",
    null,
    {
      onSuccess: ({ data }) => {
        // setLeafletsCache(new MemoryCache());
        cache.reset();
        if (shouldAddPromosRef.current) {
          const { id } = data;
          history.push(`/leaflets/${id}/promotions/new`);
        } else history.push("/leaflets");
      },
      onError: ({ error }) => {
        console.log(error);
      }
    }
  );

  const [errorState, setErrorState] = useState({});

  const isFormValid = () => {
    let valid = true;
    let newErrorState = {};
    if (!/(.|\s)*\S(.|\s)*/.test(Name)) {
      valid = false;
      newErrorState.Name = true;
    }
    if (!Shop.Id) {
      valid = false;
      newErrorState.Shop = true;
    }
    if (!DateBegin) {
      valid = false;
      newErrorState.DateBegin = true;
    }
    if (!DateEnd) {
      valid = false;
      newErrorState.DateEnd = true;
    }
    if (!Region) {
      valid = false;
      newErrorState.Region = true;
    }
    if (!Size) {
      valid = false;
      newErrorState.Size = true;
    }
    if (!TotalPages) {
      valid = false;
      newErrorState.TotalPages = true;
    }
    if (!Media) {
      valid = false;
      newErrorState.Media = true;
    }
    if (!Type) {
      valid = false;
      newErrorState.Type = true;
    }
    if (!Id && !File) {
      valid = false;
      newErrorState.File = true;
    }
    setErrorState(newErrorState);

    return valid;
  };

  const handleSubmit = (shouldAddPromos = false) => {
    shouldAddPromosRef.current = shouldAddPromos;

    if (isFormValid()) {
      const resolvedForm = {
        Name,
        ShopId: Shop.Id,
        DateBegin: getDays(DateBegin),
        DateEnd: getDays(DateEnd),
        Region,
        Size,
        Type,
        TotalPages,
        Media
      };
      const fDataFiles = File ? [File] : undefined;
      post(createFormData(resolvedForm, fDataFiles), {
        // onUploadProgress: (progressEvent) => console.log(progressEvent),
      });
    }
  };

  const [deletingComercialArea, setDeletingComercialArea] = useState(false);

  const toggleDeleteModal = (data) => {
    setDeletingComercialArea((open) => !open);
  };

  const CanvasRef = useRef();
  const ContainerCanvasRef = useRef();
  return (
    <LocalizationProvider language="pt-PT">
      <IntlProvider locale="pt-PT">
        <DeleteModal
          isOpen={deletingComercialArea}
          onClose={toggleDeleteModal}
          data={form}
          goToOnSuccess={"/leaflets"}
          closeModal={toggleDeleteModal}
          url="leaflets"
        />

        <div className="p-5">
          <Link
            to="/leaflets"
            style={{ width: "fit-content" }}
            className="Disablehover color-primary d-flex align-items-center mb-2"
          >
            <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
            <span>Voltar</span>
          </Link>
          <div className="d-flex align-items-center justify-content-between mb-3">
            <div className="fs-40 fw-medium">
              {Id ? "Editar Folheto" : "Novo Folheto"}
            </div>
            <div className="d-flex align-items-center">
              {Id && (
                <Button
                  vType="informative"
                  className="mr-2"
                  onClick={toggleDeleteModal}
                >
                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      title="Eliminar"
                      className="mr-2"
                    />
                    Eliminar
                  </div>
                </Button>
              )}
              {Id && (
                <Link
                  className="app-button rounded primary mr-2 d-flex align-items-center Disablehover"
                  to={`/leaflets/${id}/promotions/list/view`}
                >
                  Promoções Associadas
                </Link>
              )}
              {Id && (
                <Link
                  className="app-button rounded primary mr-2 d-flex align-items-center Disablehover"
                  to={`/leaflets/${id}/promotions/new`}
                >
                  <PlusIcon />
                  <span className="ml-2">Adicionar Nova Promoção</span>
                </Link>
              )}

              {!Id && !loading && (
                <LoadableButton
                  className="mr-2"
                  loading={loading}
                  onClick={() => handleSubmit(true)}
                >
                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon icon={faSave} className="mr-2" />
                    Guardar e adicionar promoção
                  </div>
                </LoadableButton>
              )}
              <LoadableButton
                loading={loading}
                onClick={() => handleSubmit(false)}
              >
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon icon={faSave} className="mr-2" />
                  Guardar
                </div>
              </LoadableButton>
            </div>
          </div>
          <div className="flex-1 of-hidden d-flex flex-column w-100 p-4 LeafletForm border">
            <div className="mb-3 d-flex align-items-center justify-content-between">
              <span>
                <FontAwesomeIcon className="color-primary mr-2" icon={faFile} />
                <span className="fw-medium text-black fs-20">
                  Detalhes do Folheto
                </span>
              </span>
              {error ? (
                <div className="text-danger mb-1 text-center">
                  <ErrorMessageHandler error={error} />
                </div>
              ) : (
                <div></div>
              )}
            </div>
            <div className="border-bottom mb-3"></div>
            <form className="d-flex flex-1 justify-content-between">
              <div className="w-100 of-y-auto mr-3">
                {convertedPdfReadError &&
                  !(pdfReadError || File || pdfPage) && (
                    <div className="mb-1 text-warning text-center fw-medium">
                      Ocorreu um error ao abrir o folheto existente.
                    </div>
                  )}
                {pdfReadError && (
                  <div className="mb-1 text-warning text-center fw-medium">
                    O ficheiro submetido não é valido.
                  </div>
                )}
                {errorState.File && (
                  <div className="text-danger fs-14 mb-1 text-center">
                    {`Ficheiro PDF ${ErrorMessage}`}
                  </div>
                )}
                {!pdfPage && !FileThumbnailUrl && (
                  <div className="d-flex justify-content-center">
                    <FileInput
                      className="bg-secondary"
                      type="file"
                      onChange={handleFileChange}
                      //   error={emailError}

                      accept=".pdf"
                      size="lg"
                      // hasErrors={!isEmailValid}
                    />
                  </div>
                )}
                {FileThumbnailUrl && (
                  <div
                    ref={ContainerCanvasRef}
                    className={classnames(
                      "of-auto br-8 position-relative canvas-container-thumbnail h-100 d-flex justify-content-center"
                    )}
                  >
                    <div
                      onClick={removeFile}
                      className="ar-file-input-remove-button cursor-pointer"
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </div>
                  </div>
                )}
                {!FileThumbnailUrl && pdfPage && (
                  <div
                    ref={ContainerCanvasRef}
                    className={classnames(
                      "of-auto br-8 position-relative h-100 d-flex justify-content-center",
                      {
                        "d-none": !pdfPage
                      }
                    )}
                  >
                    {!fileLoading && (
                      <div
                        onClick={removeFile}
                        className="ar-file-input-remove-button cursor-pointer"
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </div>
                    )}

                    {fileLoading && (
                      <LoaderSpinner
                        className="color-primary d-flex justify-content-center mx-auto my-3"
                        size="xs"
                        center
                      />
                    )}
                    <canvas ref={CanvasRef} />
                  </div>
                )}
              </div>
              <div className="w-100 d-flex flex-column mr-3">
                <LabeledInput
                  className="mb-4"
                  name="Name"
                  value={Name}
                  placeholder=" "
                  required="required"
                  error={errorState.Name && `Nome ${ErrorMessage}`}
                  pattern="(.|\s)*\S(.|\s)*"
                  onChange={handleChanges}
                  //   error={emailError}
                  // hasErrors={!isEmailValid}
                  text="Nome"
                />
                <LabeledInput
                  className="mb-4 ar-dropdown-modal"
                  name="Shop"
                  value={Shop}
                  error={errorState.Shop && `Insígnia ${ErrorMessage}`}
                  onChange={handleChanges}
                  InputComponent={ShopsDropdown}
                  //   error={emailError}
                  // hasErrors={!isEmailValid}
                  text="Insígnia"
                />
                <LabeledInput
                  className="mb-4 ar-dropdown-modal"
                  name="DateBegin"
                  value={DateBegin}
                  error={errorState.DateBegin && `Data inicio ${ErrorMessage}`}
                  onChange={handleChanges}
                  InputComponent={DatePicker}
                  //   error={emailError}
                  // hasErrors={!isEmailValid}
                  text="Data Início"
                />
                <LabeledInput
                  className="mb-4 ar-dropdown-modal"
                  name="DateEnd"
                  value={DateEnd}
                  error={errorState.DateEnd && `Data fim ${ErrorMessage}`}
                  onChange={handleChanges}
                  InputComponent={DatePicker}
                  //   error={emailError}
                  // hasErrors={!isEmailValid}
                  text="Data Fim"
                />
                <LabeledInput
                  className="mb-4"
                  name="Region"
                  value={Region}
                  error={errorState.Region && `Região ${ErrorMessage}`}
                  onChange={handleChanges}
                  InputComponent={RegionInput}
                  //   error={emailError}
                  // hasErrors={!isEmailValid}
                  text="Região"
                />
              </div>
              <div className="w-100 d-flex flex-column mr-3">
                <LabeledInput
                  className="mb-4"
                  name="Size"
                  value={Size}
                  error={errorState.Size && `Formato ${ErrorMessage}`}
                  onChange={handleChanges}
                  InputComponent={SizesInput}
                  //   error={emailError}
                  // hasErrors={!isEmailValid}
                  text="Formato"
                />
                <LabeledInput
                  className="mb-4"
                  name="Type"
                  value={Type}
                  error={errorState.Type && `Tipo ${ErrorMessage}`}
                  onChange={handleChanges}
                  InputComponent={LeafletTypeInput}
                  //   error={emailError}
                  // hasErrors={!isEmailValid}
                  text="Tipo"
                />
                <LabeledInput
                  className="mb-4"
                  name="Media"
                  value={Media}
                  error={errorState.Media && `Fonte ${ErrorMessage}`}
                  onChange={handleChanges}
                  InputComponent={MediaTypeInput}
                  //   error={emailError}
                  // hasErrors={!isEmailValid}
                  text="Fonte"
                />
                {/* <LabeledInput
                  className="mb-4 ar-form"
                  compClassName="app-input rounded"
                  name="TotalPages"
                  value={TotalPages}
                  error={
                    errorState.TotalPages && `Nº de páginas ${ErrorMessage}`
                  }
                  onChange={handleChanges}
                  min={1}
                  spinners={false}
                  InputComponent={NumericTextBox}
                  //   error={emailError}
                  // hasErrors={!isEmailValid}
                  text="Nº de páginas"
                /> */}
              </div>
            </form>
          </div>
        </div>
      </IntlProvider>
    </LocalizationProvider>
  );
};

export default LeafletForm;
