import { useEffect } from "react";

export function isEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const useEndlessScroll = ({
  listen,
  pageRef,
  onFetchMore,
  offset,
  offsettMultiplier,
}) => {
  useEffect(() => {
    if (!listen) return;
    let hasUnsubscribed = false;
    const elem = pageRef.current;

    const listener = () => {
      if (hasUnsubscribed || !elem) return;
      const { scrollHeight, scrollTop, offsetHeight: height } = elem;

      const totalScrolledHeight = scrollTop + height;

      let resolvedOffset = offset || height;
      //
      if (offsettMultiplier)
        resolvedOffset = resolvedOffset * offsettMultiplier;

      if (scrollHeight - totalScrolledHeight <= resolvedOffset) {
        // hasUnsubscribed = true;
        hasUnsubscribed = true;
        onFetchMore();
      }
    };

    listener();

    if (hasUnsubscribed) return;
    elem.addEventListener("scroll", listener);
    window.addEventListener("resize", listener);

    return () => {
      elem.removeEventListener("scroll", listener);
      window.addEventListener("resize", listener);
    };
  }, [listen, offset, offsettMultiplier, onFetchMore, pageRef]);
};

export const formatPrice = (p) => {
  let result = (p * 0.01).toFixed(2);
  return parseFloat(result).toFixed(2);
};

export const getGridItemValue = (dataItem, field) => {
  const fields = field.split(".");

  let prop = dataItem;
  for (const field of fields) {
    prop = prop[field];
    if (!prop) return prop;
  }
  return prop;
};
