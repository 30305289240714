// eslint-disable-next-line no-unused-vars
const RolesDictionary = {
  Admin: "Admin",
  Client: "Client",
  BackOne: "BackOfficeAll",
  BackTwo: "BackOfficePartial",
};

export const RolesEnum = {
  Client: 1,
  BackTwo: 2,
  BackOne: 3,
  Admin: 4,
};

export const convertToPrice = (Price) => {
  let rPrice = Price;
  if (rPrice === undefined || rPrice === null) return rPrice;

  if (typeof rPrice === "number") rPrice = rPrice.toString();

  return Math.round(parseFloat(rPrice.replace(",", ".")) * 100);
};

export const isAdmin = (Roles = {}) => {
  return Boolean(Roles.Admin);
};
export const isClient = (Roles = {}) => {
  return Boolean(Roles.Client);
};
export const isBackOne = (Roles = {}) => {
  return Boolean(Roles.BackOfficeAll);
};
export const isBackTwo = (Roles = {}) => {
  return Boolean(Roles.BackOfficePartial);
};

export const getHighestPermission = (Roles = {}) => {
  if (isAdmin(Roles)) {
    return RolesEnum.Admin;
  }
  if (isBackOne(Roles)) {
    return RolesEnum.BackOne;
  }
  if (isBackTwo(Roles)) {
    return RolesEnum.BackTwo;
  }
  if (isClient(Roles)) {
    return RolesEnum.Client;
  }
  return undefined;
};
