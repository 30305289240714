import React, { useContext, useState } from "react";
import LeafletsGrid from "./LeafletsGrid";
import {
  Route,
  Redirect,
  Switch,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import LeafletForm, { LeafletFormChecker } from "./LeafletForm";
import { PromotionsFormConverter } from "../Promotions/PromotionsFormComp";
import ExcelExportButton from "../Common/ExcelButton/ExcelExportButton";
import { UserRoleContext } from "../App";
import { getHighestPermission, RolesEnum } from "../AppHelper";
import { FilterListToggler } from "../Common/FilterList/FilterListProvider";
import ViewToggler, {
  ViewTogglerContext,
} from "../Common/ViewToggler/ViewToggler";
import { GridCacheProvider, GridProvider } from "../Common/Grid/GridHelper";
import PlusButton from "../Common/PlusButton/PlusButton";

export const LeafletsCacheContext = React.createContext();
export const LeafletsFilterCacheContext = React.createContext();
export const PromotionsFilterCacheContext = React.createContext();

const Leaflets = () => {
  const Roles = useContext(UserRoleContext);
  const Permission = getHighestPermission(Roles);

  const [currentView, setCurrentView] = useState("list");

  return (
    <GridCacheProvider>
      {/* <LeafletsCacheContext.Provider value={[leafletsCache, setLeafletsCache]}>
        <FilterListProvider>
          <LeafletsFilterCacheContext.Provider
            value={[LeafletsFilterCacheState, setLeafletsFilterCacheState]}
          >
            <PromotionsFilterCacheContext.Provider
              value={[
                PromotionsFilterCacheState,
                setPromotionsFilterCacheState,
              ]}
            > */}
      <ViewTogglerContext.Provider value={[currentView, setCurrentView]}>
        <Switch>
          <Route exact path="/leaflets">
            <GridProvider
              filterUrl={"leaflets/filters"}
              enableCustomFilters
              id="leaflets"
            >
              <LeafletsComp />
            </GridProvider>
          </Route>
          {Permission >= RolesEnum.BackTwo && (
            <Route exact path="/leaflets/new">
              <LeafletForm />
            </Route>
          )}
          {Permission >= RolesEnum.BackTwo && (
            <Route exact path="/leaflets/:id">
              <LeafletFormChecker />
            </Route>
          )}
          {/* {Permission >= RolesEnum.BackTwo && ( */}
          <Route path="/leaflets/:id/promotions">
            <PromotionsFormConverter />
          </Route>
          {/* )} */}
          <Redirect to="/leaflets" />
        </Switch>
      </ViewTogglerContext.Provider>
      {/* </PromotionsFilterCacheContext.Provider>
          </LeafletsFilterCacheContext.Provider>
        </FilterListProvider>
      </LeafletsCacheContext.Provider> */}
    </GridCacheProvider>
  );
};

const LeafletsComp = () => {
  const history = useHistory();
  const Roles = useContext(UserRoleContext);
  const Permission = getHighestPermission(Roles);
  const openForm = () => {
    history.push("/leaflets/new");
  };

  return (
    <div className="p-4 h-100 d-flex flex-column">
      <div className="d-flex align-items-center justify-content-between mb-2">
        <div className="d-flex align-items-center">
          <div className="fs-40 fw-medium mr-3">Folhetos</div>
        </div>
        <div className="d-flex flex-1 align-items-center justify-content-end">
          {Permission >= RolesEnum.BackTwo && (
            <PlusButton className="mr-3" onClick={openForm}>
              Adicionar
            </PlusButton>
          )}
          <ExcelExportButton
            // removemargin={!(Permission >= RolesEnum.BackTwo)}
            BaseUrl={"leaflets/export"}
          />
          <FilterListToggler />
          <ViewToggler className="ml-3" />
        </div>
      </div>
      <LeafletsGrid />
    </div>
  );
};

export default Leaflets;
