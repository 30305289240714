import React, { useContext, useState } from "react";
import styles from "./FilterList.module.css";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import Modal, { ModalHeader } from "../Modal/Modal";
import { usePost } from "../../Helpers/IOClient";
import { faFilter, faSave } from "@fortawesome/pro-solid-svg-icons";
import LabeledInput from "../Input/LabeledInput";
import LoadableButton from "../Button/LoadableButton";
import {
  AdvancedFilterContext,
  createFilterUrl,
  GridContext,
} from "../Grid/GridHelper";
import { FiltersQueryContext } from "./FilterListHelper";
import Button from "../Button/Button";

const EntityModalContent = ({ advancedFilter, isEditing, loading, post }) => {
  const [Name, setName] = useState(() =>
    isEditing ? advancedFilter.Name : ""
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    post({ Name });
  };

  return (
    <div className="p-4">
      <ModalHeader icon={faFilter}>Filtro</ModalHeader>
      <div style={{ width: 576 }} className="mt-4">
        <form onSubmit={handleSubmit}>
          <LabeledInput
            className="mb-4"
            name="Name"
            value={Name}
            placeholder=" "
            onChange={(e) => setName(e.target.value)}
            //   error={emailError}
            // hasErrors={!isEmailValid}
            text="Nome"
          />
          <LoadableButton
            disabled={!Name}
            loading={loading}
            className="w-100"
            type="submit"
          >
            <span>
              <FontAwesomeIcon className="mr-2" icon={faSave} />
              Guardar
            </span>
          </LoadableButton>
        </form>
      </div>
    </div>
  );
};

export const FilterListEntityModal = ({ isOpen, handleClosure, isEditing }) => {
  const { updateSettings, settings } = useContext(GridContext);

  const { filterUrl, filterType } = settings;

  const { refetch } = useContext(FiltersQueryContext) || {};
  const { advancedFilter, setAdvancedFilter } = useContext(
    AdvancedFilterContext
  );
  const [post, { loading }] = usePost(
    createFilterUrl(
      filterUrl,
      isEditing ? advancedFilter.Id : null,
      filterType
    ),
    null,
    {
      onSuccess: ({ data }) => {
        if (isEditing) {
          setAdvancedFilter((af) => ({ ...af, Name: data.Name }));
        } else {
          updateSettings({
            customFilterId: data.Id,
          });
        }
        handleClosure();
        refetch();
      },
    }
  );

  return (
    <Modal disableClosure={loading} isOpen={isOpen} onClose={handleClosure}>
      <EntityModalContent
        advancedFilter={advancedFilter}
        isEditing={isEditing}
        loading={loading}
        post={post}
      />
    </Modal>
  );
};

const FilterListCreator = ({ className, onClick }) => {
  return (
    <>
      <Button
        onClick={onClick}
        className={classnames(
          styles.filterNewButton,

          className,
          "px-2 py-1 d-flex align-items-center"
        )}
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>
    </>
  );
};

export default FilterListCreator;
