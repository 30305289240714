import React, { useState } from "react";
import { faBezierCurve, faSave } from "@fortawesome/pro-light-svg-icons";
import LabeledInput from "../../../Common/Input/LabeledInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CompanyTypesDropdown,
} from "../../../Common/Dropdown/DropdownHelper";
import LoadableButton from "../../../Common/Button/LoadableButton";
import { createEntityModal } from "../../../Common/Modal/ModalHelper";
import { ErrorMessage } from "../../../Leaflets/LeafletForm";
import ErrorMessageHandler from "../../../Common/ErrorMessage/ErrorMessage";

const InnerAddLeafletMediaModal = ({
  form,
  post,
  loading,
  error,
  handleChanges,
  setForm,
}) => {
  const { Name, CompanyType } = form;

  const [errorState, setErrorState] = useState({});

  const isFormValid = () => {
	let valid = true;
    let newErrorState = {};
    if (!/(.|\s)*\S(.|\s)*/.test(Name)) {
		valid = false;
		newErrorState.Name = true;
	  }
    if (!CompanyType) {
		valid = false;
		newErrorState.CompanyType = true;
	  }
	  setErrorState(newErrorState);
	  return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formValidation = isFormValid();
    if (formValidation) {
      const resolvedForm = {
        Name: form.Name,
        CompanyTypeId: form.CompanyType.Id,
      };
      post(resolvedForm);
    }
  };

  return (
    <div style={{ width: 576 }} className="mt-4">
      <form onSubmit={handleSubmit}>
        <LabeledInput
          className="mb-3"
          name="Name"
          value={Name}
          placeholder=" "
          error={errorState.Name && `Nome ${ErrorMessage}`}
          onChange={handleChanges}
          //   error={emailError}
          // hasErrors={!isEmailValid}
          text="Nome"
        />
        <LabeledInput
          className="mb-4 ar-dropdown-modal"
          name="CompanyType"
          value={CompanyType}
		  onChange={handleChanges}
		  error={errorState.CompanyType && `Tipo de Marca ${ErrorMessage}`}
          InputComponent={CompanyTypesDropdown}
          //   error={emailError}
          // hasErrors={!isEmailValid}
          text="Tipo de Marca"
        />
		{error && (
          <div className="text-danger mb-1 text-center">
            <ErrorMessageHandler error={error} />
          </div>
        )}
        <LoadableButton loading={loading} className="w-100" type="submit">
          <span>
            <FontAwesomeIcon className="mr-2" icon={faSave} />
            Guardar
          </span>
        </LoadableButton>
      </form>
    </div>
  );
};

const AddCompaniesModal = createEntityModal({
  getEndpoint: (data) => (data ? `companies/${data.Id}` : `companies`),
  Component: InnerAddLeafletMediaModal,
  headerTitle: "Adicionar Empresa",
  headerIcon: faBezierCurve,
});

export default AddCompaniesModal;

// const AddCompaniesModal = ({ data }) => {
//   const closeModal = useContext(ModalCloseContext);
//   const refetchRef = useContext(GridRefechContext);
//   const [form, setForm] = useState(() => {
//     return {
//       Name: "",
//       CompanyType: "",
//       ...data,
//     };
//   });

//   const handleChanges = (e) => {
//     const { name, value } = e.target;

//     setForm((f) => {
//       return {
//         ...f,
//         [name]: value,
//       };
//     });
//   };

//   const [post, { loading }] = usePost(
//     data ? `companies/${data.Id}` : `companies`,
//     null,
//     {
//       onSuccess: ({ data }) => {
//         if (refetchRef.current) refetchRef.current();
//         closeModal();
//       },
//       onError: ({ error }) => {
//         console.log(error);
//       },
//     }
//   );

//   const { Name, CompanyType } = form;

//   const isFormValid = () => {
//     if (!/(.|\s)*\S(.|\s)*/.test(Name)) return false;
//     if (!CompanyType.Id) return false;
//     else return true;
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     const formValidation = isFormValid();
//     if (formValidation) {
//       const resolvedForm = {
//         Name: form.Name,
//         CompanyTypeId: form.CompanyType.Id,
//       };
//       post(resolvedForm);
//     }
//   };

//   return (
//     <div className="p-4">
//       <ModalHeader icon={faBezierCurve}>Adicionar Empresa</ModalHeader>

//       <div style={{ width: 576 }} className="mt-4">
//         <form onSubmit={handleSubmit}>
//           <LabeledInput
//             className="mb-3"
//             name="Name"
//             value={Name}
//             placeholder=" "
//             required="required"
//             pattern="(.|\s)*\S(.|\s)*"
//             onChange={handleChanges}
//             //   error={emailError}
//             // hasErrors={!isEmailValid}
//             text="Nome"
//           />
//           <LabeledInput
//             className="mb-4 ar-dropdown-modal"
//             name="CompanyType"
//             value={CompanyType}
//             onChange={handleChanges}
//             InputComponent={CompanyTypesDropdown}
//             //   error={emailError}
//             // hasErrors={!isEmailValid}
//             text="Tipo de Empresa"
//           />
//           <LoadableButton loading={loading} className="w-100" type="submit">
//             <span>
//               <FontAwesomeIcon className="mr-2" icon={faSave} />
//               Guardar
//             </span>
//           </LoadableButton>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default AddCompaniesModal;
