import React, { useCallback, useMemo, useRef, useState } from "react";
import { MemoryCache } from "../../Helpers/IOClient";

export const GridCacheContext = React.createContext();

export const GridCacheProvider = ({ children }) => {
  const cacheRef = useRef();
  if (!cacheRef.current) cacheRef.current = new MemoryCache();

  return (
    <GridCacheContext.Provider value={cacheRef.current}>
      {children}
    </GridCacheContext.Provider>
  );
};

export const useGridSynchronizer = () => {};

export const GridContext = React.createContext();

const useGridSettings = (id, hasCustomFilter, filterUrl, filterType) => {
  const [settings, setSettings] = useState(() => {
    let localGridSettings = {};
    if (id) {
      const gridSettingsS = localStorage.getItem(`promo-grid-${id}`);

      if (gridSettingsS) {
        try {
          localGridSettings = JSON.parse(gridSettingsS);
        } catch {}
      }
    }

    const obj = {
      ...localGridSettings,
      id,
      hasCustomFilter
    };

    if (id && hasCustomFilter && obj.isCustomFilterOpen === undefined)
      obj.isCustomFilterOpen = true;

    if (id && hasCustomFilter && obj.isCustomFilterEnabled === undefined)
      obj.isCustomFilterEnabled = true;

    obj.filterUrl = filterUrl;
    obj.filterType = filterType;

    return obj;
  });

  const handleUpdate = useCallback(
    (updatedSettings) => {
      setSettings((s) => {
        const update =
          typeof updatedSettings === "function"
            ? updatedSettings(s)
            : updatedSettings;

        const newS = { ...s, ...update };
        const json = JSON.stringify(newS);
        localStorage.setItem(`promo-grid-${id}`, json);

        return newS;
      });
    },
    [id]
  );

  const filterStateSetterRef = useRef();

  const value = useMemo(
    () => ({
      settings,
      updateSettings: handleUpdate,
      filterStateSetterRef
    }),
    [handleUpdate, settings]
  );

  return value;
};

export const GridProvider = ({
  id,
  enableCustomFilters,
  filterUrl,
  filterType,
  children
}) => {
  const value = useGridSettings(id, enableCustomFilters, filterUrl, filterType);

  return <GridContext.Provider value={value}>{children}</GridContext.Provider>;
};

export const AdvancedFilterContext = React.createContext();

export const AdvancedFilterProvider = ({
  advancedFilter,
  setAdvancedFilter,
  onFilterChange,
  children
}) => {
  const value = useMemo(() => {
    return {
      advancedFilter,
      onFilterChange,
      setAdvancedFilter
    };
  }, [advancedFilter, onFilterChange, setAdvancedFilter]);
  return (
    <AdvancedFilterContext.Provider value={value}>
      {children}
    </AdvancedFilterContext.Provider>
  );
};

export const createFilterUrl = (filterUrl, customFilterId, filterType) => {
  let baseUrl = `${filterUrl}`;

  if (customFilterId) baseUrl += `/${customFilterId}`;

  if (filterType) baseUrl += `?Type=${filterType}`;

  return baseUrl;
};
