import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from "react";
import Button from "../Common/Button/Button";
import {
  Link,
  useHistory,
  Switch,
  Route,
} from "react-router-dom/cjs/react-router-dom.min";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faTimes,
  faPencil,
  faTrashAlt,
} from "@fortawesome/pro-light-svg-icons";
import "./Promotions.css";

import {
  faBadgePercent,
  faCameraAlt,
  faCut,
  faEye,
} from "@fortawesome/pro-solid-svg-icons";
import LabeledInput from "../Common/Input/LabeledInput";

import classnames from "classnames";
import LoaderSpinner from "../Common/LoaderSpinner/LoaderSpinner";
import {
  PromotionTypeDropdown,
  PromotionDropdown,
  ArticleDataDropdown,
  BrandDropdown,
  SubCategoryDropdown,
  MainCategoryDropdown,
  CategoryDropdown,
  ArticlePackDropdown,
  MainBrandDropdown,
} from "../Common/Dropdown/DropdownHelper";

import ptNumbers from "../Assets/Translations/cldr/numbers/pt-PT/numbers.json";
import ptLocalCurrency from "../Assets/Translations/cldr/numbers/pt-PT/currencies.json";
import ptCaGregorian from "../Assets/Translations/cldr/dates/pt-PT/ca-gregorian.json";
import ptDateFields from "../Assets/Translations/cldr/dates/pt-PT/dateFields.json";
import ptMessages from "../Assets/Translations/pt.json";

import "react-image-crop/dist/ReactCrop.css";
import {
  loadMessages,
  load,
  LocalizationProvider,
  IntlProvider,
} from "@progress/kendo-react-intl";
import likelySubtags from "../Assets/Translations/cldr/supplemental/likelySubtags.json";
import currencyData from "../Assets/Translations/cldr/supplemental/currencyData.json";
import weekData from "../Assets/Translations/cldr/supplemental/weekData.json";
import {
  PromotionPageInput,
  ProductsProductionType,
  ProductsUnitsInput,
  ProductsRangeInput,
  ProductsNameInput,
} from "../Common/Input/AutoCompleteInput/AutoCompleteInputHelper";

import { MemoryCache, usePost, useQuery } from "../Helpers/IOClient";
import { createFormData, buildODataQuery } from "../Helpers/ODataHelper";

import { useParams } from "react-router-dom";

import DeleteModal from "../Common/DeleteModalContent/DeleteModalContent";

import PromotionPdfPreview from "../Common/PromotionPdfPreview/PromotionPdfPreview";
import {
  PromotionLeafletContext,
  GridRefechContext,
  UserRoleContext,
} from "../App";

import { formatPrice, useEndlessScroll } from "../Helpers/MiscHelper";
import { ErrorMessage } from "../Leaflets/LeafletForm";
import EuroInput from "../Common/Input/EuroInput";
import LoadableButton from "../Common/Button/LoadableButton";
import ErrorMessageHandler from "../Common/ErrorMessage/ErrorMessage";
import ProductsSelectionGrid from "../Products/ProductsSelectionGrid";
import BaseGrid from "../Common/Grid/Grid";
import ExcelExportButton from "../Common/ExcelButton/ExcelExportButton";
import { convertToPrice, getHighestPermission, RolesEnum } from "../AppHelper";
import FilterListProvider from "../Common/FilterList/FilterListProvider";
import { GridCacheProvider } from "../Common/Grid/GridHelper";

load(
  likelySubtags,
  currencyData,
  weekData,
  ptLocalCurrency,
  ptNumbers,
  ptCaGregorian,
  ptDateFields
);
loadMessages(ptMessages, "pt-PT");

export const PromotionsFormEdit = () => {
  const { id, promoid } = useParams();
  const LeafletFileRef = useContext(PromotionLeafletContext);

  const { data, loading, error } = useQuery(
    `promotions/data/${promoid}`,
    null,
    {
      cache: false,
    }
  );

  if (loading || error) {
    return (
      <div className="p-5">
        <Link
          to="/leaflets"
          style={{ width: "fit-content" }}
          className="Disablehover color-primary d-flex align-items-center mb-2"
        >
          <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
          <span>Voltar</span>
        </Link>
        <div className="d-flex justify-content-center">
          <LoaderSpinner className="color-primary" size="sm" center />
        </div>
      </div>
    );
  } else {
    const {
      Leaflet,
      Id,

      ArticleData,
      ArticleName,
      Promotion,
      Price,
      PriceBefore,
      PromotionPage,

      Page,
      FileUrl,
    } = data;
    const resolvedData = {
      Name: ArticleName,
      ArticleData,
      Id,
      Price: formatPrice(Price),
      PriceBefore: formatPrice(PriceBefore),
      PromotionType: Promotion.PromotionType,
      PromotionId: Promotion,
      PageNumber: Page,
      PromotionPage,
      LeafletId: Leaflet.Id,
      ProductImage: FileUrl,
    };
    const ResolvedFileUrl =
      LeafletFileRef.current && LeafletFileRef.current.Id === `${id}`
        ? LeafletFileRef.current.FileUrl
        : Leaflet.FileUrl
        ? Leaflet.FileUrl
        : null;

    if (!ResolvedFileUrl)
      return (
        <div className="p-5">
          <Link
            to={`/leaflets/${id}/promotions/list/view`}
            style={{ width: "fit-content" }}
            className="Disablehover color-primary d-flex align-items-center mb-2"
          >
            <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
            <span>Voltar</span>
          </Link>
          <div className="d-flex justify-content-center">
            <LoaderSpinner className="color-primary" size="sm" center />
          </div>
        </div>
      );
    else return <PromotionsForm data={resolvedData} page={ResolvedFileUrl} />;
  }
};

export const PromotionsFormConverter = () => {
  const { id, promoid } = useParams();
  const LeafletFileRef = useContext(PromotionLeafletContext);
  // const customFilter = useContext(FilterListContext);

  // const { isHidden, setIsHidden } = customFilter;

  // useLayoutEffect(() => {

  //   if (!isHidden) {
  //     setIsHidden(true);
  //   }
  // }, [isHidden, setIsHidden]);

  const resolvedQueryUrl = useMemo(() => {
    const resovledPromoId = parseInt(promoid);

    return !isNaN(resovledPromoId)
      ? `promotions/data/${resovledPromoId}`
      : LeafletFileRef.current && LeafletFileRef.current.Id === `${id}`
      ? null
      : `Leaflets/${id}`;
  }, [LeafletFileRef, id, promoid]);

  const { data, loading, error } = useQuery(resolvedQueryUrl, null, {
    cache: false,
  });

  if (loading || error) {
    return (
      <div className="p-5">
        <Link
          to="/leaflets"
          style={{ width: "fit-content" }}
          className="Disablehover color-primary d-flex align-items-center mb-2"
        >
          <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
          <span>Voltar</span>
        </Link>
        <div className="d-flex justify-content-center">
          <LoaderSpinner className="color-primary" size="sm" center />
        </div>
      </div>
    );
  } else {
    const resovledPromoId = parseInt(promoid);
    if (!isNaN(resovledPromoId)) {
      const {
        Leaflet,
        Id,

        ArticleData,
        ArticleName,
        Promotion,
        Price,
        PriceBefore,
        PromotionPage,

        Page,
        FileUrl,
      } = data;
      const resolvedData = {
        Name: ArticleName,
        ArticleData,
        Id,
        Price: formatPrice(Price),
        PriceBefore: formatPrice(PriceBefore),
        PromotionType: Promotion.PromotionType,
        PromotionId: Promotion,
        PageNumber: Page,
        PromotionPage,
        LeafletId: Leaflet.Id,
        ProductImage: FileUrl,
      };
      const ResolvedFileUrl =
        LeafletFileRef.current && LeafletFileRef.current.Id === `${id}`
          ? LeafletFileRef.current.FileUrl
          : Leaflet.FileUrl
          ? Leaflet.FileUrl
          : null;

      //   if (!ResolvedFileUrl)
      //     return (
      //       <div className="p-5">
      //         <Link
      //           to={`/leaflets/${id}/promotions/list/view`}
      //           style={{ width: "fit-content" }}
      //           className="Disablehover color-primary d-flex align-items-center mb-2"
      //         >
      //           <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
      //           <span>Voltar</span>
      //         </Link>
      //         <div className="d-flex justify-content-center">
      //           <LoaderSpinner className="color-primary" size="sm" center />
      //         </div>
      //       </div>
      //     );
      //   else
      return (
        <PromotionsForm
          data={resolvedData}
          LeafId={id}
          page={ResolvedFileUrl}
        />
      );
    } else {
      const resolvedData = {
        LeafletId: id,
      };
      const FileUrl =
        LeafletFileRef.current && LeafletFileRef.current.Id === `${id}`
          ? LeafletFileRef.current.FileUrl
          : data
          ? data.FileUrl
          : null;

      //   if (!FileUrl)
      //     return (
      //       <div className="p-5">
      //         <Link
      //           to={`/leaflets/${id}`}
      //           style={{ width: "fit-content" }}
      //           className="Disablehover color-primary d-flex align-items-center mb-2"
      //         >
      //           <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
      //           <span>Voltar</span>
      //         </Link>
      //         <div className="d-flex justify-content-center">
      //           <LoaderSpinner className="color-primary" size="sm" center />
      //         </div>
      //       </div>
      //     );
      //   else
      return <PromotionsForm data={resolvedData} page={FileUrl} LeafId={id} />;
    }
  }
};

export const MultipleArticlesContext = React.createContext();

const PromotionsForm = ({ data, page, convertedPdfReadError, LeafId }) => {
  const Roles = useContext(UserRoleContext);
  const Permission = getHighestPermission(Roles);

  const [snipStatus, setsnipStatus] = useState(false);
  const [MultipleArticlesState, setMultipleArticlesState] = useState(false);
  const [selection, setSelection] = useState({});
  const [form, setForm] = useState(() => {
    return {
      ProductImage: undefined,
      Name: "",
      ArticleData: null,
      PromotionPage: null,
      PromotionType: null,
      MultipleArticles: [],
      PromotionId: null,
      PriceBefore: "",
      Price: "",
      PageNumber: 1,
      ...data,
    };
  });

  const LeafletId = LeafId;

  const HandleCropImageGet = useCallback((data) => {
    const { img, page } = data;
    if (img) {
      setForm((f) => {
        return {
          ...f,
          ProductImage: img,
          PageNumber: page,
        };
      });
      setsnipStatus(false);
    }
  }, []);

  const handleMAC = (state) => {
    setMultipleArticlesState(state);
  };

  // const clearDeselection = () => {
  //   if (deselection.length !== 0) setDeselection({});
  // };

  const [MultipleArticlesUrl, setMultipleArticlesUrl] = useState(null);

  const { loading, error } = useQuery(MultipleArticlesUrl, null, {
    onSuccess: ({ data }) => {
      const resolvedData = data.filter((e) => selection[e.Id]);
      setForm((f) => {
        return {
          ...f,
          ArticleData: "",
          MultipleArticles: resolvedData,
        };
      });
      setMultipleArticlesUrl("");
      handleMAC(false);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleConfirm = (arr) => {
    // eslint-disable-next-line no-unused-vars
    const [url, obj] = arr;
    setMultipleArticlesUrl(url);
  };

  return (
    <LocalizationProvider language="pt-PT">
      <IntlProvider locale="pt-PT">
        <div
          style={{ display: MultipleArticlesState ? "none" : "flex" }}
          className="flex-1 of-hidden w-100 h-100"
        >
          <PromotionPdfPreview
            isCroping={snipStatus}
            FileUrl={page}
            LeafletId={LeafletId}
            onCropEnd={HandleCropImageGet}
          />
          <Switch>
            {Permission >= RolesEnum.BackTwo && (
              <Route exact path="/leaflets/:id/promotions/:promoid">
                <PromotionFormEditOuter
                  handleMAC={handleMAC}
                  form={form}
                  setSelection={setSelection}
                  setForm={setForm}
                  setsnipStatus={setsnipStatus}
                  snipStatus={snipStatus}
                />
              </Route>
            )}
            {/* <Route exact path="/leaflets/:id/promotions/:promoid/duplicate">
              <PromotionFormEditOuter
                duplicate
                handleMAC={handleMAC}
                form={form}
                setForm={setForm}
                setsnipStatus={setsnipStatus}
                snipStatus={snipStatus}
              />
            </Route> */}
            {Permission >= RolesEnum.BackTwo && (
              <Route exact path="/leaflets/:id/promotions/new">
                <GridCacheProvider>
                  <PromotionsNewItemInnerForm
                    form={form}
                    handleMAC={handleMAC}
                    resetForm={true}
                    setForm={setForm}
                    setSelection={setSelection}
                    setsnipStatus={setsnipStatus}
                    snipStatus={snipStatus}
                  />
                </GridCacheProvider>
              </Route>
            )}
            <Route exact path="/leaflets/:id/promotions/list/view">
              <PromotionsItemListNewer LeafletId={LeafletId} />
            </Route>
          </Switch>
        </div>
        {MultipleArticlesState && (
          <MultipleArticlesContext.Provider
            value={[handleConfirm, selection, setSelection]}
          >
            <FilterListProvider>
              <ProductsSelectionGrid
                loading={loading || error}
                handleReturn={() => {
                  handleMAC(false);
                }}
                MultipleArticlesState={MultipleArticlesState}
              />
            </FilterListProvider>
          </MultipleArticlesContext.Provider>
        )}
      </IntlProvider>
    </LocalizationProvider>
  );
};

const isOdd = (num) => {
  return num % 2;
};

const PromotionItemListCell = ({
  LeafletId,
  dataItem,
  index,
  handleDelete,
}) => {
  const history = useHistory();

  const handleEditClick = () => {
    history.push(`/leaflets/${LeafletId}/promotions/${dataItem.id}`);
  };

  const handleDeleteClick = () => {
    handleDelete(dataItem);
  };
  const { ArticleName } = dataItem || {};
  const { Name: ArticleNameName } = ArticleName || {};
  const { BrandName, SubCategoryName } = dataItem.ArticleData || {};
  const { Name: BrandNameName } = BrandName || {};
  const { Name: SubCategoryNameName, CategoryName } = SubCategoryName || {};
  const { Name: CategoryNameName } = CategoryName || {};
  return (
    <div
      className="px-2 py-3 PromotionItemContainer noSelection"
      style={{ background: isOdd(index) && "#f4f5f7" }}
    >
      <div className="text-black fs-16 fw-medium">{ArticleNameName}</div>
      <div className="d-flex aling-items-center justify-content-between">
        <span className="text-secondary fs-14">
          {BrandNameName && BrandNameName + " | "}
          {CategoryNameName && CategoryNameName + " | "}
          {SubCategoryNameName}
        </span>
        <div className="d-flex align-items-center">
          <div onClick={handleEditClick} className="mr-3 cursor-pointer">
            <FontAwesomeIcon icon={faPencil} title="Editar" />
          </div>
          <div onClick={handleDeleteClick} className="mr-3 cursor-pointer">
            <FontAwesomeIcon icon={faTrashAlt} title="Eliminar" />
          </div>
        </div>
      </div>
    </div>
  );
};

const Top = 10;

const ToggleEditionModalsContext = React.createContext();

const ButtonCell = ({ dataItem }) => {
  const [toggleRemove, LeafletId] = useContext(ToggleEditionModalsContext);

  const history = useHistory();

  const openForm = () => {
    history.push(`/leaflets/${LeafletId}/promotions/${dataItem.id}`);
  };

  const handleRemove = () => {
    toggleRemove(dataItem);
  };

  return (
    <td>
      <div className="d-flex align-items-center">
        <div onClick={openForm} className="p-2 grid-button">
          <FontAwesomeIcon size="sm" icon={faPencil} title="Editar" />
        </div>
        <div onClick={handleRemove} className="p-2 grid-button">
          <FontAwesomeIcon size="sm" icon={faTrashAlt} title="Eliminar" />
        </div>
      </div>
    </td>
  );
};

const ClientButtonCell = ({ dataItem }) => {
  // const history = useHistory();

  // const openForm = () => {
  //   history.push(`/promotions/${dataItem.id}/details`);
  // };

  return (
    <td>
      <div className="d-flex align-items-center">
        <Link
          to={`/promotions/${dataItem.id}/details`}
          target="_blank"
          className="p-2 grid-button"
        >
          <FontAwesomeIcon size="sm" icon={faEye} title="Editar" />
        </Link>
      </div>
    </td>
  );
};

// const ColumnMenu = ({ ...props }) => {
//   return (
//     <div>
//       <GridColumnMenuFilter {...props} expanded={true} />
//     </div>
//   );
// };

const priceCell = ({ dataItem, field }) => {
  const value = dataItem[field]?.Name || null;
  return <td>{value === null ? "" : `${formatPrice(parseInt(value))} €`} </td>;
};

const QuantityCell = ({ dataItem, field }) => {
  const fieldArr = useMemo(() => field.split("."), [field]);
  let singleField = dataItem;
  for (const e of fieldArr) {
    singleField = singleField[e];
  }

  const value = parseInt(singleField);
  return <td>{formatPrice(value)}</td>;
};

const columnButton = [
  {
    title: "",
    minResizableWidth: 90,
    field: "Button",
    headerClassName: "hide-grid-title",
    filterable: false,
    sortable: false,
    width: 90,
    cell: ButtonCell,
    show: true,
  },
];

const clientColumnButton = [
  {
    title: "",
    minResizableWidth: 90,
    field: "Button",
    headerClassName: "hide-grid-title",
    filterable: false,
    sortable: false,
    width: 90,
    cell: ClientButtonCell,
    show: true,
  },
];

const columns = [
  // { field: "Id", title: "Id", width: 75,filterable: false },
  {
    field: "ArticleName.Name",
    title: "Nome Promoção",
    disableCustomFilter: true /*custom filter */,
  },

  {
    field: "ArticleData.Article.Name",
    title: "Artigo",
  },
  {
    field: "ArticleData.SubCategoryName.CategoryName.Name",
    title: "Categoria",
  },
  {
    field: "ArticleData.SubCategoryName.Name",
    title: "Subcategoria",
  },
  {
    field: "ArticleData.BrandName.Name",
    title: "Marca",
  },
  {
    field: "ArticleData.Range.Name",
    title: "Variedade",
  },
  {
    field: "PromotionPrice",
    filterable: false,
    title: "Preço de Promoção",
    cell: priceCell,
  },
  {
    field: "PriceBeforePromotion",
    filterable: false,
    title: "Preço",
    cell: priceCell,
  },
  {
    field: "ArticleData.Quantity.Name",
    title: "Quantidade",
    filter: "numeric",
    step: "0.01",
    cell: QuantityCell,
  },
  {
    field: "ArticleData.Unit.Name",
    title: "Unidade de Medida",
  },
  { field: "PromotionTypePromotion.PromotionType.Name", title: "Promoção" },
  {
    field: "PromotionTypePromotion.Promotion.Name",
    title: "Tipo de Promoção",
  },
];

export const PromotionsItemListNewer = ({ LeafletId }) => {
  const SelectedCARef = useRef();

  const [deletingArticles, setDeletingArticles] = useState(false);

  const toggleDeleteModal = (data) => {
    if (!deletingArticles) {
      SelectedCARef.current = data;
    } else {
      SelectedCARef.current = undefined;
    }
    setDeletingArticles((open) => !open);
  };

  const Roles = useContext(UserRoleContext);
  const Permission = getHighestPermission(Roles);

  const resolvedColumns = useMemo(() => {
    if (Permission >= RolesEnum.BackTwo) {
      return [...columnButton, ...columns];
    } else {
      return [...clientColumnButton, ...columns];
    }
  }, [Permission]);

  // const [leafletsCache] = useContext(LeafletsCacheContext);
  // const [
  //   PromotionsFilterCacheState,
  //   setPromotionsFilterCacheState,
  // ] = useContext(PromotionsFilterCacheContext);

  const cacheRef = useRef();
  if (!cacheRef.current) {
    cacheRef.current = new MemoryCache();
  }

  return (
    <div className="h-100 d-flex flex-column main-grid w-50">
      <div className="p-4">
        <Link
          to="/leaflets"
          style={{ width: "fit-content" }}
          className="Disablehover color-primary d-flex align-items-center mb-3"
        >
          <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
          <span>Voltar</span>
        </Link>
        <div className="d-flex align-items-center justify-content-between fs-20">
          <div className="d-flex align-items-center">
            <FontAwesomeIcon className="color-primary" icon={faBadgePercent} />
            <span className="ml-2 text-black fw-medium">
              Promoções Associadas
            </span>
          </div>
          <div className="d-flex align-items-center">
            {Permission >= RolesEnum.BackTwo && (
              <>
                <Link
                  to={`/leaflets/${LeafletId}`}
                  className="app-button rounded primary mr-2 d-flex align-items-center Disablehover"
                >
                  Editar Folheto
                </Link>
                <Link
                  to={`/leaflets/${LeafletId}/promotions/new`}
                  className="app-button rounded primary mr-3 d-flex align-items-center Disablehover"
                >
                  Nova Promoção
                </Link>
              </>
            )}
            <ExcelExportButton
              removemargin
              BaseUrl={`leaflets/${LeafletId}/promotions/export`}
            />
          </div>
        </div>
      </div>
      <ToggleEditionModalsContext.Provider
        value={[toggleDeleteModal, LeafletId]}
      >
        <DeleteModal
          isOpen={deletingArticles}
          onClose={toggleDeleteModal}
          data={SelectedCARef.current}
          closeModal={toggleDeleteModal}
          url="promotions/data"
        />

        <BaseGrid
          // className="Disable-Filter-Row"
          // filterCache={PromotionsFilterCacheState}
          // onFilterChange={setPromotionsFilterCacheState}
          customCache={cacheRef.current}
          Columns={resolvedColumns}
          BaseUrl={`leaflets/${LeafletId}/promotions`}
        />
      </ToggleEditionModalsContext.Provider>
    </div>
  );
};

export const PromotionsItemListInner = ({
  resetDeselection,
  LeafletId,
  handleMAC,
}) => {
  const [data, setData] = useState([]);
  const [skip, setSkip] = useState(0);
  const GridRefetchRef = useContext(GridRefechContext);
  const [hasMore, setHasMore] = useState(true);

  const { loading, error } = useQuery(
    `leaflets/${LeafletId}/promotions` +
      buildODataQuery({ take: Top, skip, count: true }, true),
    null,
    {
      cache: false,
      onSuccess: ({ data }) => {
        if (data.d.results.length !== Top) {
          setHasMore(false);
        }
        setData((f) => {
          return [...f, ...data.d.results];
        });
        resetDeselection();
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const containerRef = useRef();

  const handleDataItemRemoval = useCallback(() => {
    setData((f) => {
      const filteredArray = [...f].filter((e) => {
        return e.Id !== SelectedCARef.current.Id;
      });
      return filteredArray;
    });
  }, []);

  useEffect(() => {
    GridRefetchRef.current = handleDataItemRemoval;
    return () => {
      GridRefetchRef.current = undefined;
    };
  }, [GridRefetchRef, handleDataItemRemoval]);

  const dataStateChange = (e) => {
    setSkip((s) => s + Top);
  };

  useEndlessScroll({
    listen: hasMore && !loading && !error,
    pageRef: containerRef,
    onFetchMore: dataStateChange,
    offsettMultiplier: 1,
  });

  const [isDeleting, setIsDeleting] = useState(false);

  const toggleDeleteModal = () => {
    setIsDeleting(!isDeleting);
  };

  const SelectedCARef = useRef();
  const handleDelete = (data) => {
    SelectedCARef.current = data;
    toggleDeleteModal();
  };

  return (
    <div
      style={{ width: "50%", pageSize: 10 }}
      className="of-hidden d-flex flex-column  bg-white"
    >
      <div className="p-4">
        <Link
          to="/leaflets"
          style={{ width: "fit-content" }}
          className="Disablehover color-primary d-flex align-items-center mb-3"
        >
          <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
          <span>Voltar</span>
        </Link>
        <div className="d-flex align-items-center justify-content-between fs-20">
          <div className="d-flex align-items-center">
            <FontAwesomeIcon className="color-primary" icon={faBadgePercent} />
            <span className="ml-2 text-black fw-medium">
              Promoções Associadas
            </span>
          </div>
          <Link
            to={`/leaflets/${LeafletId}/promotions/new`}
            className="app-button rounded primary mr-2 d-flex align-items-center Disablehover"
          >
            Nova Promoção
          </Link>
        </div>
      </div>
      <div className="border-bottom"></div>
      <DeleteModal
        isOpen={isDeleting}
        onClose={toggleDeleteModal}
        data={SelectedCARef.current}
        closeModal={toggleDeleteModal}
        url="promotions/data"
      />
      <div
        ref={containerRef}
        className="main-grid flex-1 of-y-auto flex-column d-flex"
      >
        {data.map((e, i) => {
          return (
            <PromotionItemListCell
              LeafletId={LeafletId}
              handleDelete={handleDelete}
              key={e.id}
              index={i}
              dataItem={e}
            />
          );
        })}
        {(loading || error) && (
          <div className="d-flex justify-content-center align-items-center my-3">
            <LoaderSpinner size="xxs" center />
          </div>
        )}
      </div>
    </div>
  );
};

// const DefinitionOption = [
//   { Id: 1, Name: "Promoção de artigo" },
//   { Id: 2, Name: "Promoção de múltiplos artigos" },
//   { Id: 3, Name: "Promoção por marca" },
//   { Id: 4, Name: "Promoção por subcategoria" }
// ];

const PromotionFormEditOuter = ({
  duplicate,
  setForm,
  setsnipStatus,
  snipStatus,
  setSelection,
  handleMAC,
  form,
}) => {
  const { id, promoid } = useParams();
  const resolvedQueryUrl = useMemo(() => {
    const resovledPromoId = parseInt(promoid);

    return !isNaN(resovledPromoId)
      ? `promotions/data/${resovledPromoId}`
      : null;
  }, [promoid]);

  const { loading, error } = useQuery(resolvedQueryUrl, null, {
    onSuccess: ({ data }) => {
      const {
        LeafleatData,
        id,
        ArticleData,
        ArticleName,

        PromotionPrice,
        PriceBeforePromotion,
        PromotionPage,
        PromotionTypePromotion,
        Page,
        FileUrl,
      } = data;
      const resolvedData = {
        Name: duplicate ? `Cópia de ${ArticleName.Name}` : ArticleName.Name,
        ArticleData,
        Id: id,
        Price: PromotionPrice ? formatPrice(parseInt(PromotionPrice.Name)) : 0,
        PriceBefore: PriceBeforePromotion
          ? formatPrice(parseInt(PriceBeforePromotion.Name))
          : 0,
        PromotionType: PromotionTypePromotion.PromotionType,
        PromotionId: PromotionTypePromotion,
        PageNumber: Page,
        PromotionPage: PromotionPage.Name,
        LeafletId: LeafleatData.id,
        ProductImage: duplicate ? null : FileUrl,
      };
      setForm(resolvedData);
    },
    cache: false,
  });

  if (loading || error) {
    return (
      <div style={{ width: "50%" }} className="of-y-auto bg-white">
        <div className="p-4">
          <Link
            to={`/leaflets/${id}/promotions/list/view`}
            style={{ width: "fit-content" }}
            className="Disablehover color-primary d-flex align-items-center mb-3"
          >
            <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
            <span>Voltar</span>
          </Link>
          <div className="d-flex align-items-center fs-20">
            <FontAwesomeIcon className="color-primary" icon={faBadgePercent} />
            <span className="ml-2 text-black fw-medium">Editar Promoção</span>
          </div>
        </div>
        <div className="border-bottom"></div>
      </div>
    );
  } else {
    return (
      <PromotionsNewItemInnerForm
        duplicate={duplicate}
        setSelection={setSelection}
        form={form}
        handleMAC={handleMAC}
        setForm={setForm}
        setsnipStatus={setsnipStatus}
        snipStatus={snipStatus}
      />
    );
  }
};

export const ArticleInputValueRender = (element, value) => {
  if (value !== "") {
    console.log(value);
  }
  if (!value) {
    return element;
  }
  const itemchildren = <span>{value.Article.Name}</span>;
  const { children, ...rest } = element.props;
  return React.cloneElement(element, { ...rest }, itemchildren);
};

export const ArticleListValueRender = (li, itemProps) => {
  console.log(itemProps.dataItem);
  const Name = itemProps.dataItem?.Article?.Name;
  let index = `${Name} ${itemProps.dataItem?.Quantity?.Name || ""} ${
    itemProps.dataItem?.Unit?.Name || ""
  } ${itemProps.dataItem?.Pack?.Name || ""} ${
    itemProps.dataItem?.Range?.Name || ""
  } ${itemProps.dataItem?.BrandName?.MainBrandName?.CompanyName?.Name || ""} ${
    itemProps.dataItem?.BrandName?.MainBrandName?.Name || ""
  } ${itemProps.dataItem?.BrandName?.Name || ""} ${
    itemProps.dataItem?.SubCategoryName?.CategoryName?.MainCategoryName
      ?.MainSectorName?.Name
  }`;
  if (Name === "Adicionar novo artigo") index = Name;
  const itemChildren = <span>{index}</span>;
  const { children, ...rest } = li.props;
  return React.cloneElement(li, { ...rest }, itemChildren);
};

const ArticleInputs = ({
  MultipleArticles = [],
  ArticleData,
  errorState,
  handleProductChanges,
  handleProductEdition,
  enableMultipleArticles,
  removeMultipleArticles,
}) => {
  const Roles = useContext(UserRoleContext);
  const Permission = getHighestPermission(Roles);

  if (MultipleArticles.length > 0) {
    return (
      <>
        <div className="d-flex">
          <LabeledInput
            className="ar-dropdown-modal mr-2 flex-1"
            name="MultipleArticles"
            value={`Estão seleccionados ${MultipleArticles.length} artigos`}
            onChange={handleProductChanges}
            disabled
            text="Artigos"
          />

          <div>
            <div className="mb-1" style={{ height: 24 }}></div>
            <div
              style={{ width: "fit-content", height: 50 }}
              onClick={removeMultipleArticles}
              className="Disablehover cursor-pointer text-danger d-flex align-items-center"
            >
              <FontAwesomeIcon icon={faTrashAlt} title="Eliminar" />
            </div>
          </div>
        </div>
        <div
          onClick={enableMultipleArticles}
          className="Disablehover mt-2 cursor-pointer color-primary"
        >
          Adicionar vários artigos
        </div>
      </>
    );
  }

  return (
    <>
      <div className="d-flex">
        <LabeledInput
          className="ar-dropdown-modal mr-2 flex-1"
          name="ArticleData"
          value={ArticleData || ""}
          error={errorState.ArticleData && `Artigo ${ErrorMessage}`}
          onChange={handleProductChanges}
          ExtraDropdownOption={
            Permission >= RolesEnum.BackOne
              ? {
                  Article: { Name: "Adicionar novo artigo" },
                  Idd: 0,
                }
              : undefined
          }
          InputComponent={ArticleDataDropdown}
          ItemRender={ArticleListValueRender}
          ValueRender={ArticleInputValueRender}
          //   error={emailError}
          // hasErrors={!isEmailValid}
          text="Artigo"
        />
        {ArticleData && (
          <div>
            <div className="mb-1" style={{ height: 24 }}></div>
            <div
              style={{ width: "fit-content", height: 50 }}
              onClick={handleProductEdition}
              className="Disablehover cursor-pointer color-primary d-flex align-items-center"
            >
              <FontAwesomeIcon icon={faPencil} title="Editar" />
            </div>
          </div>
        )}
      </div>
      <div
        onClick={enableMultipleArticles}
        className="Disablehover mt-2 cursor-pointer color-primary"
      >
        Adicionar vários artigos
      </div>
    </>
  );
};

const PromotionsNewItemInnerForm = ({
  form,
  setForm,
  setsnipStatus,
  snipStatus,
  handleMAC,
  setSelection,
  resetForm,
  duplicate,
}) => {
  useEffect(() => {
    return () => {
      setSelection({});
      setForm((e) => {
        return {
          ...e,
          ProductImage: undefined,
          Name: "",
          Id: null,
          ArticleData: null,
          PromotionPage: null,
          PromotionType: null,
          PromotionId: null,
          PriceBefore: "",
          Price: "",
          PageNumber: 1,
          MultipleArticles: [],
        };
      });
    };
  }, [setForm, setSelection]);

  const [creatingNewProduct, setCreatingNewProduct] = useState(false);
  const [EditingProduct, setEditingProduct] = useState(false);
  const history = useHistory();
  const {
    Id,
    ArticleData,
    ArticleBrand,
    MultipleArticles,
    Price,
    Name,
    PageNumber,
    ProductImage,
    ArticleSubcategory,
    PromotionType,
    PriceBefore,
    PromotionPage,
    PromotionId,
    LeafletId,
  } = form;

  // const [, setLeafletsCache] = useContext(LeafletsCacheContext);

  const [post, { loading, error }] = usePost(
    Id && !duplicate ? `promotions/data/${Id}` : "promotions/data",
    null,
    {
      onSuccess: () => {
        setForm({
          ProductImage: undefined,
          Name: "",
          ArticleData: null,
          PromotionPage: null,
          PromotionType: null,
          ArticleSubcategory: null,
          ArticleBrand: null,
          PromotionId: null,
          PriceBefore: "",
          Price: "",
          PageNumber: 1,
          LeafletId: LeafletId,
        });
        history.push(`/leaflets/${LeafletId}/promotions/list/view`);
        // setLeafletsCache(new MemoryCache());
      },
      onError: ({ error }) => {
        console.log(error);
      },
    }
  );

  const [MultiplePost, { loading: MultipleLoading }] = usePost(
    Id ? `promotions/data/multiple/${Id}` : "promotions/data/multiple",
    null,
    {
      onSuccess: () => {
        setForm({
          ProductImage: undefined,
          Name: "",
          ArticleData: null,
          MultipleArticles: [],
          PromotionPage: null,
          PromotionType: null,
          ArticleSubcategory: null,
          ArticleBrand: null,
          PromotionId: null,
          PriceBefore: "",
          Price: "",
          PageNumber: 1,
          LeafletId: LeafletId,
        });
        // setLeafletsCache(new MemoryCache());
        history.push(`/leaflets/${LeafletId}/promotions/list/view`);
      },
      onError: ({ error }) => {
        console.log(error);
      },
    }
  );

  const [errorState, setErrorState] = useState({});

  // const closeMultipleArticles = () => {
  //   handleMAC(false);
  // };

  const isFormValid = () => {
    let valid = true;
    let newErrorState = {};
    if (!/(.|\s)*\S(.|\s)*/.test(Name)) {
      valid = false;
      newErrorState.Name = true;
    }
    if (!ArticleData && MultipleArticles.length === 0) {
      valid = false;
      newErrorState.ArticleData = true;
    }
    if (!PromotionPage) {
      valid = false;
      newErrorState.PromotionPage = true;
    }
    if (!PromotionId) {
      valid = false;
      newErrorState.PromotionId = true;
    }
    if (!LeafletId) {
      valid = false;
      newErrorState.LeafletId = true;
    }
    if (!ProductImage) {
      valid = false;
      newErrorState.ProductImage = true;
    }
    if (!PageNumber) {
      valid = false;
      newErrorState.PageNumber = true;
    }
    setErrorState(newErrorState);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isFormValid()) {
      if (MultipleArticles?.length > 0) {
        const ArticleDataIds = MultipleArticles.map((e) => e.Id);

        const resolvedForm = {
          ArticleName: Name,
          ArticleDataIds: JSON.stringify(ArticleDataIds),
          PromotionPage,
          PromotionTypePromotionId: PromotionId.Id,
          Price: convertToPrice(Price),
          PriceBefore: convertToPrice(PriceBefore),
          LeafletId: LeafletId,
          Page: PageNumber,
        };
        MultiplePost(createFormData(resolvedForm, [ProductImage]));
      } else {
        const resolvedForm = {
          ArticleName: Name,
          ArticleDataId: ArticleData.Id,
          PromotionPage,
          PromotionTypePromotionId: PromotionId.Id,
          Price: convertToPrice(Price),
          PriceBefore: convertToPrice(PriceBefore),
          LeafletId: LeafletId,
          Page: PageNumber,
        };
        post(createFormData(resolvedForm, [ProductImage]));
      }
    }
  };

  const removeFile = () => {
    setForm((f) => {
      return {
        ...f,
        ProductImage: undefined,
      };
    });
    setsnipStatus(false);
  };

  const toggleSnipStatus = () => {
    setsnipStatus((t) => {
      return !t;
    });
  };

  const CropeedImageUrlSrc = useMemo(() => {
    if (typeof ProductImage !== "string" && Boolean(ProductImage)) {
      return URL.createObjectURL(ProductImage);
    } else if (typeof ProductImage === "string") return ProductImage;
    else return null;
  }, [ProductImage]);

  const PromotionTypeExtraProfile = useMemo(() => {
    if (PromotionType) {
      return {
        field: "PromotionType/Id",
        operator: "eq",
        value: PromotionType.Id,
      };
    } else return null;
  }, [PromotionType]);

  const handleAddNewProductChange = (value) => {
    setForm((f) => {
      return {
        ...f,
        ArticleData: value,
        ArticleDataId: value.Id,
        Name: value.Name,
      };
    });
    stopEditingProduct(false);
    stopCreatingNewProduct(false);
  };

  const removeMultipleProducts = () => {
    setForm((f) => {
      return {
        ...f,
        MultipleArticles: [],
      };
    });
  };

  const handleProductChanges = (e) => {
    const { value } = e.target;

    if (value.Idd === 0) {
      setCreatingNewProduct(true);
      return;
    } else
      setForm((f) => {
        return {
          ...f,
          ArticleData: value,
          ArticleDataId: value.Id,
          Name: value.Article.Name,
        };
      });
  };

  const BrandRequestUrl = useMemo(() => {
    if (ArticleBrand) {
      return `?$filter=BrandName/Id eq ${ArticleBrand.Id}`;
    } else return null;
  }, [ArticleBrand]);

  const SubcategoryRequestUrl = useMemo(() => {
    if (ArticleSubcategory) {
      return `?$filter=SubCategoryName/Id eq ${ArticleSubcategory.Id}`;
    } else return null;
  }, [ArticleSubcategory]);

  // eslint-disable-next-line no-unused-vars
  const { loading: BrandLoading, error: BrandError } = useQuery(
    BrandRequestUrl ? "articles/data" + BrandRequestUrl : null,
    null,
    {
      onSuccess: ({ data }) => {
        setForm((f) => {
          return {
            ...f,
            ArticleData: data,
          };
        });
      },
    }
  );

  // eslint-disable-next-line no-unused-vars
  const { loading: SubcategoryLoading, error: SubcategoryError } = useQuery(
    SubcategoryRequestUrl ? "articles/data" + SubcategoryRequestUrl : null,
    null,
    {
      onSuccess: ({ data }) => {
        setForm((f) => {
          return {
            ...f,
            ArticleData: data,
          };
        });
      },
    }
  );

  const handleProductEdition = (e) => {
    setEditingProduct(true);
  };

  const handleChanges = (e) => {
    const { name, value } = e.target;

    setForm((f) => {
      if (name === "PromotionType") {
        return {
          ...f,
          [name]: value,
          PromotionId: null,
        };
      } else
        return {
          ...f,
          [name]: value,
        };
    });
  };

  const stopCreatingNewProduct = () => {
    setCreatingNewProduct(false);
  };

  const stopEditingProduct = () => {
    setEditingProduct(false);
  };

  if (EditingProduct) {
    const resolvedArticleData = {
      ...ArticleData,
      Name: ArticleData.Article?.Name,
      Unit: ArticleData.Unit?.Name,
      Range: ArticleData.Range?.Name,
      Quantity: `${formatPrice(parseFloat(ArticleData.Quantity?.Name))}`,
      Category: ArticleData.SubCategoryName?.CategoryName,
      MainCategory: ArticleData.SubCategoryName?.CategoryName?.MainCategoryName,
      SubCategory: ArticleData?.SubCategoryName,
      MainBrandName: ArticleData?.BrandName?.MainBrandName,
      Brand: ArticleData?.BrandName,
      ProductionType: ArticleData.ProductionType?.Name,
    };

    return (
      <PromotionFormCreatingNewProduct
        Data={resolvedArticleData}
        handleSubmitSuccess={handleAddNewProductChange}
        onReturn={stopEditingProduct}
      />
    );
  }
  if (creatingNewProduct) {
    return (
      <PromotionFormCreatingNewProduct
        handleSubmitSuccess={handleAddNewProductChange}
        onReturn={stopCreatingNewProduct}
      />
    );
  }

  const enableMultipleArticles = () => {
    handleMAC(true);
  };

  return (
    <div style={{ width: "50%" }} className="of-y-auto bg-white">
      <div className="p-4">
        <Link
          to={`/leaflets/${LeafletId}/promotions/list/view`}
          style={{ width: "fit-content" }}
          className="Disablehover color-primary d-flex align-items-center mb-3"
        >
          <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
          <span>Voltar</span>
        </Link>
        <div className="d-flex align-items-center fs-20">
          <FontAwesomeIcon className="color-primary" icon={faBadgePercent} />
          <span className="ml-2 text-black fw-medium">
            {Id && !duplicate ? "Editar Promoção" : "Nova Promoção"}
          </span>
        </div>
      </div>
      <div className="border-bottom"></div>
      <form className="p-4">
        <div className="d-flex justify-content-between mb-3">
          <div className="mr-3">
            <div className="mb-1 text-secondary fw-medium">Imagem</div>
            <div className="d-flex justify-content-start">
              {ProductImage ? (
                <div
                  className={classnames(
                    "rounded ar-product-image-container position-relative"
                  )}
                >
                  <div
                    onClick={removeFile}
                    className="ar-file-input-remove-button cursor-pointer"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                  <div
                    style={{
                      background: `url("${CropeedImageUrlSrc}") center`,
                    }}
                    className="w-100 border br-8 ar-product-image h-100"
                  />
                </div>
              ) : (
                <div
                  onClick={toggleSnipStatus}
                  className={classnames(
                    "p-5 rounded text-center cursor-pointer Snippet-Button bg-secondary",
                    { "snip-active": snipStatus }
                  )}
                >
                  {snipStatus ? (
                    <FontAwesomeIcon
                      size={"lg"}
                      className="input-file-icon"
                      icon={faCut}
                    />
                  ) : (
                    <FontAwesomeIcon
                      size={"lg"}
                      className="input-file-icon"
                      icon={faCameraAlt}
                    />
                  )}
                </div>
              )}
            </div>
            {errorState.ProductImage && (
              <div className="text-danger fs-14 mb-1 text-center">
                {`Imagem ${ErrorMessage}`}
              </div>
            )}
          </div>
          <div className="flex-column flex-1 d-flex">
            <ArticleInputs
              ArticleData={ArticleData}
              MultipleArticles={MultipleArticles}
              handleProductChanges={handleProductChanges}
              errorState={errorState}
              removeMultipleArticles={removeMultipleProducts}
              handleProductEdition={handleProductEdition}
              enableMultipleArticles={enableMultipleArticles}
            />
          </div>
        </div>

        <LabeledInput
          className="mb-3 ar-dropdown-modal"
          name="Name"
          value={Name}
          error={errorState.Name && `Nome ${ErrorMessage}`}
          onChange={handleChanges}
          text="Nome Promoção"
        />

        <LabeledInput
          className="mb-4 ar-dropdown-modal"
          name="PromotionType"
          value={PromotionType}
          onChange={handleChanges}
          InputComponent={PromotionTypeDropdown}
          text="Tipo de Promoção"
        />
        <LabeledInput
          className="mb-4 ar-dropdown-modal"
          name="PromotionId"
          value={PromotionId}
          error={errorState.PromotionId && `Promoção ${ErrorMessage}`}
          onChange={handleChanges}
          InputComponent={PromotionDropdown}
          optionalFilters={PromotionTypeExtraProfile}
          text="Promoção"
        />
        <LabeledInput
          className="mb-3 ar-dropdown-modal"
          name="PromotionPage"
          value={PromotionPage}
          error={
            errorState.PromotionPage && `Pagina do Promoção ${ErrorMessage}`
          }
          onChange={handleChanges}
          InputComponent={PromotionPageInput}
          text="Página da Promoção"
        />
        <LabeledInput
          className="mb-3 ar-dropdown-modal"
          name="PriceBefore"
          value={PriceBefore}
          onChange={handleChanges}
          InputComponent={EuroInput}
          type="number"
          step="0.01"
          text="Preço"
        />
        <LabeledInput
          className="mb-3 ar-dropdown-modal"
          name="Price"
          value={Price}
          InputComponent={EuroInput}
          type="number"
          step="0.01"
          onChange={handleChanges}
          text="Preço na Promoção"
        />

        {error && (
          <div className="text-danger mb-1 text-center">
            <ErrorMessageHandler error={error} />
          </div>
        )}
        <LoadableButton
          loading={loading || MultipleLoading}
          className="w-100"
          onClick={handleSubmit}
        >
          Guardar
        </LoadableButton>
      </form>
    </div>
  );
};

const PromotionFormCreatingNewProduct = ({
  Data = {},
  handleSubmitSuccess,
  onReturn,
}) => {
  const [form, setForm] = useState(() => {
    return {
      Name: "",
      MainBrandName: {},
      Brand: {},
      Pack: "",
      Range: "",
      SubCategory: {},
      Category: {},
      MainCategory: {},
      Unit: "",
      Quantity: "",
      ProductionType: "",
      NumberOfPackages: "",
      ...Data,
    };
  });

  const handleChanges = (e) => {
    const { name, value } = e.target;

    if (name === "Quantity") {
      setForm((f) => {
        return {
          ...f,
          [name]: `${value}`,
        };
      });
    } else
      setForm((f) => {
        return {
          ...f,
          [name]: value,
        };
      });
  };

  const {
    Id,
    Name,
    MainBrandName,
    Brand,
    Pack,
    Range,
    SubCategory,
    Unit,
    Category,
    MainCategory,
    Quantity,
    ProductionType,
    NumberOfPackages,
  } = form;

  const [post, { loading, error }] = usePost(
    Id ? `articles/data/${Id}` : "articles/data",
    null,
    {
      onSuccess: ({ data }) => {
        handleSubmitSuccess(data);
      },
      onError: ({ error }) => {
        console.log(error);
      },
    }
  );

  const [errorState, setErrorState] = useState({});

  const isFormValid = () => {
    let valid = true;
    let newErrorState = {};
    if (!/(.|\s)*\S(.|\s)*/.test(Name)) {
      valid = false;
      newErrorState.Name = true;
    }
    if (!SubCategory.Id) {
      valid = false;
      newErrorState.SubCategory = true;
    }

    if (!Pack) {
      valid = false;
      newErrorState.Pack = true;
    }

    if (!Unit) {
      valid = false;
      newErrorState.Unit = true;
    }
    if (!Quantity) {
      valid = false;
      newErrorState.Quantity = true;
    }

    setErrorState(newErrorState);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isFormValid()) {
      const resolvedForm = {
        Name,
        SubCategoryId: SubCategory.Id,
        BrandId: Brand && Brand.Id,
        PackId: Pack.Id,
        Range,
        Quantity: Quantity * 100,
        Unit,
        ProductionType,
        NumberOfPackages,
      };
      post(resolvedForm);
    }
  };

  const CategoryExtraProfile = useMemo(() => {
    if (MainCategory.Id) {
      return {
        field: "MainCategoryName/Id",
        operator: "eq",
        value: MainCategory.Id,
      };
    } else return null;
  }, [MainCategory]);

  const SubCategoryExtraProfile = useMemo(() => {
    if (Category.Id) {
      return {
        field: "CategoryName/Id",
        operator: "eq",
        value: Category.Id,
      };
    } else return null;
  }, [Category]);

  const PackExtraProfile = useMemo(() => {
    if (SubCategory.Id) {
      return `SubCategoryNameId=${SubCategory.Id}`;
    } else return null;
  }, [SubCategory]);

  const ContainerRef = useRef();

  const MainBrandNameExtraProfile = useMemo(() => {
    if (MainBrandName && MainBrandName.Id) {
      return {
        field: "MainBrandName/Id",
        operator: "eq",
        value: MainBrandName.Id,
      };
    } else return null;
  }, [MainBrandName]);

  return (
    <div
      style={{ width: "50%" }}
      className="of-y-auto position-relative bg-white"
    >
      <div className="p-4">
        <div
          onClick={onReturn}
          style={{ width: "fit-content" }}
          className="Disablehover cursor-pointer color-primary d-flex align-items-center mb-3"
        >
          <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
          <span>Voltar</span>
        </div>
        <div className="d-flex align-items-center fs-20">
          <FontAwesomeIcon className="color-primary" icon={faBadgePercent} />
          <span className="ml-2 text-black fw-medium">
            {Id ? "Editar Artigo" : "Novo Artigo"}
          </span>
        </div>
      </div>
      <div className="border-bottom"></div>
      <form ref={ContainerRef} className="p-4">
        <LabeledInput
          className="mb-3"
          name="Name"
          value={Name}
          onChange={handleChanges}
          InputComponent={ProductsNameInput}
          error={errorState.Name && `Nome ${ErrorMessage}`}
          text="Nome"
        />
        <div className="mb-4 d-flex">
          <LabeledInput
            className="flex-1 ar-dropdown-modal"
            name="MainCategory"
            value={MainCategory}
            onChange={handleChanges}
            InputComponent={MainCategoryDropdown}
            text="Categoria Principal"
          />
          {MainCategory.Id && (
            <div className="d-flex flex-column ml-2">
              <div className="flex-1"></div>
              <Button
                size="sm"
                type="button"
                name="MainCategory"
                vType="warning"
                onClick={handleChanges}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </div>
          )}
        </div>

        <div className="mb-4 d-flex">
          <LabeledInput
            className=" ar-dropdown-modal flex-1"
            name="Category"
            value={Category}
            onChange={handleChanges}
            optionalFilters={CategoryExtraProfile}
            InputComponent={CategoryDropdown}
            text="Categoria"
          />
          {Category.Id && (
            <div className="d-flex flex-column ml-2">
              <div className="flex-1"></div>
              <Button
                size="sm"
                type="button"
                name="Category"
                vType="warning"
                onClick={handleChanges}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </div>
          )}
        </div>

        <div className="d-flex mb-4">
          <LabeledInput
            className="flex-1 ar-dropdown-modal"
            error={errorState.SubCategory && `Subcategoria ${ErrorMessage}`}
            name="SubCategory"
            optionalFilters={SubCategoryExtraProfile}
            value={SubCategory}
            onChange={handleChanges}
            InputComponent={SubCategoryDropdown}
            text="Subcategoria"
          />
          {SubCategory.Id && (
            <div className="d-flex flex-column ml-2">
              <div className="flex-1"></div>
              <Button
                size="sm"
                type="button"
                name="SubCategory"
                vType="warning"
                onClick={handleChanges}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </div>
          )}
        </div>
        <div className="mb-4 d-flex">
          <LabeledInput
            className="ar-dropdown-modal flex-1"
            name="MainBrandName"
            value={MainBrandName}
            onChange={handleChanges}
            InputComponent={MainBrandDropdown}
            //   error={emailError}
            // hasErrors={!isEmailValid}
            error={
              errorState.MainBrandName && `Marca Principal ${ErrorMessage}`
            }
            text="Marca Principal"
          />
          {MainBrandName.Id && (
            <div className="d-flex flex-column ml-2">
              <div className="flex-1"></div>
              <Button
                size="sm"
                type="button"
                name="MainBrandName"
                vType="warning"
                onClick={handleChanges}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </div>
          )}
        </div>

        <div className="mb-4 d-flex">
          <LabeledInput
            className="ar-dropdown-modal flex-1"
            name="Brand"
            optionalFilters={MainBrandNameExtraProfile}
            value={Brand}
            onChange={handleChanges}
            InputComponent={BrandDropdown}
            text="Marca"
          />
          {Brand.Id && (
            <div className="d-flex flex-column ml-2">
              <div className="flex-1"></div>
              <Button
                size="sm"
                type="button"
                name="Brand"
                vType="warning"
                onClick={handleChanges}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </div>
          )}
        </div>

        <div className="d-flex mb-4">
          <LabeledInput
            className="ar-dropdown-modal flex-1"
            name="Pack"
            value={Pack}
            ExtraFilters={PackExtraProfile}
            error={errorState.Pack && `Formato Embalagem ${ErrorMessage}`}
            InputComponent={ArticlePackDropdown}
            onChange={handleChanges}
            text="Formato Embalagem"
          />
          {Pack.Id && (
            <div className="d-flex flex-column ml-2">
              <div className="flex-1"></div>
              <Button
                type="button"
                name="Pack"
                vType="warning"
                onClick={handleChanges}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </div>
          )}
        </div>

        <LabeledInput
          className="mb-3"
          InputComponent={ProductsRangeInput}
          name="Range"
          value={Range}
          onChange={handleChanges}
          text="Variedade"
        />

        <LabeledInput
          className="mb-3"
          name="Quantity"
          value={Quantity}
          onChange={handleChanges}
          error={errorState.Quantity && `Quantidade ${ErrorMessage}`}
          text="Quantidade"
        />
        <LabeledInput
          className="mb-4"
          name="NumberOfPackages"
          value={NumberOfPackages || 0}
          type="number"
          error={errorState.Quantity && `Número de Embalagens ${ErrorMessage}`}
          onChange={handleChanges}
          //   error={emailError}
          // hasErrors={!isEmailValid}
          text="Número de Embalagens"
        />
        <LabeledInput
          className="mb-3"
          name="Unit"
          value={Unit}
          onChange={handleChanges}
          InputComponent={ProductsUnitsInput}
          error={errorState.Unit && `Unidade de Medida ${ErrorMessage}`}
          text="Unidade de Medida"
        />
        <LabeledInput
          className="mb-3"
          name="ProductionType"
          value={ProductionType}
          extraRef={ContainerRef.current}
          onChange={handleChanges}
          InputComponent={ProductsProductionType}
          text="Tipo de Produção"
        />
        {error && (
          <div className="text-danger mb-1 text-center">
            <ErrorMessageHandler error={error} />
          </div>
        )}
        <LoadableButton
          loading={loading}
          type="button"
          className="w-100 mb-3"
          onClick={handleSubmit}
        >
          Guardar
        </LoadableButton>
      </form>
    </div>
  );
};

export default PromotionsForm;
