import React from "react";
import "./Login.css";
import LabeledInput from "../Common/Input/LabeledInput";
import { useState } from "react";
import { login } from "../Helpers/AuthHelper";
import LoadableButton from "../Common/Button/LoadableButton";
import { ReactComponent as AskLogo } from "../Assets/Img/360_logo_login.svg";

const LoginErrorTypes = {
  emailPassword: "emailPassword",
  unknown: "unknown"
};

const LoginForm = () => {
  const [form, setForm] = useState(() => {
    return {
      email: "",
      password: ""
    };
  });

  const { email, password } = form;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setError();
    setForm((f) => {
      return {
        ...f,
        [name]: value
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { error } = await login(form);

    if (error) {
      setError(error);
      setLoading(false);
    }
  };

  let emailError =
    error === LoginErrorTypes.emailPassword
      ? "Email ou password estão errados"
      : undefined;

  return (
    <div style={{ maxWidth: 576 }} className="w-100">
      <div className="mb-5 pb-5">
        <AskLogo alt="" />
      </div>

      <div className="fs-40 fw-medium mb-3">Iniciar sessão</div>
      <div className="fs-20 mb-5 text-secondary">
        Bem-vindo novamente, vamos trabalhar?
      </div>

      <form onSubmit={handleSubmit}>
        <LabeledInput
          className="mb-4"
          name="email"
          type="email"
          value={email}
          onChange={handleChange}
          error={emailError}
          // hasErrors={!isEmailValid}
          text="Email"
        />

        <LabeledInput
          className="mb-5"
          name="password"
          type="password"
          value={password}
          onChange={handleChange}
          // hasErrors={!isEmailValid}
          text="Password"
        />

        <LoadableButton loading={loading} disabled={error} className="w-100">
          Iniciar Sessão
        </LoadableButton>
      </form>
    </div>
  );
};

const Login = () => {
  return (
    <div className="d-flex h-100">
      <div className="w-50 bg-primary p-5 d-flex align-items-center justify-content-center">
        <LoginForm />
      </div>
      <div className="w-50 p-5 bg-secondary">
        <div className="app-login-ilustration" />
      </div>
    </div>
  );
};

export default Login;
