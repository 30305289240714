import React from "react";
import { useQuery } from "../Helpers/IOClient";

export const UserQueryContext = React.createContext();

const UserProvider = ({ children }) => {
  const query = useQuery("account", null, { cache: false });
  return (
    <UserQueryContext.Provider value={query}>
      {children}
    </UserQueryContext.Provider>
  );
};

export default UserProvider;
