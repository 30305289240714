import React, { useState, useEffect, useMemo } from "react";
import "./Dropdown.css";
import { useQuery } from "../../Helpers/IOClient";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { buildODataQuery } from "../../Helpers/ODataHelper";
import NoMatchsFound from "../Input/NoMatchsFound";

const createODataMultiDropdownInput = (endpoint, filterField, orderBy) => {
  const DropdownInput = ({
    value,
    optionalFilters,
    ExtraDropdownOption,
    onRemove,
	extraRef,
	tagRender,
    ...rest
  }) => {
    const [resolvedData, setData] = useState([]);
    const [filter, setFilter] = useState({});

    const [resolvedFilter, setResolvedFilter] = useState({});

    useEffect(() => {
      // if (!filter) {
      //   setResolvedFilter(filter);
      // }

      const timeout = setTimeout(() => {
        setResolvedFilter(filter);
      }, 200);

      return () => {
        clearTimeout(timeout);
      };
    }, [filter]);

    const resolvedEndpoint = useMemo(() => {
      const completeResolvedFilter = { ...resolvedFilter };

      completeResolvedFilter.filter = {
        filters: [
          ...(resolvedFilter?.filter?.filters || []),
          ...(optionalFilters ? [optionalFilters] : [])
        ],
        logic: "and"
      };

      if (orderBy) {
        completeResolvedFilter.sort = [{ field: orderBy, dir: "asc" }];
      }
      return endpoint + buildODataQuery(completeResolvedFilter, true);
    }, [optionalFilters, resolvedFilter]);

    const { loading, data } = useQuery(resolvedEndpoint, null, {
      onSuccess: ({ data }) => {
        // ;
        // timeoutRef.current = setTimeout(() => {
        //   setData(data);
        // });
      },
      cache: false
    });

    useEffect(() => {
      setData(data || []);
      // return () => {
      //   clearTimeout(timeoutRef.current);
      // };
    }, [data]);

    const filterChange = (event) => {
      setFilter({ filter: { filters: [event.filter] } });
    };

    const ExtraOptionResolvedData = useMemo(() => {
      return [...resolvedData, ExtraDropdownOption];
    }, [ExtraDropdownOption, resolvedData]);

    return (
      <MultiSelect
        autoClose={false}
        data={ExtraDropdownOption ? ExtraOptionResolvedData : resolvedData}
        filterable={Boolean(filterField)}
        textField={filterField}
        onFilterChange={filterChange}
        //   suggest={false}
        listNoDataRender={() => <NoMatchsFound/>}
        loading={loading}
		value={value}
		tagRender={tagRender}
        //   onChange={onChange}
        {...rest}
        //   placeholder="e.g. Andorra"
      />
    );
  };

  return DropdownInput;
};

export default createODataMultiDropdownInput;
