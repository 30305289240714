import React from "react";
import "./Input.css";
import classnames from "classnames";

const EuroInput = ({ className, ...rest }) => {
  return (
    <div className="euro-input-container position-relative">
      <input
	  min="0"
        className={classnames("app-input rounded px-2", className)}
        {...rest}
      />
    </div>
  );
};

export default EuroInput;
