import { faChevronLeft } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import LoaderSpinner from "../../Common/LoaderSpinner/LoaderSpinner";
import { useQuery } from "../../Helpers/IOClient";
import classnames from "classnames";
import LabeledInput from "../../Common/Input/LabeledInput";
import { faFile } from "@fortawesome/pro-solid-svg-icons";
import styles from "./PromotionDetails.module.css";
import { getDate } from "../../Helpers/DateHelper";
import moment from "moment";
import { formatPrice } from "../../Helpers/MiscHelper";
import { useGridCustomFilter } from "../../Common/Grid/GridCustomFilterProvider";

const PromotionDetails = () => {
  const { id } = useParams();

  const { data, loading, error } = useQuery(`promotions/data/${id}`, null, {
    cache: false,
  });

  const diff = useMemo(() => {
    if (!data) return null;
    const { LeafleatData } = data;
    const db = getDate(LeafleatData.DateBegin.Name);
    const de = getDate(LeafleatData.DateEnd.Name);

    return moment(de).diff(db, "days") + 1;
  }, [data]);

  const pageType = useMemo(() => {
    if (!data) return null;

    const pageTotal = data.LeafleatData?.TotalPage?.Name;

    const currentPage = Number(data.PromotionPage.Name);

    if (
      !pageTotal ||
      pageTotal <= 1 ||
      isNaN(currentPage) ||
      currentPage > pageTotal
    )
      return "-";

    if (currentPage === 1) return "Capa";
    else if (currentPage === pageTotal) return "Contracapa";
    //is odd | impar
    else if (currentPage % 2) return "Impar";
    else return "Par";
  }, [data]);

  const customFilter = useGridCustomFilter();

  const detailsFilter = customFilter ? `?customFilter=${customFilter}` : "";

  const backLink = `/promotions${detailsFilter}`;

  if (loading || error) {
    return (
      <div className="p-5">
        <Link
          to={backLink}
          style={{ width: "fit-content" }}
          className="Disablehover color-primary d-flex align-items-center mb-2"
        >
          <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
          <span>Voltar</span>
        </Link>
        <div className="d-flex justify-content-center">
          <LoaderSpinner className="color-primary" size="sm" center />
        </div>
      </div>
    );
  }

  const {
    FileUrl,
    ArticleName,
    ArticleData,
    LeafleatData,
    PromotionPage,
    PromotionTypePromotion,
    PriceBeforePromotion,
    PromotionPrice,
  } = data;

  return (
    <div className="p-5">
      <Link
        to={backLink}
        style={{ width: "fit-content" }}
        className="Disablehover color-primary d-flex align-items-center mb-2"
      >
        <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
        <span>Voltar</span>
      </Link>
      {/* <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="fs-40 fw-medium">Detalhe Promoção</div>

      </div> */}
      <div className="flex-1 of-hidden d-flex flex-column w-100 p-4 LeafletForm border">
        <div className="mb-3 d-flex align-items-center justify-content-between">
          <span>
            <FontAwesomeIcon className="color-primary mr-2" icon={faFile} />
            <span className="fw-medium text-black fs-20">
              Detalhes de Promoção
            </span>
          </span>
          <div></div>
          {/* {error ? (
                <div className="text-danger mb-1 text-center">
                  <ErrorMessageHandler error={error} />
                </div>
              ) : (
                <div></div>
              )} */}
        </div>
        <div className="border-bottom mb-3"></div>
        <div className="of-y-auto d-flex align-items-baseline mb-4">
          <img src={FileUrl} alt="" />
        </div>
        <div className={classnames("flex-1", styles.grid)}>
          <LabeledInput
            value={ArticleName?.Name}
            disabled
            readOnly
            text="Nome Promoção"
          />
          <LabeledInput
            value={PromotionTypePromotion?.PromotionType?.Name}
            disabled
            readOnly
            text="Tipo de Promoção"
          />
          <LabeledInput
            value={PromotionPage?.Name}
            disabled
            readOnly
            text="Página"
          />
          <LabeledInput
            value={pageType}
            disabled
            readOnly
            text="Tipo de Página"
          />
          <LabeledInput
            value={PromotionTypePromotion?.Promotion?.Name}
            disabled
            readOnly
            text="Promoção"
          />
          <LabeledInput
            value={
              PriceBeforePromotion?.Name
                ? `${formatPrice(parseInt(PriceBeforePromotion?.Name))} €`
                : "-"
            }
            disabled
            readOnly
            text="Preço"
          />
          <LabeledInput
            value={
              PromotionPrice?.Name
                ? `${formatPrice(parseInt(PromotionPrice?.Name))} €`
                : "-"
            }
            disabled
            readOnly
            text="Preço de promoção"
          />
          <LabeledInput
            value={ArticleData?.Article?.Name}
            disabled
            readOnly
            text="Nome produto"
          />
          <LabeledInput
            value={ArticleData?.Unit?.Name}
            disabled
            readOnly
            text="Unidade"
          />
          <LabeledInput
            value={ArticleData?.Range?.Name}
            disabled
            readOnly
            text="Variedade"
          />
          <LabeledInput
            value={ArticleData?.Quantity?.Name}
            disabled
            readOnly
            text="Quantidade"
          />
          <LabeledInput
            value={ArticleData?.SubCategoryName?.CategoryName?.Name}
            disabled
            readOnly
            text="Categoria"
          />
          <LabeledInput
            value={ArticleData?.SubCategoryName?.Name}
            disabled
            readOnly
            text="Subcategoria"
          />
          <LabeledInput
            value={
              ArticleData?.SubCategoryName?.CategoryName?.MainCategoryName?.Name
            }
            disabled
            readOnly
            text="Categoria principal"
          />
          <LabeledInput
            value={
              ArticleData?.SubCategoryName?.CategoryName?.MainCategoryName
                ?.MainSectorName?.Name
            }
            disabled
            readOnly
            text="Sector"
          />
          <LabeledInput
            value={ArticleData?.BrandName?.Name}
            disabled
            readOnly
            text="Marca"
          />
          <LabeledInput
            value={ArticleData?.BrandName?.MainBrandName?.Name}
            disabled
            readOnly
            text="Marca principal"
          />
          <LabeledInput
            value={ArticleData?.ProductionType?.Name}
            disabled
            readOnly
            text="Tipo de produção"
          />

          <LabeledInput
            value={LeafleatData?.LeafleatName?.Name}
            disabled
            readOnly
            text="Nome Folheto"
          />
          <LabeledInput
            value={LeafleatData?.ShopName?.Name}
            disabled
            readOnly
            text="Insígnia"
          />
          <LabeledInput
            value={LeafleatData?.LeafleatType?.Name}
            disabled
            readOnly
            text="Tipo"
          />
          <LabeledInput
            value={LeafleatData?.MediaName?.Name}
            disabled
            readOnly
            text="Fonte"
          />
          <LabeledInput
            value={LeafleatData?.SizeLeafleatName?.Name}
            disabled
            readOnly
            text="Formato"
          />
          <LabeledInput
            value={moment(getDate(LeafleatData.DateBegin.Name)).format(
              "DD/MM/YYYY"
            )}
            disabled
            readOnly
            text="Data Início"
          />
          <LabeledInput
            value={moment(getDate(LeafleatData.DateEnd.Name)).format(
              "DD/MM/YYYY"
            )}
            disabled
            readOnly
            text="Data Fim"
          />
          <LabeledInput value={diff} disabled readOnly text="Nº dias" />
        </div>
      </div>
    </div>
  );
};

export default PromotionDetails;
