import React, { useState, useRef, useContext, useMemo } from "react";
import BaseGrid from "../Common/Grid/Grid";
import moment from "moment";
import { faPencil, faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DeleteModal from "../Common/DeleteModalContent/DeleteModalContent";
import { InsertDateFilterCell } from "../Leaflets/LeafletsGrid";
import { formatPrice } from "../Helpers/MiscHelper";
import { faClone } from "@fortawesome/pro-solid-svg-icons";
import { UserRoleContext } from "../App";
import { getHighestPermission, RolesEnum } from "../AppHelper";
import { ProductsCacheContext, ProductsFilterCacheContext } from "./Products";

const dateCell = ({ dataItem, field }) => {
  const value = dataItem[field];
  return (
    <td>{value === null ? "" : new moment(value).format("DD/MM/YYYY")}</td>
  );
};

const quantityCell = ({ dataItem, field }) => {
  const value = dataItem[field].Name;
  return <td>{formatPrice(value)}</td>;
};

const ButtonCell = ({ dataItem }) => {
  const [toggleRemove] = useContext(ToggleEditionModalsContext);

  const history = useHistory();
  const value = dataItem["Id"];

  const openForm = () => {
    history.push(`/articles/${value}`);
  };

  const duplicateForm = () => {
    history.push(`/articles/${value}/duplicate`);
  };

  const handleRemove = () => {
    toggleRemove(dataItem);
  };

  return (
    <td>
      <div className="d-flex align-items-center justify-content-end">
        <div onClick={duplicateForm} className="p-2 mr-2 grid-button">
          <FontAwesomeIcon size="sm" icon={faClone} title="Duplicar" />
        </div>
        <div onClick={openForm} className="p-2 mr-2 grid-button">
          <FontAwesomeIcon size="sm" icon={faPencil} title="Editar" />
        </div>
        <div onClick={handleRemove} className="p-2 grid-button">
          <FontAwesomeIcon size="sm" icon={faTrashAlt} title="Eliminar" />
        </div>
      </div>
    </td>
  );
};

const ToggleEditionModalsContext = React.createContext();

const ButtonCollumn = [
  // { field: "Id", title: "Id", width: 75,filterable: false },
  {
    title: "",
    minResizableWidth: 120,
    field: "Button",
    headerClassName: "hide-grid-title",
    filterable: false,
    sortable: false,
    width: 90,
    cell: ButtonCell,
    show: true,
  },
];

const columns = [
  { field: "Article.Name", title: "Nome" },
  { field: "SubCategoryName.Name", title: "Subcategoria" },
  { field: "BrandName.Name", title: "Marca" },
  { field: "Pack.Name", title: "Formato Embalagem" },
  { field: "Range.Name", title: "Variedade" },
  { field: "Quantity", title: "Quantidade", cell: quantityCell },
  { field: "Unit.Name", title: "Unidade de Medida" },
  { field: "ProductionType.Name", title: "Produção" },
  {
    field: "InsertDate",
    filter: "date",
    filterCell: InsertDateFilterCell,
    title: "Data de Inserção",
    width: 200,
    cell: dateCell,
  },
];

const ProductsGrid = () => {
  const SelectedCARef = useRef();

  const [deletingArticles, setDeletingArticles] = useState(false);

  const toggleDeleteModal = (data) => {
    if (!deletingArticles) {
      SelectedCARef.current = data;
    } else {
      SelectedCARef.current = undefined;
    }
    setDeletingArticles((open) => !open);
  };

  const Roles = useContext(UserRoleContext);
  const Permission = getHighestPermission(Roles);

  const resolvedColumns = useMemo(() => {
    if (Permission >= RolesEnum.BackOne) {
      return [...ButtonCollumn, ...columns];
    } else {
      return columns;
    }
  }, [Permission]);

  const [productsCache] = useContext(ProductsCacheContext);
  const [ProductsFilterCacheState, setProductsFilterCacheState] = useContext(
    ProductsFilterCacheContext
  );

  return (
    <ToggleEditionModalsContext.Provider value={[toggleDeleteModal]}>
      <DeleteModal
        isOpen={deletingArticles}
        onClose={toggleDeleteModal}
        data={SelectedCARef.current}
        closeModal={toggleDeleteModal}
        url="articles/data"
      />
      <BaseGrid
        filterCache={ProductsFilterCacheState}
        onFilterChange={setProductsFilterCacheState}
        customCache={productsCache}
        Columns={resolvedColumns}
        BaseUrl={"articles/data"}
      />
    </ToggleEditionModalsContext.Provider>
  );
};

export default ProductsGrid;
