import React, { useState, useContext } from "react";

import ChannelTypeGrid from "./ChannelTypeGrid";

import AddChannelTypeModal from "./channelTypeModal/AddChannelTypeModal";
import ExcelExportButton from "../../Common/ExcelButton/ExcelExportButton";
import { UserRoleContext } from "../../App";
import { getHighestPermission, RolesEnum } from "../../AppHelper";
import PlusButton from "../../Common/PlusButton/PlusButton";

const ChannelType = () => {
  const [addingChannelType, setAddingChannelType] = useState(false);

  const Roles = useContext(UserRoleContext);
  const Permission = getHighestPermission(Roles);
  const toggleModal = () => {
    setAddingChannelType((open) => !open);
  };

  return (
    <div className="p-4 h-100 d-flex flex-column main-grid">
      <AddChannelTypeModal isOpen={addingChannelType} onClose={toggleModal} />

      <div className="d-flex align-items-center justify-content-between mb-2">
        <div className="fs-40 fw-medium">Tipo de Canal</div>
        <div className="d-flex flex-1 align-items-center justify-content-end">
          {Permission >= RolesEnum.BackOne && (
            <PlusButton className="mr-3" onClick={toggleModal}>
              Adicionar
            </PlusButton>
          )}
          <ExcelExportButton
            removemargin={!(Permission >= RolesEnum.BackOne)}
            BaseUrl={"channelTypes/export"}
          />
        </div>
      </div>
      <ChannelTypeGrid />
    </div>
  );
};

export default ChannelType;
