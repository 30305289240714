import React, { useState, useContext } from "react";
import ChainGrid from "./ChainGrid";

import AddChainModal from "./ChainModal/AddChainModal";
import ExcelExportButton from "../../Common/ExcelButton/ExcelExportButton";
import { UserRoleContext } from "../../App";
import { getHighestPermission, RolesEnum } from "../../AppHelper";
import PlusButton from "../../Common/PlusButton/PlusButton";

const Chain = () => {
  const [addingChain, setAddingChain] = useState(false);

  const Roles = useContext(UserRoleContext);
  const Permission = getHighestPermission(Roles);
  const toggleModal = () => {
    setAddingChain((open) => !open);
  };

  return (
    <div className="p-4 h-100 d-flex flex-column main-grid">
      <AddChainModal isOpen={addingChain} onClose={toggleModal} />

      <div className="d-flex align-items-center justify-content-between mb-2">
        <div className="fs-40 fw-medium">Cadeia</div>
        <div className="d-flex flex-1 align-items-center justify-content-end">
          {Permission >= RolesEnum.BackOne && (
            <PlusButton className="mr-3" onClick={toggleModal}>
              Adicionar
            </PlusButton>
          )}
          <ExcelExportButton
            removemargin={!(Permission >= RolesEnum.BackOne)}
            BaseUrl={"chains/export"}
          />
        </div>
      </div>
      <ChainGrid />
    </div>
  );
};

export default Chain;
