import { DatePicker } from "@progress/kendo-react-dateinputs";
import React, { useMemo, useState } from "react";
import { useQuery } from "../../Helpers/IOClient";
import Checkbox from "../Checkbox/Checkbox";
import LoaderSpinner from "../LoaderSpinner/LoaderSpinner";
import { FilterOperatorEnum, FilterTypeEnum } from "./FilterListTypings";
import styles from "./FilterList.module.css";
import classnames from "classnames";
import Button from "../Button/Button";
import { convertToPrice } from "../../AppHelper";

const useDependenciesQuery = (filterDependencies, filters) => {
  return useMemo(() => {
    if (!filterDependencies || filterDependencies.length === 0) return "";

    let query = "";

    for (const dep of filterDependencies) {
      const { field, queryName } = dep;
      const depFilters = filters[field];
      if (!depFilters || depFilters.length === 0) continue;

      let filterArr = [];

      for (const filter of depFilters) {
        const { field, value } = filter;

        filterArr.push(value);
      }

      query += `&${queryName}=${encodeURIComponent(JSON.stringify(filterArr))}`;
    }
    console.log(query);
    return query;
  }, [filterDependencies, filters]);
};

const FilterItemsResults = ({
  results,
  itemFiltersDict,
  handleFilterAddition
}) => {
  return (
    <div>
      {results.map((v, i) => {
        const checked = itemFiltersDict[v] || false;

        return (
          <div key={i} className="py-1">
            <Checkbox
              className={checked ? styles.selected : undefined}
              checked={checked}
              onChange={() => handleFilterAddition(v)}
            >
              {v}
            </Checkbox>
          </div>
        );
      })}
    </div>
  );
};

const BaseFilterItemSelector = ({
  column,
  itemFilters,
  onChange,
  setSelectedFilter,
  filterDependencies,
  filters
}) => {
  const [search, setSearch] = useState("");
  const { queryUrl, field } = column;
  const [results, setResults] = useState();

  const depQuery = useDependenciesQuery(filterDependencies, filters);
  const { loading, error } = useQuery(
    `${queryUrl}?search=${search}${depQuery}`,
    null,
    {
      onSuccess: ({ data }) => {
        setResults([...data]);
        console.log("data");
      },
      onError: () => {
        setResults();
      }
    }
  );

  const itemFiltersDict = useMemo(() => {
    const dict = {};

    for (const itemFilter of itemFilters) {
      dict[itemFilter.value] = true;
    }
    return dict;
  }, [itemFilters]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleFilterAddition = (v) => {
    onChange((filters) => {
      const newFilters = [...filters];
      if (itemFiltersDict[v]) {
        const index = newFilters.findIndex((f) => f.value === v);
        if (index !== -1) newFilters.splice(index, 1);
      } else {
        newFilters.push({
          value: v,
          operator: "eq",
          field: field
        });
      }
      return newFilters;
    });
  };

  return (
    <div>
      <input
        placeholder="Pesquisar"
        value={search}
        onChange={handleSearchChange}
        className={classnames("mb-3 mt-3 px-2", styles.searchInput)}
      />
      {(!results && loading) || error ? (
        <LoaderSpinner
          size="xs"
          className="mt-1 text-primary d-flex align-items-center justify-content-center"
        />
      ) : !results || results.length === 0 ? (
        <div>Sem resultados</div>
      ) : (
        <FilterItemsResults
          results={results}
          handleFilterAddition={handleFilterAddition}
          itemFiltersDict={itemFiltersDict}
        />
      )}
    </div>
  );
};

const DateFilterItemSelector = ({
  column,
  itemFilters,
  onChange,
  setSelectedFilter
}) => {
  const filter = itemFilters ? itemFilters[0] || {} : {};
  const { field, dateOperator, filterType } = column;
  const handleFilterChange = (value) => {
    onChange((filters) => {
      const newFilters = [];
      if (value) {
        newFilters.push({
          value: value,
          operator: dateOperator,
          field: field,
          filterType
        });
      }
      return newFilters;
    });
    setSelectedFilter();
  };
  const { value } = filter;

  const [sValue, setSValue] = useState(value);

  return (
    <div>
      <DatePicker
        format="dd-MM-yyyy"
        className={classnames("filterDatePicker mt-2")}
        value={sValue}
        onChange={(e) => setSValue(e.target.value)}
      />
      <Button
        className={classnames(styles.filterApplyButton, "mt-2 d-block")}
        onClick={() => handleFilterChange(sValue)}
      >
        Aplicar
      </Button>
    </div>
  );
};

const getPriceBetweenValue = (filter, propName) => {
  if (!filter) return "";

  const { operator } = filter;

  if (operator !== FilterOperatorEnum.Between) return "";

  const value = filter.value[propName];

  if (value === undefined || value === null) return "";

  return (value * 0.01).toString();
};

const getGenericPriceValue = (filter, currentOperator, targetOperator) => {
  if (!filter) return "";
  if (currentOperator === targetOperator)
    return (filter.value * 0.01).toString();

  return "";
};

const PriceFilterItemSelector = ({
  column,
  itemFilters,
  onChange,
  setSelectedFilter
}) => {
  const filter = itemFilters ? itemFilters[0] : undefined;
  const { field, filterType } = column;

  const [operator, setOperator] = useState(() =>
    filter ? filter.operator : FilterOperatorEnum.Equal
  );

  const [startValue, setStartValue] = useState(() =>
    getPriceBetweenValue(filter, "start")
  );

  const [endValue, setEndValue] = useState(() =>
    getPriceBetweenValue(filter, "end")
  );

  const [greaterValue, setGreaterValue] = useState(() =>
    getGenericPriceValue(
      filter,
      filter?.operator,
      FilterOperatorEnum.GreaterOrEqual
    )
  );

  const [lesserValue, setLesserValue] = useState(() =>
    getGenericPriceValue(
      filter,
      filter?.operator,
      FilterOperatorEnum.LesserOrEqual
    )
  );

  const handleInputChange = (e, setter) => {
    const num = Number(e.target.value);
    if (isNaN(num)) return;

    setter(e.target.value);
  };

  const handleFilterChange = () => {
    let value;
    if (operator === FilterOperatorEnum.Between) {
      if (startValue) {
        if (!endValue) return;

        value = {
          start: convertToPrice(startValue),
          end: convertToPrice(startValue)
        };
      } else {
        value = {
          start: null,
          end: null
        };
      }
    } else if (operator === FilterOperatorEnum.Between) {
      value = null;
    } else if (operator === FilterOperatorEnum.GreaterOrEqual) {
      value = convertToPrice(greaterValue);
    } else if (operator === FilterOperatorEnum.LesserOrEqual) {
      value = convertToPrice(lesserValue);
    }

    onChange((filters) => {
      const newFilters = [];

      newFilters.push({
        value: value,
        operator,
        field: field,
        filterType
      });
      return newFilters;
    });
    setSelectedFilter();
  };

  return (
    <div>
      <Checkbox
        className="flex-1 mt-2"
        checked={Boolean(operator === FilterOperatorEnum.Equal)}
        onChange={() => setOperator(FilterOperatorEnum.Equal)}
      >
        Sem Valor
      </Checkbox>
      <div className="d-flex align-items-center mt-2">
        <Checkbox
          className="flex-1 mr-2"
          checked={Boolean(operator === FilterOperatorEnum.Between)}
          onChange={() => setOperator(FilterOperatorEnum.Between)}
        >
          Valor
        </Checkbox>
        {operator === FilterOperatorEnum.Between && (
          <div className="d-flex">
            <div className="flex-1 mr-2">
              <input
                value={startValue}
                onChange={(e) => handleInputChange(e, setStartValue)}
                placeholder="De"
                className={styles.searchInput}
              />
            </div>
            <div className="flex-1 ml-2">
              <input
                value={endValue}
                onChange={(e) => handleInputChange(e, setEndValue)}
                placeholder="Até"
                className={styles.searchInput}
              />
            </div>
          </div>
        )}
      </div>

      <div className="d-flex align-items-center mt-2">
        <Checkbox
          className="flex-1 mr-2"
          checked={Boolean(operator === FilterOperatorEnum.LesserOrEqual)}
          onChange={() => setOperator(FilterOperatorEnum.LesserOrEqual)}
        >
          Menor
        </Checkbox>
        {operator === FilterOperatorEnum.LesserOrEqual && (
          <input
            value={lesserValue}
            onChange={(e) => handleInputChange(e, setLesserValue)}
            placeholder="Menor que"
            className={styles.searchInput}
          />
        )}
      </div>

      <div className="d-flex align-items-center mt-2">
        <Checkbox
          className="flex-1 mr-2"
          checked={Boolean(operator === FilterOperatorEnum.GreaterOrEqual)}
          onChange={() => setOperator(FilterOperatorEnum.GreaterOrEqual)}
        >
          Maior
        </Checkbox>
        {operator === FilterOperatorEnum.GreaterOrEqual && (
          <input
            value={greaterValue}
            onChange={(e) => handleInputChange(e, setGreaterValue)}
            placeholder="Maior que"
            className={styles.searchInput}
          />
        )}
      </div>

      <Button
        className={classnames(styles.filterApplyButton, "mt-3 d-block")}
        onClick={handleFilterChange}
      >
        Aplicar
      </Button>
    </div>
  );
};

export const FilterItemSelector = (props) => {
  const { column } = props;

  const { filterType } = column;

  switch (filterType) {
    case FilterTypeEnum.date:
      return <DateFilterItemSelector {...props} />;

    case FilterTypeEnum.price:
      return <PriceFilterItemSelector {...props} />;

    default:
      return <BaseFilterItemSelector {...props} />;
  }
};
