import React, { useContext, useEffect, useMemo, useRef } from "react";
import styles from "./Graphs.module.css";
import classnames from "classnames";
import * as echarts from "echarts";
import { useQuery } from "../../Helpers/IOClient";
import LoaderSpinner from "../../Common/LoaderSpinner/LoaderSpinner";
import { AdvancedFilterContext } from "../../Common/Grid/GridHelper";

export const GraphContainer = ({ children, url, builder, filterBuilder }) => {
  const ref = useRef();
  const chartRef = useRef();
  const { data, error, loading } = useEchartsQuery(url, builder, filterBuilder);

  useEffect(() => {
    if (error || loading || data?.promoData?.length === 0) return;

    if (!chartRef.current) chartRef.current = echarts.init(ref.current);

    const myChart = chartRef.current;

    // Draw the chart
    myChart.setOption(data);
    const listener = () => {
      myChart.resize();
    };
    window.addEventListener("resize", listener);

    return () => {
      chartRef.current.dispose();
      chartRef.current = undefined;
      window.removeEventListener("resize", listener);
    };
  }, [data, error, loading]);

  if (loading || error)
    return (
      <div
        key={0}
        ref={ref}
        className={classnames(
          "p-3 shadow bg-white rounded d-flex align-items-center justify-content-center",
          styles.graph
        )}
      >
        <LoaderSpinner size="sm" />
      </div>
    );

  if (data?.promoData?.length === 0) {
    return (
      <div
        key={3}
        className={classnames(
          "p-3 shadow bg-white rounded d-flex flex-column",
          styles.graph
        )}
      >
        <div className="fw-bold  fs-20" style={{ color: "#464646" }}>
          {data.title.text}
        </div>
        <div className="align-items-center flex-1 d-flex fw-medium justify-content-center">
          Não existem ou não tem acesso
        </div>
      </div>
    );
  }

  return (
    <div
      key={1}
      ref={ref}
      className={classnames("p-3 shadow bg-white rounded", styles.graph)}
    >
      {children}
    </div>
  );
};

export const useEchartsQuery = (url, builder, filterBuilder) => {
  const { advancedFilter } = useContext(AdvancedFilterContext) || {};
  const advancedFilterValue = advancedFilter?.Value;
  // console.log(advancedFilter);
  const resolvedUrl = useMemo(() => {
    if (!filterBuilder) return url;

    const filters = filterBuilder(advancedFilterValue, false, true);
    if (filters) return `${url}&${filters}`;
    else return url;
  }, [advancedFilterValue, filterBuilder, url]);

  const { data, error, loading } = useQuery(resolvedUrl);

  const resolvedData = useMemo(() => {
    if (!data) return data;
    return builder(data);
  }, [builder, data]);

  return { data: resolvedData, error, loading };
};

export const graphColorPallete = [
  "#5470c6",
  "#91cc75",
  "#fac858",
  "#ee6666",
  "#73c0de",
  "#3ba272",
  "#fc8452",
  "#9a60b4",
  "#ea7ccc",
  "#4a4a4a"
];
