import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Users from "./Users/Users";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import ArticlesNames from "./Articles/ArticleNames/ArticlesNames.jsx";
import Packs from "./Articles/Packs/Packs";
import Ranges from "./Articles/Range/Range";
import Quantities from "./Articles/Quantities/Quantities";
import Units from "./Articles/Units/Units";
import ProductionTypes from "./Articles/ProductionTypes/ProductionTypes";
import ArticleName from "./Promotions/ArticleName/ArticleName";
import Price from "./Promotions/Price/Price";
import PriceBefore from "./Promotions/PriceBefore/PriceBefore";
import Pages from "./Promotions/Pages/Pages";
import LeafletNames from "./Leaflets/LeafletsNames/leafletNames";
import LeafletTypes from "./Leaflets/LeafletTypes/LeafletTypes";
import LeafletSize from "./Leaflets/LeafletSize/LeafletSize";
import LeafletRegion from "./Leaflets/LeafletRegion/LeafletRegion";
import LeafletDateStart from "./Leaflets/LeafletDateStart/LeafletDateStart";
import LeafletDateEnd from "./Leaflets/LeafletDateEnd/LeafletDateEnd";
import LeafletYears from "./Leaflets/LeafletYears/LeafletYears";
import LeafletMonths from "./Leaflets/LeafletMonths/LeafletMonths";
import LeafletWeeks from "./Leaflets/LeafletWeeks/LeafletWeeks";
import LeafletPages from "./Leaflets/LeafletPages/LeafletPages";
import LeafletMedia from "./Leaflets/LeafletMedia/LeafletMedia";
import Types from "./Promotions/Types/Types";
import Discount from "./Promotions/Discount/Discount";
import { UserRoleContext } from "../App";
import { isAdmin } from "../AppHelper";
import UsersForm from "./Users/UsersForm";
import styles from "./Configurations.module.css";
import classnames from "classnames";

const ConfigurationsNavHeader = ({ children }) => {
  return (
    <div className={classnames("fw-medium p-3", styles.navbarHeader)}>
      {children}
    </div>
  );
};

const ConfigurationNavLink = ({ children, to }) => {
  return (
    <NavLink activeClassName="fw-medium" className="p-3 app-popup-link" to={to}>
      {children}
    </NavLink>
  );
};

const InnerConfigurations = ({ Roles }) => {
  return (
    <>
      <div className="p-4 h-100 d-flex main-grid">
        <div className="ar-configurations-sidebar d-flex flex-column">
          <div className="fs-24 fw-medium mb-3">Configurações</div>

          <div className={classnames("of-y-auto", styles.navbar)}>
            <div className="h-100 overflow-auto">
              {isAdmin(Roles) && (
                <>
                  <ConfigurationsNavHeader>
                    Administração
                  </ConfigurationsNavHeader>
                  <ConfigurationNavLink to={"/config/users"}>
                    Utilizadores
                  </ConfigurationNavLink>
                </>
              )}
              <ConfigurationsNavHeader className="fs-16 mb-2 fw-medium">
                Folhetos
              </ConfigurationsNavHeader>
              <ConfigurationNavLink to={"/config/leaflets/name"}>
                Nomes
              </ConfigurationNavLink>
              <ConfigurationNavLink to={"/config/leaflets/types"}>
                Tipos
              </ConfigurationNavLink>
              <ConfigurationNavLink to={"/config/leaflets/sizes"}>
                Formatos
              </ConfigurationNavLink>
              <ConfigurationNavLink to={"/config/leaflets/region"}>
                Regiões
              </ConfigurationNavLink>
              <ConfigurationNavLink to={"/config/leaflets/dateStart"}>
                Datas de Início
              </ConfigurationNavLink>
              <ConfigurationNavLink to={"/config/leaflets/dateEnd"}>
                Datas de Fim
              </ConfigurationNavLink>
              <ConfigurationNavLink to={"/config/leaflets/years"}>
                Anos
              </ConfigurationNavLink>
              <ConfigurationNavLink to={"/config/leaflets/months"}>
                Meses
              </ConfigurationNavLink>
              <ConfigurationNavLink to={"/config/leaflets/weeks"}>
                Semanas
              </ConfigurationNavLink>
              <ConfigurationNavLink to={"/config/leaflets/pages"}>
                Páginas
              </ConfigurationNavLink>
              <ConfigurationNavLink to={"/config/leaflets/media"}>
                Fonte
              </ConfigurationNavLink>
              <ConfigurationsNavHeader className="fs-16 mt-4 mb-2 fw-medium">
                Promoções
              </ConfigurationsNavHeader>
              <ConfigurationNavLink to={"/config/promotions/articleName"}>
                Nomes
              </ConfigurationNavLink>
              <ConfigurationNavLink to={"/config/promotions/types"}>
                Tipos
              </ConfigurationNavLink>
              <ConfigurationNavLink to={"/config/promotions/discount"}>
                Desconto
              </ConfigurationNavLink>
              <ConfigurationNavLink to={"/config/promotions/price"}>
                Preços de Promoção
              </ConfigurationNavLink>
              <ConfigurationNavLink to={"/config/promotions/priceBefore"}>
                Preços
              </ConfigurationNavLink>
              <ConfigurationNavLink to={"/config/promotions/pages"}>
                Páginas
              </ConfigurationNavLink>
              <ConfigurationsNavHeader className="fs-16 mt-4 mb-2 fw-medium">
                Artigos
              </ConfigurationsNavHeader>
              <ConfigurationNavLink to={"/config/articles/name"}>
                Nomes
              </ConfigurationNavLink>
              <ConfigurationNavLink to={"/config/articles/packs"}>
                Formato Embalagem
              </ConfigurationNavLink>
              <ConfigurationNavLink to={"/config/articles/range"}>
                Variedades
              </ConfigurationNavLink>
              <ConfigurationNavLink to={"/config/articles/quantities"}>
                Quantidades
              </ConfigurationNavLink>
              <ConfigurationNavLink to={"/config/articles/units"}>
                Unidades de Medida
              </ConfigurationNavLink>
              <ConfigurationNavLink to={"/config/articles/productionTypes"}>
                Tipos de Produção
              </ConfigurationNavLink>
            </div>
          </div>
        </div>
        <div className="flex-1 pl-4">
          <Switch>
            <Route path="/config/articles/name">
              <ArticlesNames />
            </Route>
            <Route path="/config/articles/packs">
              <Packs />
            </Route>
            <Route path="/config/articles/range">
              <Ranges />
            </Route>
            <Route path="/config/articles/quantities">
              <Quantities />
            </Route>
            <Route path="/config/articles/units">
              <Units />
            </Route>
            <Route path="/config/articles/productionTypes">
              <ProductionTypes />
            </Route>
            <Route path="/config/promotions/articleName">
              <ArticleName />
            </Route>
            <Route path="/config/promotions/price">
              <Price />
            </Route>
            <Route path="/config/promotions/priceBefore">
              <PriceBefore />
            </Route>
            <Route path="/config/promotions/pages">
              <Pages />
            </Route>
            <Route path="/config/promotions/types">
              <Types />
            </Route>
            <Route path="/config/promotions/discount">
              <Discount />
            </Route>
            <Route path="/config/leaflets/name">
              <LeafletNames />
            </Route>
            <Route path="/config/leaflets/types">
              <LeafletTypes />
            </Route>
            <Route path="/config/leaflets/sizes">
              <LeafletSize />
            </Route>
            <Route path="/config/leaflets/region">
              <LeafletRegion />
            </Route>
            <Route path="/config/leaflets/dateStart">
              <LeafletDateStart />
            </Route>
            <Route path="/config/leaflets/dateEnd">
              <LeafletDateEnd />
            </Route>
            <Route path="/config/leaflets/years">
              <LeafletYears />
            </Route>
            <Route path="/config/leaflets/months">
              <LeafletMonths />
            </Route>
            <Route path="/config/leaflets/weeks">
              <LeafletWeeks />
            </Route>
            <Route path="/config/leaflets/pages">
              <LeafletPages />
            </Route>
            <Route path="/config/leaflets/media">
              <LeafletMedia />
            </Route>
            {isAdmin(Roles) && (
              <Route path="/config/users">
                <Users />
              </Route>
            )}
            <Redirect to="/config/articles/name" />
          </Switch>
        </div>
      </div>
    </>
  );
};

const Configurations = () => {
  const Roles = useContext(UserRoleContext);
  return (
    <Switch>
      {isAdmin(Roles) && (
        <Route path="/config/users/:userId">
          <UsersForm />
        </Route>
      )}
      <Route path="/config">
        <InnerConfigurations Roles={Roles} />
      </Route>
    </Switch>
  );
};

export default Configurations;
