import React from "react";
import ExcelExportButton from "../Common/ExcelButton/ExcelExportButton";
import { FilterListToggler } from "../Common/FilterList/FilterListProvider";
import { GridCacheProvider, GridProvider } from "../Common/Grid/GridHelper";
import PromotionArticlesGrid from "./PromotionArticlesGrid";

const PromotionArticles = () => {
  return (
    <GridCacheProvider>
      <GridProvider
        filterUrl="PromotionArticles/filters"
        enableCustomFilters
        id="promotions-articles"
      >
        <PromotionArticlesComp />
      </GridProvider>
    </GridCacheProvider>
  );
};

const PromotionArticlesComp = () => {
  return (
    <div className="p-4 h-100 d-flex flex-column main-grid">
      <div className="d-flex align-items-center justify-content-between mb-2">
        <div className="d-flex align-items-center">
          <div className="fs-40 fw-medium mr-3">Promoções Artigos</div>
        </div>
        <div className="d-flex align-items-center">
          <ExcelExportButton BaseUrl={"promotionArticles/export"} />
          <FilterListToggler />
        </div>
      </div>
      <PromotionArticlesGrid />
    </div>
  );
};

export default PromotionArticles;
