import React from "react";
import { buildArticleNamesFilterQuery } from "../../Indicators/IndicatorsHelper";
import { GraphContainer } from "./Graphs";

const builder = (data) => {
  const values = data.map((v) => v.InsertsCount);
  const names = data.map((v) => v.Name);

  return {
    title: {
      text: "Top Inserções Artigos",
      left: "left"
    },
    tooltip: {
      trigger: "item"
    },
    // legend: {
    //   top: "5%",
    //   left: "center",
    // },
    xAxis: {
      type: "category",
      data: names,
      show: false
    },
    yAxis: {
      type: "value"
    },
    series: [
      {
        type: "bar",
        data: values
      }
    ],
    promoData: data
  };
};

const ArticlesInsertsGraph = () => {
  return (
    <GraphContainer
      filterBuilder={buildArticleNamesFilterQuery}
      url="indicators/ArticlesInsert?$orderby=InsertsCount desc&$top=10"
      builder={builder}
    />
  );
};

export default ArticlesInsertsGraph;
