import React, { useState, useMemo, useContext } from "react";
import Button from "../Common/Button/Button";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faTimes } from "@fortawesome/pro-light-svg-icons";
import "./Products.css";
import { faSave, faFile, faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import LabeledInput from "../Common/Input/LabeledInput";
import LoaderSpinner from "../Common/LoaderSpinner/LoaderSpinner";
import {
  SubCategoryDropdown,
  CategoryDropdown,
  BrandDropdown,
  MainCategoryDropdown,
  ArticlePackDropdown,
  MainBrandDropdown,
  MainSectorDropdown,
	CompanyDropdown
} from "../Common/Dropdown/DropdownHelper";
import ptNumbers from "../Assets/Translations/cldr/numbers/pt-PT/numbers.json";
import ptLocalCurrency from "../Assets/Translations/cldr/numbers/pt-PT/currencies.json";
import ptCaGregorian from "../Assets/Translations/cldr/dates/pt-PT/ca-gregorian.json";
import ptDateFields from "../Assets/Translations/cldr/dates/pt-PT/dateFields.json";
import ptMessages from "../Assets/Translations/pt.json";
import {
  loadMessages,
  load,
  LocalizationProvider,
  IntlProvider
} from "@progress/kendo-react-intl";
import likelySubtags from "../Assets/Translations/cldr/supplemental/likelySubtags.json";
import currencyData from "../Assets/Translations/cldr/supplemental/currencyData.json";
import weekData from "../Assets/Translations/cldr/supplemental/weekData.json";
import {
  ProductsNameInput,
  ProductsRangeInput,
  ProductsUnitsInput,
  ProductsProductionType
} from "../Common/Input/AutoCompleteInput/AutoCompleteInputHelper";
import { MemoryCache, usePost, useQuery } from "../Helpers/IOClient";
import { useParams } from "react-router-dom";
import DeleteModal from "../Common/DeleteModalContent/DeleteModalContent";
import LoadableButton from "../Common/Button/LoadableButton";
import { ErrorMessage } from "../Leaflets/LeafletForm";
import ErrorMessageHandler from "../Common/ErrorMessage/ErrorMessage";
import { formatPrice } from "../Helpers/MiscHelper";
import { ProductsCacheContext } from "./Products";

load(
  likelySubtags,
  currencyData,
  weekData,
  ptLocalCurrency,
  ptNumbers,
  ptCaGregorian,
  ptDateFields
);
loadMessages(ptMessages, "pt-PT");

export const LeafletFormConverter = ({ duplicate }) => {
  const { id } = useParams();
  const { data, loading, error } = useQuery(`articles/data/${id}`, null, {
    cache: false
  });

  if (loading || error) {
    return (
      <div className="p-5">
        <Link
          to="/articles"
          style={{ width: "fit-content" }}
          className="Disablehover color-primary d-flex align-items-center mb-2"
        >
          <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
          <span>Voltar</span>
        </Link>
        <div className="d-flex justify-content-center">
          <LoaderSpinner className="color-primary" size="sm" center />
        </div>
      </div>
    );
  } else {
    const resolvedData = {
      ...data,
      Name: duplicate ? `Cópia de ${data.Article?.Name}` : data.Article?.Name,
      Unit: data.Unit?.Name,
      Range: data.Range?.Name,
      Quantity: `${formatPrice(parseFloat(data.Quantity.Name))}`,
      Category: data.SubCategoryName?.CategoryName,
      MainCategory: data.SubCategoryName?.CategoryName.MainCategoryName,
      Sector:
        data.SubCategoryName?.CategoryName.MainCategoryName.MainSectorName,
      SubCategory: data.SubCategoryName,
			CompanyName: data.BrandName?.MainBrandName?.CompanyName,
      MainBrandName: data.BrandName?.MainBrandName,
      Brand: data.BrandName,
      ProductionType: data.ProductionType?.Name
    };
    return <LeafletForm duplicate={duplicate} data={resolvedData} />;
  }
};

const LeafletForm = ({ data, duplicate }) => {
  const [form, setForm] = useState(() => {
    return {
      Name: "",
      MainBrandName: {},
      CompanyName: {},
      Brand: {},
      Pack: "",
      Range: "",
      SubCategory: {},
      Category: {},
      Sector: {},
      MainCategory: {},
      Unit: "",
      Quantity: "",
      NumberOfPackages: "",
      ProductionType: "",
      ...data
    };
  });

  const history = useHistory();

  const [, setProductsCache] = useContext(ProductsCacheContext);
  const {
    Id,
    Name,
    Brand,
    Pack,
    Range,
    SubCategory,
    Unit,
    Category,
    NumberOfPackages,
    MainCategory,
    Sector,
    Quantity,
		CompanyName,
    MainBrandName,
    ProductionType
  } = form;

  const [post, { loading, error }] = usePost(
    Id && !duplicate ? `articles/data/${Id}` : "articles/data",
    null,
    {
      onSuccess: ({ data }) => {
        setProductsCache(new MemoryCache());
        history.push("/articles");
      },
      onError: ({ error }) => {
        console.log(error);
      }
    }
  );

  const [errorState, setErrorState] = useState({});

  const isFormValid = () => {
    let valid = true;
    let newErrorState = {};
    if (!/(.|\s)*\S(.|\s)*/.test(Name)) {
      valid = false;
      newErrorState.Name = true;
    }
    if (!SubCategory) {
      valid = false;
      newErrorState.SubCategory = true;
    }

    if (!Pack) {
      valid = false;
      newErrorState.Pack = true;
    }

    if (!Unit) {
      valid = false;
      newErrorState.Unit = true;
    }
    if (!Quantity) {
      valid = false;
      newErrorState.Quantity = true;
    }

    setErrorState(newErrorState);
    return valid;
  };

  const handleSubmit = () => {
    if (isFormValid()) {
      const resolvedForm = {
        Name,
        SubCategoryId: SubCategory.Id,
        BrandId: Brand && Brand.Id,
        PackId: Pack.Id,
        Range,
        SectorId: Sector.Id,
        Quantity: Quantity * 100,
        Unit,
        ProductionType,
        NumberOfPackages
      };
      post(resolvedForm);
    }
  };

  const [deletingComercialArea, setDeletingComercialArea] = useState(false);

  const toggleDeleteModal = (data) => {
    setDeletingComercialArea((open) => !open);
  };

  const MainCategoryExtraProfile = useMemo(() => {
    if (Sector?.Id) {
      return {
        field: "MainSectorName/Id",
        operator: "eq",
        value: Sector.Id
      };
    } else return null;
  }, [Sector]);

  const CategoryExtraProfile = useMemo(() => {
    if (MainCategory?.Id) {
      return {
        field: "MainCategoryName/Id",
        operator: "eq",
        value: MainCategory.Id
      };
    } else return null;
  }, [MainCategory]);

  const SubCategoryExtraProfile = useMemo(() => {
    if (Category?.Id) {
      return {
        field: "CategoryName/Id",
        operator: "eq",
        value: Category.Id
      };
    } else return null;
  }, [Category]);

  const PackExtraProfile = useMemo(() => {
    if (SubCategory?.Id) {
      return `SubCategoryNameId=${SubCategory.Id}`;
    } else return null;
  }, [SubCategory]);

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setForm((f) => {
      return {
        ...f,
        [name]: value
      };
    });
  };

  const handleMainCategoryChanges = (e) => {
    const { value } = e.target;
    setForm((f) => {
      return {
        ...f,
        MainCategory: value,
        Category: {},
        SubCategory: {},
        Pack: ""
      };
    });
  };
  const handleCategoryChanges = (e) => {
    const { value } = e.target;
    setForm((f) => {
      return {
        ...f,
        MainCategory: value.MainCategoryName,
        Category: value,
        SubCategory: {},
        Pack: ""
      };
    });
  };
  const handleSubCategoryChanges = (e) => {
    const { value } = e.target;
    setForm((f) => {
      return {
        ...f,
        MainCategory: value.CategoryName.MainCategoryName,
        Category: value.CategoryName,
        SubCategory: value,
        Pack: ""
      };
    });
  };

  const MainBrandNameExtraProfile = useMemo(() => {
    if (MainBrandName && MainBrandName.Id) {
      return {
        field: "MainBrandName/Id",
        operator: "eq",
        value: MainBrandName.Id
      };
    } else return null;
  }, [MainBrandName]);

  const CompanyNameExtraProfile = useMemo(() => {
    if (CompanyName && CompanyName.Id) {
      return {
        field: "CompanyName/Id",
        operator: "eq",
        value: CompanyName.Id
      };
    } else return null;
  }, [CompanyName]);

  return (
    <LocalizationProvider language="pt-PT">
      <IntlProvider locale="pt-PT">
        <DeleteModal
          isOpen={deletingComercialArea}
          onClose={toggleDeleteModal}
          data={form}
          goToOnSuccess={"/articles"}
          closeModal={toggleDeleteModal}
          url="articles/data"
        />

        <div className="p-5">
          <Link
            to="/articles"
            style={{ width: "fit-content" }}
            className="Disablehover color-primary d-flex align-items-center mb-2"
          >
            <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
            <span>Voltar</span>
          </Link>
          <div className="d-flex align-items-center justify-content-between mb-3">
            <div className="fs-40 fw-medium">
              {Id && !duplicate ? "Editar Artigo" : "Novo Artigo"}
            </div>
            <div className="d-flex align-items-center">
              {Id && !duplicate && (
                <Button
                  className="mr-2 informative"
                  onClick={toggleDeleteModal}
                >
                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      title="Eliminar"
                      className="mr-2"
                    />
                    Eliminar
                  </div>
                </Button>
              )}
              <LoadableButton loading={loading} onClick={handleSubmit}>
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon icon={faSave} className="mr-2" />
                  Guardar
                </div>
              </LoadableButton>
            </div>
          </div>
          <div className="flex-1 of-hidden d-flex flex-column w-100 p-4 LeafletForm border">
            <div className="mb-3 d-flex align-items-center justify-content-between">
              <span>
                <FontAwesomeIcon className="color-primary mr-2" icon={faFile} />
                <span className="fw-medium text-black fs-20">
                  Detalhes do Artigo
                </span>
              </span>
              {error ? (
                <div className="text-danger mb-1 text-center">
                  <ErrorMessageHandler error={error} />
                </div>
              ) : (
                <div></div>
              )}
            </div>
            <div className="border-bottom mb-3"></div>
            <form className="d-flex flex-1 justify-content-between">
              <div className="w-100 d-flex flex-column mr-3">
                <LabeledInput
                  className="mb-4"
                  name="Name"
                  value={Name}
                  onChange={handleChanges}
                  InputComponent={ProductsNameInput}
                  error={errorState.Name && `Nome ${ErrorMessage}`}
                  //   error={emailError}
                  // hasErrors={!isEmailValid}
                  text="Nome"
                />

                <div className="mb-4 d-flex">
                  <LabeledInput
                    className="flex-1 ar-dropdown-modal"
                    name="Sector"
                    value={Sector}
                    onChange={handleChanges}
                    InputComponent={MainSectorDropdown}
                    text="Sector"
                  />
                  {Sector?.Id && (
                    <div className="d-flex flex-column ml-2">
                      <div className="flex-1"></div>
                      <Button
                        size="sm"
                        type="button"
                        name="Sector"
                        vType="warning"
                        onClick={handleChanges}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </Button>
                    </div>
                  )}
                </div>
                <div className="mb-4 d-flex">
                  <LabeledInput
                    className="flex-1 ar-dropdown-modal"
                    name="MainCategory"
                    value={MainCategory}
                    onChange={handleMainCategoryChanges}
                    optionalFilters={MainCategoryExtraProfile}
                    InputComponent={MainCategoryDropdown}
                    text="Categoria Principal"
                  />
                  {MainCategory?.Id && (
                    <div className="d-flex flex-column ml-2">
                      <div className="flex-1"></div>
                      <Button
                        size="sm"
                        type="button"
                        name="MainCategory"
                        vType="warning"
                        onClick={handleChanges}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </Button>
                    </div>
                  )}
                </div>

                <div className="mb-4 d-flex">
                  <LabeledInput
                    className=" ar-dropdown-modal flex-1"
                    name="Category"
                    value={Category}
                    onChange={handleCategoryChanges}
                    optionalFilters={CategoryExtraProfile}
                    InputComponent={CategoryDropdown}
                    text="Categoria"
                  />
                  {Category?.Id && (
                    <div className="d-flex flex-column ml-2">
                      <div className="flex-1"></div>
                      <Button
                        size="sm"
                        type="button"
                        name="Category"
                        vType="warning"
                        onClick={handleChanges}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </Button>
                    </div>
                  )}
                </div>

                <div className="d-flex mb-4">
                  <LabeledInput
                    className="flex-1 ar-dropdown-modal"
                    error={
                      errorState.SubCategory && `Subcategoria ${ErrorMessage}`
                    }
                    name="SubCategory"
                    optionalFilters={SubCategoryExtraProfile}
                    value={SubCategory}
                    onChange={handleSubCategoryChanges}
                    InputComponent={SubCategoryDropdown}
                    text="Subcategoria"
                  />
                  {SubCategory?.Id && (
                    <div className="d-flex flex-column ml-2">
                      <div className="flex-1"></div>
                      <Button
                        size="sm"
                        type="button"
                        name="SubCategory"
                        vType="warning"
                        onClick={handleChanges}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </Button>
                    </div>
                  )}
                </div>

                <div className="mb-4 d-flex">
                  <LabeledInput
                    className="ar-dropdown-modal flex-1"
                    name="CompanyName"
                    value={CompanyName}
                    onChange={handleChanges}
                    InputComponent={CompanyDropdown}
                    //   error={emailError}
                    // hasErrors={!isEmailValid}
                    error={
                      errorState.MainBrandName &&
                      `Empresa ${ErrorMessage}`
                    }
                    text="Empresa"
                  />
                  {CompanyName?.Id && (
                    <div className="d-flex flex-column ml-2">
                      <div className="flex-1"></div>
                      <Button
                        size="sm"
                        type="button"
                        name="CompanyName"
                        vType="warning"
                        onClick={handleChanges}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </Button>
                    </div>
                  )}
                </div>
                <div className="mb-4 d-flex">
                  <LabeledInput
                    className="ar-dropdown-modal flex-1"
                    name="MainBrandName"
										optionalFilters={CompanyNameExtraProfile}
                    value={MainBrandName}
                    onChange={handleChanges}
                    InputComponent={MainBrandDropdown}
                    //   error={emailError}
                    // hasErrors={!isEmailValid}
                    error={
                      errorState.MainBrandName &&
                      `Marca Principal ${ErrorMessage}`
                    }
                    text="Marca Principal"
                  />
                  {MainBrandName?.Id && (
                    <div className="d-flex flex-column ml-2">
                      <div className="flex-1"></div>
                      <Button
                        size="sm"
                        type="button"
                        name="MainBrandName"
                        vType="warning"
                        onClick={handleChanges}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </Button>
                    </div>
                  )}
                </div>
              </div>
              <div className="w-100 d-flex flex-column mr-3">
                <div className="mb-4 d-flex">
                  <LabeledInput
                    className="ar-dropdown-modal flex-1"
                    name="Brand"
                    optionalFilters={MainBrandNameExtraProfile}
                    value={Brand}
                    onChange={handleChanges}
                    InputComponent={BrandDropdown}
                    text="Marca"
                  />
                  {Brand?.Id && (
                    <div className="d-flex flex-column ml-2">
                      <div className="flex-1"></div>
                      <Button
                        size="sm"
                        type="button"
                        name="Brand"
                        vType="warning"
                        onClick={handleChanges}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </Button>
                    </div>
                  )}
                </div>
                <div className="d-flex mb-4">
                  <LabeledInput
                    className="ar-dropdown-modal flex-1"
                    name="Pack"
                    value={Pack}
                    ExtraFilters={PackExtraProfile}
                    error={
                      errorState.Pack && `Formato Embalagem ${ErrorMessage}`
                    }
                    InputComponent={ArticlePackDropdown}
                    onChange={handleChanges}
                    text="Formato Embalagem"
                  />
                  {Pack?.Id && (
                    <div className="d-flex flex-column ml-2">
                      <div className="flex-1"></div>
                      <Button
                        type="button"
                        name="Pack"
                        vType="warning"
                        onClick={handleChanges}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </Button>
                    </div>
                  )}
                </div>
                <LabeledInput
                  className="mb-4"
                  InputComponent={ProductsRangeInput}
                  name="Range"
                  value={Range}
                  onChange={handleChanges}
                  //   error={emailError}
                  // hasErrors={!isEmailValid}
                  text="Variedade"
                />
                <LabeledInput
                  className="mb-4"
                  name="Quantity"
                  value={Quantity}
                  type="number"
                  error={errorState.Quantity && `Quantidade ${ErrorMessage}`}
                  onChange={handleChanges}
                  //   error={emailError}
                  // hasErrors={!isEmailValid}
                  text="Quantidade"
                />
                <LabeledInput
                  className="mb-4"
                  name="NumberOfPackages"
                  value={NumberOfPackages || 0}
                  type="number"
                  error={
                    errorState.Quantity &&
                    `Número de Embalagens ${ErrorMessage}`
                  }
                  onChange={handleChanges}
                  //   error={emailError}
                  // hasErrors={!isEmailValid}
                  text="Número de Embalagens"
                />
                <LabeledInput
                  className="mb-4"
                  name="Unit"
                  value={Unit}
                  error={errorState.Unit && `Unidade de Medida ${ErrorMessage}`}
                  onChange={handleChanges}
                  InputComponent={ProductsUnitsInput}
                  //   error={emailError}
                  // hasErrors={!isEmailValid}
                  text="Unidade de Medida"
                />
                <LabeledInput
                  className="mb-4"
                  name="ProductionType"
                  value={ProductionType}
                  onChange={handleChanges}
                  InputComponent={ProductsProductionType}
                  //   error={emailError}
                  // hasErrors={!isEmailValid}
                  text="Tipo de Produção"
                />
              </div>
            </form>
          </div>
        </div>
      </IntlProvider>
    </LocalizationProvider>
  );
};

export default LeafletForm;
