import React, { useState } from "react";
import { faBezierCurve, faSave } from "@fortawesome/pro-light-svg-icons";
import LabeledInput from "../../../../Common/Input/LabeledInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadableButton from "../../../../Common/Button/LoadableButton";
import { createEntityModal } from "../../../../Common/Modal/ModalHelper";
import { ErrorMessage } from "../../../../Leaflets/LeafletForm";
import { getDays } from "../../../../Helpers/DateHelper";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { load, loadMessages, LocalizationProvider, IntlProvider } from "@progress/kendo-react-intl";
import likelySubtags from "../../../../Assets/Translations/cldr/supplemental/likelySubtags.json";
import currencyData from "../../../../Assets/Translations/cldr/supplemental/currencyData.json";
import weekData from "../../../../Assets/Translations/cldr/supplemental/weekData.json";
import ptNumbers from "../../../../Assets/Translations/cldr/numbers/pt-PT/numbers.json";
import ptLocalCurrency from "../../../../Assets/Translations/cldr/numbers/pt-PT/currencies.json";
import ptCaGregorian from "../../../../Assets/Translations/cldr/dates/pt-PT/ca-gregorian.json";
import ptDateFields from "../../../../Assets/Translations/cldr/dates/pt-PT/dateFields.json";
import ptMessages from "../../../../Assets/Translations/pt.json";
import ErrorMessageHandler from "../../../../Common/ErrorMessage/ErrorMessage";
load(
  likelySubtags,
  currencyData,
  weekData,
  ptLocalCurrency,
  ptNumbers,
  ptCaGregorian,
  ptDateFields
);
loadMessages(ptMessages, "pt-PT");

const InnerAddLeafletMediaModal = ({
  form,
  post,
  loading,
  handleChanges,
  setForm,
  error
}) => {
  const { Name } = form;

  const [errorState, setErrorState] = useState({});

  const isFormValid = () => {
    let valid = true;
    let newErrorState = {};
    if (!/(.|\s)*\S(.|\s)*/.test(Name)) {
      valid = false;
      newErrorState.Name = true;
    }
    setErrorState(newErrorState);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formValidation = isFormValid();
    if (formValidation) {
      post({ Name: getDays(Name) });
    }
  };

  return (
    <LocalizationProvider language="pt-PT">
      <IntlProvider locale="pt-PT">
        <div style={{ width: 576 }} className="mt-4">
          <form onSubmit={handleSubmit}>
            <LabeledInput
              className="mb-4 ar-dropdown-modal"
              name="Name"
              value={Name}
              error={errorState.Name && `Data fim ${ErrorMessage}`}
              onChange={handleChanges}
              InputComponent={DatePicker}
              //   error={emailError}
              // hasErrors={!isEmailValid}
              text="Data Fim"
            />
			{error && (
          <div className="text-danger mb-1 text-center">
            <ErrorMessageHandler error={error} />
          </div>
        )}
            <LoadableButton loading={loading} className="w-100" type="submit">
              <span>
                <FontAwesomeIcon className="mr-2" icon={faSave} />
                Guardar
              </span>
            </LoadableButton>
          </form>
        </div>
      </IntlProvider>
    </LocalizationProvider>
  );
};

const AddLeafletDateEndModal = createEntityModal({
  getEndpoint: (data) => (data ? `dates/end/${data.Id}` : `dates/end`),
  Component: InnerAddLeafletMediaModal,
  headerTitle: "Adicionar Date de Fim",
  headerIcon: faBezierCurve,
});

export default AddLeafletDateEndModal;

// const AddLeafletDateEndModal = ({data}) => {
//   const closeModal = useContext(ModalCloseContext);
//   const refetchRef = useContext(GridRefechContext);
//   const [form, setForm] = useState(() => {
//     return {
// 	  Name: "",
// 	  ...data
//     };
//   });

//   const handleChanges = (e) => {
//     const { name, value } = e.target;

//     setForm((f) => {
//       return {
//         ...f,
//         [name]: value,
//       };
//     });
//   };

//   const [post, { loading }] = usePost(data?`dates/end/${data.Id}`:`dates/end`, null, {
//     onSuccess: ({ data }) => {
// 		if(refetchRef.current)refetchRef.current()
// 		closeModal();
// 	},
//     onError: ({ error }) => {
// 		console.log(error)
// 	},
//   });

//   const { Name } = form;

//   const isFormValid = () => {
// 	  if(!(/(.|\s)*\S(.|\s)*/.test(Name))) return false
// 	  else return true
//   };

//   const handleSubmit = (e) => {
// 	  e.preventDefault();

// 	  const formValidation = isFormValid();
// 	  if(formValidation){
// 		post(form)
// 	  }
//   };

//   return (
//     <div className="p-4">
//       <ModalHeader icon={faBezierCurve}></ModalHeader>

//       <div style={{ width: 576 }} className="mt-4">
//         <form
// 		onSubmit={handleSubmit}>
//           <LabeledInput
//             className="mb-4"
//             name="Name"
// 			value={Name}
// 			placeholder=" "
//             required="required"
//             pattern="(.|\s)*\S(.|\s)*"
//             onChange={handleChanges}
//             //   error={emailError}
//             // hasErrors={!isEmailValid}
//             text="Nome"
//           />
//           <LoadableButton loading={loading} className="w-100" type="submit">
//             <span>
//               <FontAwesomeIcon className="mr-2" icon={faSave} />
//               Guardar
//             </span>
//           </LoadableButton>
//         </form>
//       </div>
//     </div>
//   );
// };
