import React, { useState } from "react";
import { usePost } from "../../../Helpers/IOClient";
import {
  passwordErrorTypes,
  usePasswordErrors,
} from "../../../Helpers/PasswordHelper";
import LabeledInput from "../../../Common/Input/LabeledInput";
import LoadableButton from "../../../Common/Button/LoadableButton";
import Modal, { ModalHeader } from "../../../Common/Modal/Modal";
import { faKey } from "@fortawesome/pro-solid-svg-icons";

const UsersPasswordModal = ({ user, open, toggleOpen }) => {
  const [form, setForm] = useState(() => {
    return {
      newPassword: "",
      confirmPassword: "",
    };
  });

  const [error, setError] = useState();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setError();
    setForm((f) => {
      return {
        ...f,
        [name]: value,
      };
    });
  };
  const { Id } = user || {};
  const [unknownError, setUnknownError] = useState();
  const [post, { loading }] = usePost(
    `admins/users/${Id}/ChangePassword`,
    null,
    {
      onSuccess: () => {
        toggleOpen();
        //   setSucceeded(true);
      },
      onError: ({ error }) => {
        if (error.data && error.data.Message) {
          if (error.data.Message === "Incorrect password.")
            setError(passwordErrorTypes.incorrectPassword);
          else if (error.data.Message.includes("Passwords must have"))
            setError(passwordErrorTypes.newPasswordRequired);
          else setUnknownError(error);
        } else {
          setUnknownError(error);
        }
      },
    }
  );

  const { newPassword, confirmPassword } = form;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUnknownError();
    if (newPassword === "" || newPassword.length < 6) {
      setError(passwordErrorTypes.newPasswordRequired);
      return;
    } else if (newPassword !== confirmPassword) {
      setError(passwordErrorTypes.passwordsDontMatch);
      return;
    }

    post({
      Password: newPassword,
    });
  };

  const { newPasswordError, passowrdMismatchError } = usePasswordErrors(error);

  return (
    <Modal isOpen={open} disableClosure={loading} onClose={toggleOpen}>
      <div className="p-4">
        <ModalHeader icon={faKey}>Alterar Pssword</ModalHeader>
        <form onSubmit={handleSubmit} className="mt-4" style={{ width: 576 }}>
          <LabeledInput
            className="mb-4"
            name="newPassword"
            type="password"
            value={newPassword}
            onChange={handleChange}
            error={newPasswordError}
            autoComplete="new-password"
            // hasErrors={!isEmailValid}
            text="Nova password"
          />

          <LabeledInput
            className="mb-5"
            name="confirmPassword"
            type="password"
            value={confirmPassword}
            onChange={handleChange}
            error={passowrdMismatchError}
            autoComplete="new-password"
            // hasErrors={!isEmailValid}
            text="Confirmar nova password"
          />

          {unknownError && (
            <div className="text-center text-danger mb-3">
              Não conseguimos alterar a sua password. Por favor tente mais tarde
            </div>
          )}

          <LoadableButton
            loading={loading}
            disabled={Boolean(error)}
            className="w-100"
          >
            Alterar
          </LoadableButton>
        </form>
      </div>
    </Modal>
  );
};

export default UsersPasswordModal;
