import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import MainSector from "./MainSectors/MainSectors";
import MainCatagories from "./MainCategories/MainCatagories";
import Categories from "./Categories/Categories";
import SubCategory from "./SubCategories/SubCategories";


const CategoriesRouter = () => {
  return (
    <Switch>
      <Route path="/mainSectors">
        <MainSector />
      </Route>
      <Route path="/categories/main"><MainCatagories/></Route>
      <Route path="/categories/sub"><SubCategory/></Route>
      <Route exact path="/categories"><Categories/></Route>
      <Redirect to="/categories" />
    </Switch>
  );
};

export default CategoriesRouter;
