import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useLayoutEffect,
  useMemo,
  useCallback
} from "react";
import ReactDOM from "react-dom";
import {
  LocalizationProvider,
  IntlProvider,
  load,
  loadMessages
} from "@progress/kendo-react-intl";
import classnames from "classnames";
import {
  Grid,
  GridColumn as Column,
  GridColumnMenuFilter,
  GridColumnMenuItemContent,
  GridColumnMenuItemGroup,
  GridNoRecords
} from "@progress/kendo-react-grid";
import "./Grid.css";
import likelySubtags from "../../Assets/Translations/cldr/supplemental/likelySubtags.json";
import currencyData from "../../Assets/Translations/cldr/supplemental/currencyData.json";
import weekData from "../../Assets/Translations/cldr/supplemental/weekData.json";
import ptNumbers from "../../Assets/Translations/cldr/numbers/pt-PT/numbers.json";
import ptLocalCurrency from "../../Assets/Translations/cldr/numbers/pt-PT/currencies.json";
import ptCaGregorian from "../../Assets/Translations/cldr/dates/pt-PT/ca-gregorian.json";
import ptDateFields from "../../Assets/Translations/cldr/dates/pt-PT/dateFields.json";
import ptMessages from "../../Assets/Translations/pt.json";
import LoaderSpinner from "../LoaderSpinner/LoaderSpinner";
import { buildODataQuery } from "../../Helpers/ODataHelper";
import { cacheType, client, useQuery } from "../../Helpers/IOClient";
import {
  GridRefechContext,
  ExportsFilterObjectContext,
  UserRoleContext,
  ExportsFilterBuilderContext
} from "../../App";
import FilterList from "../FilterList/FilterList";
import { buildCustomODataFilter } from "../../Helpers/CustomFilterBuilder";
import {
  AdvancedFilterProvider,
  createFilterUrl,
  GridCacheContext,
  GridContext
} from "./GridHelper";
import { FiltersQueryContext } from "../FilterList/FilterListHelper";
import { getHighestPermission, RolesEnum } from "../../AppHelper";
import { useGridCustomFilter } from "./GridCustomFilterProvider";

load(
  likelySubtags,
  currencyData,
  weekData,
  ptLocalCurrency,
  ptNumbers,
  ptCaGregorian,
  ptDateFields
);
loadMessages(ptMessages, "pt-PT");

const LoadingPanel = () => {
  const [element, setElement] = useState(null);

  const loadingPanel = (
    <div
      style={{ position: "absolute", left: 0, top: 0, zIndex: 5 }}
      className="p-3 justify-content-center w-100 h-100 d-flex align-items-center"
    >
      <LoaderSpinner className="color-primary" size="sm" center />
    </div>
  );

  useLayoutEffect(() => {
    const gridContent = document && document.querySelector(".k-grid-content");
    setElement(gridContent);
  }, []);

  return element ? ReactDOM.createPortal(loadingPanel, element) : null;
};

// const dateCell = ({ dataItem, field }) => {
//   const value = dataItem[field];
//   return (
//     <td>
//       {value === null ? "" : new moment(getDate(value)).format("DD/MM/YYYY")}
//     </td>
//   );
// };

// const RefreshButton = ({ Refresh }) => {
//   return (
//     <button
//       className={"k-button k-button-icon"}
//       title="Refresh"
//       onClick={Refresh}
//     >
//       <span className="k-icon k-i-refresh" />
//       REFRESH
//     </button>
//   );
// };

const pageableOptions = {
  buttonCount: 10,
  info: true,
  type: "numeric",
  pageSizes: [50, 100, 250, 500],
  previousNext: true,
  refresh: true
};

export const GridItemsContext = React.createContext();

const filterOperators = {
  text: [
    { operator: "contains", text: "grid.filterContainsOperator" },
    {
      operator: "startswith",
      text: "grid.filterStartsWithOperator"
    },

    { operator: "eq", text: "grid.filterEqOperator" },
    {
      operator: "doesnotcontain",
      text: "grid.filterNotContainsOperator"
    },

    { operator: "endswith", text: "grid.filterEndsWithOperator" }
    //   { operator: "isempty", text: "grid.filterIsEmptyOperator" },
    //   {
    //     operator: "isnotempty",
    //     text: "grid.filterIsNotEmptyOperator"
    //   }
  ],
  numeric: [
    { text: "grid.filterEqOperator", operator: "eq" },

    { text: "grid.filterGteOperator", operator: "gte" },
    { text: "grid.filterGtOperator", operator: "gt" },
    { text: "grid.filterLteOperator", operator: "lte" },
    { text: "grid.filterLtOperator", operator: "lt" }
  ]
};

const GridFiller = ({ ...props }) => {
  return <td></td>;
};

export const InnerBaseGrid = ({
  Columns,
  filterCache = {},
  onFilterChange,
  onUrlUpdate,
  defaultSorts,
  BaseUrl,
  GridSettings,
  customCache,
  customODataFilterBuilder,
  className
}) => {
  const { settings, filterStateSetterRef } = useContext(GridContext) || {};

  const {
    isCustomFilterOpen,
    isCustomFilterEnabled: isAdvancedFilterEnabled,
    id,
    hasCustomFilter: hasAdvancedFilter
  } = settings || {};
  // const [previousActiveFitler, setPreviousActiveFitler] = useState(
  //   isCustomFilterOpen || false
  // );

  const [gridState, setGridState] = useState({
    products: { data: [], total: 0 },
    dataState: {
      take: 50,
      skip: 0,
      count: true,
      sort: defaultSorts ? defaultSorts : [],
      ...filterCache
    }
  });

  const gridCache = useContext(GridCacheContext);

  useEffect(() => {
    if (!id || !gridCache) return;
    gridCache.write(id, gridState.dataState);
  }, [gridCache, gridState.dataState, id]);

  const setFilter = useCallback((filter) => {
    setGridState((kendoFilter) => {
      let rFilter;
      if (typeof filter === "function") {
        rFilter = filter(kendoFilter.dataState.filter);
      } else {
        rFilter = filter;
      }
      return {
        ...kendoFilter,
        dataState: { ...kendoFilter.dataState, skip: 0, filter: rFilter }
      };
    });
  }, []);

  useEffect(() => {
    if (!filterStateSetterRef) return;
    filterStateSetterRef.current = setFilter;

    return () => (filterStateSetterRef.current = undefined);
  }, [filterStateSetterRef, setFilter]);

  // useEffect(() => {
  //   const ActiveCustomFilter = isCustomFilterOpen;
  //   if (previousActiveFitler !== ActiveCustomFilter) {
  //     setPreviousActiveFitler(ActiveCustomFilter);
  //     setGridState((f) => {
  //       return {
  //         ...f,
  //         dataState: { ...f.dataState, skip: 0, filter: undefined },
  //       };
  //     });
  //   }
  // }, [isCustomFilterOpen, previousActiveFitler]);

  const GridRefetchRef = useContext(GridRefechContext);

  const [ActiveColumns, setActiveColumns] = useState(() => {
    const newcolumns = Columns.map((e) => e);
    const test = HandleColumnInitialization(newcolumns, BaseUrl);
    return [
      ...test,
      {
        field: "",
        title: "Fake",
        filterable: false,
        resizable: false,
        show: true,
        Cell: GridFiller,
        reorderable: false
      }
    ];
  });

  // useEffect(() => {
  //   onFilterChange && onFilterChange({ skip, take, filter, sort, group });
  // }, [filter, group, onFilterChange, skip, sort, take]);

  const dataStateChange = useCallback((e) => {
    setGridState((f) => {
      return {
        ...f,
        dataState: { ...f.dataState, ...e.data }
      };
    });
  }, []);

  const dataRecieved = useCallback((products) => {
    setGridState((f) => {
      return {
        ...f,
        products: products
      };
    });
  }, []);

  const lastSuccessRef = useRef();

  const FilterObjRef = useContext(ExportsFilterObjectContext);
  const FilterBuilderRef = useContext(ExportsFilterBuilderContext);

  const MountedRef = useRef(false);

  const [resolvedDataState, setResolvedDataState] = useState({
    ...gridState.dataState
  });

  useEffect(() => {
    if (!MountedRef.current) {
      MountedRef.current = true;
      return;
    }
    const Timeout = setTimeout(() => {
      setResolvedDataState({ ...gridState.dataState });
    }, 300);
    return () => {
      clearTimeout(Timeout);
    };
  }, [gridState.dataState]);

  const customFilter = useGridCustomFilter();

  const ResolvedUrl = useMemo(() => {
    const url =
      BaseUrl +
      buildODataQuery({ ...resolvedDataState, customFilter }, true, {
        filterBuilder:
          hasAdvancedFilter && !customFilter && isAdvancedFilterEnabled
            ? customODataFilterBuilder || buildCustomODataFilter
            : undefined
      });

    if (onUrlUpdate) {
      let NewGridState = { ...resolvedDataState };
      onUrlUpdate(BaseUrl, NewGridState);
    }
    return url;
  }, [
    BaseUrl,
    customFilter,
    customODataFilterBuilder,
    hasAdvancedFilter,
    isAdvancedFilterEnabled,
    onUrlUpdate,
    resolvedDataState
  ]);

  const { loading, refetch } = useQuery(ResolvedUrl, null, {
    cache: customCache || cacheType.enabled,
    onSuccess: ({ data }) => {
      lastSuccessRef.current = buildODataQuery(resolvedDataState, true);
      if (data && data.d && data.d.__count > 0) {
        dataRecieved({
          data: data.d.results,
          total: data.d.__count
        });
      } else {
        dataRecieved({
          data: [],
          total: 0
        });
      }
      let gridContainer = document.querySelector(".k-grid-content");
      if (gridContainer) {
        gridContainer.scrollTop = 0;
      }
    },
    onError: (error) => {
      console.log(error);
    }
  });

  const handleFilterChange = useCallback(
    (e) => {
      const { filter } = e;
      setFilter(filter);
      // setGridState((f) => {
      //   return {
      //     ...f,
      //     dataState: { ...f.dataState, skip: 0, filter },
      //   };
      // });
    },
    [setFilter]
  );

  useEffect(() => {
    FilterBuilderRef.current = customODataFilterBuilder;

    return () => (FilterBuilderRef.current = undefined);
  }, [FilterBuilderRef, customODataFilterBuilder, gridState.dataState]);

  useEffect(() => {
    FilterObjRef.current = gridState.dataState;

    return () => {
      FilterObjRef.current = undefined;
    };
  }, [FilterObjRef, gridState.dataState]);

  useEffect(() => {
    GridRefetchRef.current = refetch;
    return () => {
      GridRefetchRef.current = undefined;
    };
  }, [FilterObjRef, GridRefetchRef, refetch]);

  const gridRef = useRef();

  const onColumnsSubmit = useCallback(
    (newColumns) => {
      UpdateShowLocalStorage(newColumns, BaseUrl);
      setActiveColumns(newColumns);
    },
    [BaseUrl]
  );

  const forceUpdate = useCallback(() => {
    // const newcolumns = [...ActiveColumns];
    // const test = HandleColumnInitialization(newcolumns, BaseUrl);
    // setActiveColumns(test);

    const newcolumns = Columns.map((e) => e);
    const test = HandleColumnInitialization(newcolumns, BaseUrl);
    setActiveColumns([
      ...test,
      {
        field: "",
        title: "Fake",
        filterable: false,
        resizable: false,
        show: true,
        Cell: GridFiller,
        reorderable: false
      }
    ]);
  }, [BaseUrl, Columns]);

  const onColumnsReset = useCallback(() => {
    setActiveColumns(Columns);
  }, [Columns]);

  const handleColumnsResize = useCallback(
    (props) => {
      const { columns, end } = props;
      if (end) {
        UpdateWidthLocalStorage(columns, BaseUrl, forceUpdate);
      }
      // UpdateWidthLocalStorage(columns, BaseUrl);
    },
    [BaseUrl, forceUpdate]
  );

  const handleColumnsReorder = useCallback(
    (props) => {
      const { columns } = props;
      UpdateOrderLocalStorage(columns, BaseUrl);
    },
    [BaseUrl]
  );

  const columns = useMemo(() => {
    return ActiveColumns.map((e, i) => {
      const options = {
        ...e,
        columnMenu: (props) => (
          <div>
            {e.columnMenu && <GridColumnMenuFilter {...props} />}
            <br />
            <CustomColumnMenu
              {...props}
              OriginalColumns={Columns}
              onColumnsReset={onColumnsReset}
              BaseUrl={BaseUrl}
              columns={ActiveColumns}
              onColumnsSubmit={onColumnsSubmit}
            />
          </div>
        ),

        width: e.field ? (e.width ? e.width : 250) : 150,
        minResizableWidth: e.field ? 200 : 120
      };
      if (e.show !== undefined && !e.show) return null;
      if (!e.resizable && e.title === "Fake") {
        return <Column key={i} {...e} title="" />;
      }

      return <Column key={i} {...options} />;
    });
  }, [ActiveColumns, BaseUrl, Columns, onColumnsReset, onColumnsSubmit]);

  return (
    <div className="leafleft-grid main-grid flex-1 overflow-auto">
      <GridItemsContext.Provider value={gridState}>
        <LocalizationProvider language="pt-PT">
          <IntlProvider locale="pt-PT">
            <div className="h-100 d-flex">
              {isAdvancedFilterEnabled &&
                isCustomFilterOpen &&
                !customFilter && (
                  <FilterList
                    // filter={filter}
                    // setFilter={setFilter}
                    columns={Columns}
                  />
                )}
              <div className="flex-1 overflow-hidden h-100">
                <Grid
                  // key={gridKey}
                  // rowRender={rowRender}
                  ref={gridRef}
                  className={classnames(
                    "overflow-auto position-relative h-100",
                    className
                  )}
                  filterable={isAdvancedFilterEnabled ? false : true}
                  resizable
                  // reorderable
                  onColumnResize={handleColumnsResize}
                  onColumnReorder={handleColumnsReorder}
                  sortable={true}
                  onFilterChange={
                    hasAdvancedFilter ? undefined : handleFilterChange
                  }
                  filterOperators={filterOperators}
                  // 	date: [
                  // 		{ text: "grid.filterEqOperator", operator: "eqDate" },
                  // 		{ text: "grid.filterEqOperator", operator: "neqDate" },
                  // 		{ text: "grid.filterEqOperator", operator: "ltDate" },
                  // 		{ text: "grid.filterEqOperator", operator: "gtDate" },
                  // 		{ text: "grid.filterEqOperator", operator: "lteDate" },
                  // 		{ text: "grid.filterEqOperator", operator: "gteDate" },
                  // 	  ],
                  //   }}

                  pageable={pageableOptions}
                  {...gridState.dataState}
                  {...gridState.products}
                  onDataStateChange={dataStateChange}
                >
                  <GridNoRecords>
                    {!loading ? (
                      "Não existe dados."
                    ) : (
                      <LoaderSpinner
                        className="color-primary"
                        size="xs"
                        center
                      />
                    )}
                  </GridNoRecords>
                  {columns}
                </Grid>

                {loading && <BetterLoadingPanel />}
                {/* {loading && gridState?.products?.data?.length > 0 && (
                  <LoadingPanel />
                )} */}
              </div>
            </div>
          </IntlProvider>
        </LocalizationProvider>
      </GridItemsContext.Provider>
    </div>
  );
};

const BetterLoadingPanel = () => {
  const loadingPanel = (
    <div className="k-loading-mask color-primary">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );

  const gridContent = document && document.querySelector(".k-grid-content");
  return gridContent
    ? ReactDOM.createPortal(loadingPanel, gridContent)
    : loadingPanel;
};

export const CustomColumnMenu = ({
  columns,
  BaseUrl,
  OriginalColumns,
  onColumnsSubmit,
  onColumnsReset,
  ...props
}) => {
  // const [columnsExpanded, setColumnsExpanded] = useState(false);

  const [columnsState, setColumnsState] = useState(() => {
    return columns.map((c) => {
      return {
        show: true,
        ...c
      };
    });
  });

  const onReset = (event) => {
    event.preventDefault();
    removeFromLs(BaseUrl);
    onColumnsReset();
    if (props.onCloseMenu) {
      props.onCloseMenu();
    }
  };

  const handleToggle = (id) => {
    setColumnsState((t) => {
      return t.map((column, idx) => {
        return idx === id ? { ...column, show: !column.show } : column;
      });
    });
  };

  // const onMenuItemClick = () => {
  //   setColumnsExpanded(!columnsExpanded);
  // };

  const oneVisibleColumn = columnsState.filter((c) => c.show).length === 1;

  const onSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    onColumnsSubmit(columnsState);
    if (props.onCloseMenu) {
      props.onCloseMenu();
    }
  };

  return (
    <div>
      <GridColumnMenuItemGroup>
        <div className="py-3 px-2 fs-14 fw-medium text-black noselect">
          Colunas
        </div>
        <GridColumnMenuItemContent show={true}>
          <div className={"k-column-list-wrapper"}>
            <form onSubmit={onSubmit} onReset={onReset}>
              <div className={"k-column-list"}>
                {columnsState.map((column, idx) => {
                  if (
                    column.title &&
                    column.title !== "" &&
                    column.title !== "Fake"
                  )
                    return (
                      <div key={idx} className={"k-column-list-item px-2"}>
                        <span>
                          <input
                            id={`column-visiblity-show-${idx}`}
                            className="k-checkbox"
                            type="checkbox"
                            readOnly={true}
                            disabled={column.show && oneVisibleColumn}
                            checked={column.show || false}
                            onClick={() => {
                              handleToggle(idx);
                            }}
                          />
                          <label
                            htmlFor={`column-visiblity-show-${idx}`}
                            className="k-checkbox-label"
                            style={{ userSelect: "none" }}
                          >
                            {column.title}
                          </label>
                        </span>
                      </div>
                    );
                  else return null;
                })}
              </div>
              <div className={"k-columnmenu-actions"}>
                <button type={"reset"} className={"k-button"}>
                  Restaurar
                </button>
                <button className={"k-button k-primary"}>Guardar</button>
              </div>
            </form>
          </div>
        </GridColumnMenuItemContent>
      </GridColumnMenuItemGroup>
    </div>
  );
};

const removeFromLs = (BaseUrl) => {
  const LSData = localStorage.getItem("ColumnsState");
  const parsedLS = JSON.parse(LSData) || {};
  if (!parsedLS[BaseUrl]) {
    return;
  } else {
    let newLSData = { ...JSON.parse(LSData) };
    newLSData[BaseUrl] = null;
    localStorage.setItem("ColumnsState", JSON.stringify(newLSData));
  }
};

const UpdateOrderLocalStorage = (NewColumns, BaseUrl) => {
  const LSData = localStorage.getItem("ColumnsState");
  const parsedLS = JSON.parse(LSData) || {};
  if (!parsedLS[BaseUrl]) {
    const ShowObj = {};
    const OrderArr = [];
    const WidthsObj = {};
    for (const c of NewColumns) {
      if (c.field) {
        ShowObj[c.field] = c.show !== undefined ? c.show : true;
        WidthsObj[c.field] = c.width !== undefined ? c.width : 250;
        OrderArr.push(c.field);
      }
    }

    const State = {
      [BaseUrl]: { Shows: ShowObj, Widths: WidthsObj, Order: OrderArr }
    };
    localStorage.setItem("ColumnsState", JSON.stringify(State));
  } else {
    const FullState = parsedLS[BaseUrl] || {};
    const OrderState = [];

    const sortedNewColumnsByOrderIndex = NewColumns.sort((a, b) => {
      if (a.orderIndex > b.orderIndex) {
        return 1;
      } else if (a.orderIndex < b.orderIndex) {
        return -1;
      } else return 0;
    });

    for (const c of sortedNewColumnsByOrderIndex) {
      if (c.field) {
        OrderState.push(c.field);
      }
    }
    const AllGrids = { ...parsedLS };
    AllGrids[BaseUrl] = { ...FullState, Order: OrderState };
    localStorage.setItem("ColumnsState", JSON.stringify(AllGrids));
  }
};

const UpdateWidthLocalStorage = (NewColumns, BaseUrl, forceUpdate) => {
  const LSData = localStorage.getItem("ColumnsState");
  const parsedLS = JSON.parse(LSData) || {};
  if (!parsedLS[BaseUrl]) {
    const ShowObj = {};
    const OrderArr = [];
    const WidthsObj = {};
    for (const c of NewColumns) {
      if (c.field) {
        ShowObj[c.field] = c.show !== undefined ? c.show : true;
        WidthsObj[c.field] = c.width !== undefined ? c.width : 250;
        OrderArr.push(c.field);
      }
    }

    const State = {
      [BaseUrl]: { Shows: ShowObj, Widths: WidthsObj, Order: OrderArr }
    };
    localStorage.setItem("ColumnsState", JSON.stringify(State));
    if (forceUpdate) forceUpdate();
  } else {
    const FullState = parsedLS[BaseUrl] || {};
    const WidthState = FullState.Widths;

    for (const c of NewColumns) {
      if (c.field) {
        WidthState[c.field] = c.width !== undefined ? c.width : 250;
      }
    }
    const AllGrids = { ...parsedLS };
    AllGrids[BaseUrl] = { ...FullState, Widths: WidthState };
    localStorage.setItem("ColumnsState", JSON.stringify(AllGrids));
    if (forceUpdate) forceUpdate();
  }
};

const UpdateShowLocalStorage = (NewColumns, BaseUrl) => {
  const LSData = localStorage.getItem("ColumnsState");
  const parsedLS = JSON.parse(LSData) || {};
  if (!parsedLS[BaseUrl]) {
    const ShowObj = {};
    const OrderArr = [];
    const WidthsObj = {};
    for (const c of NewColumns) {
      if (c.field) {
        ShowObj[c.field] = c.show !== undefined ? c.show : true;
        WidthsObj[c.field] = c.width !== undefined ? c.width : 250;
        OrderArr.push(c.field);
      }
    }

    const State = {
      [BaseUrl]: { Shows: ShowObj, Widths: WidthsObj, Order: OrderArr }
    };
    localStorage.setItem("ColumnsState", JSON.stringify(State));
  } else {
    const FullState = parsedLS[BaseUrl] || {};
    const ShowState = FullState.Shows;

    for (const c of NewColumns) {
      if (c.field) {
        ShowState[c.field] = c.show !== undefined ? c.show : true;
      }
    }
    const AllGrids = { ...parsedLS };
    AllGrids[BaseUrl] = { ...FullState, Shows: ShowState };
    localStorage.setItem("ColumnsState", JSON.stringify(AllGrids));
  }
};

// const ColumnOrderSort = (target, ref) => {
//   const result = [];
//   const tempTarget = target.map((e) => e);
//   for (const field of ref) {
//     const index = tempTarget.findIndex((e) => e.field === field);
//     if (index >= 0) {
//       result.push(tempTarget[index]);
//       tempTarget.splice(index, 1);
//     }
//   }

//   if (tempTarget.length > 0) {
//     result.push(...tempTarget);
//   }
//   return result;
// };

export const HandleColumnInitialization = (Columns, BaseUrl) => {
  const cols = Columns.reduce((allCols, currCol) => {
    if (!currCol.disableOnGrid) allCols.push(currCol);
    return allCols;
  }, []);

  const LSData = localStorage.getItem("ColumnsState");
  const parsedLS = JSON.parse(LSData) || {};
  if (!parsedLS[BaseUrl]) {
    const ShowObj = {};
    const OrderArr = [];
    const WidthsObj = {};
    for (const c of cols) {
      if (c.field) {
        ShowObj[c.field] = c.show !== undefined ? c.show : true;
        WidthsObj[c.field] = c.width !== undefined ? c.width : 250;
        OrderArr.push(c.field);
      }
    }
    const State = {
      [BaseUrl]: { Shows: ShowObj, Widths: WidthsObj, Order: OrderArr }
    };
    localStorage.setItem("ColumnsState", JSON.stringify(State));
    return cols;
  } else {
    const FullState = parsedLS[BaseUrl] || {};
    const ShowState = FullState.Shows;
    const WidthsState = FullState.Widths;
    // const OrderState = FullState.Order;

    let ResolvedColumns = cols.map((e) => e);
    for (const t of ResolvedColumns) {
      t.show = ShowState[t.field] !== undefined ? ShowState[t.field] : true;
      t.width = WidthsState[t.field] !== undefined ? WidthsState[t.field] : 250;
    }
    return ResolvedColumns;
    // let OrderedResolvedColumns = ColumnOrderSort(ResolvedColumns, OrderState);
    // return OrderedResolvedColumns;
  }
};

// export const VirtualGrid = ({ Columns, BaseUrl }) => {
//   const value = useMemo(() => {
//     const newcolumns = [...Columns];
//     const test = HandleColumnInitialization(newcolumns, BaseUrl);
//     return [...test];
//   }, [BaseUrl, Columns]);
//   const [ActiveColumns, setActiveColumns] = useState(value);

//   const GridRefetchRef = useContext(GridRefechContext);
//   const [queryLoading, setLoading] = useState(false);
//   const [gridState, setGridState] = useState({
//     products: { data: [], total: 0 },
//     dataState: { take: 10, skip: 0, count: true }
//   });

//   const dataStateChange = (e) => {
//     setGridState((f) => {
//       return {
//         ...f,
//         dataState: { ...f.dataState, ...e.data }
//       };
//     });
//   };

//   const dataRecieved = (products) => {
//     setGridState((f) => {
//       return {
//         ...f,
//         products: products
//       };
//     });
//   };

//   const lastSuccessRef = useRef();

//   const { loading, error, refetch } = useQuery(
//     BaseUrl + buildODataQuery(gridState.dataState, true),
//     null,
//     {
//       cache: false,
//       onSuccess: ({ data }) => {
//         lastSuccessRef.current = buildODataQuery(gridState.dataState, true);
//         if (data && data.d && data.d.__count > 0) {
//           dataRecieved({
//             data: data.d.results,
//             total: data.d.__count
//           });
//         } else {
//           dataRecieved({
//             data: [],
//             total: 0
//           });
//         }
//       },
//       onError: (error) => {
//         console.log(error);
//       }
//     }
//   );

//   const [virtualGridSettings, setVirtualGridSettings] = useState({});

//   useEffect(() => {
//     if (!virtualGridSettings.style && gridState.products.data.length > 0) {
//       const height = containerRef.current.offsetHeight;

//       const rows = document.getElementsByClassName("k-master-row");
//       const rowHeight = rows[0].offsetHeight;
//       setVirtualGridSettings({ style: { height }, rowHeight });
//     }
//   }, [gridState.products.data, virtualGridSettings.style]);

//   useEffect(() => {
//     GridRefetchRef.current = refetch;

//     return () => {
//       GridRefetchRef.current = undefined;
//     };
//   }, [GridRefetchRef, queryLoading, refetch]);

//   const onColumnsSubmit = (newColumns) => {
//     UpdateShowLocalStorage(newColumns, BaseUrl);
//     setActiveColumns(newColumns);
//   };

//   const onColumnsReset = (newColumns) => {
//     setActiveColumns(newColumns);
//   };

//   const gridRef = useRef();
//   const containerRef = useRef();
//   return (
//     <div ref={containerRef} className="leafleft-grid flex-1 overflow-auto">
//       <LocalizationProvider language="pt-PT">
//         <IntlProvider locale="pt-PT">
//           <Grid
//             ref={gridRef}
//             className="overflow-auto position-relative h-100"
//             filterable={true}
//             sortable={true}
//             resizable
//             reorderable
//             refresh={true}
//             scrollable={"virtual"}
//             {...virtualGridSettings}
//             {...gridState.dataState}
//             {...gridState.products}
//             onDataStateChange={dataStateChange}
//           >
//             <GridNoRecords>
//               {!loading ? (
//                 "Não existe dados."
//               ) : (
//                 <LoaderSpinner className="color-primary" size="xs" center />
//               )}
//             </GridNoRecords>
//             {ActiveColumns.map((e, i) => {
//               const options = {
//                 columnMenu: (props) => (
//                   <CustomColumnMenu
//                     {...props}
//                     OriginalColumns={Columns}
//                     BaseUrl={BaseUrl}
//                     onColumnsReset={onColumnsReset}
//                     columns={ActiveColumns}
//                     onColumnsSubmit={onColumnsSubmit}
//                   />
//                 ),
//                 ...e,
//                 width: e.field ? 250 : 150,
//                 minResizableWidth: e.field ? 200 : 120
//               };
//               if (e.show !== undefined && !e.show) return null;

//               return <Column key={i} {...options} />;
//             })}
//           </Grid>
//           {loading && gridState?.products?.data?.length > 0 && <LoadingPanel />}
//         </IntlProvider>
//       </LocalizationProvider>
//     </div>
//   );
// };

// const DateFilterCell = ({ onChange, ...rest }) => {
//   const [Operator, setOperator] = useState("eqDate");
//   const Operators = [
//     "eqDate",
//     "neqDate",
//     "ltDate",
//     "gtDate",
//     "lteDate",
//     "gteDate"
//   ];

//   const handleChange = (event) => {
//     onChange({
//       value: event.target.value,
//       operator: Operator,
//       syntheticEvent: event.syntheticEvent
//     });
//   };

//   const HandleOperatorChange = (event) => {
//     setOperator(event.value);
//   };

//   const onClearButtonClick = (event) => {
//     event.preventDefault();
//     onChange({
//       value: null,
//       operator: "",
//       syntheticEvent: event
//     });
//   };

//   return (
//     <div className="k-filtercell">
//       <div className="k-filtercell-wrapper">
//         <DatePicker {...rest} onChange={handleChange} />
//         <DropDownList
//           data={Operators}
//           onChange={HandleOperatorChange}
//           value={Operator}
//         />
//         <button
//           className="k-button k-button-icon k-clear-button-visible"
//           title="Clear"
//           disabled={Boolean(rest.value)}
//           onClick={onClearButtonClick}
//         >
//           <span className="k-icon k-i-filter-clear" />
//         </button>
//       </div>
//     </div>
//   );
// };

const CustomFilterGrid = ({
  settings,
  updateSettings,
  filterStateSetterRef,
  filterCache,
  Component,
  ...rest
}) => {
  const { customFilterId, filterUrl, filterType } = settings;
  const [filter, setFilter] = useState(() => {
    if (customFilterId) return undefined;

    return {
      Value: filterCache.filter || {}
    };
  });

  const url = useMemo(() => {
    if (!customFilterId) return null;

    return createFilterUrl(filterUrl, customFilterId, filterType);
  }, [customFilterId, filterType, filterUrl]);

  useQuery(url, null, {
    onSuccess: ({ data }) => {
      setFilter(data);
    },
    onError: ({ error }) => {
      if (error?.status === 404) {
        updateSettings({ customFilterId: undefined });
      }
      setFilter({ Value: {} });
    },
    cache: false
  });

  const handleFilterChange = useCallback(
    (update) => {
      setFilter((currentFilter) => {
        const rUpdate =
          typeof update === "function" ? update(currentFilter) : currentFilter;

        const newFilter = { ...currentFilter, ...rUpdate };

        if (filterStateSetterRef.current)
          filterStateSetterRef.current(newFilter.Value);

        if (customFilterId)
          client.post(
            createFilterUrl(filterUrl, customFilterId, filterType),
            newFilter
          );

        return newFilter;
      });
    },
    [customFilterId, filterStateSetterRef, filterType, filterUrl]
  );

  const resolvedFilterCache = useMemo(() => {
    if (!filter) return null;

    return {
      ...filterCache,
      filter: filter.Value
    };
  }, [filter, filterCache]);

  const query = useQuery(createFilterUrl(filterUrl, null, filterType), null, {
    cache: false
  });

  if (!resolvedFilterCache)
    return (
      <div className="d-flex align-items-center justify-content-center text-primary">
        <LoaderSpinner size="sm" />
      </div>
    );

  return (
    <FiltersQueryContext.Provider value={query}>
      <AdvancedFilterProvider
        advancedFilter={filter}
        onFilterChange={handleFilterChange}
        setAdvancedFilter={setFilter}
      >
        <Component filterCache={resolvedFilterCache} {...rest} />
      </AdvancedFilterProvider>
    </FiltersQueryContext.Provider>
  );
};

const BaseGrid = (props) => {
  const gridCache = useContext(GridCacheContext);
  const { CustomComponent, Columns, ...rest } = props;
  const Roles = useContext(UserRoleContext);
  const resolvedColumns = useMemo(() => {
    const Permission = getHighestPermission(Roles);

    const isBackend = Permission > RolesEnum.Client;

    const cols = [];

    for (const col of Columns) {
      if (!isBackend && col.disableForClients) continue;
      cols.push(col);
    }
    return cols;
  }, [Columns, Roles]);

  const { settings, filterStateSetterRef, updateSettings } =
    useContext(GridContext) || {};

  const { id, hasCustomFilter, customFilterId, isCustomFilterEnabled } =
    settings || {};

  const cachedState = useMemo(() => {
    if (!id || !gridCache) return {};

    const state = gridCache.read(id);
    return state || {};
  }, [gridCache, id]);

  const ResolvedComponent = CustomComponent || InnerBaseGrid;

  const customFilter = useGridCustomFilter();

  if (hasCustomFilter && isCustomFilterEnabled && !customFilter)
    return (
      <CustomFilterGrid
        updateSettings={updateSettings}
        key={customFilterId}
        filterStateSetterRef={filterStateSetterRef}
        settings={settings}
        filterCache={cachedState}
        Component={ResolvedComponent}
        Columns={resolvedColumns}
        {...rest}
      />
    );

  return (
    <ResolvedComponent
      filterCache={cachedState}
      Columns={resolvedColumns}
      {...rest}
    />
  );
};

export default BaseGrid;
