
import { faBezierCurve, faSave } from "@fortawesome/pro-solid-svg-icons";
import LabeledInput from "../../../Common/Input/LabeledInput";
import {
  MainCategoryDropdown,
  MainSectorDropdown,
} from "../../../Common/Dropdown/DropdownHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useMemo } from "react";
import LoadableButton from "../../../Common/Button/LoadableButton";
import { createEntityModal } from "../../../Common/Modal/ModalHelper";
import { ErrorMessage } from "../../../Leaflets/LeafletForm";
import ErrorMessageHandler from "../../../Common/ErrorMessage/ErrorMessage";

const InnerAddLeafletMediaModal = ({
  form,
  post,
  loading,
  handleChanges,
  error,
  setForm,
}) => {
  const { Name, MainCategoryName, MainSectorName } = form;

  const [errorState, setErrorState] = useState({});

  const isFormValid = () => {
	let valid = true;
    let newErrorState = {};
    if (!/(.|\s)*\S(.|\s)*/.test(Name)) {
		valid = false;
		newErrorState.Name = true;
	  }
    if (!MainCategoryName) {
		valid = false;
		newErrorState.MainCategoryName = true;
	  }
	  setErrorState(newErrorState);
	  return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formValidation = isFormValid();
    if (formValidation) {
      const resolvedForm = {
        Name: form.Name,
        MainCategoryNameId: form.MainCategoryName.Id,
      };
      post(resolvedForm);
    }
  };

  const onRemoveMainSectorName = () => {
    handleChanges({ target: { name: "MainSectorName", value: {} } });
  };

  const MainSectorNameExtraProfile = useMemo(() => {
    if (MainSectorName && MainSectorName.Id) {
      return {
        field: "MainSectorName/Id",
        operator: "eq",
        value: MainSectorName.Id,
      };
    } else return null;
  }, [MainSectorName]);

  return (
    <div style={{ width: 576 }} className="mt-4">
      <form onSubmit={handleSubmit}>
        <LabeledInput
          className="mb-3"
          name="Name"
          value={Name}
          placeholder=" "
          error={errorState.Name && `Nome ${ErrorMessage}`}
          onChange={handleChanges}
          //   error={emailError}
          // hasErrors={!isEmailValid}
          text="Nome"
        />
        <LabeledInput
          className="mb-1 ar-dropdown-modal"
          name="MainSectorName"
          value={MainSectorName}
          onChange={handleChanges}
          InputComponent={MainSectorDropdown}
          //   error={emailError}
          // hasErrors={!isEmailValid}
          text="Sector"
        />
        {MainSectorName && MainSectorName.Id ? (
          <div
            onClick={onRemoveMainSectorName}
            className="text-danger cursor-pointer mb-3"
          >
            Remover
          </div>
        ) : (
          <div className="mb-3"></div>
        )}

        <LabeledInput
          className="mb-4 ar-dropdown-modal"
          name="MainCategoryName"
          value={MainCategoryName}
          onChange={handleChanges}
		  InputComponent={MainCategoryDropdown}
		  optionalFilters={MainSectorNameExtraProfile}
		  error={errorState.MainCategoryName && `Categoria Principal ${ErrorMessage}`}
          //   error={emailError}
          // hasErrors={!isEmailValid}
          text="Categoria Principal"
        />
		{error && (
          <div className="text-danger mb-1 text-center">
            <ErrorMessageHandler error={error} />
          </div>
        )}
        <LoadableButton loading={loading} className="w-100" type="submit">
          <span>
            <FontAwesomeIcon className="mr-2" icon={faSave} />
            Guardar
          </span>
        </LoadableButton>
      </form>
    </div>
  );
};

const AddBrandsMainModal = createEntityModal({
  getEndpoint: (data) => (data ? `categories/${data.Id}` : `categories`),
  Component: InnerAddLeafletMediaModal,
  headerTitle: "Adicionar Categoria",
  headerIcon: faBezierCurve,
});

export default AddBrandsMainModal;

// const AddBrandsMainModalT = ({ data }) => {
//   const closeModal = useContext(ModalCloseContext);
//   const refetchRef = useContext(GridRefechContext);
//   const [form, setForm] = useState(() => {
//     return {
//       Name: "",
//       MainCategoryName: {},
//       ...data,
//     };
//   });

//   const handleChanges = (e) => {
//     const { name, value } = e.target;

//     setForm((f) => {
//       return {
//         ...f,
//         [name]: value,
//       };
//     });
//   };

//   const [post, { loading }] = usePost(
//     data ? `categories/${data.Id}` : `categories`,
//     null,
//     {
//       onSuccess: ({ data }) => {
//         if (refetchRef.current) refetchRef.current();
//         closeModal();
//       },
//       onError: ({ error }) => {
//         console.log(error);
//       },
//     }
//   );

//   const { Name, MainCategoryName } = form;

//   const isFormValid = () => {
//     if (!/(.|\s)*\S(.|\s)*/.test(Name)) return false;
//     if (!MainCategoryName.Id) return false;
//     else return true;
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     const formValidation = isFormValid();
//     if (formValidation) {
//       const resolvedForm = {
//         Name: form.Name,
//         MainCategoryNameId: form.MainCategoryName.Id,
//       };
//       post(resolvedForm);
//     }
//   };

//   return (
//     <div className="p-4">
//       <ModalHeader icon={faBezierCurve}>Adicionar Categoria</ModalHeader>

//       <div style={{ width: 576 }} className="mt-4">
//         <form onSubmit={handleSubmit}>
//           <LabeledInput
//             className="mb-3"
//             name="Name"
//             value={Name}
//             placeholder=" "
//             required="required"
//             pattern="(.|\s)*\S(.|\s)*"
//             onChange={handleChanges}
//             //   error={emailError}
//             // hasErrors={!isEmailValid}
//             text="Nome"
//           />
//           <LabeledInput
//             className="mb-4 ar-dropdown-modal"
//             name="MainCategoryName"
//             value={MainCategoryName}
//             onChange={handleChanges}
//             InputComponent={MainCategoryDropdown}
//             //   error={emailError}
//             // hasErrors={!isEmailValid}
//             text="Categoria Principal"
//           />
//           <LoadableButton loading={loading} className="w-100" type="submit">
//             <span>
//               <FontAwesomeIcon className="mr-2" icon={faSave} />
//               Guardar
//             </span>
//           </LoadableButton>
//         </form>
//       </div>
//     </div>
//   );
// };
