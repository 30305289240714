import React, { useState, useRef, useContext, useMemo } from "react";
import BaseGrid from "../Common/Grid/Grid";
import DatePicker from "../Common/DatePicker/DatePicker";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import moment from "moment";
import classnames from "classnames";
import { getDate } from "../Helpers/DateHelper";
import {
  faPencil,
  faTrashAlt,
  faPercent
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DeleteModal from "../Common/DeleteModalContent/DeleteModalContent";
import { UserRoleContext } from "../App";
import { getHighestPermission, RolesEnum } from "../AppHelper";
import {
  FilterOperatorEnum,
  FilterTypeEnum
} from "../Common/FilterList/FilterListTypings";
import { getGridItemValue } from "../Helpers/MiscHelper";
import Cards from "../Common/Cards/Cards";
import { ViewTogglerContext } from "../Common/ViewToggler/ViewToggler";

const dateCell = ({ dataItem, field }) => {
  const value = getGridItemValue(dataItem, field);
  return (
    <td>
      {value === null ? "" : new moment(getDate(value)).format("DD/MM/YYYY")}
    </td>
  );
};

const normalDateCell = ({ dataItem, field }) => {
  const value = dataItem[field];
  return (
    <td>{value === null ? "" : new moment(value).format("DD/MM/YYYY")}</td>
  );
};

export const DateFilterCell = ({ onChange, ...rest }) => {
  const DatePickerRef = useRef();
  const [Operator, setOperator] = useState({
    value: "eqDate",
    Text: "É igual a"
  });
  const Operators = [
    { value: "eqDate", Text: "É igual a" },
    { value: "neqDate", Text: "Não é igual a" },
    { value: "ltDate", Text: "É menor que" },
    { value: "gtDate", Text: "É maior que" },
    { value: "lteDate", Text: "É menor ou igual que" },
    { value: "gteDate", Text: "É maior ou igual que" }
  ];

  const focusedItemIndex = (data, inputText, textField) => {
    return Operators.findIndex((e) => {
      return e.value === Operator.value;
    });
  };

  const handleChange = (event) => {
    onChange({
      value: event.target.value,
      operator: Operator.value,
      syntheticEvent: event.syntheticEvent
    });
  };

  const HandleOperatorChange = (event) => {
    if (DatePickerRef.current.value) {
      onChange({
        value: DatePickerRef.current.value,
        operator: event.value.value,
        syntheticEvent: event.syntheticEvent
      });
    }
    setOperator(event.value);
  };

  const onClearButtonClick = (event) => {
    event.preventDefault();
    onChange({
      value: null,
      operator: "",
      syntheticEvent: event
    });
  };

  return (
    <div className="k-filtercell">
      <div className="k-filtercell-wrapper">
        <DatePicker
          format="dd-MM-yyyy"
          ref={DatePickerRef}
          className="flex-1"
          {...rest}
          onChange={handleChange}
        />
        <DropDownList
          focusedItemIndex={focusedItemIndex}
          style={{ width: 32 }}
          popupSettings={{ width: "" }}
          iconClassName="k-i-filter k-icon"
          className="k-dropdown-operator ml-2"
          data={Operators}
          textField={"Text"}
          onChange={HandleOperatorChange}
          value={Operator}
        />
        <button
          className={classnames("k-button k-button-icon", {
            "k-clear-button-visible": Boolean(rest.value),
            "k-clear-button-hidden": !Boolean(rest.value)
          })}
          title="Clear"
          disabled={!Boolean(rest.value)}
          onClick={onClearButtonClick}
        >
          <span className="k-icon k-i-filter-clear" />
        </button>
      </div>
    </div>
  );
};

export const InsertDateFilterCell = ({ onChange, ...rest }) => {
  const DatePickerRef = useRef();
  const [Operator, setOperator] = useState({
    value: "eqContains",
    Text: "É igual a"
  });
  const Operators = [
    {
      value: "eqContains",
      Text: "É igual a"
    },
    { value: "lt", Text: "É menor que" },
    { value: "gt", Text: "É maior que" },
    { value: "ne", Text: "Não é igual a" }
  ];

  const focusedItemIndex = (data, inputText, textField) => {
    return Operators.findIndex((e) => {
      return e.value === Operator.value;
    });
  };

  const handleChange = (event) => {
    onChange({
      value: event.target.value,
      operator: Operator.value,
      syntheticEvent: event.syntheticEvent
    });
  };

  const HandleOperatorChange = (event) => {
    if (DatePickerRef.current.value) {
      onChange({
        value: DatePickerRef.current.value,
        operator: event.value.value,
        syntheticEvent: event.syntheticEvent
      });
    }
    setOperator(event.value);
  };

  const onClearButtonClick = (event) => {
    event.preventDefault();
    onChange({
      value: null,
      operator: "",
      syntheticEvent: event
    });
  };

  return (
    <div className="k-filtercell">
      <div className="k-filtercell-wrapper">
        <DatePicker
          format="dd-MM-yyyy"
          ref={DatePickerRef}
          className="flex-1"
          {...rest}
          onChange={handleChange}
        />
        <DropDownList
          focusedItemIndex={focusedItemIndex}
          style={{ width: 32 }}
          popupSettings={{ width: "" }}
          iconClassName="k-i-filter k-icon"
          className="k-dropdown-operator ml-2"
          data={Operators}
          textField={"Text"}
          onChange={HandleOperatorChange}
          value={Operator}
        />
        <button
          className={classnames("k-button k-button-icon", {
            "k-clear-button-visible": Boolean(rest.value),
            "k-clear-button-hidden": !Boolean(rest.value)
          })}
          title="Clear"
          disabled={!Boolean(rest.value)}
          onClick={onClearButtonClick}
        >
          <span className="k-icon k-i-filter-clear" />
        </button>
      </div>
    </div>
  );
};

const ButtonCell = ({ dataItem }) => {
  const [toggleRemove] = useContext(ToggleEditionModalsContext);
  const Roles = useContext(UserRoleContext);
  const Permission = getHighestPermission(Roles);
  const history = useHistory();
  const value = dataItem["id"];

  const openForm = () => {
    history.push(`/leaflets/${value}`);
  };

  const openPromotionForm = () => {
    history.push(`/leaflets/${value}/promotions/list/view`);
  };

  const handleRemove = () => {
    toggleRemove(dataItem);
  };

  return (
    <td>
      <div className="d-flex align-items-center justify-content-end">
        <div onClick={openPromotionForm} className="p-2 mr-2 grid-button">
          <FontAwesomeIcon
            size="sm"
            icon={faPercent}
            title="Promoções Associadas"
          />
        </div>
        {Permission >= RolesEnum.BackTwo && (
          <>
            <div onClick={openForm} className="p-2 mr-2 grid-button">
              <FontAwesomeIcon size="sm" icon={faPencil} title="Editar" />
            </div>
            <div onClick={handleRemove} className="p-2 grid-button">
              <FontAwesomeIcon size="sm" icon={faTrashAlt} title="Eliminar" />
            </div>
          </>
        )}
      </div>
    </td>
  );
};

const ToggleEditionModalsContext = React.createContext();

const DaysTotalCell = ({ dataItem, field, ...rest }) => {
  const { DateBegin, DateEnd } = dataItem;

  const diff = useMemo(() => {
    const db = getDate(DateBegin.Name);
    const de = getDate(DateEnd.Name);

    return moment(de).diff(db, "days") + 1;
  }, [DateBegin.Name, DateEnd.Name]);

  return <td>{diff}</td>;
};

const PagesCell = ({ dataItem, field, ...rest }) => {
  const pages = dataItem.TotalPage.Name;

  return <td>{pages}</td>;
};

const WeeksCell = ({ dataItem, field, ...rest }) => {
  const { DateBegin, DateEnd } = dataItem;
  const db = getDate(DateBegin.Name);
  const de = getDate(DateEnd.Name);

  const startWeek = moment(db).week();
  const endWeek = moment(de).week();

  if (startWeek === endWeek) return <td>{startWeek}</td>;

  let weekString = `${startWeek}`;

  for (let i = startWeek + 1; i <= endWeek; i++) {
    weekString += `, ${i}`;
  }

  return <td>{weekString}</td>;
};

const columns = [
  {
    title: "",
    minResizableWidth: 140,
    field: "Button",
    headerClassName: "hide-grid-title",
    filterable: false,
    sortable: false,
    width: 140,
    cell: ButtonCell,
    show: true,
    disableCustomFilter: true /*custom filter */
  },
  {
    field: "ShopName.ChainName.Name",
    title: "Cadeia",
    filterOrder: 1,
    queryUrl: "chains/query" /*custom filter */,
    disableCustomFilter: true,
    dependents: ["ShopName.Name"]
  },
  {
    field: "ShopName.Name",
    title: "Insígnia",
    filterOrder: 1,
    queryUrl: "shops/query" /*custom filter */,
    filterDependencies: [
      {
        field: "ShopName.ChainName.Name",
        queryName: "chainNames"
      }
    ]
  },
  {
    field: "LeafleatName.Name",
    title: "Nome",
    filterOrder: 4,
    queryUrl: "leaflets/names/query" //custom filter
  },
  {
    field: "DateBegin.Name",
    title: "Data de Inicio",
    filter: "date",
    width: 200,
    filterCell: DateFilterCell,
    cell: dateCell,
    filterOrder: 2,
    filterType: FilterTypeEnum.date, //custom filter
    dateOperator: FilterOperatorEnum.GreaterOrEqual //custom filter
  },
  {
    field: "DateEnd.Name",
    title: "Data de Fim",
    filter: "date",
    width: 200,
    filterCell: DateFilterCell,
    cell: dateCell,
    filterOrder: 3,
    filterType: FilterTypeEnum.date, //custom filter
    dateOperator: FilterOperatorEnum.LesserOrEqual //custom filter
  },
  {
    field: "RegionName.Name",
    title: "Região",
    filterOrder: 5,
    disableForClients: true,
    queryUrl: "regions/query" /*custom filter */
  },
  {
    field: "InsertDate",
    title: "Data de Inserção",
    filter: "date",
    width: 200,
    filterCell: InsertDateFilterCell,
    cell: normalDateCell,
    disableForClients: true,
    disableCustomFilter: true /*custom filter */
  },
  {
    // field: "DateEnd.Name",
    title: "Nº dias",
    filterable: false,
    disableCustomFilter: true /*custom filter */,
    cell: DaysTotalCell
  },
  {
    title: "Nº páginas",
    filterable: false,
    disableCustomFilter: true /*custom filter */,
    cell: PagesCell
  },
  {
    field: "LeafleatType.Name",
    title: "Tipo de Campanha",
    filterable: false,
    // disableCustomFilter: true, //custom filter
    queryUrl: "leaflets/types/query" /*custom filter */
  },
  {
    field: "MediaName.Name",
    title: "Fonte",
    filterable: false,
    // disableCustomFilter: true, //custom filter
    queryUrl: "media/query" /*custom filter */
  },
  {
    field: "MainCategoryNamesCount",
    title: "Nº Categorias Principais",
    filterable: false,
    disableCustomFilter: true //custom filter
  },
  {
    field: "CategoryNamesCount",
    title: "Nº Categorias",
    filterable: false,
    disableCustomFilter: true //custom filter
  },
  {
    field: "SubCategoryNamesCount",
    title: "Nº Subcategorias",
    filterable: false,
    disableCustomFilter: true //custom filter
  },
  {
    field: "BrandsCount",
    title: "Nº Marcas",
    filterable: false,
    disableCustomFilter: true //custom filter
  },
  {
    field: "ArticlesCount",
    title: "Nº Artigos",
    filterable: false,
    disableCustomFilter: true //custom filter
  },
  {
    field: "PromotionsCount",
    title: "Nº Promoções",
    filterable: false,
    disableCustomFilter: true //custom filter
  },
  {
    title: "Semana(s) do ano",
    filterable: false,
    disableCustomFilter: true /*custom filter */,
    cell: WeeksCell
  }
];

const filterOperators = {
  date: [
    { text: "grid.filterEqOperator", operator: "eqDate" },
    { text: "grid.filterEqOperator", operator: "neqDate" },
    { text: "grid.filterEqOperator", operator: "ltDate" },
    { text: "grid.filterEqOperator", operator: "gtDate" },
    { text: "grid.filterEqOperator", operator: "lteDate" },
    { text: "grid.filterEqOperator", operator: "gteDate" }
  ]
};

const LeafletsGrid = () => {
  const SelectedCARef = useRef();

  const [deletingComercialArea, setDeletingComercialArea] = useState(false);

  const toggleDeleteModal = (data) => {
    if (!deletingComercialArea) {
      SelectedCARef.current = data;
    } else {
      SelectedCARef.current = undefined;
    }
    setDeletingComercialArea((open) => !open);
  };

  // const [leafletsCache] = useContext(LeafletsCacheContext);
  // const [LeafletsFilterCacheState, setLeafletsFilterCacheState] = useContext(
  //   LeafletsFilterCacheContext
  // );

  const [currentView] = useContext(ViewTogglerContext);

  return (
    <ToggleEditionModalsContext.Provider value={[toggleDeleteModal]}>
      <DeleteModal
        isOpen={deletingComercialArea}
        onClose={toggleDeleteModal}
        data={SelectedCARef.current}
        closeModal={toggleDeleteModal}
        url="leaflets"
      />

      <BaseGrid
        CustomComponent={currentView === "cards" ? Cards : undefined}
        customFilter
        defaultSorts={[{ dir: "desc", field: "DateBegin.Name" }]}
        // filterCache={LeafletsFilterCacheState}
        // onFilterChange={setLeafletsFilterCacheState}
        // customCache={leafletsCache}
        Columns={columns}
        filterOperators={filterOperators}
        BaseUrl={"leaflets"}
      />
    </ToggleEditionModalsContext.Provider>
  );
};

export default LeafletsGrid;
