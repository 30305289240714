import React from "react";
import PromotionsGrid from "./PromotionsGrid";
import {
  Route,
  Redirect,
  Switch,
} from "react-router-dom/cjs/react-router-dom.min";
import ExcelExportButton from "../Common/ExcelButton/ExcelExportButton";
import { FilterListToggler } from "../Common/FilterList/FilterListProvider";
import { GridCacheProvider, GridProvider } from "../Common/Grid/GridHelper";
import PromotionDetails from "./PromotionDetails/PromotionDetails";
import GridCustomFilterProvider from "../Common/Grid/GridCustomFilterProvider";

const Promotions = () => {
  return (
    <GridCacheProvider>
      <GridCustomFilterProvider>
        <Switch>
          <Route path="/promotions/:id/details">
            <PromotionDetails />
          </Route>
          <Route exact path="/promotions">
            <GridProvider
              filterUrl="promotions/filters"
              enableCustomFilters
              id="promotions"
            >
              <PromotionsComp />
            </GridProvider>
          </Route>
          <Redirect to="/promotions" />
        </Switch>
      </GridCustomFilterProvider>
    </GridCacheProvider>
  );
};

const PromotionsComp = () => {
  return (
    <div className="p-4 h-100 d-flex flex-column main-grid">
      <div className="d-flex align-items-center justify-content-between mb-2">
        <div className="d-flex align-items-center">
          <div className="fs-40 fw-medium mr-3">Promoções</div>
        </div>
        <div className="d-flex align-items-center">
          <ExcelExportButton removemargin BaseUrl={"promotions/data/export"} />
          <FilterListToggler className="ml-3" />
        </div>
      </div>
      <PromotionsGrid />
    </div>
  );
};

export default Promotions;
