import React, { useMemo } from "react";
import { FilterTypeEnum } from "../Common/FilterList/FilterListTypings";
import { InnerBaseGrid } from "../Common/Grid/Grid";
import { buildCustomODataFilter } from "../Helpers/CustomFilterBuilder";
import { getDays } from "../Helpers/DateHelper";
import { replaceSpecialCharacters } from "../Helpers/ODataHelper";

const indicatorBeginDateName = "DateBegin.Name";
const indicatorEndDateName = "DateEnd.Name";
const indicatorChainNames = "ShopName.ChainName.Name";
const indicatorShopNames = "ShopName.Name";

export const buildCategoryNameFilterQuery = (filters, isUrl, isApiRequest) => {
  const newFilters = { ...filters };

  let beginDateValue, endDateValue;

  const filterObj = {};
  let hasAddedFields = false;
  const addFilterValue = (field, value) => {
    hasAddedFields = true;

    filterObj[field] = value;
  };

  if (
    newFilters[indicatorBeginDateName] &&
    newFilters[indicatorBeginDateName].length > 0
  ) {
    beginDateValue = getDays(newFilters[indicatorBeginDateName][0].value);
    addFilterValue("BeginDate", beginDateValue);
    delete newFilters[indicatorBeginDateName];
  }

  if (
    newFilters[indicatorEndDateName] &&
    newFilters[indicatorEndDateName].length > 0
  ) {
    endDateValue = getDays(newFilters[indicatorEndDateName][0].value);
    addFilterValue("EndDate", endDateValue);
    delete newFilters[indicatorEndDateName];
  }

  if (
    newFilters[indicatorChainNames] &&
    newFilters[indicatorChainNames].length > 0
  ) {
    // endDateValue = getDays(newFilters[indicatorEndDateName][0].value);
    const values = newFilters[indicatorChainNames].map((ind) => ind.value);
    addFilterValue("ChainNames", values);
    delete newFilters[indicatorChainNames];
  }

  if (
    newFilters[indicatorShopNames] &&
    newFilters[indicatorShopNames].length > 0
  ) {
    // endDateValue = getDays(newFilters[indicatorEndDateName][0].value);
    const values = newFilters[indicatorShopNames].map((ind) => ind.value);
    addFilterValue("ShopNames", values);
    delete newFilters[indicatorShopNames];
  }

  let baseFilterQuery = buildCustomODataFilter(newFilters, isUrl, isApiRequest);

  if (hasAddedFields) {
    const serializedFilters = JSON.stringify(filterObj);
    if (baseFilterQuery) baseFilterQuery += "&";
    baseFilterQuery += `filters=${serializedFilters}`;
  }

  // if (beginDateValue) {
  //   if (baseFilterQuery) baseFilterQuery += "&";
  //   baseFilterQuery += `BeginDate=${beginDateValue}`;
  // }

  // if (endDateValue) {
  //   if (baseFilterQuery) baseFilterQuery += "&";
  //   baseFilterQuery += `EndDate=${endDateValue}`;
  // }

  return baseFilterQuery;
};

export const buildShopBrandsFilterQuery = (filters, isUrl, isApiRequest) => {
  const newFilters = { ...filters };

  let beginDateValue, endDateValue;

  const filterObj = {};
  let hasAddedFields = false;
  const addFilterValue = (field, value) => {
    hasAddedFields = true;

    filterObj[field] = value;
  };

  if (
    newFilters[indicatorBeginDateName] &&
    newFilters[indicatorBeginDateName].length > 0
  ) {
    beginDateValue = getDays(newFilters[indicatorBeginDateName][0].value);
    addFilterValue("BeginDate", beginDateValue);
    delete newFilters[indicatorBeginDateName];
  }

  if (
    newFilters[indicatorEndDateName] &&
    newFilters[indicatorEndDateName].length > 0
  ) {
    endDateValue = getDays(newFilters[indicatorEndDateName][0].value);
    addFilterValue("EndDate", endDateValue);
    delete newFilters[indicatorEndDateName];
  }

  if (
    newFilters[indicatorChainNames] &&
    newFilters[indicatorChainNames].length > 0
  ) {
    // endDateValue = getDays(newFilters[indicatorEndDateName][0].value);
    const values = newFilters[indicatorChainNames].map((ind) => ind.value);
    addFilterValue("ChainNames", values);
    delete newFilters[indicatorChainNames];
  }

  let baseFilterQuery = buildCustomODataFilter(newFilters, isUrl, isApiRequest);

  if (hasAddedFields) {
    const serializedFilters = JSON.stringify(filterObj);
    if (baseFilterQuery) baseFilterQuery += "&";
    baseFilterQuery += `filters=${serializedFilters}`;
  }

  // if (beginDateValue) {
  //   if (baseFilterQuery) baseFilterQuery += "&";
  //   baseFilterQuery += `BeginDate=${beginDateValue}`;
  // }

  // if (endDateValue) {
  //   if (baseFilterQuery) baseFilterQuery += "&";
  //   baseFilterQuery += `EndDate=${endDateValue}`;
  // }

  return baseFilterQuery;
};

export const createGraphFilterBuilder =
  (options) => (filters, isUrl, isApiRequest) => {
    const oDataFilterObj = {};

    const filterObj = {};
    let hasAddedFields = false;
    const addFilterValue = (field, value) => {
      hasAddedFields = true;

      filterObj[field] = value;
    };
    if (filters) {
      for (const option of options) {
        const { name, targetName, isCustomFilter } = option;

        if (!filters[name] || filters[name].length === 0) continue;

        const resolvedName = targetName || name;
        if (!isCustomFilter) {
          oDataFilterObj[resolvedName] = filters[name];
        } else {
          const filterValues = filters[name];
          let resolvedValue;

          if (
            filterValues.length === 1 &&
            filterValues[0].filterType === FilterTypeEnum.date
          ) {
            resolvedValue = getDays(filterValues[0].value);
          } else {
            resolvedValue = filterValues.map((ind) => {
              const v = replaceSpecialCharacters(ind.value);
              return v;
            });
          }

          addFilterValue(resolvedName, resolvedValue);
        }
      }
    }

    let baseFilterQuery = buildCustomODataFilter(
      oDataFilterObj,
      isUrl,
      isApiRequest
    );

    if (hasAddedFields) {
      let serializedFilters = JSON.stringify(filterObj);
      serializedFilters = serializedFilters.replaceAll("+", "%2B");
      if (baseFilterQuery) baseFilterQuery += "&";

      baseFilterQuery += `filters=${serializedFilters}`;
    }

    return baseFilterQuery;
  };

export const buildCategoriesOnlyFilterQuery = createGraphFilterBuilder([
  { name: "ShopName", targetName: "ShopNames", isCustomFilter: true },
  { name: "Categories", targetName: "CategoryNames", isCustomFilter: true },
  { name: "MainBrandName", targetName: "MainBrands", isCustomFilter: true },
  { name: "BeginDate", targetName: "BeginDate", isCustomFilter: true },
  { name: "EndDate", targetName: "EndDate", isCustomFilter: true }
]);

export const buildSubcategoriesFilterQuery = createGraphFilterBuilder([
  { name: "ShopName", targetName: "ShopNames", isCustomFilter: true },
  { name: "Categories", targetName: "CategoryNames", isCustomFilter: true },
  { name: "MainBrandName", targetName: "MainBrands", isCustomFilter: true },
  { name: "BeginDate", targetName: "BeginDate", isCustomFilter: true },
  { name: "EndDate", targetName: "EndDate", isCustomFilter: true }
]);

export const buildLeafleatFilterQuery = createGraphFilterBuilder([
  { name: "ShopName", targetName: "ShopName.Name" },
  { name: "Categories", targetName: "CategoryNames", isCustomFilter: true },
  { name: "MainBrandName", targetName: "MainBrands", isCustomFilter: true },
  { name: "BeginDate", targetName: "DateBegin.Name" },
  { name: "EndDate", targetName: "DateEnd.Name" }
]);

export const buildMainBrandsFilterQuery = createGraphFilterBuilder([
  { name: "ShopName", targetName: "ShopNames", isCustomFilter: true },
  { name: "Categories", targetName: "CategoryNames", isCustomFilter: true },
  { name: "MainBrandName", targetName: "MainBrands", isCustomFilter: true },
  { name: "BeginDate", targetName: "BeginDate", isCustomFilter: true },
  { name: "EndDate", targetName: "EndDate", isCustomFilter: true }
]);

export const buildArticleNamesFilterQuery = createGraphFilterBuilder([
  { name: "ShopName", targetName: "ShopNames", isCustomFilter: true },
  { name: "Categories", targetName: "CategoryNames", isCustomFilter: true },
  { name: "MainBrandName", targetName: "MainBrands", isCustomFilter: true },
  { name: "BeginDate", targetName: "BeginDate", isCustomFilter: true },
  { name: "EndDate", targetName: "EndDate", isCustomFilter: true }
]);

export const buildShopNamesFilterQuery = createGraphFilterBuilder([
  { name: "MainBrandName", targetName: "MainBrands", isCustomFilter: true },
  { name: "BeginDate", targetName: "BeginDate", isCustomFilter: true },
  { name: "EndDate", targetName: "EndDate", isCustomFilter: true },
  { name: "ShopName", targetName: "ShopNames", isCustomFilter: true },
  { name: "Categories", targetName: "CategoryNames", isCustomFilter: true }
]);

export const buildBrandsOnlyFilterQuery = createGraphFilterBuilder([
  { name: "MainBrandName", targetName: "MainBrands", isCustomFilter: true },
  { name: "BeginDate", targetName: "BeginDate", isCustomFilter: true },
  { name: "EndDate", targetName: "EndDate", isCustomFilter: true },
  { name: "ShopName", targetName: "ShopNames", isCustomFilter: true },
  { name: "Categories", targetName: "CategoryNames", isCustomFilter: true }
]);
