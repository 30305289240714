import React from "react";

const NoMatchsFound = () => {
  return (
    <div
      style={{
        pointerEvents: "none",
        paddingLeft: "1rem",
        paddingRight: "1rem"
      }}
      className="k-list-scroller"
    >
      <ul className="k-list k-reset">
        <li className="k-item">
          Não foram encontrados registos ou não tem permissões
        </li>
      </ul>
    </div>
  );
};

export default NoMatchsFound;
