import React, { useState, useEffect, useRef } from "react";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { useQuery } from "../../../Helpers/IOClient";
import "./AutoCompleteInput.css";
import NoMatchsFound from "../NoMatchsFound";

const createAutoCompleteInput = (endpoint) => {
  const AutoCompleteInput = ({ value, extraRef,...rest }) => {
    const [resolvedData, setData] = useState([]);

    const [resolvedVal, setResolvedVal] = useState();
    // eslint-disable-next-line no-unused-vars
    const AutoCompleteRef = useRef();
    const mountedRef = useRef();

    useEffect(() => {
      if (!mountedRef.current) {
        mountedRef.current = true;
        return;
      }
      // if (!value) {
      //   setResolvedVal(value);
      // }

      const timeout = setTimeout(() => {
        setResolvedVal(value);
      }, 200);

      return () => {
        clearTimeout(timeout);
      };
    }, [value]);

    const resolvedEndpoint = resolvedVal
      ? `${endpoint}?search=${resolvedVal}`
      : undefined;

    // const timeoutRef = useRef();

    const { loading, data } = useQuery(resolvedEndpoint, null, {
      onSuccess: ({ data }) => {
        
        // ;
        // timeoutRef.current = setTimeout(() => {
        //   setData(data);
        // });
      },
      // cache: false,
    });

    useEffect(() => {
      setData(data || []);
      // return () => {
      //   clearTimeout(timeoutRef.current);
      // };
    }, [data]);

    return (
      <AutoComplete
        popupSettings={{
          appendTo: extraRef ? extraRef : undefined
        }}
        data={resolvedData}
        //   suggest={false}
        listNoDataRender={() => <NoMatchsFound/>}
        loading={loading}
        value={value}
        //   onChange={onChange}
        {...rest}
        //   placeholder="e.g. Andorra"
      />
    );
  };

  return AutoCompleteInput;
};

export default createAutoCompleteInput;
