import React, { useContext } from "react";
import BaseGrid from "../../Common/Grid/Grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey, faPencil, faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import { UsersFunctionContext } from "./Users";
import { roleTypes } from "./UsersHelper";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const RoleDict = {
  [roleTypes.admin]: "Admin",
  [roleTypes.backofficeAll]: "Backoffice 1",
  [roleTypes.BackOfficePartial]: "Backoffice 2",
  [roleTypes.client]: "Cliente",
};

const RoleField = ({ roles }) => {
  const v = roles
    .reduce((rRoles, role) => {
      const text = RoleDict[role.Name];

      if (!text) return rRoles;

      rRoles.push(text);

      return rRoles;
    }, [])
    .join(", ");
  return v;
};

const RoleCell = ({ dataItem, field }) => {
  const value = dataItem[field];

  if (value) {
    return (
      <td>
        <RoleField roles={value} />
      </td>
    );
  } else return <td></td>;
};

const ButtonCell = ({ dataItem }) => {
  const Callbacks = useContext(UsersFunctionContext);
  const { ChangePassword, RemoveUser } = Callbacks;

  const togglePasswordModal = () => {
    ChangePassword(dataItem);
  };

  // const toggleUserModal = () => {
  //   EditUser(dataItem);
  // };

  const toggleRemovalModal = () => {
    RemoveUser(dataItem);
  };

  return (
    <td>
      <div className="d-flex align-items-center justify-content-end">
        <Link to={`/config/users/${dataItem.Id}`} className="p-2 grid-button">
          <FontAwesomeIcon size="sm" icon={faPencil} title="Editar" />
        </Link>
        <div onClick={togglePasswordModal} className="p-2 grid-button">
          <FontAwesomeIcon size="sm" icon={faKey} />
        </div>
        <div onClick={toggleRemovalModal} className="p-2 grid-button">
          <FontAwesomeIcon size="sm" icon={faTrashAlt} title="Eliminar" />
        </div>
      </div>
    </td>
  );
};

const UsersGrid = () => {
  const columns = [
    {
      title: "",
      cell: ButtonCell,
      filterable: false,
      sortable: false,
      width: 90,
    },
    { field: "Id", title: "Id", filterable: false },
    { field: "UserName", title: "Email" },
    {
      field: "Roles",
      filterable: false,
      sortable: false,
      title: "Funções",
      cell: RoleCell,
    },
  ];

  return <BaseGrid Columns={columns} BaseUrl={"admins/users"} />;
};

export default UsersGrid;
