import { useMemo } from "react";

export const usePageType = (TotalPage, PromotionPage) => {
  const pageType = useMemo(() => {
    if (!TotalPage || !PromotionPage) return null;

    const pageTotal = TotalPage.Name;

    const currentPage = Number(PromotionPage.Name);

    if (
      !pageTotal ||
      pageTotal <= 1 ||
      isNaN(currentPage) ||
      currentPage > pageTotal
    )
      return "-";

    if (currentPage === 1) return "Capa";
    else if (currentPage === pageTotal) return "Contracapa";
    //is odd | impar
    else if (currentPage % 2) return "Impar";
    else return "Par";
  }, [PromotionPage, TotalPage]);

  return pageType;
};
