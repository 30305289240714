import React from "react";
import "./Checkbox.css";
import classnames from "classnames";

const Checkbox = ({ className, children, ...rest }) => {
  return (
    <label className={classnames("app-checkbox", className)}>
      <div className="text-truncate">{children}</div>
      <input type="checkbox" {...rest} />
      <span className="checkmark"></span>
    </label>
  );
};

export default Checkbox;
