import React, { useState, useMemo } from "react";

import UsersGrid from "./UsersGrid";
import UsersPasswordModal from "./UsersPasswordModal/UsersPasswordModal";
import UsersUpdateModal from "./UsersUpdateModal/UsersUpdateModal";
import UsersCreationModal from "./UsersCreationModal/UsersCreationModal";
import DeleteModal from "../../Common/DeleteModalContent/DeleteModalContent";
import PlusButton from "../../Common/PlusButton/PlusButton";

export const UsersFunctionContext = React.createContext();

const Users = () => {
  const [user, setUser] = useState();

  const [passwordModal, setPasswordModal] = useState(false);
  const [userModal, setUserModal] = useState(false);
  const [removalModal, setRemovalModal] = useState(false);
  const [userCreationModal, setUserCreationModal] = useState(false);

  const togglePasswordModal = () => {
    setPasswordModal((open) => !open);
  };

  const toggleUserModal = () => {
    setUserModal((open) => !open);
  };

  const toggleRemovalModal = () => {
    setRemovalModal((open) => !open);
  };

  const toggleUserCreationModal = () => {
    setUserCreationModal((open) => !open);
  };

  const Callbacks = useMemo(() => {
    return {
      ChangePassword: (user) => {
        setUser(user);
        setPasswordModal((open) => !open);
      },
      EditUser: (user) => {
        setUser(user);
        setUserModal((open) => !open);
      },
      RemoveUser: (user) => {
        setUser(user);
        setRemovalModal((open) => !open);
      },
    };
  }, []);

  return (
    <UsersFunctionContext.Provider value={Callbacks}>
      <UsersPasswordModal
        key={user?.Id + "Password"}
        open={passwordModal}
        toggleOpen={togglePasswordModal}
        user={user}
      />
      <UsersUpdateModal
        key={user?.Id + "Update"}
        open={userModal}
        toggleOpen={toggleUserModal}
        user={user}
      />
      <UsersCreationModal
        open={userCreationModal}
        toggleOpen={toggleUserCreationModal}
      />
      <DeleteModal
        isOpen={removalModal}
        onClose={toggleRemovalModal}
        data={user}
        closeModal={toggleRemovalModal}
        url="admins/users"
      />
      <div className="h-100 d-flex flex-column main-grid">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div className="fs-24 fw-medium">Users</div>

          <PlusButton onClick={toggleUserCreationModal}>Adicionar</PlusButton>
        </div>
        <UsersGrid />
      </div>
    </UsersFunctionContext.Provider>
  );
};

export default Users;
