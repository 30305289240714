import React, { useContext, useCallback, useState, useMemo } from "react";
import Modal from "../Modal/Modal";
import { GridRefechContext } from "../../App";
import { useDelete } from "../../Helpers/IOClient";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import "./DeleteModalContent.css";
import { faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import Button from "../Button/Button";
import { useHistory } from "react-router-dom";
import LoadableButton from "../Button/LoadableButton";
import ErrorMessageHandler, {
  handleError,
  errorDict,
} from "../ErrorMessage/ErrorMessage";
import querystring from "query-string";

const DeleteModalContent = ({
  url,
  data,
  goToOnSuccess,
  closeModal,
  onClose,
  setLoading,
  isOpen,
  onSuccess,
  params,
}) => {
  const history = useHistory();

  const refetchRef = useContext(GridRefechContext);

  const rUrl = useMemo(() => {
    return querystring.stringifyUrl({
      url: `${url}/${data?.Id || data?.id}`,
      query: params,
    });
  }, [data, params, url]);

  const [post, { loading, error }] = useDelete(rUrl, null, {
    onSuccess: ({ data }) => {
      if (refetchRef.current) refetchRef.current();
      closeModal();
      if (goToOnSuccess) {
        history.push(goToOnSuccess);
      }
      onSuccess && onSuccess();
      setLoading(false);
    },
    onError: ({ error }) => {
      console.log(error);
      setLoading(false);
    },
  });

  const handleClosure = useCallback(() => {
    if (onClose && !loading && isOpen) onClose();
  }, [isOpen, loading, onClose]);

  const errorMsg = error ? handleError(error) : "";

  const handlePost = () => {
    setLoading(true);
    post();
  };

  return (
    <div className="p-4">
      <div className="d-flex align-items-center justify-content-between pb-4">
        <div></div>
        <div className="text-secondary cursor-pointer" onClick={handleClosure}>
          <FontAwesomeIcon className="d-block" icon={faTimes} size="lg" />
        </div>
      </div>

      <div
        style={{ width: 576 }}
        className="mt-3 d-flex justify-content-end flex-column align-items-center"
      >
        <div className="DeleteIconContainer d-flex align-items-center justify-content-center mb-4">
          <FontAwesomeIcon size="2x" icon={faTrashAlt} title="Eliminar" />
        </div>
        <div className="text-black fs-24 fw-medium mb-2">Eliminar item</div>
        <div style={{ opacity: 0.5 }} className="text-black mb-4">
          Tem a certeza que pretende eliminar este item?
        </div>
        {error && (
          <div className="text-danger mb-1 text-center">
            <ErrorMessageHandler error={error} />
          </div>
        )}
        <div className="d-flex align-items-center justify-content-between w-100">
          <Button
            onClick={handleClosure}
            vType="informative"
            className="w-100 mr-3 informative"
          >
            Cancelar
          </Button>
          {errorMsg === errorDict.RelationshipExists ? (
            <div></div>
          ) : (
            <LoadableButton
              loading={loading}
              onClick={handlePost}
              className="w-100 ml-3 warning"
              vType="delete"
            >
              Eliminar
            </LoadableButton>
          )}
        </div>
      </div>
    </div>
  );
};

const DeleteModal = ({ ...rest }) => {
  const { isOpen, onClose } = rest;
  const [loading, setLoading] = useState(false);

  return (
    <Modal disableClosure={loading} isOpen={isOpen} onClose={onClose}>
      <DeleteModalContent setLoading={setLoading} {...rest} />
    </Modal>
  );
};

export default DeleteModal;
