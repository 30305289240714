import { getDays } from "./DateHelper";

export const convertObjToFormData = (obj) => {
  if (obj instanceof Date) {
    return obj.toISOString();
  } else return obj;
};

export const appendFormDataFiles = (formData, files) => {
  for (let i = 0; i < files.length; i++) {
    const obj = files[i];
    if (obj instanceof File || obj instanceof Blob) {
      formData.append("File", obj, obj.name);
    } else if (files[i].File) {
      formData.append(
        "File",
        files[i].File,
        files[i].Name + files[i].Extension
      );
    } else {
      formData.append("File", files[i].Id);
    }
  }
};

export const createFormData = (object, files) => {
  const formData = new FormData();
  for (const objKey in object) {
    if (object.hasOwnProperty(objKey)) {
      const objProp = convertObjToFormData(object[objKey]);

      if (objProp !== undefined) formData.append(objKey, objProp);
    }
  }

  if (Array.isArray(files) && files.length > 0)
    appendFormDataFiles(formData, files);
  return formData;
};

export function DoubleDigitFormat(num) {
  if (num > 9) return num;
  else return "0" + num;
}

export function dateFormat(date, format) {
  if (!format) return date.toUTCString();

  return format
    .replace("MM", DoubleDigitFormat(date.getMonth() + 1))
    .replace("dd", DoubleDigitFormat(date.getDate()))
    .replace("yyyy", date.getFullYear())
    .replace("yy", date.getFullYear().toString().slice(2))
    .replace("hh", DoubleDigitFormat(date.getHours()))
    .replace("mm", DoubleDigitFormat(date.getMinutes()))
    .replace("ss", DoubleDigitFormat(date.getSeconds()))
    .replace("a", DoubleDigitFormat(date.getHours()) > 12 ? "PM" : "AM");
}

const buildODataOrderBy = (orderBy) => {
  // if (Array.isArray(orderBy)) orderBy = orderBy[0]
  let orderByString = "";

  if (orderBy.length === 0) return orderByString;
  orderByString = "$orderby=";
  let hasSetFistOrderBy = false;

  for (const ob of orderBy) {
    const { field, dir } = ob;
    if (hasSetFistOrderBy) orderByString += ",";
    orderByString += `${field.split(".").join("/")} ${dir}`;
    hasSetFistOrderBy = true;
  }

  return orderByString;
};

const getResolvedValue = (value, type) => {
  switch (type) {
    case "insertDate":
      const d = new Date(value);

      return `datetimeoffset'${d.toISOString()}'`;
    case "date":
    case "dateTimeOffset":
      return `datetimeoffset'${dateFormat(value, "yyyy-MM-dd")}'`;

    case "dateTime":
      return `datetime'${dateFormat(value, "yyyy-MM-dd")}'`;
    case "number":
      return value;

    default:
      return `'${replaceSpecialCharacters(value)}'`;
  }
};

const getResolvedFieldName = (originalFilterName, isUrl) =>
  typeof originalFilterName === "object"
    ? isUrl
      ? originalFilterName.field
      : originalFilterName.resolvedField
    : originalFilterName;

function isBlank(str) {
  return !str || /^\s*$/.test(str);
}

export const buildODataFilter = (filters, logic, isUrl, isApiRequest) => {
  let query = isUrl ? "" : "$filter=";
  // if (isApiRequest) query += "(";
  let hasSetFilters = false;

  const setFilter = (p, logic) => {
    if (hasSetFilters) {
      query += ` ${logic} ${p}`;
    } else {
      hasSetFilters = true;
      query += `${p}`;
    }
  };

  const setFilterOperators = (
    originalFilterName,
    operator,
    operatorValue,
    dataType,
    currentLogic
  ) => {
    const filterName = getResolvedFieldName(originalFilterName, isUrl);
    // if (filterName === "DateBegin" || filterName === "DateEnd") {
    // 	operator = "eqDate"
    // }
    let resolvedValue;

    if (operator !== "isempty" && operator !== "isnotempty") {
      if (operator === "isbetween") {
        resolvedValue = {
          start: getResolvedValue(operatorValue.start, dataType),
          end: getResolvedValue(operatorValue.end, dataType)
        };
      } else if (originalFilterName === "InsertDate") {
        resolvedValue = getResolvedValue(operatorValue, "insertDate");
      } else {
        resolvedValue = getResolvedValue(operatorValue, typeof operatorValue);
      }
    }

    switch (operator) {
      case "doesnotcontain":
        setFilter(
          `substringof(${resolvedValue},${filterName
            .split(".")
            .join("/")}) eq false`,
          currentLogic
        );
        break;

      case "contains":
        setFilter(
          `substringof(${resolvedValue.toLowerCase()},tolower(${filterName
            .split(".")
            .join("/")}))`,
          currentLogic
        );
        break;

      case "endswith":
        setFilter(
          `endswith(tolower(${filterName
            .split(".")
            .join("/")}),${resolvedValue})`,
          currentLogic
        );
        break;

      case "startswith":
        setFilter(
          `startswith(tolower(${filterName
            .split(".")
            .join("/")}), ${resolvedValue})`,
          currentLogic
        );
        break;

      case "isempty":
        setFilter(
          `${filterName} eq null or ${filterName.split(".").join("/")} eq ''`,
          currentLogic
        );
        break;

      case "isnotempty":
        setFilter(
          `${filterName} ne null and ${filterName.split(".").join("/")} ne ''`,
          currentLogic
        );
        break;

      case "isbetween":
        setFilter(
          `${filterName.split(".").join("/")} gt ${
            resolvedValue.start
          } and ${filterName.split(".").join("/")} lt ${resolvedValue.end}`,
          currentLogic
        );
        break;

      case "eqContains":
        const startTime = new Date(operatorValue);
        startTime.setHours(0, 0, 0, 0);
        const endTime = new Date(operatorValue);
        endTime.setHours(23, 59, 59, 0);
        setFilter(
          `${filterName} gt datetimeoffset'${startTime.toISOString()}' and ${filterName} lt datetimeoffset'${endTime.toISOString()}'`,
          currentLogic
        );
        break;
      case "eqDate":
        setFilter(
          `${getDays(new Date(resolvedValue))} eq ${filterName
            .split(".")
            .join("/")}`,
          currentLogic
        );
        break;
      case "neqDate":
        setFilter(
          `${getDays(new Date(resolvedValue))} ne ${filterName
            .split(".")
            .join("/")}`,
          currentLogic
        );
        break;
      case "ltDate":
        setFilter(
          `${getDays(new Date(resolvedValue))} lt ${filterName
            .split(".")
            .join("/")}`,
          currentLogic
        );
        break;
      case "gtDate":
        setFilter(
          `${getDays(new Date(resolvedValue))} gt ${filterName
            .split(".")
            .join("/")}`,
          currentLogic
        );
        break;
      case "lteDate":
        setFilter(
          `${getDays(new Date(resolvedValue))} lte ${filterName
            .split(".")
            .join("/")}`,
          currentLogic
        );
        break;
      case "gteDate":
        setFilter(
          `${getDays(new Date(resolvedValue))} gte ${filterName
            .split(".")
            .join("/")}`,
          currentLogic
        );
        break;
      case "forced":
        setFilter(`${filterName.split(".").join("/")}`, currentLogic);
        break;
      default:
        setFilter(
          `${filterName.split(".").join("/")} ${operator} ${resolvedValue}`,
          currentLogic
        );
    }
  };

  for (var filter of filters) {
    // if (filterName === "filter") {
    // 	for (const f of filter.filters) {
    if (filter.value === "forced") {
      setFilterOperators(filter.field, "forced", "", logic, logic);
    } else if (!isBlank(filter.value))
      setFilterOperators(
        filter.field,
        filter.operator,
        filter.value,
        logic,
        logic
      );
    else if (filter.filters && filter.logic) {
      for (var subFilter of filter.filters) {
        if (subFilter.value)
          setFilterOperators(
            subFilter.field,
            subFilter.operator,
            subFilter.value,
            undefined,
            filter.logic
          );
      }
    }
    // 	}
    // }
    // else {
    // setFilter(
    // 	`${getResolvedFieldName(filterName, isUrl)} ${filter}`

    // )

    // }

    // if (typeof filter !== "object") setFilter(`${filterName} eq ${filter}`);
    // else {
    //   const { field, operator, value } = filter;
    //   setFilterOperators(field, operator, value);
    // }
  }

  if (isApiRequest) {
    // query += ")";
    query.replace("+", "%2B");
  }
  if (!hasSetFilters) return "";
  return query;
};

export function replaceSpecialCharacters(attribute) {
  // replace the single quotes

  attribute = attribute.replace(/'/g, "''");
  // attribute = attribute.replace(/ /g, "%20");
  attribute = attribute.replace(/%/g, "%25");
  attribute = attribute.replace(/\+/g, "%2B");
  attribute = attribute.replace(/\//g, "%2F");
  attribute = attribute.replace(/\?/g, "%3F");

  attribute = attribute.replace(/#/g, "%23");
  attribute = attribute.replace(/&/g, "%26");
  return attribute;
}

export const buildODataQuery = (
  parameters,
  isApiRequest = false,
  { pipelineSettings, disablePipeline, filterBuilder, exporting = false } = {}
) => {
  let query = "";
  let hasSetParameters = false;
  const setParameter = (p) => {
    if (hasSetParameters) query += `&${p}`;
    else {
      hasSetParameters = true;
      query += `?${p}`;
    }
  };
  // setParameter("$Includes=teste")
  let {
    count,
    sort: $orderBy,
    ExtraFilters,
    filter,
    customFilter,
    take,
    skip,
    query: queryParam,
    // eslint-disable-next-line no-unused-vars
    advancedFilters,
    pipeline
  } = parameters;

  if (count) setParameter("$inlinecount=allpages");

  if ($orderBy) {
    const resolvedOrderBy = buildODataOrderBy($orderBy);
    if (resolvedOrderBy) setParameter(resolvedOrderBy);
  }

  if (!disablePipeline && pipeline && pipelineSettings) {
    const { field } = pipelineSettings;
    filter = [
      ...filter,
      { field, operator: "eq", value: pipeline, data: { type: "string" } }
    ];
  }

  if (filterBuilder) {
    setParameter(filterBuilder(filter, undefined, isApiRequest));
  } else {
    if (customFilter) {
      setParameter(`$filter=${customFilter}`);
    } else if (filter && filter.filters && filter.filters.length !== 0)
      setParameter(
        buildODataFilter(filter.filters, filter.logic, undefined, isApiRequest)
      );
  }

  // if (advancedFilters)
  // 	setParameter(`customFilters=${advancedFilters.join(",")}`);

  if (skip && !exporting) setParameter(`$skip=${skip}`);

  if (queryParam) setParameter(`query=${queryParam}`);

  if (ExtraFilters) setParameter(ExtraFilters);

  if (take && !exporting) setParameter(`$top=${take}`);

  return query;
};

window.buildODataQuery = buildODataQuery;

// export const useOdataQuery = (params, disablePipeline) => {
// 	const pipelineSettings = usePipelineSettings()
// 	return useMemo(() => {
// 		const resolvedParams = {
// 			...defaultODataParameters,
// 			...params
// 		};

// 		return buildODataQuery(resolvedParams, true, {
// 			pipelineSettings,
// 			disablePipeline
// 		});
// 	}, [disablePipeline, params, pipelineSettings]);
// };
