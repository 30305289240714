import React, { useCallback, useContext, useState } from "react";
import styles from "./FilterList.module.css";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/pro-light-svg-icons";
import { useMemo } from "react";
import { FilterItemSelector } from "./FilterItemSelector";
import FilterItemBadges from "./FilterItemBadges";
import { FilterTypeEnum } from "./FilterListTypings";
import { FilterListEntityModal } from "./FilterListCreator";
import { AdvancedFilterContext, GridContext } from "../Grid/GridHelper";
import FilterListHeader from "./FilterListHeader";
import {
  FilterListViewContext,
  FiltersQueryContext,
  FilterViewTypes
} from "./FilterListHelper";
import FilterListChanger from "./FilterListChanger";
import DeleteModal from "../DeleteModalContent/DeleteModalContent";

const FilterItem = ({
  isSelected,
  column,
  setFilters,
  setSelectedFilter,
  itemFilters = [],
  filters
}) => {
  const { title, field, filterType, filterDependencies, dependents } = column;

  const toggleSelect = () => {
    setSelectedFilter((f) => (f === field ? undefined : field));
  };

  const handleFilterItemUpdate = (updater) => {
    setFilters((f = {}) => {
      const currentFilter = f[field];

      const newFieldFilter = updater(currentFilter || []);

      const newFilters = {
        ...f,
        [field]: newFieldFilter
      };

      if (dependents) {
        for (const dep of dependents) {
          if (newFilters[dep]) newFilters[dep] = [];
        }
      }

      return newFilters;
    });
  };

  const hasFilters = itemFilters.length > 0;
  // const handleAllFiltersRemoval = () => {
  //   handleFilterItemUpdate((filters) => []);
  // };

  return (
    <div
      className={classnames("p-3", styles.filter, {
        [styles.selected]: isSelected
      })}
    >
      <div className={classnames("d-flex align-items-center")}>
        <div
          className={classnames("flex-1 text-truncate", {
            "fw-medium": isSelected
          })}
        >
          {title}
        </div>

        {((filterType !== FilterTypeEnum.date &&
          filterType !== FilterTypeEnum.price) ||
          !hasFilters) && (
          <div
            onClick={toggleSelect}
            className={classnames("ml-2", styles.filterButton)}
          >
            <FontAwesomeIcon icon={isSelected ? faMinus : faPlus} />
          </div>
        )}
      </div>
      {itemFilters.length > 0 && (
        <div className="mt-2">
          <FilterItemBadges
            column={column}
            itemFilters={itemFilters}
            handleFilterItemUpdate={handleFilterItemUpdate}
          />
        </div>
      )}
      {isSelected && (
        <FilterItemSelector
          filterDependencies={filterDependencies}
          filters={filters}
          setSelectedFilter={setSelectedFilter}
          itemFilters={itemFilters}
          onChange={handleFilterItemUpdate}
          column={column}
        />
      )}
    </div>
  );
};

const FilterListDefault = ({
  columns,
  handleCreation,
  handleRemoval,
  handleEdit
}) => {
  const { advancedFilter, onFilterChange } = useContext(AdvancedFilterContext);

  const filter = advancedFilter.Value;
  const setFilter = useCallback(
    (update) => {
      onFilterChange((advancedFilter) => {
        const rUpdate =
          typeof update === "function" ? update(advancedFilter.Value) : update;

        return {
          ...advancedFilter,
          Value: rUpdate
        };
      });
    },
    [onFilterChange]
  );

  const [selectedFilter, setSelectedFilter] = useState();

  return (
    <>
      <FilterListHeader
        advancedFilter={advancedFilter}
        filter={filter}
        setFilter={setFilter}
        handleCreation={handleCreation}
        handleRemoval={handleRemoval}
        handleEdit={handleEdit}
      />
      <div className="flex-1 overflow-auto">
        {columns.map((c) => {
          const { field, disableCustomFilter } = c;
          if (disableCustomFilter) return null;
          return (
            <FilterItem
              key={field}
              isSelected={selectedFilter === field}
              column={c}
              setFilters={setFilter}
              setSelectedFilter={setSelectedFilter}
              itemFilters={filter[field]}
              filters={filter}
            />
          );
        })}
      </div>
    </>
  );
};

const FilterListViewProvider = ({ children }) => {
  const [view, setView] = useState();
  const value = useMemo(() => ({ view, setView }), [view]);
  return (
    <FilterListViewContext.Provider value={value}>
      {children}
    </FilterListViewContext.Provider>
  );
};

const FilterListBody = (props) => {
  const { view } = useContext(FilterListViewContext);

  switch (view) {
    case FilterViewTypes.filterSelector:
      return <FilterListChanger {...props} />;

    default:
      return <FilterListDefault {...props} />;
  }
};

const FilterListDeleteModal = ({ isDeleting, closeDeleteModal }) => {
  const { setView } = useContext(FilterListViewContext);
  const { updateSettings, settings } = useContext(GridContext);
  const { filterUrl, filterType } = settings;
  const { refetch } = useContext(FiltersQueryContext) || {};

  const handleSuccess = useCallback(() => {
    updateSettings({
      customFilterId: undefined
    });
    setView();
    refetch();
  }, [refetch, setView, updateSettings]);
  const { advancedFilter } = useContext(AdvancedFilterContext);

  return (
    <DeleteModal
      isOpen={isDeleting}
      onClose={closeDeleteModal}
      data={advancedFilter}
      closeModal={closeDeleteModal}
      onSuccess={handleSuccess}
      url={filterUrl}
      params={{ Type: filterType }}
    />
  );
};

const FilterList = ({ columns }) => {
  const [isEntityModalOpen, setIsEntityModalOpen] = useState(false);

  const [isEditing, setIsEditing] = useState(false);

  const handleClosure = useCallback(() => setIsEntityModalOpen(false), []);
  const handleCreation = useCallback(() => {
    setIsEntityModalOpen(true);
    setIsEditing(false);
  }, []);

  const handleEdit = useCallback(() => {
    setIsEntityModalOpen(true);
    setIsEditing(true);
  }, []);

  const [isDeleting, setIsDeleting] = useState();

  const closeDeleteModal = useCallback(() => setIsDeleting(false), []);
  const handleRemoval = useCallback(() => setIsDeleting(true), []);

  const orderedColumns = useMemo(() => {
    return [...columns].sort(
      (a, b) => (a.filterOrder || 0) - (b.filterOrder || 0)
    );
  }, [columns]);

  return (
    <>
      <FilterListViewProvider>
        <FilterListEntityModal
          isEditing={isEditing}
          isOpen={isEntityModalOpen}
          handleClosure={handleClosure}
        />

        <FilterListDeleteModal
          isDeleting={isDeleting}
          closeDeleteModal={closeDeleteModal}
        />

        <div
          className={classnames("mr-4 d-flex flex-column", styles.filterList)}
        >
          <FilterListBody
            columns={orderedColumns}
            handleCreation={handleCreation}
            handleRemoval={handleRemoval}
            handleEdit={handleEdit}
          />
        </div>
      </FilterListViewProvider>
    </>
  );
};

export default FilterList;
