import React, { useCallback, useRef, useState } from "react";
import CategorySOVGraph from "./Graphs/CategorySOVGraph";
import MainBrandsSOVGraph from "./Graphs/MainBrandsSOVGraph";
import Tabs from "./Tabs/Tabs";
import ArticlesInsertsGraph from "./Graphs/ArticlesInsertsGraph";
import ArticlesActionsGraph from "./Graphs/ArticlesActionsGraph";
import SubCategoriesSOVGraph from "./Graphs/SubcategoriesSOVGraph";
import ShopNamesSOVGraph from "./Graphs/ShopNamesSOVGraph";
import BrandsSOVGraph from "./Graphs/BrandsSOVGraph";
import { GraphTabContainer } from "./GraphDashboardHelper";
import ArticlePriceEvoGraph from "./Graphs/ArticlePriceEvoGraph";
import LabeledInput from "../Common/Input/LabeledInput";
import { ArticleDataDropdown } from "../Common/Dropdown/DropdownHelper";
import pdfjsLib from "pdfjs-dist/webpack";
import {
  ArticleInputValueRender,
  ArticleListValueRender
} from "../Promotions/PromotionsFormComp";
import styles from "./Graphs/Graphs.module.css";
import classnames from "classnames";
import {
  FilterOperatorEnum,
  FilterTypeEnum
} from "../Common/FilterList/FilterListTypings";
import LeafleatListGraph from "./Graphs/LeafleatListGraph";
import Button from "../Common/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/pro-light-svg-icons";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import LeaftletsByShopGraph from "./Graphs/LeafletByShopGraph";
import InsertsByShopGraph from "./Graphs/InsertsByShopGraph";
import PromotionsByShopsGraph from "./Graphs/PromotionByShopGraph";
import InsertsByMonth from "./Graphs/InsertsByMonth";
import InsertsByPromotionType from "./Graphs/InsertsByPromotionType";
import { AdvancedFilterProvider } from "../Common/Grid/GridHelper";
const shopColumns = [
  {
    field: "ShopName",
    title: "Insígnia",
    disableOnGrid: true,
    queryUrl: "shops/query" /*custom filter */
  },
  {
    field: "BeginDate",
    title: "Data Inicial",
    filterType: FilterTypeEnum.date, //custom filter
    dateOperator: FilterOperatorEnum.GreaterOrEqual //custom filter
  },
  {
    field: "EndDate",
    title: "Data Final",
    filterType: FilterTypeEnum.date //custom filter
  }
  // {
  //   field: "Categories",
  //   title: "Categoria",
  //   queryUrl: "Categories/query", //custom filter
  // },
  // {
  //   field: "SubCategoryName",
  //   title: "Subcategoria",
  //   queryUrl: "Categories/sub/query", //custom filter
  // },
  // {
  //   field: "ShopName.ChainName.Name",
  //   title: "Cadeia",
  //   disableOnGrid: true,
  //   queryUrl: "chains/query" /*custom filter */,
  //   dependents: ["ShopName.Name"],
  // },
  // {
  //   field: "ShopName.Name",
  //   title: "Insígnia",
  //   disableOnGrid: true,
  //   queryUrl: "shops/query" /*custom filter */,
  //   filterDependencies: [
  //     {
  //       field: "ShopName.ChainName.Name",
  //       queryName: "chainNames",
  //     },
  //   ],
  // },
  // {
  //   field: "DateBegin.Name",
  //   title: "Data de Inicio",
  //   filter: "date",
  //   disableOnGrid: true,
  //   // cell: dateCell,
  //   filterType: FilterTypeEnum.date, //custom filter
  //   dateOperator: FilterOperatorEnum.GreaterOrEqual, //custom filter
  // },
  // {
  //   field: "DateEnd.Name",
  //   title: "Data de Fim",
  //   filter: "date",
  //   disableOnGrid: true,

  //   // cell: dateCell,
  //   filterType: FilterTypeEnum.date, //custom filter
  //   dateOperator: FilterOperatorEnum.LesserOrEqual, //custom filter
  // },
];

const categoriesColumns = [
  {
    field: "ShopName",
    title: "Insígnia",
    disableOnGrid: true,
    queryUrl: "shops/query" /*custom filter */
  },
  {
    field: "Categories",
    title: "Categoria",
    queryUrl: "Categories/query" //custom filter
  },
  {
    field: "BeginDate",
    title: "Data Inicial",
    filterType: FilterTypeEnum.date, //custom filter
    dateOperator: FilterOperatorEnum.GreaterOrEqual //custom filter
  },
  {
    field: "EndDate",
    title: "Data Final",
    filterType: FilterTypeEnum.date //custom filter
  }
];

const generalColumns = [
  {
    field: "ShopName",
    title: "Insígnia",
    disableOnGrid: true,
    queryUrl: "shops/query" /*custom filter */
  },
  {
    field: "Categories",
    title: "Categoria",
    queryUrl: "Categories/query" //custom filter
  },
  {
    field: "MainBrandName",
    title: "Marca Principal",
    queryUrl: "brands/main/query" //custom filter
  },
  {
    field: "BeginDate",
    title: "Data Inicial",
    filterType: FilterTypeEnum.date, //custom filter
    dateOperator: FilterOperatorEnum.GreaterOrEqual //custom filter
  },
  {
    field: "EndDate",
    title: "Data Final",
    dateOperator: FilterOperatorEnum.LesserOrEqual, //custom filter
    filterType: FilterTypeEnum.date //custom filter
  }
];
const leafletsColumns = [
  {
    field: "ShopName",
    title: "Insígnia",
    disableOnGrid: true,
    queryUrl: "shops/query" /*custom filter */
  },
  {
    field: "Categories",
    title: "Categoria",
    queryUrl: "Categories/query" //custom filter
  },
  {
    field: "MainBrandName",
    title: "Marca Principal",
    queryUrl: "brands/main/query" //custom filter
  },
  {
    field: "BeginDate",
    title: "Data Inicial",
    filterType: FilterTypeEnum.date, //custom filter
    dateOperator: FilterOperatorEnum.GreaterOrEqual //custom filter
  },
  {
    field: "EndDate",
    title: "Data Final",
    filterType: FilterTypeEnum.date //custom filter
  }
];

// const articlesColumns = [
//   {
//     field: "ArticleName",
//     title: "Artigo",
//     queryUrl: "articles/names/query" /*custom filter */,
//   },
// ];

const ArticleTab = () => {
  const [articleData, setArticleData] = useState();

  const handleChange = (e) => {
    setArticleData(e.target.value);
  };

  return (
    <div className="tabsArticles">
      <LabeledInput
        textClassName={"text-blacker"}
        className={classnames(
          "ar-dropdown-modal w-100 mb-3 flex-1",
          styles.articleInput
        )}
        name="ArticleData"
        value={articleData || ""}
        // error={errorState.ArticleData && `Artigo ${ErrorMessage}`}
        onChange={handleChange}
        // ExtraDropdownOption={
        //   Permission >= RolesEnum.BackOne
        //     ? {
        //         Article: { Name: "Adicionar novo artigo" },
        //         Idd: 0,
        //       }
        //     : undefined
        // }
        InputComponent={ArticleDataDropdown}
        ItemRender={ArticleListValueRender}
        ValueRender={ArticleInputValueRender}
        //   error={emailError}
        // hasErrors={!isEmailValid}
        text="Artigo"
      />

      <div className={classnames(styles.graphContainer)}>
        <ArticlePriceEvoGraph articleDataId={articleData?.Id} />
        <LeafleatListGraph articleDataId={articleData?.Id} />
      </div>
    </div>
  );
};

const tabs = [
  {
    name: "Insígnias",
    Component: () => {
      return (
        <GraphTabContainer columns={generalColumns}>
          <CategorySOVGraph />
          <MainBrandsSOVGraph />
          <ArticlesInsertsGraph />
          <LeafleatListGraph />
          {/* <ArticlesActionsGraph /> */}
        </GraphTabContainer>
      );
    }
  },
  {
    name: "Folhetos",
    Component: () => {
      return (
        <GraphTabContainer columns={generalColumns}>
          <LeaftletsByShopGraph />
          <InsertsByShopGraph />
          <InsertsByMonth />
          <InsertsByPromotionType />
          {/* <PromotionsByShopsGraph /> */}
        </GraphTabContainer>
      );
    }
  },
  {
    name: "Categorias",
    Component: () => {
      return (
        <GraphTabContainer columns={generalColumns}>
          <SubCategoriesSOVGraph />
          <MainBrandsSOVGraph />
          <ArticlesInsertsGraph />
          <LeafleatListGraph />
          {/* <ArticlesActionsGraph /> */}
        </GraphTabContainer>
      );
    }
  },
  {
    name: "Marcas",
    Component: () => {
      return (
        <GraphTabContainer columns={generalColumns}>
          <ShopNamesSOVGraph />
          <BrandsSOVGraph />
          <ArticlesInsertsGraph />
          <LeafleatListGraph />
          {/* <ArticlesActionsGraph /> */}
        </GraphTabContainer>
      );
    }
  },
  {
    name: "Artigos",
    Component: ArticleTab
  }
];

const GraphDashboard = () => {
  const containerRef = useRef();
  const contentRef = useRef();

  const exportToPdf = () => {
    containerRef.current.classList.add("disable-all-overflow");
    html2canvas(contentRef.current, {
      // width: window.innerWidth,
      // height: 800,
      // windowWidth: 1600,
      // windowHeight: 1200,
      scale: 0.75
    }).then((canvas) => {
      const myImage = canvas.toDataURL("image/png");
      containerRef.current.classList.remove("disable-all-overflow");
      var pdf = new jsPDF({
        orientation: "landscape",
        unit: "px"
      });
      pdf.addImage(myImage, "PNG", 0, 0);
      pdf.save("Dashboard.pdf");
    });
  };

  return (
    <div ref={containerRef} className="p-4 d-flex flex-column">
      <div className="d-flex align-items-center justify-content-between mb-2">
        <div className="d-flex w-100 justify-content-between align-items-center">
          <div className="fs-40 fw-medium mr-3">Dashboard</div>
          <Button onClick={exportToPdf}>
            <FontAwesomeIcon size="lg" icon={faFilePdf} />
          </Button>
        </div>
      </div>
      {/* <DashFilterProvider> */}
      <Tabs domRef={contentRef} options={tabs} />
      {/* </DashFilterProvider> */}
      {/* <div className={styles.graphContainer}>
        <CategorySOVGrapgh></CategorySOVGrapgh>
        <MainBrandsSOVGrapgh></MainBrandsSOVGrapgh>
      </div> */}
    </div>
  );
};

export const DashFilterProvider = ({ children }) => {
  const [filter, setFilter] = useState(() => {
    return {
      Value: {}
    };
  });

  const handleFilterChange = useCallback((update) => {
    setFilter((currentFilter) => {
      const rUpdate =
        typeof update === "function" ? update(currentFilter) : currentFilter;

      const newFilter = { ...currentFilter, ...rUpdate };

      return newFilter;
    });
  }, []);

  return (
    <AdvancedFilterProvider
      advancedFilter={filter}
      onFilterChange={handleFilterChange}
      setAdvancedFilter={setFilter}
    >
      {children}
    </AdvancedFilterProvider>
  );
};

export default GraphDashboard;
