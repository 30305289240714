import React, {
  useRef,
  useState,
  useLayoutEffect,
  useCallback,
  useContext,
} from "react";
import ReactDOM from "react-dom";
import classnames from "classnames";
// import { RootElement } from "../../Helpers/MiscHelper";
import "./Modal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
// import { subscribeUniqueEvent } from "../../Helpers/ComponentsHelper";

export const ModalCloseContext = React.createContext();

const Modal = ({
  isOpen,
  onClose,
  className,
  containerClassName,
  disableClosure,
  extraCloseButton,
  children,
  onEntered,
  onExited,
  customContent,
}) => {
	const [isAnimating, setIsAnimating] = useState(false);
	const popupRef = useRef();
	const handleClosure = useCallback(() => {
	  if (onClose && !disableClosure && isOpen) onClose();
	}, [disableClosure, isOpen, onClose]);
	// useEffect(() => {
  //   if (isOpen) {
  //     return subscribeUniqueEvent((e) => {
  //       if (e.keyCode === 27) {
  //         e.preventDefault();
  //         onClose && onClose();
  //       }
  //     });
  //   }
  // }, [isOpen, onClose]);

  useLayoutEffect(() => {
    const elem = popupRef.current;
    if (!elem) return;
    if (isOpen) {
      let frame = requestAnimationFrame(() => {
        frame = requestAnimationFrame(() => {
          elem.classList.add("active");
          if (onEntered) {
            let timeout = setTimeout(() => {
              onEntered();
            }, 300);
            return () => clearTimeout(timeout);
          }
        });
      });
      return () => cancelAnimationFrame(frame);
    } else {
      elem.classList.remove("active");
      let timeout = setTimeout(() => {
        setIsAnimating(false);
        onExited && onExited();
      }, 300);
      return () => clearTimeout(timeout);
    }
  }, [isOpen, onEntered, onExited]);

  const handleClick = useCallback(
    (e) => {
      if (e.target !== popupRef.current) {
        return;
      } else {
        handleClosure();
      }
    },
    [handleClosure]
  );


  if (isOpen && !isAnimating) return setIsAnimating(true);

  if (!isOpen && !isAnimating) return null;

  const comp = (
    <ModalCloseContext.Provider value={handleClosure}>
      <div
        ref={popupRef}
        onMouseDown={!disableClosure ? handleClick : undefined}
        className={classnames(`ar-modal`, containerClassName)}
      >
        {customContent ? (
          <>
            {children}
            {extraCloseButton ? extraCloseButton : null}
          </>
        ) : (
          <div className={classnames("ar-modal-content", className)}>
            {children}
            {extraCloseButton ? extraCloseButton : null}
          </div>
        )}
      </div>
    </ModalCloseContext.Provider>
  );
  return ReactDOM.createPortal(comp, document.body);
};

export default Modal;

export const ModalHeader = ({ icon, children }) => {
  const closeModal = useContext(ModalCloseContext);
  return (
    <div className="d-flex align-items-center justify-content-between border-bottom pb-4">
      <div className="d-flex align-items-center">
        <FontAwesomeIcon className="mr-2 color-primary" size="lg" icon={icon} />
        <div className="fw-medium fs-20">{children}</div>
      </div>
      <div className="text-secondary cursor-pointer" onClick={closeModal}>
        <FontAwesomeIcon className="d-block" icon={faTimes} size="lg" />
      </div>
    </div>
  );
};
