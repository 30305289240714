
import { faBezierCurve, faSave } from "@fortawesome/pro-solid-svg-icons";
import LabeledInput from "../../../Common/Input/LabeledInput";
import {
  MainBrandDropdown,
  CompanyTypesDropdown,
  CompanyDropdown,
} from "../../../Common/Dropdown/DropdownHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useMemo } from "react";
import LoadableButton from "../../../Common/Button/LoadableButton";
import { createEntityModal } from "../../../Common/Modal/ModalHelper";
import { ErrorMessage } from "../../../Leaflets/LeafletForm";
import ErrorMessageHandler from "../../../Common/ErrorMessage/ErrorMessage";

const InnerAddBrandModalModal = ({
  form,
  post,
  loading,
  handleChanges,
  setForm,
  error,
}) => {
  const { Name, MainBrandName, CompanyName, CompanyType } = form;

  const [errorState, setErrorState] = useState({});

  const isFormValid = () => {
    let valid = true;
    let newErrorState = {};
    if (!/(.|\s)*\S(.|\s)*/.test(Name)) {
      valid = false;
      newErrorState.Name = true;
    }
    if (!MainBrandName) {
      valid = false;
      newErrorState.MainBrandName = true;
    }
    setErrorState(newErrorState);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formValidation = isFormValid();
    if (formValidation) {
      const resolvedForm = {
        Name: form.Name,
        MainBrandNameId: form.MainBrandName.Id,
      };
      post(resolvedForm);
    }
  };

  const onRemoveCompanyType = () => {
    handleChanges({ target: { name: "CompanyType", value: {} } });
  };

  const onRemoveCompanyName = () => {
    handleChanges({ target: { name: "CompanyName", value: {} } });
  };

  const CompanyTypeaExtraProfile = useMemo(() => {
    if (CompanyType && CompanyType.Id) {
      return {
        field: "CompanyType/Id",
        operator: "eq",
        value: CompanyType.Id,
      };
    } else return null;
  }, [CompanyType]);

  const CompanyNameExtraProfile = useMemo(() => {
    if (CompanyName && CompanyName.Id) {
      return {
        field: "CompanyName/Id",
        operator: "eq",
        value: CompanyName.Id,
      };
    } else return null;
  }, [CompanyName]);

  return (
    <div style={{ width: 576 }} className="mt-4">
      <form onSubmit={handleSubmit}>
        <LabeledInput
          className="mb-3"
          name="Name"
          value={Name}
          placeholder=" "
          error={errorState.Name && `Nome ${ErrorMessage}`}
          onChange={handleChanges}
          //   error={emailError}
          // hasErrors={!isEmailValid}
          text="Nome"
        />
        <LabeledInput
          className="mb-4 ar-dropdown-modal"
          name="CompanyType"
          value={CompanyType}
          onChange={handleChanges}
          InputComponent={CompanyTypesDropdown}
          //   error={emailError}
          // hasErrors={!isEmailValid}
          text="Tipo de Marca"
        />
        {CompanyType && CompanyType.Id ? (
          <div
            onClick={onRemoveCompanyType}
            className="text-danger cursor-pointer mb-3"
          >
            Remover
          </div>
        ) : (
          <div className="mb-3"></div>
        )}
        <LabeledInput
          className="mb-4 ar-dropdown-modal"
          name="CompanyName"
          value={CompanyName}
          onChange={handleChanges}
          InputComponent={CompanyDropdown}
          optionalFilters={CompanyTypeaExtraProfile}
          //   error={emailError}
          // hasErrors={!isEmailValid}
          text="Empresa"
        />
        {CompanyName && CompanyName.Id ? (
          <div
            onClick={onRemoveCompanyName}
            className="text-danger cursor-pointer mb-3"
          >
            Remover
          </div>
        ) : (
          <div className="mb-3"></div>
        )}
        <LabeledInput
          className="mb-4 ar-dropdown-modal"
          name="MainBrandName"
          value={MainBrandName}
          onChange={handleChanges}
          InputComponent={MainBrandDropdown}
          optionalFilters={CompanyNameExtraProfile}
          //   error={emailError}
          // hasErrors={!isEmailValid}
          error={errorState.MainBrandName && `Marca Principal ${ErrorMessage}`}
          text="Marca Principal"
        />
        {error && (
          <div className="text-danger mb-1 text-center">
            <ErrorMessageHandler error={error} />
          </div>
        )}
        <LoadableButton loading={loading} className="w-100" type="submit">
          <span>
            <FontAwesomeIcon className="mr-2" icon={faSave} />
            Guardar
          </span>
        </LoadableButton>
      </form>
    </div>
  );
};

const AddBrandModalModal = createEntityModal({
  getEndpoint: (data) => (data ? `brands/${data.Id}` : `brands`),
  Component: InnerAddBrandModalModal,
  headerTitle: "Adicionar Marca",
  headerIcon: faBezierCurve,
});

export default AddBrandModalModal;

// const AddBrandsMainModal = ({ data }) => {
//   const closeModal = useContext(ModalCloseContext);
//   const refetchRef = useContext(GridRefechContext);
//   const [form, setForm] = useState(() => {
//     return {
//       Name: "",
//       MainBrandName: {},
//       ...data,
//     };
//   });

//   const handleChanges = (e) => {
//     const { name, value } = e.target;

//     setForm((f) => {
//       return {
//         ...f,
//         [name]: value,
//       };
//     });
//   };

//   const [post, { loading }] = usePost(
//     data ? `brands/${data.Id}` : `brands`,
//     null,
//     {
//       onSuccess: ({ data }) => {
//         if (refetchRef.current) refetchRef.current();
//         closeModal();
//       },
//       onError: ({ error }) => {
//         console.log(error);
//       },
//     }
//   );

//   const { Name, MainBrandName } = form;

//   const isFormValid = () => {
//     if (!/(.|\s)*\S(.|\s)*/.test(Name)) return false;
//     if (!MainBrandName.Id) return false;
//     else return true;
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     const formValidation = isFormValid();
//     if (formValidation) {
//       const resolvedForm = {
//         Name: form.Name,
//         MainBrandNameId: form.MainBrandName.Id,
//       };
//       post(resolvedForm);
//     }
//   };

//   return (
//     <div className="p-4">
//       <ModalHeader icon={faBezierCurve}>Adicionar Marca</ModalHeader>

//       <div style={{ width: 576 }} className="mt-4">
//         <form onSubmit={handleSubmit}>
//           <LabeledInput
//             className="mb-3"
//             name="Name"
//             value={Name}
//             placeholder=" "
//             required="required"
//             pattern="(.|\s)*\S(.|\s)*"
//             onChange={handleChanges}
//             //   error={emailError}
//             // hasErrors={!isEmailValid}
//             text="Nome"
//           />
//           <LabeledInput
//             className="mb-4 ar-dropdown-modal"
//             name="MainBrandName"
//             value={MainBrandName}
//             onChange={handleChanges}
//             InputComponent={MainBrandDropdown}
//             //   error={emailError}
//             // hasErrors={!isEmailValid}
//             text="Marca Principal"
//           />
//           <LoadableButton loading={loading} className="w-100" type="submit">
//             <span>
//               <FontAwesomeIcon className="mr-2" icon={faSave} />
//               Guardar
//             </span>
//           </LoadableButton>
//         </form>
//       </div>
//     </div>
//   );
// };
