export const getDate = (epochDays) => {
	const epochHours = epochDays * 24;
	const epochMinutes = epochHours * 60;
	const epochSeconds = epochMinutes * 60;
	const epochMil = epochSeconds * 1000;

	return new Date(epochMil);
};




export const getDays = (epochDate) => {
	const resolvedDate = new Date(
		epochDate
	)
	resolvedDate.setMilliseconds(0);
	resolvedDate.setSeconds(0)
	resolvedDate.setMinutes(0);
	resolvedDate.setHours(0)
	const epochSeconds = resolvedDate.getTime() / 1000;
	const epochMinutes = epochSeconds / 60;
	const epochHours = epochMinutes / 60;
	const epochDays = epochHours / 24;

	return Math.round(epochDays);
};