import React, { useState } from "react";

import LeafletNamesGrid from "./LeafletNamesGrid.jsx";

import AddLeafletNamesModal from "./AddLeafletNamesModal/AddLeafletNamesModal";
import ExcelExportButton from "../../../Common/ExcelButton/ExcelExportButton.jsx";
import PlusButton from "../../../Common/PlusButton/PlusButton.jsx";

const LeafletNames = () => {
  const [addingArticleNames, setAddingArticleNames] = useState(false);

  const toggleModal = () => {
    setAddingArticleNames((open) => !open);
  };

  return (
    <>
      <AddLeafletNamesModal isOpen={addingArticleNames} onClose={toggleModal} />

      <div className=" h-100 d-flex flex-column main-grid">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div className="fs-24 fw-medium">Nomes</div>
          <div className="d-flex flex-1 align-items-center justify-content-end">
            <PlusButton className="mr-3" onClick={toggleModal}>
              Adicionar
            </PlusButton>
            <ExcelExportButton BaseUrl={"leaflets/names/export"} />
          </div>
        </div>
        <LeafletNamesGrid />
      </div>
    </>
  );
};

export default LeafletNames;
