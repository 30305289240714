import React, { useCallback, useContext, useMemo, useState } from "react";
import FilterList from "../Common/FilterList/FilterList";
import {
  AdvancedFilterProvider,
  createFilterUrl,
  GridCacheContext,
  GridContext,
  GridProvider
} from "../Common/Grid/GridHelper";
import styles from "./Graphs/Graphs.module.css";
import classnames from "classnames";
import { client, useQuery } from "../Helpers/IOClient";
import { FiltersQueryContext } from "../Common/FilterList/FilterListHelper";
import LoaderSpinner from "../Common/LoaderSpinner/LoaderSpinner";


const FiltersProvider = ({...rest}) => {
	const { settings,  } =
    useContext(GridContext) || {};
		const { customFilterId } = settings;
		return <InnerFiltersProvider key={customFilterId} {...rest} />
}


const InnerFiltersProvider = ({ children }) => {
  const gridCache = useContext(GridCacheContext);
  const { settings, filterStateSetterRef, updateSettings, id } =
    useContext(GridContext) || {};
  const filterCache = useMemo(() => {
    if (!id || !gridCache) return {};

    const state = gridCache.read(id);
    return state || {};
  }, [gridCache, id]);
  const { customFilterId, filterUrl, filterType } = settings;
  const [filter, setFilter] = useState(() => {

    if (customFilterId) return undefined;

    return {
      Value: filterCache.filter || {}
    };
  });

  const url = useMemo(() => {
    if (!customFilterId) return null;

    return createFilterUrl(filterUrl, customFilterId, filterType);
  }, [customFilterId, filterType, filterUrl]);

  const {loading:loadingFilter, error: errorFilter} = useQuery(url, null, {
    onSuccess: ({ data }) => {
 
      setFilter(data);
    },
    onError: ({ error }) => {
      if (error?.status === 404) {
        updateSettings({ customFilterId: undefined });
      }
  
      setFilter({ Value: {} });
    },
    cache: false
  });

  const handleFilterChange = useCallback(
    (update) => {
      setFilter((currentFilter) => {
        const rUpdate =
          typeof update === "function" ? update(currentFilter) : currentFilter;

        const newFilter = { ...currentFilter, ...rUpdate };

        if (filterStateSetterRef.current)
          filterStateSetterRef.current(newFilter.Value);

        if (customFilterId)
          client.post(
            createFilterUrl(filterUrl, customFilterId, filterType),
            newFilter
          );

        return newFilter;
      });
    },
    [customFilterId, filterStateSetterRef, filterType, filterUrl]
  );

  const query = useQuery(createFilterUrl(filterUrl, null, filterType), null, {
    cache: false
  });

  const { loading, error } = query;

  if (loadingFilter || errorFilter || loading || error)
    return (
      <div className="d-flex align-items-center justify-content-center w-100 h-100">
        <LoaderSpinner center size="sm" className="text-primary" />
      </div>
    );

  return (
    <React.Fragment key={customFilterId}>
      <FiltersQueryContext.Provider value={query}>
        <AdvancedFilterProvider
          advancedFilter={filter}
          onFilterChange={handleFilterChange}
          setAdvancedFilter={setFilter}
        >
          {children}
        </AdvancedFilterProvider>
      </FiltersQueryContext.Provider>
    </React.Fragment>
  );
};

export const GraphTabContainer = ({ columns, children }) => {
  return (
    <GridProvider
      filterUrl={"genericFilters"}
      filterType="graphDashboard"
      enableCustomFilters
      id="dashGraph"
    >
      <FiltersProvider>
        <div className="d-flex flex-1 mt-3">
          <FilterList columns={columns} />
          <div className={"flex-1"}>
            <div className={classnames(styles.graphContainer)}>{children}</div>
          </div>
        </div>
      </FiltersProvider>
    </GridProvider>
  );
};
