import {
  FilterOperatorEnum,
  FilterTypeEnum
} from "../Common/FilterList/FilterListTypings";
import { getDays } from "./DateHelper";
import { replaceSpecialCharacters } from "./ODataHelper";

const buildPriceFilter = (
  setInnerFilter,
  columnFilter,
  resolvedFilterField
) => {
  const { operator, value } = columnFilter;

  if (operator === FilterOperatorEnum.Equal) {
    setInnerFilter(`(${resolvedFilterField} ${operator} null)`);
  } else if (operator === FilterOperatorEnum.Between) {
    if (value.start === null || value.end === null) {
      setInnerFilter(`(${resolvedFilterField} ne null)`);
    } else {
      const { start, end } = value;

      setInnerFilter(
        `(${resolvedFilterField} ge ${start}) and (${resolvedFilterField} le ${end})`
      );
    }
  } else {
    setInnerFilter(`(${resolvedFilterField} ${operator} ${value})`);
  }
};

export const buildCustomODataFilter = (filters, isUrl, isApiRequest) => {
  let query = isUrl ? "" : "$filter=";
  // if (isApiRequest) query += "(";
  let hasSetFilters = false;

  const setFilter = (p, logic) => {
    if (hasSetFilters) {
      query += ` ${logic} ${p}`;
    } else {
      hasSetFilters = true;
      query += `${p}`;
    }
  };

  for (const filterField in filters) {
    const columnFilters = filters[filterField];

    let innerQuery = "";
    let hasSetInnerFilter = false;

    const setInnerFilter = (p) => {
      if (hasSetInnerFilter) {
        innerQuery += ` or ${p}`;
      } else {
        hasSetInnerFilter = true;
        innerQuery += `(${p}`;
      }
    };

    for (const columnFilter of columnFilters) {
      const { value, operator, filterType } = columnFilter;

      const resolvedFilterField = filterField.replaceAll(".", "/");

      switch (filterType) {
        case FilterTypeEnum.date:
          setInnerFilter(
            `(${resolvedFilterField} ${operator} ${getDays(value)})`
          );
          break;

        case FilterTypeEnum.price:
          buildPriceFilter(setInnerFilter, columnFilter, resolvedFilterField);

          break;

        default:
          setInnerFilter(
            `(${resolvedFilterField} ${operator} '${replaceSpecialCharacters(
              value
            )}')`
          );
          break;
      }
    }
    if (hasSetInnerFilter) setFilter(`${innerQuery})`, "and");
  }

  if (isApiRequest) {
    // query += ")";
    query.replace("+", "%2B");
  }
  if (!hasSetFilters) return "";
  return query;
};
