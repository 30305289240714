import React from "react";
import ExcelExportButton from "../Common/ExcelButton/ExcelExportButton";
import { FilterListToggler } from "../Common/FilterList/FilterListProvider";
import {
  FilterOperatorEnum,
  FilterTypeEnum,
} from "../Common/FilterList/FilterListTypings";
import BaseGrid from "../Common/Grid/Grid";
import { GridProvider } from "../Common/Grid/GridHelper";
import { buildCategoryNameFilterQuery } from "./IndicatorsHelper";

const SOVCell = ({ dataItem, field }) => {
  const value = dataItem[field];

  const resolvedVal = value.toFixed(2);

  return <td>{resolvedVal}%</td>;
};

const columns = [
  {
    field: "MainBrandName",
    title: "Marca Principal",
    queryUrl: "brands/main/query", //custom filter
  },
  {
    field: "ActionsCount",
    title: "Nº Ações",
    disableCustomFilter: true,
  },
  {
    field: "ActionsSOV",
    title: "SOV Ações",
    cell: SOVCell,
    disableCustomFilter: true,
  },
  {
    field: "InsertsCount",
    title: "Nº Inserções",
    disableCustomFilter: true,
  },
  {
    field: "InsertsSOV",
    title: "SOV Inserções",
    cell: SOVCell,
    disableCustomFilter: true,
  },
  {
    field: "BrandName",
    title: "Marca",
    queryUrl: "brands/query", //custom filter
  },
  {
    field: "MainActionsCount",
    title: "Nº Ações",
    disableCustomFilter: true,
  },
  {
    field: "MainActionsSOV",
    title: "SOV Ações",
    cell: SOVCell,
    disableCustomFilter: true,
  },
  {
    field: "MainInsertsCount",
    title: "Nº Inserções",
    disableCustomFilter: true,
  },
  {
    field: "MainInsertsSOV",
    title: "SOV Inserções",
    cell: SOVCell,
    disableCustomFilter: true,
  },
  {
    field: "ShopName.ChainName.Name",
    title: "Cadeia",
    disableOnGrid: true,
    queryUrl: "chains/query" /*custom filter */,
    dependents: ["ShopName.Name"],
  },
  {
    field: "ShopName.Name",
    title: "Insígnia",
    disableOnGrid: true,
    queryUrl: "shops/query" /*custom filter */,
    filterDependencies: [
      {
        field: "ShopName.ChainName.Name",
        queryName: "chainNames",
      },
    ],
  },
  {
    field: "DateBegin.Name",
    title: "Data de Inicio",
    filter: "date",
    disableOnGrid: true,
    // cell: dateCell,
    filterType: FilterTypeEnum.date, //custom filter
    dateOperator: FilterOperatorEnum.GreaterOrEqual, //custom filter
  },
  {
    field: "DateEnd.Name",
    title: "Data de Fim",
    filter: "date",
    disableOnGrid: true,

    // cell: dateCell,
    filterType: FilterTypeEnum.date, //custom filter
    dateOperator: FilterOperatorEnum.LesserOrEqual, //custom filter
  },
];

const Grid = () => {
  return (
    <BaseGrid
      customFilter
      customODataFilterBuilder={buildCategoryNameFilterQuery}
      defaultSorts={[{ dir: "asc", field: "MainBrandName" }]}
      // filterCache={LeafletsFilterCacheState}
      // onFilterChange={setLeafletsFilterCacheState}
      // customCache={leafletsCache}
      Columns={columns}
      //   filterOperators={filterOperators}
      BaseUrl={"indicators/brands"}
    />
  );
};

const BrandsIndicators = () => {
  //   const openForm = () => {
  //     history.push("/leaflets/new");
  //   };

  return (
    <GridProvider
      filterUrl={"genericFilters"}
      filterType="indicatorBrands"
      enableCustomFilters
      id="indicatorBrands"
    >
      <div className="p-4 h-100 d-flex flex-column">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div className="d-flex align-items-center">
            <div className="fs-40 fw-medium mr-3">Análise de Marcas</div>
          </div>
          <div className="d-flex flex-1 align-items-center justify-content-end">
            <ExcelExportButton
              // removemargin={!(Permission >= RolesEnum.BackTwo)}
              BaseUrl={"indicators/brands/export"}
            />
            <FilterListToggler />
            {/* <ViewToggler className="ml-3" /> */}
          </div>
        </div>
        <Grid />
      </div>
    </GridProvider>
  );
};

export default BrandsIndicators;
