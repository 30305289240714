export enum FilterTypeEnum {
  date = "date",
  price = "price",
}

export enum FilterOperatorEnum {
  Equal = "eq",
  GreaterOrEqual = "ge",
  LesserOrEqual = "le",
  Between = "between",
}
