import React from "react";

export const FiltersQueryContext = React.createContext();

export const FilterViewTypes = {
  filterSelector: "filterSelector",
};

export const FiltersViewContext = React.createContext();

export const FilterListViewContext = React.createContext();
