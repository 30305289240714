import React, { useEffect, useRef } from "react";
import "./Percentage.css";

var svgStrokeLength = 380;

const Percentage = ({ percentage }) => {
  const loading = useRef();

  useEffect(() => {
    if (loading.current && percentage) {
      var percent = svgStrokeLength - (svgStrokeLength * percentage) / 100;

      const circleColor = loading.current.querySelector("circle.circle-color");

      circleColor.style.strokeDashoffset = percent;
    }
  }, [percentage]);

  return (
    <div ref={loading} className="loading">
      <svg
        className="percentage-loader"
        xmlns="http://www.w3.org/2000/svg"
        width="124"
        height="124"
        viewBox="0 0 124 124"
      >
        <circle id="circle" className="circle-bg" cx="62" cy="62" r="59" />
        <circle
          id="circle"
          className="circle-color"
          cx="62"
          cy="62"
          r="59"
          transform="rotate(-90 62 62)"
        />
        <text
          x="50%"
          y="50%"
          fill="#00abcd"
          dominantBaseline="middle"
          className="percentage-text"
          textAnchor="middle"
        >
          {percentage || 0}%
        </text>
      </svg>
    </div>
  );
};

export default Percentage;
