import { apiUrl } from "../config";
import { client } from "./IOClient";

const { getCookie, setCookie } = require("./CookiesHelper");

export const authObject = {
  token: getCookie("tkn"),
};

export const LoginErrorTypes = {
  emailPassword: "emailPassword",
  unknown: "unknown",
};

export const login = async (form) => {
  const { email, password } = form;

  var details = {
    username: email,
    password,
    grant_type: "password",
  };

  var formBody = new URLSearchParams(details);

  try {
    const response = await fetch(`${apiUrl}/account/login`, {
      method: "POST",
      headers: {
        // "Cache-Control": "no-cache",
        // Accept: "*/*",
        // "Accept-Encoding": "gzip, deflate, br",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
    });

    let error, data;

    const json = await response.json();

    if (response.ok) {
      data = json;

      setCookie("tkn", json.access_token, 366);
      authObject.token = json.access_token;
      if (authObject.onAuthStateChange) authObject.onAuthStateChange(true);
    } else {
      if (
        json.error_description === "The user name or password is incorrect."
      ) {
        error = LoginErrorTypes.emailPassword;
      } else {
        error = LoginErrorTypes.unknown;
      }
    }

    return { error, data };
  } catch (e) {
    return { error: LoginErrorTypes.unknown };
  }
};

export const logout = () => {
  setCookie("tkn", "", -1);
  authObject.token = "";
	client.cache.reset()
  if (authObject.onAuthStateChange) authObject.onAuthStateChange(false);
};
