import React, { useCallback, useContext, useMemo, useState } from "react";
import classnames from "classnames";
import styles from "./Graphs.module.css";
import { useRef } from "react";
import { useEndlessScroll } from "../../Helpers/MiscHelper";
import { useQuery } from "../../Helpers/IOClient";
import LoaderSpinner from "../../Common/LoaderSpinner/LoaderSpinner";
import moment from "moment";
import { getDate } from "../../Helpers/DateHelper";
import { AdvancedFilterContext } from "../../Common/Grid/GridHelper";
import { buildLeafleatFilterQuery } from "../../Indicators/IndicatorsHelper";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const TopValueSkip = 10;

const LeafleatItem = ({ value }) => {
  const { DateBegin, DateEnd, LeafleatName, FileThumbnailUrl, ShopName } =
    value;
  const dateString = useMemo(() => {
    const dateS = moment(getDate(DateBegin.Name)).format("DD/MM/YYYY");
    const dateE = moment(getDate(DateEnd.Name)).format("DD/MM/YYYY");

    return `${dateS} - ${dateE}`;
  }, [DateBegin.Name, DateEnd.Name]);

  return (
    <div
      className={classnames(
        "p-2 rounded d-flex overflow-hidden",
        styles.leafleatItem
      )}
    >
      {/* <img
        src={FileThumbnailUrl}
        alt=""
        className={classnames("rounded mr-3", styles.leafleatThumb)}
      /> */}

      <div className="d-flex flex-column justify-content-between text-truncate">
        <Link
          to={`leaflets/${value.id}/promotions/list/view`}
          className={classnames("text-truncate", styles.leafleatLink)}
          title={LeafleatName.Name}
        >
          {LeafleatName.Name}
        </Link>
        <div className="fs-14">
          <div className="text-truncate" title={ShopName.Name}>
            {ShopName.Name}
          </div>
          <div className={classnames("text-truncate", styles.leafleatDates)}>
            {dateString}
          </div>
        </div>
      </div>
    </div>
  );
};

const InnerLeafleatListGraph = ({ url }) => {
  const pageRef = useRef();
  const [hasMore, setHasMore] = useState(true);

  const [skip, setSkip] = useState(0);

  const [response, setResponse] = useState([]);

  const resolvedUrl = useMemo(() => {
    return `${url}&$skip=${skip}`;
  }, [skip, url]);

  const { loading, error } = useQuery(resolvedUrl, null, {
    cache: false,
    onSuccess: ({ data }) => {
      if (data.length === 0) setHasMore(false);
      setResponse((r) => [...r, ...data]);
    }
  });

  const fetchMore = useCallback(() => {
    setSkip((s) => s + TopValueSkip);
  }, []);

  useEndlessScroll({
    listen: hasMore && !loading && !error,
    onFetchMore: fetchMore,
    offsettMultiplier: 0.5,
    pageRef
  });

  return (
    <div
      className={classnames(
        " shadow bg-white rounded",
        styles.graph,
        styles.leafleatGraph
      )}
    >
      <div
        ref={pageRef}
        className={classnames("of-auto h-100", {
          "d-flex flex-column": response.length === 0
        })}
      >
        <div
          style={{ color: "#464646" }}
          className={classnames("fs-20 fw-bold p-3", styles.stickyTitle)}
        >
          Folhetos
        </div>
        {response.length > 0 && (
          <div className={classnames("p-3 pt-0", styles.leafleatContainer)}>
            {response.map((v) => {
              return <LeafleatItem key={v.id} value={v} />;
            })}
          </div>
        )}
        {response.length === 0 && (
          <div className="align-items-center p-3 flex-1 d-flex fw-medium justify-content-center">
            Não existem ou não tem acesso
          </div>
        )}
        {loading && (
          <div className="d-flex justify-content-center py-3">
            <LoaderSpinner size="xxs" className="text-primary" />
          </div>
        )}
      </div>
    </div>
  );
};

const LeafleatListGraph = ({ articleDataId }) => {
  const { advancedFilter } = useContext(AdvancedFilterContext) || {};
  const advancedFilterValue = advancedFilter?.Value;

  const resolvedUrl = useMemo(() => {
    const filters = buildLeafleatFilterQuery(advancedFilterValue, false, true);
    let url = `leaflets?$orderby=DateBegin/Name desc&$top=${TopValueSkip}`;

    if (articleDataId) url += `&articleDataId=${articleDataId}`;
    url += `&${filters}`;
    return url;
  }, [advancedFilterValue, articleDataId]);

  return <InnerLeafleatListGraph key={resolvedUrl} url={resolvedUrl} />;
};

export default LeafleatListGraph;
