import React, { useState } from "react";

import PacksGrid from "./PacksGrid.jsx";

import AddPacksModal from "./AddPacksModal/AddPacksModal";
import ExcelExportButton from "../../../Common/ExcelButton/ExcelExportButton.jsx";
import PlusButton from "../../../Common/PlusButton/PlusButton.jsx";

const Packs = () => {
  const [addingArticleNames, setAddingArticleNames] = useState(false);

  const toggleModal = () => {
    setAddingArticleNames((open) => !open);
  };

  return (
    <>
      <AddPacksModal isOpen={addingArticleNames} onClose={toggleModal} />

      <div className=" h-100 d-flex flex-column main-grid">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div className="fs-24 fw-medium">Formatos Embalagem</div>
          <div className="d-flex flex-1 align-items-center justify-content-end">
            <ExcelExportButton className="mr-3" BaseUrl={"packs/export"} />
            <PlusButton onClick={toggleModal}>Adicionar</PlusButton>
          </div>
        </div>
        <PacksGrid />
      </div>
    </>
  );
};

export default Packs;
