import moment from "moment";
import React, { useContext, useMemo } from "react";
import { AdvancedFilterContext } from "../../Common/Grid/GridHelper";
import { getDate } from "../../Helpers/DateHelper";
import { GraphContainer } from "./Graphs";
import styles from "./Graphs.module.css";
import classnames from "classnames";
import { formatPrice } from "../../Helpers/MiscHelper";

const builder = (data) => {
  const values = data.map((v) => formatPrice(parseInt(v.Price)));

  const dates = data.map((v) => moment(getDate(v.Date)).format("DD/MM/YYYY"));

  return {
    title: {
      text: "Evolução do Preço",
      left: "left"
    },
    tooltip: {
      trigger: "item"
    },
    xAxis: {
      type: "category",
      data: dates
    },
    yAxis: {
      type: "value"
    },
    series: [
      {
        type: "line",
        data: values
      }
    ],
    promoData: data
  };
};

const ArticlePriceEvoGraph = ({ articleDataId }) => {
  const { url, isInvalid, hasArticles } = useMemo(() => {
    if (!articleDataId) return { hasArticles: false };

    const url = `indicators/ArticlePrice/${articleDataId}?$orderby=Date asc&$top=30`;

    return { hasArticles: true, url };
  }, [articleDataId]);

  if (!hasArticles || isInvalid)
    return (
      <div
        style={{ color: "#464646" }}
        className={classnames(
          "p-3 shadow bg-white fw-medium rounded d-flex align-items-center justify-content-center",
          styles.graph
        )}
      >
        {!hasArticles
          ? "Por favor selecione um Artigo"
          : "Por favor selecione apenas um Artigo"}
      </div>
    );

  return <GraphContainer url={url} builder={builder} />;
};

export default ArticlePriceEvoGraph;
