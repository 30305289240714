import { faChevronLeft, faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import classnames from "classnames";
import styles from "./FilterList.module.css";
import { FilterListViewContext, FiltersQueryContext } from "./FilterListHelper";
import { AdvancedFilterContext, GridContext } from "../Grid/GridHelper";

const Item = ({ value, onClick }) => {
  const { Name } = value;

  return (
    <div
      onClick={onClick}
      className={classnames("py-2 px-3 fs-14", styles.filterListChangerItem)}
    >
      {Name}
    </div>
  );
};

const FilterListChanger = ({ ...props }) => {
  console.log(props);
  const { advancedFilter } = useContext(AdvancedFilterContext);
  console.log(advancedFilter);
  const { Id, Name } = advancedFilter;
  const { setView } = useContext(FilterListViewContext);

  const { data } = useContext(FiltersQueryContext);

  const { updateSettings } = useContext(GridContext);

  const handleRemoveFilters = () => {
    updateSettings({
      customFilterId: undefined,
    });
    setView();
  };

  const handleItemSelection = (id) => {
    updateSettings({
      customFilterId: id,
    });
    setView();
  };

  return (
    <>
      <div
        className={classnames(
          "d-flex align-items-center justify-content-between px-3 fw-medium",
          styles.filterListHeader
        )}
      >
        <div
          onClick={() => setView()}
          className={classnames(
            "pr-1 mr-1 d-flex align-items-center",
            styles.filterListHeaderChevron
          )}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>

        <span className="fs-14 fw-medium flex-1">ALTERAR FILTRO</span>
      </div>
      <div className="flex-1 overflow-auto">
        {Id && (
          <div
            onClick={() => handleRemoveFilters()}
            className={classnames(
              styles.filterListChangerSelectedItem,
              "d-inline-flex align-items-center px-3 py-2 mb-2 mx-3 my-2"
            )}
          >
            <span className="mr-2 fs-14">{Name}</span>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        )}

        {data.map((v) => {
          if (v.Id === Id) return null;
          return (
            <Item
              onClick={() => handleItemSelection(v.Id)}
              value={v}
              key={v.Id}
            />
          );
        })}
      </div>
    </>
  );
};

export default FilterListChanger;
