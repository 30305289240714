import React, { useState, useRef, useContext } from "react";

import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import AddPacksModal from "./AddPagesModal/AddPagesModal";
import DeleteModal from "../../../Common/DeleteModalContent/DeleteModalContent";
import BaseGrid from "../../../Common/Grid/Grid";
import { InsertDateFilterCell } from "../../../Leaflets/LeafletsGrid";

const dateCell = ({ dataItem, field }) => {
  const value = dataItem[field];
  return (
    <td>{value === null ? "" : new moment(value).format("DD/MM/YYYY")}</td>
  );
};

const ButtonCell = ({ dataItem }) => {
  const [toggleEdit, toggleRemove] = useContext(ToggleEditionModalsContext);

  const handleEdit = () => {
    toggleEdit(dataItem);
  };

  const handleRemove = () => {
    toggleRemove(dataItem);
  };

  return (
    <td>
      <div className="d-flex align-items-center justify-content-end">
        <div onClick={handleEdit} className="p-2 mr-2 grid-button">
          <FontAwesomeIcon size="sm" icon={faPencil} title="Editar" />
        </div>
        <div onClick={handleRemove} className="p-2 grid-button">
          <FontAwesomeIcon size="sm" icon={faTrashAlt} title="Eliminar" />
        </div>
      </div>
    </td>
  );
};

const ToggleEditionModalsContext = React.createContext();

const PagesGrid = () => {
  const SelectedCARef = useRef();

  const [editingComercialArea, setEditinggComercialArea] = useState(false);

  const toggleEditModal = (data) => {
    if (!editingComercialArea) {
      SelectedCARef.current = data;
    } else {
      SelectedCARef.current = undefined;
    }
    setEditinggComercialArea((open) => !open);
  };

  const [deletingComercialArea, setDeletingComercialArea] = useState(false);

  const toggleDeleteModal = (data) => {
    if (!deletingComercialArea) {
      SelectedCARef.current = data;
    } else {
      SelectedCARef.current = undefined;
    }
    setDeletingComercialArea((open) => !open);
  };

  const columns = [
    {
      title: "",
      minResizableWidth: 120,
      field: "Button",
      headerClassName: "hide-grid-title",
      filterable: false,
      sortable: false,
      width: 90,
      cell: ButtonCell,
      show: true,
    },
    { field: "Code", title: "Código" },
    { field: "Name", title: "Nome" },
    {
      filter: "date",
      filterCell: InsertDateFilterCell,
      title: "Data de Inserção",
      cell: dateCell,
      field: "InsertDate",
    },
  ];

  return (
    <ToggleEditionModalsContext.Provider
      value={[toggleEditModal, toggleDeleteModal]}
    >
      <AddPacksModal
        isOpen={editingComercialArea}
        onClose={toggleEditModal}
        data={SelectedCARef.current}
      />

      <DeleteModal
        isOpen={deletingComercialArea}
        onClose={toggleDeleteModal}
        data={SelectedCARef.current}
        closeModal={toggleDeleteModal}
        url="promotions/pages"
      />

      <BaseGrid Columns={columns} BaseUrl={"promotions/pages"} />
    </ToggleEditionModalsContext.Provider>
  );
};

export default PagesGrid;
